import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import {
    getEntityList,
    getCriteriaList,
    setCatchedError,
} from '../../redux';
import { ValueEditor } from '../../pages/QueryBuilder/components';

const ModalEntryInputForm = ({
    showModal,
    setShowModal,
    handleOK,
    entityCriterias,
    entities,
    criteriaList,
    setCatchedError,
}) => {

    const handleClickOk = () => {
        //required
        const requiredItemList = entities.filter((item) => item.required);
        if (requiredItemList.length > 0) {
            const data = criteriaList.filter(
                (elem) =>
                    _.isEmpty(elem.value) &&
                    _.find(requiredItemList, ['id', elem.id])
            );
            if (data.length > 0) {
                const lineArr = data.map((item) => {
                    return item.id.split('-').length > 0
                        ? item.id.split('-')[0]
                        : '';
                });
                setCatchedError(
                    `Required values must be filled in: ${lineArr.join(',')}`
                );
            } else {
                handleOK();
            }
        } else {
            handleOK();
        }
    };

    return (
        <Modal
            enforceFocus={false}
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title> Enter values</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {entityCriterias.map((item, i) => {
                    return (
                        <Row
                            style={{ alignItems: 'baseline' }}
                            key={`item-${i}`}
                        >
                            <span style={{ paddingRight: '15px' }}>
                                {item.id.split('-')[0]}
                            </span>
                            <div key={`div-${i}`} style={{ width: '70%' }}>
                                <ValueEditor
                                    key={`value-${i}`}
                                    activeAttribute={item}
                                    type={_.get(item, 'type', null)}
                                    enumList={_.get(item, 'enumList', null)}
                                />
                            </div>
                        </Row>
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClickOk}>
                    OK
                </Button>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    entities: getEntityList(state),
    criteriaList: getCriteriaList(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setCatchedError,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalEntryInputForm);
