import { API } from '../../../API';
import store, { addAzureFileList } from '../../../redux';
import { RestHeaders, ResponseRest } from '../../../types';

export const refreshHandler = (): void => {
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers: RestHeaders = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  api
    .getRestMetadataStorageGetFileList({ headers })
    .then((resMetadataFiles) => {
      api.getRestFileStorageGetFileList({ headers }).then((resUserFiles) => {
        const azureList: any[] = [
          ...resMetadataFiles.data,
          ...resUserFiles.data
        ];
        store.dispatch(addAzureFileList(azureList));
      });
    });
};

export const uploadHandler = async (
  file: Blob,
  filename: string | null = null
) => {
  const formData: FormData = new FormData();
  if (!filename) {
    formData.append('file', file);
  } else {
    formData.append('file', file, filename);
  }
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers: RestHeaders = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  try {
    const res: ResponseRest = await api.postRestFileStorageUploadFile({
      headers,
      file: formData
    });
    return { status: true, statusMessage: res.message };
  } catch (err: any) {
    return {
      status: false,
      statusMessage: err
    };
  }
};

export const uploadMetadataHandler = async (
  file: Blob,
  filename: string | null = null
) => {
  const formData: FormData = new FormData();
  if (!filename) {
    formData.append('file', file);
  } else {
    formData.append('file', file, filename);
  }
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers: RestHeaders = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  try {
    const res: ResponseRest = await api.postRestMetadataStorageUploadFile({
      headers,
      file: formData
    });
    return { status: true, statusMessage: res.message };
  } catch (err: any) {
    return {
      status: false,
      statusMessage: err
    };
  }
};

export const deleteHandler = async (fileNameList: string) => {
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers: RestHeaders = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  const res: ResponseRest = await api.postRestFileStorageDeleteFile({
    headers,
    fileNameList: fileNameList
  });
  if (res.code === 200) {
    return { status: true, statusMessage: res.message };
  } else {
    return { status: false, statusMessage: res.message };
  }
};

export const downloadFileHandler = async (fileNameList: string) => {
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers: RestHeaders = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  try {
    const res: Blob = await api.getRestFileStorageDownloadFile({
      headers,
      fileNameList: fileNameList
    });
    return res;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const downloadMetadataFileHandler = async (fileNameList: string) => {
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers: RestHeaders = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  try {
    const res: Blob = await api.getRestMetadataStorageDownloadFile({
      headers,
      fileNameList: fileNameList
    });
    return res;
  } catch (err: any) {
    throw new Error(err);
  }
};
