import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';
import _ from 'lodash';

const ValueMultipleSelect = ({
    value,
    type,
    onChange,
    enumList,
    isDisabled,
}) => {
    const [options, setOptions] = useState([]);
    const [activeOptions, setActiveOptions] = useState([]);

    useEffect(() => {
        if (Array.isArray(value)) {
            const data = value.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            });
            if (data.length) {
                setOptions(data);
                setActiveOptions(data);
            }
        }
    }, []);

    useEffect(() => {
        if (enumList.length) {
            const data = enumList.map((item) => {
                return { value: item, label: item };
            });
            setOptions(data);
        }
    }, [enumList]);

    const handleChange = (newValue, actionMeta) => {
        if (newValue) {
            const data = newValue.map((item) => item.value);
            setActiveOptions(newValue);
            onChange(data);
        } else {
            setActiveOptions('');
            onChange([]);
        }
    };

    return (
        <Select
            isSearchable
            isMulti
            placeholder={'Select values'}
            onChange={handleChange}
            options={options}
            value={activeOptions}
            isDisabled={isDisabled}
        />
    );
};

export default ValueMultipleSelect;
