import React, { useState, Fragment } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useApolloClient } from 'react-apollo';
import { genericQuery } from '../../GraphQL';
import { divideNestedAttribute, convertSearchOptionToText } from '../../utils';

const RelationInputSearch = ({ data, metaData, setRenderedData }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const apolloClient = useApolloClient();
  const attribute = divideNestedAttribute(metaData.attribute);
  const mainAttribute = attribute[0].replace('Relation', '');
  const renderedData = data;

  const getDefaultValue = () => {
    if (data[attribute[0]]) {
      const defaultValue = attribute[1]
        .split(' ')
        .map((field) => {
          return data[attribute[0]] ? data[attribute[0]][field] : '';
        })
        .join(' ');
      return defaultValue;
    }
    return '';
  };
  const [defaultValue, setDefaultValue] = useState(getDefaultValue());

  const getOptions = () => {
    const { dataSource } = metaData;
    const passedFields = attribute[1].split(' ');
    const query = genericQuery(dataSource, passedFields, '', '', 0);
    apolloClient.query({ query }).then((res) => {
      const options = res.data[dataSource].map((option) => {
        return convertSearchOptionToText(option);
      });
      setDropdownOptions(options);
    });
  };

  return (
    <AsyncTypeahead
      isLoading={false}
      id={`select-${metaData.selectFrom}`}
      className='form-group'
      labelKey={'optionText'}
      minLength={0}
      useCache={true}
      onSearch={getOptions}
      options={dropdownOptions}
      defaultInputValue={defaultValue}
      onFocus={getOptions}
      onChange={(selected) => {
        if (selected[0]) {
          renderedData[mainAttribute] = selected[0].id;
          renderedData[`${mainAttribute}Relation`] = selected[0];
          setRenderedData({ ...renderedData });
        }
      }}
      renderMenuItemChildren={(option, props) => {
        return (
          <Fragment>
            <span>{option.optionText}</span>
          </Fragment>
        );
      }}
    />
  );
};

export default RelationInputSearch;
