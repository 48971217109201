import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import { textGenerator, redirectToDetail } from '../../utils';

const MiniGrid = ({
  data,
  metadata,
  fieldsAttributes,
  fetchMore,
  history,
  hasMore,
  setNotesValue,
  filterExpression,
  setFilterExpression,
}) => {
  const [orderRule, setOrderRule] = useState({});
  const [metadatasList] = useLocalStorage('metadatasList');
  const { fields } = metadata;

  const onClickHandler = (e, dataEl) => {
    const tag = e.target.attributes.getNamedItem('data-fieldtype').value;
    if (tag.includes('primaryCdmcommRelation')) {
      const fieldValue = e.target.innerText;
      fieldValue ? setNotesValue(fieldValue) : setNotesValue('');
      setNotesValue(e.target.innerText);
    } else {
      redirectToDetail(metadata.selectForm, dataEl, history, {
        id: dataEl.id,
        fileName: metadata.selectForm,
      });
    }
  };

  const setSorting = (sortBy) => {
    const sortFilters = filterExpression.includes('orderBy')
      ? filterExpression
          .split(',')
          .map((key) => {
            const orderRule = key
              .slice(0, key.indexOf(':'))
              .replaceAll(' ', '');
            if (orderRule === 'orderBy') {
              key = `orderByDesc:${sortBy.attribute}`;
              setOrderRule({ [sortBy.attribute]: 'orderByDesc' });
            }
            if (orderRule === 'orderByDesc') {
              key = `orderBy:${sortBy.attribute}`;
              setOrderRule({ [sortBy.attribute]: 'orderBy' });
            }
            return key;
          })
          .join(',')
      : filterExpression.concat(`,orderBy:${sortBy.attribute}`);
    setFilterExpression(sortFilters);
  };
  return (
    <React.Fragment>
      <div className='table-responsive minigrid-table'>
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={() => {
            if (data.length > 0) {
              fetchMore(data.length);
            }
          }}
          hasMore={hasMore}
        >
          <table className='table '>
            <thead className='fullGrid-thead'>
              <tr>
                {fields.map((field) => (
                  <Col
                    sm={field.defCol}
                    onClick={field.sortable ? () => setSorting(field) : null}
                    style={field.sortable ? { cursor: 'pointer' } : null}
                  >
                    <th>
                      {field.caption}
                      {field.sortable && (
                        <span>
                          {orderRule[field.attribute] ? (
                            orderRule[field.attribute] === 'orderBy' ? (
                              <ArrowUp className='sort-icons' />
                            ) : (
                              <ArrowDown className='sort-icons' />
                            )
                          ) : null}
                        </span>
                      )}
                    </th>
                  </Col>
                ))}
              </tr>
            </thead>
            <tbody
              className='fullGrid-tbody'
              style={
                metadata
                  ? {
                      maxHeight: metadata.maxHeight
                        ? metadata.maxHeight
                        : '300px',
                      minHeight: metadata.maxHeight
                        ? metadata.maxHeight
                        : '10vh',
                    }
                  : { maxHeight: '29rem' }
              }
            >
              {data.length === 0 ? (
                <></>
              ) : (
                data.map((dataEl) => {
                  return (
                    <tr>
                      {fields.map((field) => (
                        <Col sm={field.defCol}>
                          <td
                            className='fullGrid-item-link'
                            data-fieldtype={field.attribute}
                            onClick={(e) => onClickHandler(e, dataEl)}
                          >
                            {textGenerator(dataEl, field)}
                          </td>
                        </Col>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MiniGrid);
