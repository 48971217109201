import React, { useState, useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FileManagerTableRow from './FileManagerTableRow';
import { fileListFormat } from './fileListFormat';
import {
  addAzureFileList,
  getAzureFileList,
  addActiveAzureContainer,
  getActiveAzureContainer
} from '../../../redux';
import {
  useLocalStorage,
  writeStorage,
  deleteFromStorage
} from '@rehooks/local-storage';

const FileManagerTable = (props: any) => {
  const { selectAll, selectedAll, azureFileList, activeAzureContainer } = props;
  const [data, setData] = useState([]);
  const tableRef = useRef<HTMLTableElement>(null);
  const [scrollTopValue, setScrollTop] = useState<number>(0);
  const [scrollTop] = useLocalStorage('file-manager-table-scroll');

  useEffect(() => {
    setScrollTop(parseInt(scrollTop ?? ''));
  }, [scrollTop]);

  useEffect(() => {
    if (activeAzureContainer) {
      const filteredAzureFileList = azureFileList.filter(
        (item: any) => item.name === activeAzureContainer
      );
      if (filteredAzureFileList.length) {
        setData(filteredAzureFileList[0].items);
      }
    } else {
      setData(
        azureFileList.map((item: any) => ({
          name: item.name,
          isDirectory: true
        }))
      );
    }
  }, [azureFileList, activeAzureContainer]);

  useEffect(() => {
    if (tableRef.current && scrollTopValue) {
      setTimeout(() => {
        if (null !== tableRef.current) {
          tableRef.current.scrollTop = scrollTopValue;
          deleteFromStorage('file-manager-table-scroll');
        }
      }, 2000);
    }
  }, [scrollTop, tableRef]);

  const onFileSelect = () => {
    writeStorage('file-manager-table-scroll', tableRef.current?.scrollTop);
  };

  return (
    <React.Fragment>
      <div className='table-responsive'>
        <table ref={tableRef} id='table-fullGrid' className='table fullGrid '>
          <thead className='fullGrid-thead'>
            <tr>
              <Col className='fullGrid-checkbox'>
                <th>
                  <input
                    type='checkbox'
                    id='header-input'
                    className='check-box header-check-box fullGrid-checkbox'
                    onChange={() => selectAll(!selectedAll)}
                    checked={selectedAll}
                    disabled={!activeAzureContainer ? true : false}
                  />
                  <label
                    className='fullGrid-checkbox-label'
                    htmlFor='header-input'
                  ></label>
                </th>
              </Col>
              {fileListFormat.map((col, index) => (
                <Col sm={col.defCol}>
                  <th key={index}>{col.caption}</th>
                </Col>
              ))}
            </tr>
          </thead>
          <tbody className='fullGrid-tbody file-manager-tbody'>
            {data.length > 0
              ? data.map((el: any, i: number) => (
                  <FileManagerTableRow
                    onFileSelect={onFileSelect}
                    el={el}
                    selectedAll={selectedAll}
                    key={`${el.id}-${i}`}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  azureFileList: getAzureFileList(state),
  activeAzureContainer: getActiveAzureContainer(state)
});

export default connect(mapStateToProps)(FileManagerTable);
