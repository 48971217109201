import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import eachDeep from 'deepdash/eachDeep';

import { ModalCreateEntry } from './commonComponents';
import {
    getCriteriaList,
    updateCriteriaItem,
    getEntityList,
    deleteEntityItem,
    updateEntityItem,
} from '../../../redux';

const selectCustomStyles = {
    container: (provided, state) => ({
        ...provided,
        minWidth: '300px',
    }),
};

const QueryCriteriaType = ({
    activeAttribute,
    criterias,
    entities,
    updateCriteriaItem,
    deleteEntityItem,
    updateEntityItem,
    updateEntityInCriterias,
}) => {
    const [activeOption, setActiveOption] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [criteriaType, setCriteriaType] = useState();


    useEffect(() => {
        const emptyEntity = entities.filter((item) => _.isEmpty(item.belongTo));
        if (emptyEntity.length > 0) {
            deleteEntityItem(emptyEntity[0].id);
        }

        const types = [
            { label: 'Enter value', value: 'EnterValue' },
            { label: 'Create entry', value: 'CreateEntry' },
        ];
        const data = entities.map((item) => {
            const val = `${item.id}_${item.entityType}`;
            const label = `Entry:${
                item.id.split('-').length > 0 ? item.id.split('-')[0] : ''
            }(${item.entityType})`;
            return {
                label: label,
                value: val,
            };
        });
        setCriteriaType([...types, ...data]);
    }, [entities]);

    useEffect(() => {
        const activeEntity = entities.filter((item) =>
            item.belongTo.includes(activeAttribute.id)
        );
        if (activeEntity.length > 0) {
            const data = activeEntity.map((item) => {
                const val = `${item.id}_${item.entityType}`;
                const label = `Entry:${item.translation}(${item.entityType})`;
                return {
                    label: label,
                    value: val,
                };
            });
            if (data.length > 0) {
                setActiveOption(data[0]);
            }
        } else {
            if (!_.get(activeAttribute, 'isEntity', true)) {
                setActiveOption({ label: 'Enter value', value: 'EnterValue' });
            }
        }
    }, [activeAttribute, entities]);

    const handleChange = (newValue, actionMeta) => {
        if (newValue) {
            setActiveOption(newValue);
            if (newValue.value === 'CreateEntry') {
                setShowModal(true);
            } else if (newValue.value === 'EnterValue') {
                let activeCriteria;
                eachDeep(
                    _.cloneDeep(criterias),
                    (child, i, parent, ctx) => {
                        if (child.id === activeAttribute.id) {
                            activeCriteria = _.cloneDeep(child);
                        }
                    },
                    { childrenPath: 'children' }
                );
                updateCriteriaItem({
                    ...activeCriteria,
                    isEntity: false,
                });
                updateEntityInCriterias();
                deleteEntityItem(activeAttribute.id);
            } else {
                let activeCriteria;
                eachDeep(
                    _.cloneDeep(criterias),
                    (child, i, parent, ctx) => {
                        if (child.id === activeAttribute.id) {
                            activeCriteria = _.cloneDeep(child);
                        }
                    },
                    { childrenPath: 'children' }
                );

                if (_.isEmpty(activeCriteria)) {
                    const newId = newValue.value.split('_').length
                        ? newValue.value.split('_')[0]
                        : '';
                    const activeEntity = entities.filter(
                        (item) => item.id === newId
                    );
                    if (activeEntity.length > 0) {
                        const deletedEntity = entities.filter((item) =>
                            item.belongTo.includes(activeCriteria.id)
                        );

                        if (deletedEntity.length) {
                            const deleteBelongTo = deletedEntity[0].belongTo.slice();
                            _.remove(deleteBelongTo, (item) => {
                                return item === activeCriteria.id;
                            });
                            updateEntityItem({
                                ...deletedEntity[0],
                                belongTo: deleteBelongTo,
                            });
                        }
                        updateEntityItem({
                            ...activeEntity[0],
                            belongTo: _.uniq([
                                ...activeEntity[0].belongTo,
                                ...[activeCriteria.id],
                            ]),
                        });
                    }
                }
            }
        }
    };

    return (
        <Fragment>
            <ModalCreateEntry
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <Select
                isSearchable
                placeholder={'Select type '}
                onChange={handleChange}
                options={criteriaType}
                value={activeOption}
                styles={selectCustomStyles}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    criterias: getCriteriaList(state),
    entities: getEntityList(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateCriteriaItem,
            deleteEntityItem,
            updateEntityItem,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryCriteriaType);
