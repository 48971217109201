import React, { useEffect, useState, Fragment } from 'react';
import { Dropbox } from 'dropbox';
import { Button } from 'react-bootstrap';

const DropboxLogin = (props: any) => {
  const dbx: Dropbox = new Dropbox({
    clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID
  });

  const [authUrl, setAuthUrl] = useState<string>('');

  useEffect(() => {
    (async () => {
      //@ts-ignore
      let tmpAuthUrl: string = await dbx.auth.getAuthenticationUrl(
        process.env.REACT_APP_DROPBOX_REDIRECT_URI,
        undefined,
        'code',
        'offline',
        undefined,
        undefined,
        false
      );
      setAuthUrl(tmpAuthUrl);
    })();
  }, []);

  const redirectToDropboxAuth = async (): Promise<void> => {
    const newWindow = window.open(authUrl, '_parent', 'width=500,height=600');
  };

  return (
    <Fragment>
      <Button onClick={redirectToDropboxAuth}>Dropbox</Button>
    </Fragment>
  );
};

export default DropboxLogin;
