import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { TFieldsValues, IFormField } from '../types';
import { genericQuery } from '../GraphQL';
import { viewMapTemplate } from '../pages/DetailsPage/functions';

import {
  getLocalStorageItem,
  getRenderedMeta,
  updateFormatList
} from '../utils';

interface ISPPLSegment {
  apolloClient: any;
  history: RouteComponentProps['history'];
  setAlertBarOptions: (AlertBarOptions: TFieldsValues) => void;
  selectedItems: TFieldsValues[];
}

export class SPPLSegment {
  private selectedItems;
  private apolloClient;
  private history;
  private setAlertBarOptions;

  constructor(classProps: ISPPLSegment) {
    this.apolloClient = classProps.apolloClient;
    this.history = classProps.history;
    this.setAlertBarOptions = classProps.setAlertBarOptions;
    this.selectedItems = classProps.selectedItems;
  }

  capabilityShowCompanies = async () => {
    const { id } = this.selectedItems[0];

    await updateFormatList(
      'cdmcomps',
      'cdmcomps',
      getLocalStorageItem('tableFormatList')
    );
    await updateFormatList(
      'cdmcomps',
      'cdmcomps.Standard',
      getLocalStorageItem('tableFormatList')
    );
    await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      'Frm.CompanyOverview.json'
    );
    this.history.push('/CompanyOverview');
  };

  capabilityOpenMap = () => {
    const { apolloClient, selectedItems, setAlertBarOptions } = this;
    const { id } = selectedItems[0];
    const query = genericQuery(
      'spplSegment2Companys',
      [
        `primaryCompanyRelation{
          geolocationLat 
          geolocationLon 
          customer
          supplier
          alias
          name
        name2
        addr1
        addr2
        primaryPostCodeRelation{postCode town}
        country:primCounRelation{code head}}`
      ],
      `primarySegment:"${id}"`
    );
    apolloClient.query({ query }).then(async (res: any) => {
      const fitlerResults = res.data.spplSegment2Companys.filter(
        (item: TFieldsValues) => item.primaryCompanyRelation !== null
      );
      const cdmcomps = fitlerResults.map((item: TFieldsValues) => {
        return { ...item.primaryCompanyRelation };
      });

      await viewMapTemplate(
        { cdmcomps },
        {
          apolloClient,
          setCatchedError: (alertText: string) => {
            setAlertBarOptions({
              show: true,
              alertText,
              variant: 'warning'
            });
          }
        }
      );
    });
  };
}
