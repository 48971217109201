import _ from 'lodash';
import { IFormFullGrid, IFormField } from '../../types';
import { updateFormatList } from '../../utils';

export const getQueryFields = (
  formatFields: any,
  additionalAttributes: Array<string>,
) => {
  if (!formatFields || !formatFields.fields) {
    return [];
  }
  const queryFields = formatFields.fields.map(
    (field: IFormField) => field.attribute,
  );
  if (additionalAttributes) {
    const fieldsToString = queryFields.join(' ');
    additionalAttributes.map((attr) => {
      if (!fieldsToString.includes(` ${attr}`)) queryFields.push(attr);
    });
  }
  return queryFields;
};

export const selectFormat = async (
  caption: string,
  fullGridFormats: { formats: Array<any> },
  tableFormatList: any,
  setFields: Function,
  setSelectedFullGridFormat: Function,
  setCatchedError: Function,
  fullGrid: IFormFullGrid,
  setFormatName: Function,
  setQueryDataSource: Function
) => {
  const { dataSource, additionalAttributes } = fullGrid;
  let selectedFormat;
  const selectedFormatObject = fullGridFormats.formats.find(
    (format: { caption: string }) => format.caption === caption,
  );
  const findFormatName = selectedFormatObject.selectFrom
    .replace(`lst.`, '')
    .replace('.json', '');
  if (!_.find(tableFormatList, ['formatName', findFormatName])) {
    try {
      const tableFormatListResult = await updateFormatList(
        dataSource,
        findFormatName,
        tableFormatList,
      );
      const selectedFormat = _.find(tableFormatListResult, [
        'formatName',
        findFormatName,
      ]);   
      setFields(
        selectedFormat.fields.map((field: IFormField) => field.attribute),
      ); 
      setQueryDataSource(selectedFormat.dataSource || dataSource);
      
      setSelectedFullGridFormat(selectedFormat);
      setFields(getQueryFields(selectedFormat, additionalAttributes || []));
      setFormatName(findFormatName);
    } catch (err) {
      console.log(err);
      setCatchedError(err.message);
    }
  } else {
    selectedFormat = _.find(tableFormatList, ['formatName', findFormatName]);
    setFields(
      selectedFormat.fields.map((field: IFormField) => field.attribute),
    );
    setQueryDataSource(selectedFormat.dataSource || dataSource);
    setSelectedFullGridFormat(selectedFormat);
    setFields(getQueryFields(selectedFormat, additionalAttributes || []));
    setFormatName(findFormatName);
  }
};
