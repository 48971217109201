import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CustomModal = ({ type, message, closeHandler}) => {
  return (
    <div className={`alert-modal ${type}`}>
      <Modal.Dialog>
        <Modal.Header>
          <h4>{type.toUpperCase()}</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeHandler}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={() => window.location.reload(false)}
          >
            Retry
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

export default CustomModal;
