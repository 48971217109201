import React, { useEffect, useState } from 'react';

const NotesField = ({ layoutMeta, defaultElement, notesValue }) => {
  const [notesInnerText, setInnerText] = useState('');

  useEffect(() => {
    if (layoutMeta) {
      setInnerText(notesValue);
    }
  }, [notesValue]);

  return <div className={'details-notes-textArea'}>{notesInnerText}</div>;
};

export default NotesField;
