import React from 'react';
import { useHistory } from 'react-router';
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  AvInput,
  AvGroup,
  AvFeedback //@ts-ignore
} from 'availity-reactstrap-validation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import _ from 'lodash';
import { TextEditor } from '../..';
import { EventType } from '../../../types';
import {
  formatTimeInput,
  alignTypedFields,
  formatDateInput,
  applyFormatType,
  getLocalStorageItem
} from '../../../utils';
const locales: { [key: string]: any } = require('date-fns/locale');

const InputRender = ({
  caption,
  className,
  defCol,
  keyProp,
  defaultValue,
  onChange,
  field,
  renderedData,
  setRenderedData,
  renderAlertBar,
  printHandlerButtons,
  editField,
  itemId,
  disabled
}: any) => {
  const history = useHistory();
  const localUserData = getLocalStorageItem('localUserData');
  const localeIso = localUserData
    ? localUserData.primLangRelation?.isoCode.toLowerCase()
    : 'enGB';
  const attribute =
    field.attribute && field.attribute.includes('primaryCdmcommRelation')
      ? 'primaryCdmcommRelation'
      : field.attribute;
  const dateLimitation = new Date().setFullYear(new Date().getFullYear() - 10);
  const {
    inputType,
    type,
    captionSize,
    validationError,
    validation,
    selectOptions,
    measuredIn,
    fieldText,
    dataSource
  } = field;
  const fieldIsTextArea =
    type === 'textAreaPlain' || inputType === 'textAreaPlain';

  const renderInput = () => {
    if (inputType === 'document') {
      const fileName = defaultValue
        ? `${itemId.substring(0, 8)}.${defaultValue}`
        : '';
      return (
        <Form.Control
          type={'text'}
          className={'rendered-field_document'}
          onDoubleClick={() => history.push(`?doc-${fileName}`)}
          value={fileName}
        />
      );
    }
    if (inputType === 'date') {
      const defaultFormatDateValue = formatDateInput(defaultValue);
      const dateArray =
        defaultFormatDateValue && defaultFormatDateValue.split('-');
      const dateString =
        dateArray && `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
      const dateParts = dateString?.split('-');
      const dateObject =
        dateParts &&
        new Date(dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1]);
      return (
        <DatePicker
          //@ts-ignore
          selected={
            defaultValue &&
            defaultFormatDateValue !== 'Invalid date' &&
            dateString
              ? dateObject
              : null
          }
          dateFormat={'dd-MM-yyyy'}
          locale={locales[localeIso]}
          disabled={disabled}
          onChange={(date: any) => {
            const e = document.createEvent('event');
            onChange({ ...e, target: { value: formatDateInput(date) } });
          }}
          onBlur={(e: EventType) => {
            e.target.value = formatDateInput(e.target.value, () =>
              renderAlertBar(true, 'Invalid date format', 'danger')
            );
            if (formatDateInput(defaultValue)) {
              e.target.value !== formatDateInput(defaultValue) && onChange(e);
            }
          }}
        />
      );
    }
    if (inputType === 'time') {
      return (
        <>
          <Form.Control
            className={`form-control ${className}`}
            disabled={disabled}
            defaultValue={formatTimeInput(defaultValue)}
            onBlur={(e: EventType) => {
              e.target.value = formatTimeInput(e.target.value, () =>
                renderAlertBar(true, 'Invalid time format', 'danger')
              );
              e.target.value !== formatTimeInput(defaultValue) && onChange(e);
            }}
          />
        </>
      );
    } else {
      const formatDefaultValue = applyFormatType(
        defaultValue,
        inputType,
        attribute
      );
      return (
        <AvInput
          key={`input-form-${keyProp}`}
          name={`${inputType}-${caption}` || `${inputType}-${attribute}`}
          style={{
            textAlign: alignTypedFields(inputType),
            textDecoration: field.inputType === 'link' ? 'underline' : 'none'
          }}
          type={inputType || 'text'}
          errorMessage={validationError}
          validate={{
            required: {
              value: validation ? true : false
            }
          }}
          format={inputType === 'time' ? 'HH:mm' : ''}
          disabled={disabled}
          className={`form-control ${className}`}
          value={formatDefaultValue}
          onChange={onChange}
          onDoubleClick={() =>
            field.form &&
            history.push({
              pathname: `${field.form}-${renderedData.id}`,
              state: { dataSource }
            })
          }
          min={
            inputType === 'date'
              ? attribute === 'endDate'
                ? renderedData.startDate
                : dateLimitation
              : null
          }
        >
          {selectOptions &&
            selectOptions.map((selectOption: any) => (
              <option>{selectOption}</option>
            ))}
        </AvInput>
      );
    }
  };

  const renderFields = () => {
    if (fieldIsTextArea) {
      if (attribute.includes('Mth:')) {
        return (
          <div
            className={`form-control mth_textAreaPlain`}
            dangerouslySetInnerHTML={{ __html: defaultValue }}
          ></div>
        );
      }
      const textDefaultValue =
        renderedData['primaryCdmcommRelation'] &&
        renderedData['primaryCdmcommRelation'].find(
          (note: { com: string }) => note.com === field.com
        );
      return (
        <div style={{ paddingRight: !field.com ? '30px' : '' }}>
          {field.mail ||
          (field.com &&
            (renderedData.hasOwnProperty('primaryCdmcommRelation') ||
              window.location.pathname.includes('-create'))) ? (
            <TextEditor
              editingBar={
                field?.com === '12' || field?.com === '2' || field.mail
                  ? true
                  : false
              }
              field={field}
              text={{
                attribute,
                value: textDefaultValue || {
                  txt: ''
                }
              }}
              disabled={disabled}
              renderedValues={renderedData}
              setRenderedValues={setRenderedData}
              editField={editField}
            />
          ) : (
            <Form.Control
              as='textarea'
              className={`form-control ${className}_textAreaPlain`}
              disabled={disabled}
              rows={field?.defCol || 4}
              style={{ maxHeight: '200px' }}
              value={defaultValue}
              onChange={onChange}
            />
          )}
        </div>
      );
    } else if (caption) {
      return (
        <AvGroup>
          <Row>
            <Col sm={measuredIn ? 8 : 12}>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  field.tipText || field.tooltip ? (
                    <Tooltip id='tooltip'>
                      {field.tipText || field.tooltip}
                    </Tooltip>
                  ) : (
                    <span></span>
                  )
                }
              >
                {renderInput()}
              </OverlayTrigger>
            </Col>
            {measuredIn && <Col>{measuredIn}</Col>}
          </Row>
          <AvFeedback>
            {inputType === 'date'
              ? `Date value must not be less than ${moment(
                  dateLimitation
                ).format('MMM DD YYYY')}`
              : 'This field is invalid'}
          </AvFeedback>
        </AvGroup>
      );
    }
  };
  return (
    <Row key={keyProp} className='editData-row'>
      {caption && (
        <Col
          sm={attribute ? captionSize - 2 || 4 : 1}
          style={{ alignSelf: fieldIsTextArea ? 'start' : 'center' }}
        >
          {caption}
        </Col>
      )}
      {attribute && (
        <Col
          sm={caption ? defCol : 11}
          style={{
            width: window.innerWidth < 768 && !fieldIsTextArea ? '85%' : '100%'
          }}
        >
          {renderFields()}
        </Col>
      )}
      {fieldText && (
        <Col
          sm={attribute ? 12 : 7}
          className={attribute && 'rendered-field-text'}
        >
          {fieldText}
        </Col>
      )}
      {printHandlerButtons(field)}
    </Row>
  );
};

export default InputRender;
