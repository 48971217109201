import React, { Fragment, useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import _ from 'lodash';

const ValueBetween = ({ value, type, onChange, isDisabled }) => {
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');

    useEffect(() => {
        if (Array.isArray(value) && value.length) {
            setFromValue(value[0]);
            setToValue(value[1]);
        }
    }, []);

    useEffect(() => {
        onChange([fromValue, toValue]);
    }, [fromValue, toValue]);

    const handleChange = (e) => {
        if (e.target.name === 'from') {
            setFromValue(e.target.value);
        } else if (e.target.name === 'to') {
            setToValue(e.target.value);
        }
    };

    return (
        <Fragment>
            <Form.Group>
                <Row>
                    <Form.Label column sm={3}>
                        from
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type={type}
                            name="from"
                            value={fromValue}
                            onChange={(e) => handleChange(e)}
                            disabled={isDisabled}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Form.Label column sm={3}>
                        to
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type={type}
                            name="to"
                            value={toValue}
                            onChange={(e) => handleChange(e)}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </Fragment>
    );
};

export default ValueBetween;
