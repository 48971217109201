import {
  ADD_DATA,
  ADD_FRM,
  SET_SORTINGDATA,
  CLEAR_DATA,
  SET_NAVIGATION_VISIBILITY,
  SET_FETCH_FUNCTION,
  SELECT_ITEM,
  SELECT_ALL,
  UNSELECT_ITEM,
  UNSELECT_ALL,
  SET_DETAILS,
  SET_DOC_PREVIEW,
  ADD_CAPABILITY_PARAMS
} from '../types';

const initialState = {
  data: {},
  FRM: { dataSource: '', fields: [] },
  sortingData: { prop: '', by: '' }, //by asc desc or ''
  fetchData: () => {},
  isNavigationVisible: false,
  selected: [],
  details: null,
  docPreview: {
    show: false,
    head: ' ',
    docExt: '',
    blob: new Blob()
  },
  capabilityParams: {
    form: {},
    step: 0,
    method: '',
    dataSource: '',
    accessTokenRequired: false,
    args: {},
    ids: [],
    accessToken: null,
    action: '',
    cvrRegisterInfo: []
  }
};

const addData = (state, payload) => ({
  ...state,
  data: { ...state.data, ...payload }
});

const addFrm = (state, payload) => ({
  ...state,
  FRM: payload
});

const setDetails = (state, payload) => {
  return {
    ...state,
    details: payload
  };
};

const setSortingData = (state, payload) => ({
  ...state,
  sortingData: {
    prop: payload,
    by: !state.sortingData.by || state.sortingData.by === 'dec' ? 'asc' : 'dec'
  }
});

const setNavigationVisibility = (state) => ({
  ...state,
  isNavigationVisible: !state.isNavigationVisible
});

const setFetchFunction = (state, payload) => ({
  ...state,
  fetchData: payload
});

const clearData = (state) => ({
  ...state,
  data: {},
  scroll: 0,
  isNavigationVisible: false
});

const selectItem = (state, payload) => ({
  ...state,
  selected: [...state.selected, payload]
});
const selectAll = (state, payload) => ({
  ...state,
  selected: payload
});

const unselectItem = (state, payload) => ({
  ...state,
  selected: state.selected.filter((el) => el.id !== payload)
});

const unselectAll = (state, payload) => ({
  ...state,
  selected: []
});

const setDocPreview = (state, payload) => ({
  ...state,
  docPreview: payload
});

const addCapabilityParams = (state, payload) => ({
  ...state,
  capabilityParams: { ...state.capabilityParams, ...payload}
});

const handlers = {
  [ADD_DATA]: addData,
  [ADD_FRM]: addFrm,
  [SET_SORTINGDATA]: setSortingData,
  [CLEAR_DATA]: clearData,
  [SET_NAVIGATION_VISIBILITY]: setNavigationVisibility,
  [SET_FETCH_FUNCTION]: setFetchFunction,
  [SELECT_ITEM]: selectItem,
  [SELECT_ALL]: selectAll,
  [UNSELECT_ITEM]: unselectItem,
  [UNSELECT_ALL]: unselectAll,
  [SET_DETAILS]: setDetails,
  [SET_DOC_PREVIEW]: setDocPreview,
  [ADD_CAPABILITY_PARAMS]: addCapabilityParams
};

export default (state = initialState, action) => {
  const handler = handlers[action.type];
  const newState = handler ? handler(state, action.payload) : state;
  return newState;
};
