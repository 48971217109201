import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory, withRouter, Link } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import { Row, Col, Card, CardBody, Alert, Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import _ from 'lodash';
import {
  useLocalStorage,
  writeStorage,
  deleteFromStorage
} from '@rehooks/local-storage';

import { MsAuthModule } from '../../helpers/AuthLogic/msAuth';
import { googleInit, googleSignIn } from '../../helpers/AuthLogic/googleAuth';
import {
  loadUser,
  authorizationType,
  setCatchedError,
  subscriptionDB,
  addCurrentDB,
  setShowDropboxLogin
} from '../../redux';
import { login, genericQuery } from '../../GraphQL';
import {
  clearCache,
  getDefaultLocale,
  updateMetadataList,
  isIOS,
  setLocalStorageItem
} from '../../utils';
import windows_logo from '../../assets/photos/windows_logo.png';
import google_logo from '../../assets/photos/google_logo.svg';
import logoDark from '../../assets/photos/logoDark_sm.png';
import bg from '../../assets/photos/bg.jpg';
import loaderImg from '../../assets/photos/loader.gif';

const Authorization = (props) => {
  const authModule = new MsAuthModule();
  const [locale, setLocale] = useState({});
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [metadatasList] = useLocalStorage('metadatasList');
  const [msToken] = useLocalStorage('msToken');
  const [isLoginInProgress] = useLocalStorage('isLoginInProgress');
  const metadataNameList =
    'Mnu.main.json,Frm.Lookups.json,Dlg.DropboxLogin.json';

  useEffect(() => {
    (async () => {
      await googleInit();
      setLocale(getDefaultLocale());
    })();
    authModule.loadAuthModule();
  }, []);

  useEffect(() => {
    (async () => {
      if (msToken) {
        const loginMutation = await login(msToken, 'ms');
        apolloClient
          .mutate({ mutation: loginMutation })
          .then(async (res) => {
            const data = res.data[Object.keys(res.data)[0]];
            if (data) {
              try {
                const successMetadata = await updateMetadataList(
                  metadataNameList,
                  [],
                  `Bearer ${data.token}`,
                  'ms',
                  false
                );
                if (successMetadata) {
                  localStorage.setItem('id', data.id);
                  localStorage.setItem('authEmail', data.email);
                  localStorage.setItem('authType', 'ms');
                  localStorage.setItem('token', data.token);
                  localStorage.setItem(
                    'expDate',
                    new Date(data.expDate * 1000)
                  );
                  localStorage.setItem(
                    'userName',
                    `${data.firstN} ${data.lastN}`
                  );
                  localStorage.setItem('permission', data.permission);
                  writeStorage('dropboxAuth', data.isDropboxAuth);
                  writeStorage('fileStorage', data.fileStorage);
                  props.authorizationType('ms');
                  props.loadUser({
                    email: data.email,
                    userName: `${data.firstN} ${data.lastN}`,
                    id: data.id
                  });
                  props.subscriptionDB(JSON.parse(data.subscriptionDBs));
                  props.addCurrentDB(data.currentDB);
                  deleteFromStorage('msToken');
                  if (data.fileStorage === 'Dropbox' && !data.isDropboxAuth) {
                    props.setShowDropboxLogin({
                      show: true,
                      message: data.dropboxMessage
                    });
                  }
                  writeStorage('isLoginInProgress', false);
                  history.push('/SalesHome');
                }
              } catch (err) {
                writeStorage('isLoginInProgress', false);
                setCatchedError(`${err.message}`);
                clearCache();
              }
            } else {
              writeStorage('isLoginInProgress', false);
              history.push('/contact-with-admin');
            }
          })
          .catch((err) => {
            writeStorage('isLoginInProgress', false);
            let message;
            if (
              err.message.includes('Unexpected token') ||
              err.message.includes('Failed to fetch')
            ) {
              message = locale.ServerTimeOutError;
            } else {
              message = err.message;
            }
            props.setCatchedError(
              `${locale.TXTConnectionErrorDescription} ${message}`
            );
            clearCache();
          });
      }
    })();
  }, [msToken]);

  const handleValidSubmit = (event, values) => {
    // props.loginUser(values, props.history);
    console.log(values);
  };

  const handleAuth = async (type) => {
    switch (type) {
      case 'google':
        const googleCode = await googleSignIn();
        writeStorage('isLoginInProgress', true);
        const loginMutation = await login(
          _.get(googleCode, 'code', null),
          'google'
        );
        apolloClient
          .mutate({ mutation: loginMutation })
          .then(async (res) => {
            const data = res.data[Object.keys(res.data)[0]];
            if (data) {
              try {
                const successMetadata = await updateMetadataList(
                  metadataNameList,
                  [],
                  `Bearer ${data.token}`,
                  'google',
                  false
                );
                if (successMetadata) {
                  localStorage.setItem('id', data.id);
                  localStorage.setItem('authEmail', data.email);
                  localStorage.setItem('authType', 'google');
                  localStorage.setItem('token', data.token);
                  localStorage.setItem('expDate', new Date(data.expDate));
                  localStorage.setItem(
                    'userName',
                    `${data.firstN} ${data.lastN}`
                  );
                  writeStorage('dropboxAuth', data.isDropboxAuth);
                  writeStorage('fileStorage', data.fileStorage);
                  localStorage.setItem('permission', data.permission);
                  props.authorizationType('google');
                  props.loadUser({
                    email: data.email,
                    userName: `${data.firstN} ${data.lastN}`,
                    id: data.id
                  });
                  props.subscriptionDB(JSON.parse(data.subscriptionDBs));
                  props.addCurrentDB(data.currentDB);
                  if (data.fileStorage === 'Dropbox' && !data.isDropboxAuth) {
                    props.setShowDropboxLogin({
                      show: true,
                      message: data.dropboxMessage
                    });
                  }
                  writeStorage('isLoginInProgress', false);
                  history.push('/SalesHome');
                }
              } catch (err) {
                writeStorage('isLoginInProgress', false);
                console.log('err', err);
                setCatchedError(`${err.message}`);
                clearCache();
              }
            } else {
              writeStorage('isLoginInProgress', false);
              history.push('/contact-with-admin');
            }
          })
          .catch((err) => {
            writeStorage('isLoginInProgress', false);
            let message;
            if (
              err.message.includes('Unexpected token') ||
              err.message.includes('Failed to fetch')
            ) {
              message = locale.ServerTimeOutError;
            } else {
              message = err.message;
            }
            props.setCatchedError(
              `${locale.TXTConnectionErrorDescription} ${message}`
            );
            clearCache();
          });
        break;
      case 'ms':
        authModule.login();
        break;
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      {isLoginInProgress ? (
        <div className='loading-container'>
          <div className='loading'>
            <img src={loaderImg}></img>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div
            className='accountbg'
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${bg})`
            }}
          ></div>
          <div className='wrapper-page account-page-full'>
            <Card className='shadow-none'>
              <div className='card-block card-block_auth'>
                <div className='account-box'>
                  <div className='card-box shadow-none p-4'>
                    <div className='p-2'>
                      <div className='text-center mt-4'>
                        <img
                          className='auth-logo'
                          src={logoDark}
                          height='22'
                          alt='logo'
                        />
                      </div>

                      <h4 className='font-size-18 mt-5 text-center'>
                        {locale.TXTPageHeader}
                      </h4>
                      <p className='text-muted text-center'>
                        {locale.TXTPageHeaderSecondary}
                      </p>

                      <AvForm className='mt-4' action='#'>
                        <div className='form-group'>
                          <label htmlFor='username'>{locale.TXTUserName}</label>
                          <AvField
                            id='username'
                            name='username'
                            className='form-control'
                            errorMessage={locale.TXTUsernameValidation}
                            validate={{
                              required: { value: true }
                            }}
                            type={'text'}
                            placeholder={locale.EnterUsername}
                          />
                        </div>

                        <div className='form-group'>
                          <label htmlFor='userpassword'>
                            {locale.TXTUserPassword}
                          </label>
                          <AvField
                            id='userpassword'
                            name='userpassword'
                            className='form-control'
                            errorMessage={locale.TXTPasswordValidation}
                            validate={{
                              required: { value: true }
                            }}
                            type={'password'}
                            placeholder={locale.EnterPassword}
                          />
                        </div>

                        <Row className='form-group auth-form-group '>
                          <Col sm={6}>
                            <div className='custom-control custom-checkbox'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id='customControlInline'
                              />
                              <label
                                className='custom-control-label'
                                htmlFor='customControlInline'
                              >
                                {locale.TXTLoginDialogRememberPassword}
                              </label>
                            </div>
                          </Col>

                          <Col sm='6' className='text-right'>
                            <button
                              className='btn btn-primary w-md waves-effect waves-light'
                              type='submit'
                            >
                              {locale.TXTLogInButton}
                            </button>
                          </Col>
                        </Row>

                        <Row className='form-group mt-2 mb-0'>
                          <div className='col-12 mt-2'>
                            <Link to='pages-recoverpw-2'>
                              <i className='icon-security_role'></i>{' '}
                              {locale.TXTForgotPassword}
                            </Link>
                          </div>
                        </Row>
                      </AvForm>
                      <div className='button_wrapper mt-2'>
                        <div
                          className='google-button'
                          onClick={() => handleAuth('google')}
                        >
                          <img src={google_logo} alt='' />
                          <span>{locale.SignInGoogle}</span>
                        </div>
                        <div style={{ width: 100, height: 30 }} />
                        <div
                          className='windows-button'
                          onClick={() => handleAuth('ms')}
                        >
                          <img src={windows_logo} alt='' />
                          <span>{locale.SignInMicrosoft}</span>
                        </div>
                      </div>
                      <div className='mt-5 pt-4 text-center'>
                        <p>
                          {locale.SignupMessage}
                          <Link
                            to='/auth'
                            className='font-weight-medium text-primary'
                            style={{ marginLeft: '3px' }}
                          >
                            {locale.SignupLink}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.authorization.user,
  authType: state.authorization.authType
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadUser,
      authorizationType,
      setCatchedError,
      subscriptionDB,
      addCurrentDB,
      setShowDropboxLogin
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
