import React from 'react';
import { updateMetadataList, getLocalStorageItem } from '../../utils';

class ErrorBoundary extends React.Component<
  { hasErrorDefault?: boolean },
  { hasError: boolean; errorTxt: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: this.props.hasErrorDefault || false,
      errorTxt: ''
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  async componentDidMount() {
    const metadatasList = getLocalStorageItem('metadatasList');
    if (
      !metadatasList.find(
        (meta: { fileName: string }) =>
          meta.fileName === 'Error.DocPreview.json'
      )
    ) {
      await updateMetadataList('Error.DocPreview.json', metadatasList);
    }
    const errorTxt = getLocalStorageItem('metadatasList').find(
      (meta: { fileName: string }) => meta.fileName === 'Error.DocPreview.json'
    ).data.form.caption;
    this.setState({ ...this.state, errorTxt });
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          <strong>{this.state.errorTxt}</strong>
        </p>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
