import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button, Col, Row, Table } from 'react-bootstrap';
import _ from 'lodash';

const ModalTable = ({
    listField,
    queryResult,
    showModal,
    setShowModal,
    setQueryResult,
}) => {
    const handleClose = () => {
        setQueryResult([]);
        setShowModal(!showModal);
    };

    const renderTableItem = (obj) => {
        const values = [];
        getProp(obj);

        function getProp(o) {
            for (let key in o) {
                if (key !== '__typename') {
                    if (o[key] !== null && typeof o[key] === 'object') {
                        getProp(o[key]);
                    } else {
                        values.push({ key: key, value: o[key] });
                    }
                }
            }
        }
        return values.map((item, i) => {
            return (
                <td key={`class-${item.key}-${i}`}>
                    {item.value === null ? ' ' : item.value}
                </td>
            );
        });
    };

    return (
        <Modal
            enforceFocus={false}
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Query result</Modal.Title>
            </Modal.Header>
            <Modal.Body className="query-result-table">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {listField.map((item, ind) => (
                                <th key={`item-${item}-${ind}`}>
                                    {item.replace('{', '.').replace('}', '')}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {queryResult
                            ? queryResult.map((item, i) => {
                                  return (
                                      <tr key={`option-item-${i}`}>
                                          {renderTableItem(item)}
                                      </tr>
                                  );
                              })
                            : null}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalTable;
