import React, { Fragment, useEffect, useState, FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useApolloClient } from 'react-apollo';
import _ from 'lodash';
import {
  getUser,
  getDetails,
  setCatchedError,
  setDocPreview,
  getSelectedData
} from '../../../../redux';
import { NotesField } from '..';
import { getItemId } from '../../functions';
import { FullGrid } from '../../..';
import {
  genericQuery,
  genericMutation,
  deleteMutation
} from '../../../../GraphQL';
import { toggleButtonStatus } from '../../../../components/GenericToolbar/functions';
import {
  SelectionModal,
  FileDropzone,
  AlertBar,
  DocumentPreview,
  IconWrapper
} from '../../../../components';
import { API } from '../../../../API';
import { GridClass } from '../../../../classes';
import { IMiniGrid, IFormField, AlertBarOptions } from '../../../../types';
import { downloadFileHandler } from '../../../FileManagerAzure/components/handlers';
import * as toolbarHandlers from '../../../../components/GenericToolbar/handlers';

const DetailsMiniGrid: FC<IMiniGrid> = ({
  layoutMeta,
  user,
  details,
  setCatchedError,
  docPreview,
  setDocPreview,
  selectedData
}: any) => {
  const itemId = getItemId();
  const [renderedData] = useState([]);
  const [selectedFile] = useState();
  const [fullGridKey, setFullGridKey] = useState(1);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [notesValue, setNotesValue] = useState('');
  const [miniGridFields, setMiniGridFields] = useState<IFormField[] | any[]>(
    []
  );
  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });
  const [selectionModal, setSelectionModal] = useState<any>({
    show: false
  });

  const [filteredId, setFilteredId] = useState(itemId);
  const apolloClient = useApolloClient();
  const miniGrid = layoutMeta ? layoutMeta.miniGrid : null;
  const dataSource = miniGrid ? miniGrid.dataSource : '';
  const gridClass = new GridClass(miniGrid || { dataSource: '' }, user);

  const [filterExpression, setFilterExpression] = useState(
    miniGrid?.parentRelation ? null : gridClass.getFilterExpression(filteredId)
  );

  const buttonClickHandler = (miniGridHandler: string | undefined) => {
    if (miniGridHandler) {
      const handler = require(`../../handlers`)[miniGridHandler];
      if (handler) {
        handler({ selectedData, filterExpression, apolloClient });
      } else {
        const methodPayload = miniGridHandler.split('.');
        toolbarHandlers.openModalHandler({
          buttonItem: {
            class: methodPayload[0],
            method: miniGridHandler
          },
          setSelectionModal,
          selectedData: [{ id: itemId }],
          setAlertBarOptions
        });
      }
    }
  };
  const loadFileHandler = (
    file: any,
    docDataSource: string,
    fileName: string[]
  ) => {
    const setActivityStatus = (createdId: string) => {
      const activityStatusUpdate = genericMutation(
        'cdmacti',
        'update',
        `id:"${createdId}" done:true`
      );
      apolloClient.mutate({ mutation: activityStatusUpdate }).catch((err) => {
        setCatchedError(err);
      });
    };
    const defaultMutatuion = (id: string) => {
      const loadFileMutation = genericMutation(
        docDataSource,
        'create',
        `primaryActivity: "${id}", head: "${fileName[0]}",docExt:"${fileName[1]}"`
      );
      apolloClient
        .mutate({ mutation: loadFileMutation })
        .then(async (res) => {
          const docId = res.data[`create${docDataSource}`].id.substring(0, 8);
          const uploadStatus = await uploadFileAzureBlob(
            file,
            `${docId}.${fileName[1]}`
          );
          if (!uploadStatus) {
            const deleteActivityMutation = deleteMutation(id, 'cdmacti');
            apolloClient
              .mutate({ mutation: deleteActivityMutation })
              .then(() => {
                console.log(`Delete activity: ${id}`);
              });
            const deleteDocumentMutation = deleteMutation(
              res.data[`create${docDataSource}`].id,
              docDataSource
            );
            apolloClient
              .mutate({ mutation: deleteDocumentMutation })
              .then(() => {
                console.log(
                  `Delete document: ${res.data[`create${docDataSource}`].id}`
                );
              });
          } else {
            setFullGridKey(Math.random() + fullGridKey);
          }
        })
        .catch((err) => {
          const deleteActivityMutation = deleteMutation(id, 'cdmacti');
          apolloClient.mutate({ mutation: deleteActivityMutation }).then(() => {
            console.log(`Delete activity: ${id}`);
          });
          setCatchedError(err);
        });
    };
    if (miniGrid?.handler) {
      const handler = require('../../handlers')[miniGrid.handler];
      const mutation = handler({
        head: fileName[0],
        filterExpression: miniGrid.filterExpression,
        itemId: itemId,
        userId: user.id,
        contactId: details?.primCont
      });
      apolloClient
        .mutate({ mutation })
        .then((res) => {
          const createdId = res.data.createcdmacti.id;
          defaultMutatuion(createdId);
          setActivityStatus(createdId);
        })
        .catch((err) => {
          setCatchedError(err.message);
        });
    } else {
      if (!miniGrid?.infoTable) {
        defaultMutatuion(itemId);
      }
    }
  };
  const loadFile = async (file: any) => {
    const fileName = file.name.split('.');
    const docDataSource = dataSource.slice(0, -1);
    if (fileName[1] === 'csv') {
      const reader = new FileReader();
      await reader.readAsText(file, 'ISO-8859-4');
      reader.onload = (e: any) => {
        const text = e?.target?.result;
        const csvFile = new Blob([text], {
          type: 'text/csv; charset=utf-8'
        });
        loadFileHandler(csvFile, docDataSource, fileName);
      };
      return;
    }
    loadFileHandler(file, docDataSource, fileName);
  };

  const uploadFileAzureBlob = async (file: any, fileName: string) => {
    const formData = new FormData();
    formData.append('file', file, fileName);
    const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
    const headers: { authorization: string; typeauthorization: string } = {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      typeauthorization: localStorage.getItem('authType') || ''
    };
    try {
      const uploadFileResult = await api.postRestFileStorageUploadFile({
        headers,
        file: formData
      });
      if (uploadFileResult.code === 200) {
        const successAlert = {
          show: true,
          alertText: 'Success!',
          variant: 'success'
        };
        setAlertBarOptions(successAlert);
        setTimeout(() => {
          setAlertBarOptions({
            ...successAlert,
            show: false
          });
        }, 3500);
        return true;
      } else {
        setAlertBarOptions({
          show: true,
          alertText: uploadFileResult.message,
          variant: 'danger'
        });
        return false;
      }
    } catch (err) {
      console.log('err', err);
      setAlertBarOptions({
        show: true,
        alertText: err,
        variant: 'danger'
      });
      return false;
    }
  };

  //@ts-ignore
  useEffect(() => {
    (async () => {
      if (layoutMeta) {
        const { parentDataSource, parentAttribute, parentRelation, fields } =
          miniGrid || {};
        if (fields) {
          setMiniGridFields(fields);
        }
        if (parentDataSource) {
          const relation = parentRelation ? [parentRelation] : [];
          const query = genericQuery(
            parentDataSource,
            relation,
            `${itemId !== '1' ? `${parentAttribute || 'id'}:"${itemId}"` : ''}`
          );

          apolloClient
            .query({ query })
            .then((res: { data: { [key: string]: any } }) => {
              let newFilterId = '';
              if (res.data[parentDataSource]) {
                const data = res.data[parentDataSource];
                const source = parentRelation || parentAttribute;
                newFilterId = source && data[source];
              }
              setFilteredId(newFilterId);
              setFilterExpression(gridClass.getFilterExpression(newFilterId));
            });
        }
      }
    })();
  }, [selectedFile, filteredId]);

  useEffect(() => {
    const { hash } = window.location;
    if (hash.includes('?doc')) {
      const params = hash.split('?')[1];
      const hashResult: any = params.split('&').reduce((res, item) => {
        const parts = item.split('=');
        //@ts-ignore
        res[parts[0]] = parts[1];
        return res;
      }, {});
      const { doc, fileName } = hashResult;
      const [head, docExt] = doc.split('.');
      if (head && docExt) {
        downloadFileHandler(`${head}.${docExt}`)
          .then((blob: Blob) => {
            setDocPreview &&
              setDocPreview({
                show: true,
                head: fileName || head || ' ',
                docExt,
                blob
              });
          })
          .catch((err) => {
            setCatchedError(err.message);
          });
      }
    }
  }, [window.location.hash]);
  return (
    <Fragment>
      {alertBarOptions.show && (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      )}
      {selectionModal.show && (
        <SelectionModal
          selectionMeta={selectionModal}
          setModal={setSelectionModal}
        />
      )}

      <Col
        md={layoutMeta.defCol ? layoutMeta.defCol : 11}
        className={`edit-wrapper`}
        onDragOver={() => {
          if (!dropzoneActive) {
            setDropzoneActive(true);
          }
        }}
      >
        {renderedData && layoutMeta && (
          <>
            {window.location.hash &&
            docPreview.show &&
            miniGrid?.selectForm === 'DocumentDetail' ? (
              <DocumentPreview
                preview={docPreview}
                onHide={() =>
                  setDocPreview &&
                  setDocPreview({
                    blob: new Blob(),
                    docExt: '',
                    head: '',
                    show: false
                  })
                }
              />
            ) : (
              <>
                <Row className='justify-content-between'>
                  <h3>{layoutMeta.caption}</h3>
                </Row>
                <Row className='align-items-start'>
                  {miniGrid?.miniGridHandlers && (
                    <Col sm={1} className='justify-content-between pt-3'>
                      {miniGrid.miniGridHandlers.map(
                        (
                          {
                            icon,
                            handler,
                            tooltip,
                            selection
                          }: { [k: string]: string },
                          index: number
                        ) => {
                          return (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id={`${tooltip}-${index}`}>
                                  {tooltip}
                                </Tooltip>
                              }
                            >
                              <Button
                                className='m-1'
                                onClick={() => buttonClickHandler(handler)}
                                disabled={
                                  selection
                                    ? toggleButtonStatus(
                                        selection,
                                        selectedData?.length || 0
                                      )
                                    : false
                                }
                              >
                                <IconWrapper iconName={icon} />
                              </Button>
                            </OverlayTrigger>
                          );
                        }
                      )}
                    </Col>
                  )}
                  <Col className='minigrid-wrapper'>
                    {layoutMeta.miniGrid.dropAction && (
                      <FileDropzone
                        loadFile={loadFile}
                        dropzoneActive={dropzoneActive}
                        setDropzoneActive={setDropzoneActive}
                      />
                    )}
                    {filterExpression && (
                      <FullGrid
                        key={fullGridKey}
                        location={{
                          pathname: window.location.pathname,
                          state: {
                            defaultFilterExpression: filterExpression,
                            defaultFormat: null
                          }
                        }}
                        gridToolbar={miniGrid?.toolbar}
                        gridMeta={miniGrid || {}}
                        gridFilterId={filteredId}
                        gridCaption={layoutMeta?.caption}
                        miniGridFields={miniGridFields}
                        setNotesValue={setNotesValue}
                      />
                    )}
                  </Col>
                </Row>
                {layoutMeta.miniGrid.notesField && (
                  <NotesField
                    layoutMeta={layoutMeta.miniGrid.notesField}
                    defaultElement={renderedData[0]}
                    notesValue={notesValue}
                  />
                )}
              </>
            )}
          </>
        )}
      </Col>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  user: getUser(state),
  details: getDetails(state),
  docPreview: state.dataManagement.docPreview,
  selectedData: getSelectedData(state)
});
const mapActionToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setCatchedError,
      setDocPreview
    },
    dispatch
  );
};

export default withRouter<IMiniGrid & RouteComponentProps<{}>, any>(
  connect<any, any>(mapStateToProps, mapActionToProps)(DetailsMiniGrid) as any
);
