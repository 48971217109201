import {
    ADD_AZURE_FILE_LIST,
    ADD_ACTIVE_AZURE_CONTAINER,
    SELECT_AZURE_FILE,
    UNSELECT_AZURE_FILE,
    DELETE_SELECTED_AZURE_FILES,
} from '../types';

export const addAzureFileList = (payload) => ({
    type: ADD_AZURE_FILE_LIST,
    payload,
});

export const addActiveAzureContainer = (payload) => ({
    type: ADD_ACTIVE_AZURE_CONTAINER,
    payload,
});

export const selectAzureFile = (payload) => ({
    type: SELECT_AZURE_FILE,
    payload,
});

export const unselectAzureFile = (payload) => ({
    type: UNSELECT_AZURE_FILE,
    payload,
});

export const deleteSelectedAzureFiles = (payload) => ({
    type: DELETE_SELECTED_AZURE_FILES,
    payload,
});
