import React, { useState, useEffect, Fragment } from 'react';
import { useApolloClient } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
} from 'react-sortable-tree';
import { Button, Card, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { Archive, XSquareFill, XCircle } from 'react-bootstrap-icons';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';

import {
  getTableFieldList,
  addFormats,
  addFormatItem,
  getFormats,
  getCurrentTable,
  getCurrentFormatList,
  addCurrentFormatList,
  deleteFormatItem,
} from '../../../../redux';
import { changeIdFormatDeep } from '../../../../helpers';
import { ModalTitle } from '../commonComponents';
import { API } from '../../../../API';
import { FormatValueEditor } from './components';
import { tableFieldList_query } from '../../../../GraphQL';
import { updateFormatList } from '../../../../utils';

const QueryFormatWidget = ({
  formatActiveOperation,
  setFormatActiveOperation,
  setCustomActiveTab,
  setIsFormatVisible,
  currentTable,
  currentFormatList,
  tableFieldList,
  formats,
  addFormats,
  addFormatItem,
  addCurrentFormatList,
  deleteFormatItem,
}) => {

  const apolloClient = useApolloClient();
  const [tableFormatList] = useLocalStorage('tableFormatList');

  const [activeFormatField, setActiveFormatField] = useState({});
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [formatFieldList, setFormatFieldList] = useState([]);
  const [externalNodeType, setExternalNodeType] = useState('yourNodeType');
  const [titleModalVisible, setTitleModalVisible] = useState(false);
  const [isStandardFormatCreated, setIsStandardFormatCreated] = useState(false);

  const createRelationInfoObj = (relationArr, parentId, level) => {
    return relationArr.map((item) => {
      const relInheritedAttr = item.attributesInfo.inheritedAttributes.map(
        (elem) => {
          return {
            id: `${parentId !== null ? parentId + '_' : ''}${
              item.relationName
            }_${elem.attributeName}`,
            title: elem.attributeTranslate
              ? elem.attributeTranslate
              : elem.attributeName,
            attribute: elem.attributeName,
            isAttribute: true,
            translation: elem.attributeTranslate,
          };
        },
      );
      const relMainAttr = item.attributesInfo.attributes.map((elem) => {
        return {
          id: `${parentId !== null ? parentId + '.' : ''}${item.relationName}.${
            elem.attributeName
          }`,
          title: elem.attributeTranslate
            ? elem.attributeTranslate
            : elem.attributeName,
          attribute: elem.attributeName,
          isAttribute: true,
          translation: elem.attributeTranslate,
        };
      });
      return {
        id: `${parentId !== null ? parentId + '.' : ''}${item.relationName}`,
        title: `${item.translatedTableName}-${item.relationName}`,
        isGroup: true,
        isAdditionalLoading: true,
        level: level,
        tableName: item.tableName,
        children: [
          {
            id: `AttributesRelations${item.relationName}_${
              parentId !== null ? parentId + '.' : ''
            }${item.relationName}`,
            title: 'Attributes',
            isGroup: true,
            children: [
              ...[
                {
                  id: `InheritedAttributes.${
                    parentId !== null ? parentId + '.' : ''
                  }${item.relationName}`,
                  title: 'Inherited attributes',
                  isGroup: true,
                  children: relInheritedAttr,
                },
              ],
              ...relMainAttr,
            ],
          },
        ],
      };
    });
  };

  useEffect(() => {
    if (formatActiveOperation === 'create') {
      if (!tableFormatList) {
        setIsStandardFormatCreated(true);
        setTitle('Standard');
      }
      const initialFormats = [
        {
          id: 'FieldsGroup',
          title: 'Fields',
          isCoreGroup: true,
          isAttribute: false,
        },
      ];
      setFormatFieldList(initialFormats);
      addFormats(initialFormats);
    } else if (formatActiveOperation === 'edit') {
      const currentFormat = _.find(tableFormatList, [
        'formatName',
        currentFormatList,
      ]);
      if (currentFormat) {
        setTitle(_.get(currentFormat, 'name', ''));
        let fields = _.get(currentFormat, 'fields', [])
          .map((item) => {
            if (item.attribute.indexOf('(') === -1) {
              return {
                ...item,
                title: item.attribute,
                id: item.attribute,
                isAttribute: true,
              };
            }
          })
          .filter((elem) => {
            return elem && elem !== ' ';
          });
        const initialFormats = [
          {
            id: 'FieldsGroup',
            title: 'Fields',
            isCoreGroup: true,
            isAttribute: false,
            children: fields,
          },
        ];
        const arrFormats = changeIdFormatDeep(initialFormats, false);
        setFormatFieldList(arrFormats);
        addFormats(arrFormats);
      }
    }
    setActiveFormatField({});
  }, [formatActiveOperation]);

  useEffect(() => {
    if (!_.isEmpty(tableFieldList)) {
      const inheritedAttr =
        tableFieldList.attributesInfo.inheritedAttributes.map((item) => {
          return {
            id: `${tableFieldList.tableName}.${item.attributeName}`,
            title: item.attributeTranslate
              ? item.attributeTranslate
              : item.attributeName,
            attribute: item.attributeName,
            isAttribute: true,
            translation: item.attributeTranslate,
          };
        });
      const mainAttr = tableFieldList.attributesInfo.attributes.map((item) => {
        return {
          id: `${tableFieldList.tableName}.${item.attributeName}`,
          title: item.attributeTranslate
            ? item.attributeTranslate
            : item.attributeName,
          attribute: item.attributeName,
          isAttribute: true,
          translation: item.attributeTranslate,
        };
      });

      const relations = createRelationInfoObj(
        tableFieldList.relations,
        null,
        1,
      );

      const notes = tableFieldList.notes.map((item) => {
        return {
          id: `${tableFieldList.tableName}.${item.noteType}.${item.noteKey}`,
          title: `${item.noteTranslate}(${item.noteType})-#${item.noteKey}`,
          type: 'Note',
          key: item.noteKey,
          isNote: true,
          translation: item.noteTranslate,
          className: tableFieldList.className,
        };
      });

      const data = [
        {
          id: tableFieldList.tableName,
          title: tableFieldList.tableName,
          isGroup: true,
          children: [
            {
              id: 'Attributes',
              title: 'Attributes',
              isGroup: true,
              children: [
                ...[
                  {
                    id: 'InheritedAttributes',
                    title: 'Inherited attributes',
                    isGroup: true,
                    children: inheritedAttr,
                  },
                ],
                ...mainAttr,
              ],
            },
            {
              id: 'Relations_1level',
              title: 'Look-up relations (Foreign Keys)',
              isGroup: true,
              children: relations,
            },
            {
              id: 'Notes',
              title: 'Notes',
              isGroup: true,
              children: notes,
            },
          ],
        },
      ];
      const procData = changeIdFormatDeep(data, false);
      setNodes(procData);
    }
  }, [tableFieldList]);

  const getTableFormatListObj = (fileName) => {
    const insertedObj = {
      caption: title,
      selectFrom: `lst.${currentTable}s.${fileName}.json`,
    };
    if (tableFormatList) {
      const findFormats = _.find(tableFormatList, [
        'formatName',
        `${currentTable}sFormats`,
      ]);
      if (findFormats) {
        if (formatActiveOperation === 'create') {
          findFormats.formats.push(insertedObj);
        }
        return {
          name: findFormats.name,
          formats: findFormats.formats,
        };
      }
    } else {
      return {
        name: `${currentTable}GridFormats`,
        formats: [insertedObj],
      };
    }
  };

  const getTableFormatObj = () => {
    if (formats[0].children && formats[0].children.length) {
      let formatList = formats[0].children.map((item) => {
        let attributeName;
        let fieldName;
        let relationName;
        let filterNoteinfo;
        if (item.id.indexOf('Relation') !== -1) {
          const arrId = item.id.split('.');
          let fieldNameArr = arrId.pop().split('-');
          if (fieldNameArr.length) {
            fieldName = fieldNameArr[0];
            relationName = `${arrId.join('{')}{${fieldName}`;
          }
          const bracketsCount = (relationName.match(/{/g) || []).length;
          attributeName = relationName
            ? relationName + '}'.repeat(bracketsCount)
            : null;
        } else {
          attributeName = item.attribute;
        }
        if (item.isNote) {
          filterNoteinfo = [
            {
              field: 'com',
              operator: '=',
              value: item.key,
            },
            {
              field: 'className',
              operator: '=',
              value: item.className,
            },
          ];
        }
        const formatListItem = {
          defCol: _.get(item, 'defCol', 4),
          deckRow: _.get(item, 'deckRow', 'top'),
          deckCol: _.get(item, 'deckCol', 1),
          caption: item.caption,
          sortable: _.get(item, 'sortable', false),
          attribute: attributeName,
        };

        if (attributeName) {
          if (item.isNote) {
            return { ...formatListItem, filters: filterNoteinfo };
          }
          return formatListItem;
        }
      });
      return {
        name: title,
        fields: formatList,
      };
    }
    return null;
  };

  const onClickSaveFormat = () => {
    if (formatActiveOperation === 'create' && !isStandardFormatCreated) {
      setTitleModalVisible(true);
    } else if (formatActiveOperation === 'edit' || isStandardFormatCreated) {
      handleSaveFormat();
    }
  };

  const handleSaveFormat = () => {
    let fileNameFromTitle = title.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    fileNameFromTitle = fileNameFromTitle
      .split(/\s+/)
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ');
    fileNameFromTitle = fileNameFromTitle.replace(' ', '');
    const tableFormatListObj = getTableFormatListObj(fileNameFromTitle);
    const tableFormatObj = getTableFormatObj();
    if (tableFormatListObj && tableFormatObj) {
      let contentType = 'application/json';
      let tableFormatListFilename = `lst.${currentTable}s.json`;
      let tableFormatListBlob = new Blob([JSON.stringify(tableFormatListObj)], {
        type: contentType,
      });
      let tableFormatFilename = `lst.${currentTable}s.${fileNameFromTitle}.json`;
      let tableFormatBlob = new Blob([JSON.stringify(tableFormatObj)], {
        type: contentType,
      });
      const formData = new FormData();
      formData.append(
        'tableFormatListFile',
        tableFormatListBlob,
        tableFormatListFilename,
      );
      formData.append('tableFormatFile', tableFormatBlob, tableFormatFilename);
      const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
      const headers = {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        typeauthorization: localStorage.getItem('authType'),
      };
      api
        .postRestMetadataStorageUpdateTableFormatList({
          headers,
          files: formData,
        })
        .then(async (res) => {
          if (res.code === 200) {
            let tableFormatListResult = await updateFormatList(`${currentTable}s`,currentFormatList ? currentFormatList : `${currentTable}s.Standard`, tableFormatList);
            setFormatActiveOperation(null);
            addCurrentFormatList(
              tableFormatListResult
                ? currentFormatList
                : null,
            );
            setIsStandardFormatCreated(false);
            setCustomActiveTab('format-list');
            setIsFormatVisible(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const onClose = () => {
    setCustomActiveTab('format-list');
    setFormatActiveOperation(null);
    setIsFormatVisible(false);
  };

  const checkAvailabilityTitle = () => {
    let checker = false;
    let fileNameFromTitle = title.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    fileNameFromTitle = fileNameFromTitle
      .split(/\s+/)
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ');
    fileNameFromTitle = fileNameFromTitle.replace(' ', '');
    const findFormats = _.find(tableFormatList, [
      'formatName',
      `${currentTable}sFormats`,
    ]);
    if (findFormats) {
      findFormats.formats.forEach((element) => {
        if (element.selectFrom.indexOf(fileNameFromTitle) >= 0) {
          setTitleError('File with the same name already exist');
          checker = true;
        }
      });
      if (!checker) {
        setTitleError(null);
        return true;
      }
      return false;
    }
  };

  const canDropAttribute = () => {
    return false;
  };

  const canDragAttribute = ({ node }) => {
    return !node.isGroup;
  };

  const canDropFormatItem = ({ node, nextParent, prevPath, nextPath }) => {
    if (node.children && node.children.length) {
      return false;
    }
    if (!nextParent) {
      return false;
    }
    return true;
  };

  const removeNode = (node, path, treeIndex) => {
    setFormatFieldList(
      removeNodeAtPath({
        treeData: formatFieldList,
        path: path,
        getNodeKey: ({ node: { id } }) => id,
      }),
    );
    deleteFormatItem(node);
  };

  const handleClickNode = (node) => {
    const item = _.find(formats[0].children, ['id', node.id]);
    setActiveFormatField(item);
  };

  const onChangeFormatFieldList = (newFormatFieldList) => {  
    const arrFormats = changeIdFormatDeep(newFormatFieldList, true);  
    if (formats[0].children && formats[0].children.length) {
      arrFormats[0].children.map((item) => {
        if (!_.find(formats[0].children, ['id', item.id])) {
          if (item.isNote) {
            addFormatItem({
              id: item.id,
              attribute: 'primaryCdmcommRelation{line}',
              caption: 'Comments',
              defCol: 4,
              deckCol: 1,
              sortable: false,
              deckRow: 'top',
              isNote: true,
              key: item.key,
              className: item.className,
            });
          } else {
            addFormatItem({
              id: item.id,
              attribute: item.attribute,
              caption: item.translation,
              defCol: 4,
              deckCol: 1,
              sortable: false,
              deckRow: 'top',
            });
          }
        }
      });
    } else {
      const item = arrFormats[0].children[0];
      if (item.isNote) {
        addFormatItem({
          id: item.id,
          attribute: 'primaryCdmcommRelation{line}',
          caption: 'Comments',
          defCol: 4,
          deckCol: 1,
          sortable: false,
          deckRow: 'top',
          isNote: true,
          key: item.key,
          className: item.className,
        });
      } else {
        addFormatItem({
          id: item.id,
          attribute: item.attribute,
          caption: item.translation,
          defCol: 4,
          deckCol: 1,
          sortable: false,
          deckRow: 'top',
        });
      }
    }
    setFormatFieldList(arrFormats);
  };

  const onVisibilityToggle = ({ treeData, node, expanded, path }) => {
    const nodeRelation = _.find(node.children, [
      'title',
      'Look-up relations (Foreign Keys)',
    ]);
    if (
      _.get(node, 'isAdditionalLoading', false) &&
      !nodeRelation &&
      expanded
    ) {
      let level = node.level + 1;
      if (level <= 3) {
        apolloClient
          .query({
            query: tableFieldList_query,
            variables: {
              tableName: _.get(node, 'tableName', null),
            },
          })
          .then((res) => {
            const parentId = node.id.split('-').length
              ? node.id.split('-')[0]
              : null;
            const relations = createRelationInfoObj(
              res.data.getTableFieldsList.relations,
              parentId,
              level,
            );
            const newRelationNode = {
              id: `Relations_${level}level`,
              title: 'Look-up relations (Foreign Keys)',
              isGroup: true,
              children: relations,
            };
            const data = addNodeUnderParent({
              treeData: nodes,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ node: { id } }) => id,
              newNode: newRelationNode,
            }).treeData;

            const procData = changeIdFormatDeep(data, false);
            setNodes(procData);
          });
      }
    }
  };

  return (
    <Fragment>
      <ModalTitle
        title={title}
        setTitle={setTitle}
        titleModalVisible={titleModalVisible}
        setTitleModalVisible={setTitleModalVisible}
        handleRun={handleSaveFormat}
        isFormat={true}
        error={titleError}
        checkAvailabilityTitle={checkAvailabilityTitle}
      />
      <Card className='query-criteria-widget-card'>
        <Card.Header>
          <Row className='header-row'>
            <Button key={`btn-SaveFormat`} onClick={onClickSaveFormat}>
              <div>
                <Archive />
                <span className='primary-button-content'>Save</span>
              </div>
            </Button>
            <Button key={`btn-CloseFormat`} onClick={onClose}>
              <div>
                <XCircle />
                <span className='primary-button-content'>Close</span>
              </div>
            </Button>
          </Row>
        </Card.Header>
        <Card.Body>
          <div className='query-criteria-widget-card-body'>
            <Col md={6} className='tab-component criteria-col'>
              <div className='criteria-sortable-tree-wrapper'>
                <SortableTree
                  treeData={formatFieldList}
                  onChange={(formatFieldList) =>
                    onChangeFormatFieldList(formatFieldList)
                  }
                  shouldCopyOnOutsideDrop={true}
                  canDrop={canDropFormatItem}
                  canDrag={canDragAttribute}
                  getNodeKey={({ node }) => node.id}
                  dndType={externalNodeType}
                  canNodeHaveChildren={(node) => !node.isAttribute}
                  generateNodeProps={({ node, path, treeIndex }) => ({
                    buttons: [
                      !node.isCoreGroup ? (
                        <button
                          className='sortable-tree-button'
                          onClick={(event) => removeNode(node, path, treeIndex)}
                        >
                          <XSquareFill />
                        </button>
                      ) : null,
                    ],
                    title: (
                      <button
                        className={`button-sortable-tree  ${
                          node.id === _.get(activeFormatField, 'id', '')
                            ? 'active'
                            : null
                        }`}
                        onClick={() => handleClickNode(node, path, treeIndex)}
                      >
                        {node.title}
                      </button>
                    ),
                  })}
                />
              </div>
              {!_.isEmpty(activeFormatField) && (
                <FormatValueEditor activeFormatField={activeFormatField} />
              )}
            </Col>
            <Col md={7} className='tab-component criteria-col'>
              <div className='attribute-sortable-tree-wrapper'>
                <SortableTree
                  treeData={nodes}
                  onChange={(nodes) => setNodes(nodes)}
                  shouldCopyOnOutsideDrop={true}
                  canDrop={canDropAttribute}
                  canDrag={canDragAttribute}
                  getNodeKey={({ node }) => node.id}
                  dndType={externalNodeType}
                  canNodeHaveChildren={(node) => !node.isAttribute}
                  onVisibilityToggle={(item) => onVisibilityToggle(item)}
                />
              </div>
            </Col>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  currentTable: getCurrentTable(state),
  currentFormatList: getCurrentFormatList(state),
  tableFieldList: getTableFieldList(state),
  formats: getFormats(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFormats,
      addFormatItem,
      deleteFormatItem,
      addCurrentFormatList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryFormatWidget);
