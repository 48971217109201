import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Form, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Children } from 'react';
import { drop, size } from 'lodash';

import {formatBytes} from '../../utils';
import AlertBar from '../AlertBar';

const FormUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDropZone, setShowDropZone] = useState(false);
  const [zIndex, setZindex] = useState(-1);
  const [alertBarOptions, setAlertBarOptions] = useState({
    show: false,
    alertText: '',
    variant: '',
  });

  const handleAcceptedFiles = (files) => {
    hideDropzone();
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
    if(parseFloat(files[0].size) > parseFloat(process.env.REACT_APP_MAX_FILE_SIZE) ) {
        setAlertBarOptions({
          show: true,
          alertText: `The file must not be bigger than ${formatBytes(process.env.REACT_APP_MAX_FILE_SIZE)}`,
          variant: 'danger',
        });
        return;
    }
    props.loadFile(files[0]);
  };

  const hideDropzone = () => {
    props.setDropzoneActive(false);
  };

  return (
    <React.Fragment>
       {alertBarOptions.show ? (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show,
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      ) : null}
      {props.dropzoneActive ? (
        <div className='dropzone-container' onDragLeave={hideDropzone}>
          <Form>
            <Dropzone
              onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className='dropzone dropzone-transparent'>
                  <div className='dz-message needsclick' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <h3>Drop files here or click to upload.</h3>
                  </div>
                </div>
              )}
            </Dropzone>
          </Form>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default FormUpload;
