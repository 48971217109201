import React from 'react';
import { Search } from 'react-bootstrap-icons';
import { InputGroup, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const searchBar = ({ placeholder, onChange }) => {
  return (
    <div className='search-bar'>
      <InputGroup className='mb-3'>
        <InputGroup.Prepend>
          <InputGroup.Text id='basic-addon1'>
            <Search />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder={placeholder}
          aria-label={placeholder}
          onChange={onChange}
          aria-describedby='basic-addon1'
        />
      </InputGroup>
    </div>
  );
};

export default searchBar;
