import React, { useState, useEffect, FC } from 'react';
import { useHistory, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import { downloadFileHandler } from '../../pages/FileManagerAzure/components/handlers';
import { textGenerator } from '../../utils';
//@ts-ignore
import colors from '../../scss/colors.scss';
import {
  updateMetadataList,
  alignTypedFields,
  getCellStyles
} from '../../utils';
import { documentExtensionIcon, getResponsiveCellWidth } from './functions';
import { setDocPreview, getSelectedData, setCatchedError } from '../../redux';
import { ITableRow, IFormField } from '../../types';

const TableRow = ({
  el,
  metadata,
  fields,
  selectItem,
  unselectItem,
  selectedAll,
  itemClick,
  itemPosition,
  filterExpression,
  setDocPreview,
  setCatchedError,
  setNotesValue,
  highlightOnClick,
  dataStorageId
}: ITableRow) => {
  const [metadatasList] = useLocalStorage('metadatasList');

  const [isSelected, setSelect] = useState(false);
  const [cellStyle, setCellStyle] = useState({});
  const history = useHistory();

  const onChangeHandler = (selected = isSelected) => {
    if (selected) {
      selectItem && selectItem(el);
    } else {
      unselectItem && unselectItem(el.id);
    }
  };

  const getRowStyles = () => {
    let setStyle: any = {};
    if (metadata.rowStyles) {
      metadata.rowStyles.map(
        (rowStyle: {
          rule: { attribute: string; equalsTo: string };
          style: { color: string };
        }) => {
          const { rule, style } = rowStyle;
          //@ts-ignore
          const currentDateRule = el[rule.attribute];
          if (
            rule.attribute.toLowerCase().includes('date') &&
            currentDateRule
          ) {
            const attributeDate = moment(currentDateRule).toDate();
            const currentDate = moment(new Date()).toDate();
            const expired = moment(currentDate).isAfter(moment(attributeDate));
            setStyle = expired === true ? { color: colors[style.color] } : {};
          }
          if (rule.equalsTo) {
            setStyle = currentDateRule === rule.equalsTo ? style : {};
          }
        }
      );
    }
    if (isSelected) {
      setStyle.backgroundColor = '#deecf9';
    }
    return setStyle;
  };

  const handleRowClick = async (
    doc: { head?: string; id?: string; docExt?: string },
    meta: { [k: string]: string },
    field: any
  ) => {
    const item = meta.selectForm;
    if (meta.notesField && field.attribute.includes('primaryCdmcommRelation')) {
      const note = el?.primaryCdmcommRelation[0];
      setNotesValue && setNotesValue(note?.txt || note?.line || '');
      return;
    }
    if (item !== 'DocumentDetail') {
      if (itemClick) {
        highlightOnClick && setSelect(!isSelected);
        itemClick(el);
        return;
      }
      await redirectToDetail(item);
    } else {
      const { head, id, docExt } = doc;
      if (id && docExt) {
        const fileName = id.substring(0, 8);
        try {
          const res = await downloadFileHandler(`${fileName}.${docExt}`);
          history.push({
            pathname: history.location.pathname + history.location.hash,
            search: `?doc=${fileName}.${docExt}&fileName=${head}`
          });
          setDocPreview &&
            setDocPreview({
              show: true,
              head: head || ' ',
              docExt,
              blob: res
            });
        } catch (err: any) {
          setCatchedError && setCatchedError(err.message);
        }
      }
    }
  };
  const redirectToDetail = async (item: string) => {
    if (_.find(metadatasList, ['fileName', `Frm.${item}.json`])) {
      history.push({
        pathname: `/${item}-${el.id}#0`,
        state: {
          id: el.id,
          dataStorageId,
          itemPosition,
          filterExpression,
          dataSource: metadata.dataSource
        }
      });
    } else {
      try {
        const successMetadata = await updateMetadataList(
          `Frm.${item}.json`,
          metadatasList
        );
        if (successMetadata) {
          history.push({
            pathname: `/${item}-${el.id}#0`,
            state: {
              id: el.id,
              itemPosition,
              filterExpression,
              dataSource: metadata.dataSource
            }
          });
        }
      } catch (err) {
        console.log(`${err.message}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getCellStyles(el, metadata.dataSource, setCellStyle);
    })();
  }, []);

  useEffect(() => {
    if (isSelected !== selectedAll) {
      setSelect(selectedAll);
      onChangeHandler(selectedAll);
    }
  }, [selectedAll]);
  return (
    <tr style={{ ...getRowStyles(), ...cellStyle }}>
      <div>
        {(metadata.listFormat || metadata.selection) && (
          <th
            style={{
              backgroundColor: isSelected ? '#deecf9' : ''
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onChangeHandler(!isSelected);
              setSelect(!isSelected);
            }}
          >
            <input
              type='checkbox'
              id={el.id}
              className='check-box fullGrid-checkbox'
              checked={isSelected}
            />
            <label htmlFor={el.id}></label>
          </th>
        )}
      </div>
      {fields.map((field: IFormField, index: number) => {
        if (field.caption) {
          return (
            <div
              style={{
                width: getResponsiveCellWidth(field?.width),
                textAlign: field.type ? alignTypedFields(field.type) : 'left'
              }}
            >
              <th
                style={{
                  backgroundColor: isSelected ? '#deecf9' : '',
                  width: getResponsiveCellWidth(field?.width)
                }}
                onClick={() => handleRowClick(el, metadata, field)}
              >
                {metadata.selectForm === 'DocumentDetail' && index === 0 && (
                  <span
                    className={`table-cell`}
                    style={{ fontSize: '1.1rem', marginRight: '2px' }}
                  >
                    <i
                      className={`mdi ${documentExtensionIcon(
                        el.docExt ? el.docExt.toLowerCase() : 'unknown'
                      )}`}
                    ></i>
                  </span>
                )}
                {
                  //Remove comment after converting functions.js to TypeScript
                  //@ts-ignore
                  textGenerator(el, field)
                }
              </th>
            </div>
          );
        }
      })}
    </tr>
  );
};

const mapStateToProps = (state: any) => ({
  selectedData: getSelectedData(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setDocPreview,
      setCatchedError
    },
    dispatch
  );
};
export default withRouter<ITableRow & RouteComponentProps<{}>, any>(
  connect<any, any>(mapStateToProps, mapDispatchToProps)(TableRow) as any
);
