import { FileListFormat } from '../../../types';

export const fileListFormat: FileListFormat[] = [
  {
    caption: 'Name',
    defCol: 6,
    attribute: 'name'
  },
  { caption: 'Date Modified', defCol: 2, attribute: 'modified' },
  { caption: 'File Size', defCol: 2, attribute: 'size' }
];
