import { ADD_METADATA_FOR_EDIT, HANDLE_CHANGE_METADATA } from '../types';

export const addMetadataForEdit = (payload) => {
  return {
    type: ADD_METADATA_FOR_EDIT,
    payload,
  };
};

export const handleChangeMetadata = (payload) => {
  return {
    type: HANDLE_CHANGE_METADATA,
    payload,
  };
};