import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const ValueInput = ({ value, type, onChange, isDisabled, label }) => {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        if (!Array.isArray(value)) {
            setInputValue(value);
        }
        else {
            setInputValue('');
        }
    }, [value]);

    const handleChange = (e) => {
        e.preventDefault();
        setInputValue(e.target.value);
        onChange(e.target.value, e.target.name);
    };

    return (
        <Form.Group>
            <Row style={{ paddingLeft: '20px' }}>
                {label && (
                    <Form.Label
                        style={{ paddingRight: '0', paddingLeft: '0' }}
                        column
                        sm={4}
                    >
                        {label}
                    </Form.Label>
                )}
                <Col sm={8}>
                    <Form.Control
                        name={label}
                        type={type}
                        value={inputValue}
                        onChange={(e) => handleChange(e)}
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default ValueInput;
