import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  EditorState,
  Editor,
  ContentState,
  RichUtils,
  convertToRaw
} from 'draft-js';
import moment from 'moment';
import _ from 'lodash';
import { styleMap } from './constants';
import {
  extendedBlockRenderMap,
  getBlockStyle,
  convertHtmlToRtf,
  rtfToHtmlFromString
} from './functions';
import { BlockStyleControls, InlineStyleControls } from './components';
import { getUser } from '../../redux';
import { TFieldsValues } from '../../types';

interface ITextEditor {
  editorState: EditorState;
  defaultValues: TFieldsValues;
  showControlsBar: boolean;
  defaultState: EditorState | null;
}
interface IProps {
  text: TFieldsValues;
  user: TFieldsValues;
  field: TFieldsValues;
  editingBar: boolean;
  renderedValues: TFieldsValues;
  setRenderedValues: Function;
  editField: Function;
  disabled: boolean;
}
class TextEditor extends React.Component<IProps, ITextEditor> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      showControlsBar: false,
      defaultValues: this.props.renderedValues.primaryCdmcommRelation,
      defaultState: null
    };
    //@ts-ignore
    this.focus = () => this.refs.editor.focus();
  }
  onChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= 253
    ) {
      this.setState({ editorState });
    } else {
      const editorState = EditorState.undo(
        EditorState.push(
          this.state.editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character'
        )
      );
      this.setState({ editorState });
    }
    this.exitEditing(editorState);
  };

  toggleBlockType = (blockType: string) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle: string) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  convertTextToEditor = (value: string, com: string) => {
    if (com === '2') {
      rtfToHtmlFromString(value, (err: any, html: any) => {
        this.setEditorStateFromHtml(html);
      });
      return;
    }
    this.setEditorStateFromHtml(value);
  };

  convertRtfToPlain = (rtf: string) => {
    rtf = rtf.replace(/\\par[d]?/g, '');
    return rtf
      .replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, '')
      .trim();
  };

  setEditorStateFromHtml = (editorText: string) => {
    const blocksFromHtml = htmlToDraft(editorText);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    this.setState({ editorState, defaultState: editorState });
  };

  enterEditing = () => {
    const { editingBar } = this.props;
    if (editingBar) {
      this.setState({ showControlsBar: true });
    }
  };
  exitEditing = (editorState: EditorState) => {
    const { renderedValues, setRenderedValues, editField, text, field } =
      this.props;
    const { defaultValues } = this.state;
    const { com, table, type, mail } = field;
    const defaultNote =
      defaultValues?.find((note: { com: string }) => note.com === com) || null;
    const currentTxtContent = editorState.getCurrentContent();
    const rawValue = convertToRaw(currentTxtContent);
    let txt = draftToHtml(rawValue);
    if (mail) {
      setRenderedValues({ ...renderedValues, [`${text.attribute}`]: txt });
    } else {
      const id = renderedValues.id;
      const updateValues = () => {
        const newNote = {
          id,
          txt: txt.replaceAll('&nbsp;', '').replace(/\\/g, '\\\\'),
          com,
          table
        };
        let editedNote = [];
        if (
          _.find(renderedValues.primaryCdmcommRelation, ['com', newNote.com])
        ) {
          editedNote = renderedValues.primaryCdmcommRelation.map(
            (note: { com: string }) => {
              if (note.com === newNote.com) {
                note = newNote;
              }
              return note;
            }
          );
        } else {
          editedNote.push(newNote);
        }
        setRenderedValues({
          ...renderedValues,
          [`${text.attribute}`]: editedNote
        });
        editField({
          [`${text.attribute}`]: editedNote
        });
      };
      const plainTxt = currentTxtContent.getPlainText();
      if (type === 'textAreaPlain' && com !== '12' && com !== '2') {
        txt = plainTxt;
      }
      if (com === '2') {
        txt = convertHtmlToRtf(txt) || '';
        if (defaultNote) {
          rtfToHtmlFromString(defaultNote.txt, (err: any, html: any) => {
            convertHtmlToRtf(html);
            const oldContent = this.state?.defaultState?.getCurrentContent();
            const rtfChanged = plainTxt !== oldContent?.getPlainText();
            if (rtfChanged) {
              updateValues();
            }
          });
          return;
        }
      }
      if (defaultNote) {
        const editedNote = renderedValues.primaryCdmcommRelation.map(
          (note: { com: string; txt: string }) => {
            if (note.com === text.com) {
              note.txt = txt;
            }
            return note;
          }
        );
        if (defaultNote.txt === txt) {
          return;
        } else {
          typeof text.value === 'string'
            ? setRenderedValues({
                ...renderedValues,
                [`${text.attribute}`]: editedNote
              })
            : updateValues();
        }
      } else {
        updateValues();
      }
    }
  };

  componentDidMount() {
    const editorField: any = document.getElementsByClassName(
      'public-DraftEditor-content'
    )[0];
    editorField.style.height =
      this.props.field.maxHeight || this.props.field.minHeight || '100%';
    editorField.style.minWidth = this.props.field.minWidth || '100%';
    const { text, user, field } = this.props;
    if (text.value) {
      let htmlVal =
        typeof text.value === 'string' ? text.value : text.value.txt;
      if (field.timestamp) {
        htmlVal += `\r${moment(new Date()).format('yyyy-MM-DD')} ${
          user.firstN
        } ${user.lastN} `;
      }
      this.convertTextToEditor(htmlVal, field.com);
    }
  }

  render() {
    const { editorState, showControlsBar } = this.state;
    let className = 'RichEditor-editor';
    let contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div className='RichEditor-root'>
        <div
          className={className}
          //@ts-ignore
          onClick={this.focus}
        >
          <Editor
            //@ts-ignore
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            onChange={this.onChange}
            ref='editor'
            blockRenderMap={extendedBlockRenderMap}
            onFocus={this.enterEditing}
            onBlur={() => this.setState({ showControlsBar: false })}
            length={253}
            readOnly={this.props.field.disabled}
          />
          {showControlsBar && (
            <Row className='RichEditor-style-controls'>
              <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
              {this.props.field.com !== '2' && (
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                />
              )}
            </Row>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { user: TFieldsValues }) => ({
  user: getUser(state)
});

export default connect(mapStateToProps)(TextEditor);
