import { HttpLink, concat, fromPromise } from '@apollo/client';
import _ from 'lodash';
import { from } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import { clearCache, tokenPromise } from './';
import store, { setCatchedError } from '../redux';
import { getDefaultLocale } from './';

const authLink = setContext((operation) =>
  tokenPromise('gql', true)
    .then((data) => {
      return {
        // Make sure to actually set the headers here
        headers: {
          authorization: data.token,
          typeauthorization: data.authType
        }
      };
    })
    .catch((error) => {
      console.log(error);
    })
);

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_ENDPOINT_URL}/gql`
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            if (!localStorage.getItem('msToken')) {
              clearCache();
            }
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

export const apolloLink = concat(errorLink, from([authLink, httpLink]));
