import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { textGenerator, redirectToDetail } from '../../utils';
import { IFormField, TFieldsValues } from '../../types';
import _ from 'lodash';

const Card = (props: TFieldsValues) => {
  const {
    data,
    fullGridFormat,
    filterExpression,
    metadata,
    history,
    itemPosition
  } = props;
  const { fields } = fullGridFormat || { fields: [] };
  const center = fields.sort((a: { width: number }, b: { width: number }) =>
    a?.width < b?.width ? 1 : -1
  )[0];
  const updatedFields = fields.filter(
    (item: IFormField) =>
      item.type !== 'boolean' && item.attribute !== center.attribute
  );
  const top = fields ? [updatedFields[0], updatedFields[1]] : [];
  const bottom = fields ? [updatedFields[1], updatedFields[2]] : [];

  return (
    <Container
      className='detailed-card'
      onClick={() =>
        redirectToDetail(metadata.selectForm, data, history, {
          id: data.id,
          dataSource: metadata.dataSource,
          filterExpression,
          itemPosition
        })
      }
    >
      <Row>
        {top &&
          top.map((row, i) => {
            return (
              <Col key={`top-${i}`}>{row ? textGenerator(data, row) : ''}</Col>
            );
          })}
      </Row>
      <Row>
        {center && (
          <Col key={`center-${0}`} className='detailed-card-center'>
            {textGenerator(data, center)}
          </Col>
        )}
      </Row>
      <Row>
        {bottom &&
          bottom.map((row, i) => {
            return (
              <Col key={`bottom-${i}`}>
                {row ? textGenerator(data, row) : ''}
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};
export default Card;
