import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { useApolloClient } from 'react-apollo';
import { genericQuery } from '../../../GraphQL';
import { setDetails, getDetails, setCatchedError } from '../../../redux';
import { EditableFields, DialogModal } from '../../../components';
import useLocalStorage, { writeStorage } from '@rehooks/local-storage';
import { getDetailName } from '../../../helpers';
import { divideNestedAttribute } from '../../../utils';
import { TFieldsValues } from '../../../types';
import { getItemId } from '../functions';

const toolbarHandlers: {
  [k: string]: Function;
} = require('../../../components/GenericToolbar/handlers');
const handlers: TFieldsValues = require('../handlers');
const classes: TFieldsValues = require('../../../classes');

const DetailsComponent = ({
  layoutMeta,
  setDetails,
  setCatchedError,
  saveTabChanges,
  editedFields,
  setEditedFields,
  setFieldsValues,
  renderAlertBar,
  setAlertBarOptions
}: any) => {
  const [mainId, setMainId] = useState<string>();
  const [breadCrumbDetails] = useLocalStorage<any>('details');
  const [renderedData, setRenderedData] = useState({});
  const [dialogWindowProps, setDialogWindowProps] = useState();
  const apolloClient = useApolloClient();
  const history: any = useHistory();
  const { fields } = layoutMeta.dataEntry;
  const defaultFieldSize = {
    caption: 5,
    field: 6
  };
  const { defaultState }: any = history.location.state || { defaultState: {} };
  const fieldClickHandler = (handlerField: any, field: any) => {
    if (handlerField.class && handlerField.method) {
      const currentClass = new classes[handlerField.class]({
        field,
        data: renderedData,
        apolloClient,
        setRenderedData,
        setDialogWindowProps,
        history,
        setCatchedError,
        setAlertBarOptions
      });
      currentClass[handlerField.method]();
    } else if (handlers[handlerField.handler]) {
      handlers[handlerField.handler]({
        field,
        data: renderedData,
        apolloClient,
        setRenderedData,
        setDialogWindowProps,
        history,
        setCatchedError,
        setAlertBarOptions
      });
    } else {
      console.log(handlerField);
    }
  };

  const fetchData = (id: string | undefined = mainId) => {
    if (id && id !== 'create') {
      const { dataEntry } = layoutMeta;
      const { itemPosition, filterExpression, ...historyState }: any = history
        ?.location?.state || {
        itemPosition: undefined,
        filterExpression: id ? `id: "${id}"` : '',
        dataSource: `${dataEntry.dataSource}s`
      };
      const dataSource = dataEntry?.parentDataSource
        ? `${dataEntry?.dataSource}s`
        : historyState.dataSource;
      if (dataSource.includes(dataEntry.dataSource)) {
        let filterString =
          !dataEntry?.parentDataSource && Number.isInteger(itemPosition)
            ? filterExpression
            : dataEntry.filterExpression
            ? dataEntry.filterExpression
            : id
            ? `id: "${id}"`
            : '';
        filterString = filterString.includes('orderBy')
          ? filterString
          : `${filterString} orderBy:id`;
        if (dataEntry?.parentDataSource) {
          filterString += ' showDeleted:true';
        }
        const query = genericQuery(
          dataSource,
          window.location.pathname.includes('/SystemSettings')
            ? dataEntry.fields
            : layoutMeta.tabFields,
          `${filterString} `,
          '',
          1
        );
        apolloClient
          .query({
            query,
            variables: {
              offset:
                (!dataEntry?.parentDataSource &&
                  Number.isInteger(itemPosition) &&
                  Math.abs(itemPosition)) ||
                0
            },
            fetchPolicy: 'no-cache'
          })
          .then((res) => {
            const data = res.data[dataSource] && res.data[dataSource][0];
            const name = getDetailName(data);
            if (
              !_.find(breadCrumbDetails, ['link', window.location.pathname])
            ) {
              const { state } = history.location;
              if (state && state.ignoreBreadCrumbs) {
                breadCrumbDetails.pop();
              }
              writeStorage('details', [
                ...breadCrumbDetails,
                { caption: name, link: window.location.pathname, active: true }
              ]);
            } else {
              if (
                breadCrumbDetails[breadCrumbDetails.length - 2]?.link ===
                window.location.pathname
              ) {
                breadCrumbDetails.pop();
                writeStorage('details', breadCrumbDetails);
              }
            }
            setDetails(data);
            if (data && mainId !== data.id && !dataEntry.filterExpression) {
              history.push({
                pathname: `${history.location.pathname.split('-')[0]}-${
                  data.id
                }`,
                state: history.location.state
              });
            }
            setRenderedData(data);
            setFieldsValues(data);
            layoutMeta.tabFields.map((field: any) => {
              if (field.handler) {
                handlers[field.handler]({
                  data,
                  field,
                  setRenderedData
                });
              }
            });
          })
          .catch((err) => setCatchedError(err.message));
      }
    }
  };
  const setEmptyFields = () => {
    const emptyData: TFieldsValues = {};
    fields.map(({ attribute }: TFieldsValues) => {
      if (attribute) {
        if (attribute.includes('primaryCdmcommRelation')) {
          emptyData.primaryCdmcommRelation = null;
        } else {
          const [mainAttribute] = divideNestedAttribute(attribute);
          emptyData[mainAttribute] = null;
        }
      }
    });
    setRenderedData(emptyData);
  };

  useEffect(() => {
    if (layoutMeta) {
      const { parentDataSource, parentRelation } = layoutMeta.dataEntry;
      if (parentDataSource && parentRelation) {
        const query = genericQuery(
          parentDataSource,
          [parentRelation],
          `id:"${getItemId()}"`
        );
        apolloClient.query({ query }).then(({ data }) => {
          const result = data[parentDataSource];
          if (result) {
            if (result[parentRelation] === null) {
              setMainId('deleted');
            }
            result[parentRelation] &&
              setMainId(data[parentDataSource][parentRelation]);
          } else {
            setMainId('deleted');
          }
        });
      } else {
        setMainId(getItemId());
      }
    }
  }, [window.location.hash, layoutMeta]);

  useEffect(() => {
    if (layoutMeta.dataEntry && layoutMeta.dataEntry.class) {
      toolbarHandlers.updateCapabilityState({
        step: 0,
        method: layoutMeta.dataEntry.class,
        args: {}
      });
      toolbarHandlers
        .callMethodFromBackend(() => {})
        .then((res: any) => {
          setRenderedData(_.get(res, 'defaultValues', {}));
        });
    } else {
      if (mainId && mainId !== 'create' && mainId !== 'deleted') {
        const { parentDataSource, relationID } = layoutMeta;
        if (parentDataSource) {
          const query = genericQuery(
            parentDataSource,
            [relationID || ''],
            `${relationID ? (mainId ? `id:"${mainId}"` : '') : ''}`
          );
          apolloClient.query({ query }).then((res) => {
            const newId = res.data[parentDataSource][relationID || 'id'];
            fetchData(newId);
          });
          return;
        }
        fetchData(mainId);
      } else {
        setEmptyFields();
      }
    }

    return () => {
      setRenderedData({});
    };
  }, [mainId]);

  const renderEditableFields = () => (
    <EditableFields
      itemId={mainId || ''}
      layoutMeta={layoutMeta}
      fieldsValues={renderedData}
      fieldClickHandler={fieldClickHandler}
      fetchData={fetchData}
      defaultEdits={defaultState || {}}
      saveTabChanges={saveTabChanges}
      editedFields={editedFields}
      setEditedFields={setEditedFields}
      renderAlertBar={renderAlertBar}
    />
  );

  return (
    <Col
      md={layoutMeta.defCol ? layoutMeta.defCol : defaultFieldSize.field}
      className='tab-component'
    >
      {renderedData && (
        <>
          {
            //@ts-ignore
            dialogWindowProps && dialogWindowProps?.show && (
              <DialogModal
                {...dialogWindowProps}
                setShowModal={setDialogWindowProps}
              />
            )
          }
          <Row className={`edit-wrapper edit-${layoutMeta.name}`}>
            <Col>
              {layoutMeta.equalsTo ? (
                <>
                  {mainId &&
                    //@ts-ignore
                    renderedData[layoutMeta.attribute] ===
                      layoutMeta.equalsTo &&
                    renderEditableFields()}
                </>
              ) : (
                Object.keys(renderedData).length > 0 &&
                mainId &&
                renderEditableFields()
              )}
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
};

const mapStateToProps = (state: any) => ({
  details: getDetails(state)
});
const mapActionToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setDetails,
      setCatchedError
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(DetailsComponent));
