import React, { useState } from 'react';
import { Modal, Row, Col, NavLink } from 'react-bootstrap';
import { useHistory } from 'react-router';
import _ from 'lodash';

const BrowserList = ({ formType, list, setList, onClickAction, header }) => {
  const history = useHistory();
  const [orderBy, setOrderBy] = useState('caption');
  return (
    <Modal
      show={list}
      size={'md'}
      className='editing-modal'
      onHide={() => setList(false)}
    >
      <Modal.Header closeButton onClick={() => setList(false)}>
        {header || list.header}
      </Modal.Header>
      <Modal.Body className='editing-modal-body' style={{ overflow: 'auto' }}>
        {formType === 'Formats' && (
          <Row className='pl-4 mb-2'>
            <Col
              className='dx-g-bs4-cursor-pointer'
              onClick={() => setOrderBy('caption')}
            >
              Table
            </Col>
            <Col
              className='dx-g-bs4-cursor-pointer'
              onClick={() => setOrderBy('dataSource')}
            >
              Class
            </Col>
          </Row>
        )}
        <Col className='modal-horizontal-scroll modal-list'>
          {formType === 'Formats' &&
            _.orderBy(
              list,
              [(entry) => entry[orderBy].toLowerCase()],
              ['asc']
            ).map((field, i) => (
              <Row
                className='option-row pl-2'
                key={`${field.caption}-${i}`}
                onClick={() => {
                  onClickAction(field);
                }}
              >
                <Col>
                  <span>{field.caption}</span>
                </Col>
                <Col>
                  <span>{field.dataSource}</span>
                </Col>
              </Row>
            ))}
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default BrowserList;
