import { takeEvery, put, select, call } from 'redux-saga/effects';
import { getTheme } from '../selectors';
import { SET_THEME } from '../types';

function* updateTheme() {
  const currentTheme = yield select(getTheme);
  const body = document.body;
  body.dataset.topbar = currentTheme;
  body.dataset.sidebar = currentTheme;
}
export function* updateThemeOnLoad() {
  yield takeEvery(SET_THEME, updateTheme);
}
