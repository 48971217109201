import React, { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { InputRender } from '../Components';
import { ConfirmationModal, RelationInputSearch } from '../../../components';
import { RadiosComponent } from '../../../pages/DetailsPage/components';
import _ from 'lodash';
const RegularView = ({
  layoutMeta,
  onHide,
  fieldsValues,
  renderedData,
  setRenderedData,
  saveChanges,
  showConfirmationModal,
  setShowConfirmationModal,
  setDefaultNotesValue,
}) => {
  const fields = layoutMeta.dataEntry.fields;

  const handleChange = (event, field, valueIsString = true) => {
    const { attribute, inputType } = field;
    let type = '';
    let checked = false;
    let inputValue = '';
    if (event) {
      type = event.target.type;
      checked = event.target.checked;
      inputValue = valueIsString
        ? event.target.value
        : parseInt(event.target.value);
    }
    if (inputType === 'color') {
      inputValue = parseInt(inputValue.replace('#', '0x'), 16);
    }
    if (inputType === 'radio') {
      if (inputValue === 'true' || inputValue === 'false') {
        inputValue = inputValue === 'true';
      }
    }
    if (inputType === 'checkbox') {
      inputValue = checked;
    }
    renderedData[attribute] = inputValue;
    setRenderedData({ ...renderedData, [attribute]: inputValue });
  };

  const renderCheckBoxes = (field, isGrouped = false) => {
    return (
      <Col className={isGrouped ? 'rendered-field-group' : 'details-fields'}>
        <label>
          <input
            id={`${field.inputType}-${field.attribute}`}
            type={field.inputType}
            defaultChecked={renderedData[field.attribute]}
            onChange={(e) => handleChange(e, field, false)}
          />
          {field.caption}
        </label>
      </Col>
    );
  };

  useEffect(() => {
    if (fieldsValues) {
      if (Object.keys(fieldsValues).length > 0 && !renderedData) {
        setRenderedData(fieldsValues);
        setDefaultNotesValue(fieldsValues.primaryCdmcommRelation);
        const attributes = {};
        fields.map((field) => {
          if (field.attribute) {
            attributes[field.attribute] = fieldsValues[field.attribute]
              ? fieldsValues[field.attribute]
              : '';
          }
        });
      }
    }
  }, [fieldsValues, renderedData]);

  return (
    <div>
      <h3>{layoutMeta.caption}</h3>
      {renderedData ? (
        <>
          <ConfirmationModal
            showModal={showConfirmationModal}
            innerText={'Do you want to save changes?'}
            onConfirm={saveChanges}
            onDiscard={onHide}
            setShowModal={setShowConfirmationModal}
          />
          <Col sm={12}>
            <div className='edit-input-fields'>
              <AvForm>
                {fields.map((field, i) => {
                  const {
                    dataSource,
                    caption,
                    inputType,
                    attribute,
                    group,
                    validation,
                    fieldType,
                  } = field;
                  if (dataSource) {
                    return (
                      <Row>
                        <Col sm={3}>
                          {validation ? (
                            <>
                              {caption}
                              <span className='edit-input_important'>*</span>
                            </>
                          ) : (
                            caption
                          )}
                        </Col>
                        <Col sm={7}>
                          <RelationInputSearch
                            data={renderedData}
                            metaData={field}
                            setRenderedData={setRenderedData}
                          />
                        </Col>
                      </Row>
                    );
                  }
                  if (inputType === 'checkbox') {
                    return renderCheckBoxes(field);
                  }
                  if (inputType === 'color') {
                    return (
                      <Row>
                        <Col sm={3}>{caption}</Col>
                        <Col sm={7}>
                          <input
                            id={`${field.inputType}-${field.attribute}`}
                            type={field.inputType}
                            onChange={(e) => handleChange(e, field)}
                            style={{ width: '100%' }}
                            defaultValue={`#${renderedData[
                              field.attribute
                            ].toString(16)}`}
                          />
                        </Col>
                      </Row>
                    );
                  }
                  if (inputType === 'radio') {
                    return (
                      <RadiosComponent
                        field={field}
                        renderedData={renderedData}
                        handleChange={handleChange}
                        editing={true}
                      />
                    );
                  }
                  if (!group) {
                    return (
                      <InputRender
                        caption={
                          validation ? (
                            <>
                              {caption}
                              <span className='edit-input_important'>*</span>
                            </>
                          ) : (
                            caption
                          )
                        }
                        field={field}
                        renderedData={renderedData}
                        setRenderedData={setRenderedData}
                        className='edit-input-fields'
                        key={`inputRender-${caption}-${i}`}
                        keyProp={`${caption}-${i}`}
                        defCol={7}
                        defaultValue={renderedData[attribute]}
                        onChange={(e) => handleChange(e, field, field)}
                      />
                    );
                  } else {
                    return (
                      <Row style={{ marginLeft: 0 }}>
                        {caption && <Col sm={3}>{caption}</Col>}
                        <Col>
                          <Row className='details-fields '>
                            {group.map((groupElement, i) => {
                              const { inputType, defCol, attribute, disabled } =
                                groupElement;
                              if (inputType === 'checkbox') {
                                return renderCheckBoxes(groupElement, true);
                              }
                              if (!groupElement.fieldText) {
                                return (
                                  <Col sm={defCol}>
                                    <Form.Control
                                      id={`group-${attribute}`}
                                      type={inputType ? inputType : 'text'}
                                      onChange={(e) =>
                                        handleChange(e, field, false)
                                      }
                                      disabled={disabled}
                                      defaultValue={
                                        renderedData[attribute] || ''
                                      }
                                    />
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                        </Col>
                      </Row>
                    );
                  }
                })}
              </AvForm>
            </div>
          </Col>
        </>
      ) : null}
    </div>
  );
};

export default RegularView;
