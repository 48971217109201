import { ADD_METADATA_FOR_EDIT, HANDLE_CHANGE_METADATA } from '../types';

const initialState = {
  metadataForEdit:'',
  isMetadataChange: false
};

const addMetadataForEdit = (state, payload) => ({
  ...state,
  metadataForEdit: payload,
});

const handleChangeMetadata = (state, payload) => ({
  ...state,
  isMetadataChange: payload,
});

const handlers = {
  [ADD_METADATA_FOR_EDIT]: addMetadataForEdit,
  [HANDLE_CHANGE_METADATA]: handleChangeMetadata
};

export default (state = initialState, action) => {
  const handler = handlers[action.type];
  const newState = handler ? handler(state, action.payload) : state;
  return newState;
};