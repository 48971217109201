import React from 'react';
import { Row, Button } from 'react-bootstrap';

const GenericWindowToolbar = ({ toolbar, onSaveData, buttonClickHandler }) => {
  const toolbarHandler = (handler) => {
    onSaveData(handler);
    if (handler) {
      buttonClickHandler(handler);
    }
  };

  return (
    <>
      {toolbar && (
        <Row className='modal-header-row'>
          {toolbar.map((btnField, i) => {
            return (
              <Button
                key={`btn-${btnField.caption}-${i}`}
                onClick={() => toolbarHandler(btnField.handler)}
              >
                <i className={`${btnField.icon} btn-icon`}></i>
                {btnField.caption}
              </Button>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default GenericWindowToolbar;
