import {
  ADD_AUTH_TYPE,
  ADD_USER,
  ADD_SUBSCRIPTIONS_DB,
  ADD_CURRENT_DB,
  SET_SHOW_DROPBOX_LOGIN,
  IS_LOGIN_REQUIRED,
  IS_LOGIN_REQUEST_FOR_MAIL
} from '../types';

import _ from 'lodash';

const initialState = {
  user: null,
  authType: null,
  isAuth: false,
  subscriptionsDB: [],
  currentDB: null,
  dropboxCode: '',
  showDropboxLogin: false,
  dropboxMessage: '',
  isLoginRequired: false,
  isLoginRequestForMail: false
};

const addAuthType = (state, payload) => ({
  ...state,
  authType: payload,
});

const addUser = (state, payload) => {
  const isAuth = payload !== null ? true : false;
  const auth = {
    ...state,
    user: payload,
    isAuth,
  };
  return auth;
};

const addSubscriptionsDB = (state, payload) => {
  const subscriptions = {
    ...state,
    subscriptionsDB: payload,
  };
  return subscriptions;
};

const addCurrentDB = (state, payload) => {
  const current = {
    ...state,
    currentDB: payload,
  };
  return current;
};

const setShowDropboxLogin = (state, payload) => ({
  ...state,
  showDropboxLogin: _.get(payload,'show',false),
  dropboxMessage: _.get(payload,'message',''),
});

const setIsLoginRequired = (state, payload) => ({
  ...state,
  isLoginRequired: payload,
});

const setIsLoginRequestForMail = (state, payload) => ({
  ...state,
  isLoginRequestForMail: payload,
});


const handlers = {
  [ADD_AUTH_TYPE]: addAuthType,
  [ADD_USER]: addUser,
  [ADD_SUBSCRIPTIONS_DB]: addSubscriptionsDB,
  [ADD_CURRENT_DB]: addCurrentDB,
  [SET_SHOW_DROPBOX_LOGIN]: setShowDropboxLogin,
  [IS_LOGIN_REQUIRED]: setIsLoginRequired,
  [IS_LOGIN_REQUEST_FOR_MAIL]: setIsLoginRequestForMail
};

export default (state = initialState, action) => {
  const handler = handlers[action.type];
  const newState = handler ? handler(state, action.payload) : state;
  return newState;
};
