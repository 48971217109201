import {
    ADD_TRANSLATION_LIST
} from '../types';

const initialState = {
    translationList: []
}

const addTranslationList = (state, payload) => ({
    ...state,
    translationList: payload,
});


const handlers = {
    [ADD_TRANSLATION_LIST]: addTranslationList
};

export default (state = initialState, action) => {
    const handler = handlers[action.type];
    const newState = handler ? handler(state, action.payload) : state;
    return newState;

}