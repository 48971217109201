export const ADD_DATA = 'ADD_DATA';
export const SCROLL = 'SCROLL';
export const ADD_FRM = 'ADD_FRM';
export const ADD_SCREENSIZE = 'ADD_SCREENSIZE';
export const SET_SORTINGDATA = 'SET_SORTINGDATA';
export const CLEAR_DATA = 'CLEAR_DATA';
export const SET_NAVIGATION_VISIBILITY = 'SET_NAVIGATION_VISIBILITY';
export const SET_FETCH_FUNCTION = 'SET_FETCH_FUNCTION';
export const SET_CURRENT_PAGE_TAG = 'SET_CURRENT_PAGE_TAG';
export const SET_CATCHED_ERROR = 'SET_CATCHED_ERROR';
export const SELECT_ITEM = 'SELECT_ITEM';
export const SELECT_ALL = 'SELECT_ALL';
export const UNSELECT_ITEM = 'UNSELECT_ITEM';
export const UNSELECT_ALL = 'UNSELECT_ALL';
export const ADD_USER = 'ADD_USER';
export const ADD_AUTH_TYPE = 'ADD_AUTH_TYPE';
export const ADD_CALENDAR_EVENTS = 'ADD_CALENDAR_EVENTS';
export const SET_DETAILS = 'SET_DETAILS';
export const ADD_DASHBOARD_ITEMS = 'ADD_DASHBOARD_ITEMS';
export const ADD_DASHBOARD_TYPE = 'ADD_DASHBOARD_TYPE';
export const ADD_DASHBOARD_USERS = 'ADD_DASHBOARD_USERS';
export const ADD_METADATA_FOR_EDIT = 'ADD_METADATA_FOR_EDIT';
export const SET_THEME = 'SET_THEME';
export const ADD_SUBSCRIPTIONS_DB = 'ADD_SUBSCRIPTIONS_DB';
export const ADD_CURRENT_DB = 'ADD_CURRENT_DB';
export const HANDLE_CHANGE_METADATA = 'HANDLE_CHANGE_METADATA';
export const SET_BROWSER_LIST = 'SET_BROWSER_LIST';
export const ADD_AZURE_FILE_LIST = 'ADD_AZURE_FILE_LIST';
export const ADD_ACTIVE_AZURE_CONTAINER = 'ADD_ACTIVE_AZURE_CONTAINER';
export const SELECT_AZURE_FILE = 'SELECT_AZURE_FILE';
export const UNSELECT_AZURE_FILE = 'UNSELECT_AZURE_FILE';
export const DELETE_SELECTED_AZURE_FILES = 'DELETE_SELECTED_AZURE_FILES';
export const SET_DOC_PREVIEW = 'SET_DOC_PREVIEW';

export const ADD_TABLE_LIST = 'ADD_TABLE_LIST';
export const ADD_TABLE_FIELD_LIST = 'ADD_TABLE_FIELD_LIST';
export const ADD_QUERY_LIST = 'ADD_QUERY_LIST';
export const ADD_TRANSLATION_LIST = 'ADD_TRANSLATION_LIST';
export const ADD_ENTITIES = 'ADD_ENTITIES';
export const ADD_ENTITY_ITEM = 'ADD_ENTITY_ITEM';
export const DELETE_ENTITY_ITEM = 'DELETE_ENTITY_ITEM';
export const UPDATE_ENTITY_ITEM = 'UPDATE_ENTITY_ITEM';
export const ADD_CRITERIAS = 'ADD_CRITERIAS';
export const ADD_CRITERIA_ITEM = 'ADD_CRITERIA_ITEM';
export const DELETE_CRITERIA_ITEM = 'DELETE_CRITERIA_ITEM';
export const UPDATE_CRITERIA_ITEM = 'UPDATE_CRITERIA_ITEM';
export const ADD_CURRENT_TABLE = 'ADD_CURRENT_TABLE';
export const ADD_CURRENT_FORMAT_LIST = 'ADD_CURRENT_FORMAT_LIST';
export const ADD_FORMATS = 'ADD_FORMATS';
export const ADD_FORMAT_ITEM = 'ADD_FORMAT_ITEM';
export const DELETE_FORMAT_ITEM = 'DELETE_FORMAT_ITEM';
export const UPDATE_FORMAT_ITEM = 'UPDATE_FORMAT_ITEM';
export const ADD_TABLE_COUNTER = 'ADD_TABLE_COUNTER';

export const ADD_CAPABILITY_PARAMS = 'ADD_CAPABILITY_PARAMS';

export const SET_SHOW_DROPBOX_LOGIN = 'SET_SHOW_DROPBOX_LOGIN';
export const IS_LOGIN_REQUIRED = 'IS_LOGIN_REQUIRED';
export const IS_LOGIN_REQUEST_FOR_MAIL = 'IS_LOGIN_REQUEST_FOR_MAIL';