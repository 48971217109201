import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { genericQuery } from '../../GraphQL';
import { useApolloClient } from 'react-apollo';

const Head = ({ isAuth }: { isAuth: boolean }) => {
  const [companyName, setCompanyName] = useState('360 - Business Tool');
  const apolloClient = useApolloClient();

  useEffect(() => {
    const getCompanyName = async () => {
      const query = genericQuery('cdmsyst', [
        'primaryCompanyUsRelation{ alias }'
      ]);
      const { data } = await apolloClient.query({ query });
      return data.cdmsyst.primaryCompanyUsRelation.alias;
    };
    if (isAuth) {
      getCompanyName()
        .then((companyName) => setCompanyName('360 - ' + companyName))
        .catch((err) => console.log(err));
    }
  }, [isAuth, apolloClient]);

  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{companyName}</title>
    </Helmet>
  );
};

export { Head };
