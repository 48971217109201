import React, { useEffect, useState, FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLazyQuery } from 'react-apollo';
import SimpleBar from 'simplebar-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import {
  loadUser,
  setTheme,
  authorizationType,
  subscriptionDB,
  addCurrentDB,
  addScreenSizeAction,
  setCatchedError,
  getCatchedError,
  setBrowserList,
  getShowDropboxLogin,
  setShowDropboxLogin
} from './redux';
import { googleInit } from './helpers/AuthLogic/googleAuth';
import {
  Header,
  Navigation,
  Alert,
  BrowserList,
  Head,
  CapabilityWrapper,
  DropboxModal,
  AlertBar
} from './components';
import { auth_query, subscriptionsDB_query, currentDB_query } from './GraphQL';
import GenericRouter from './router';

import {
  setAppTheme,
  getLocalStorageItem,
  setLocalStorageItem,
  getRenderedMeta,
  updateFormatListForNavigation
} from './utils';
import { IApp, AlertBarOptions } from './types';
import { Authorization } from './pages';
import { API } from './API';
import _ from 'lodash';
import { MsAuthModule } from './helpers/AuthLogic/msAuth';

import './scss/style.scss';
import './App.scss';

const App: FC<IApp> = ({
  addScreenSizeAction,
  Error,
  setCatchedError,
  isAuth,
  loadUser,
  setTheme,
  theme,
  authorizationType,
  subscriptionDB,
  addCurrentDB,
  browserList,
  setBrowserList,
  showDropboxLogin,
  setShowDropboxLogin
}) => {
  const authModule = new MsAuthModule();
  const [isLoginStarted] = useLocalStorage('isLoginStarted');
  const [metadatasList] = useLocalStorage('metadatasList');

  const history = useHistory();
  const location = useLocation();

  const [menuMetadata, setMenuMetadata] = useState<any>(
    _.find(metadatasList, ['fileName', `Mnu.main.json`])
  );

  const [menuAttributes, setMenuAttributes] = useState<Array<string>>([]);
  const [getUser, { data }] = useLazyQuery<any>(auth_query);
  const [getSubscriptionsDB, dbList] = useLazyQuery(subscriptionsDB_query);
  const [getCurrentDB, currentDB] = useLazyQuery(currentDB_query);
  const [authEmail, setAuthEmail] = useState<string | null>(
    getLocalStorageItem('authEmail')
  );
  const [isMobile, setIsMobile] = useState(false);
  const screenWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  const [navbarType, setNavbarType] = useState({ type: 'default', isMobile });
  const localUserData = getLocalStorageItem('localUserData');
  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });

  useEffect(() => {
    if (!localUserData) {
      (async () => {
        await googleInit();
      })();
      authorizationType(localStorage.getItem('authType'));
      setAuthEmail(localStorage.getItem('authEmail'));
    }
    if (!getLocalStorageItem('recentViewsList')) {
      setLocalStorageItem('recentViewsList', []);
    }
  }, []);

  useEffect(() => {
    if (isLoginStarted) {
      authModule.loadAuthModule();
    }
  }, [isLoginStarted]);

  useEffect(() => {
    if (metadatasList) {
      if (_.find(metadatasList, ['fileName', `Mnu.main.json`])) {
        setMenuMetadata(_.find(metadatasList, ['fileName', `Mnu.main.json`]));
      }
    }
  }, [metadatasList]);

  useEffect(() => {
    if (menuMetadata) {
      setMenuAttributes(menuMetadata.data.menu.attributes);
    }
  }, [menuMetadata]);

  useEffect(() => {
    if (!isAuth && !localUserData) {
      history.push('/auth');
    }
  }, [isAuth]);

  useEffect(() => {
    if (authEmail || localUserData) {
      getUser({ variables: { email: authEmail || localUserData.email } });
      getSubscriptionsDB();
      getCurrentDB();
    } else {
      history.push('/auth');
    }
  }, [authEmail]);

  useEffect(() => {
    let userData = localUserData;
    if (data) {
      setLocalStorageItem('localUserData', data.cdmuser);
      userData = localUserData || data.cdmuser;
    }
    if (userData) {
      loadUser(userData);
      setTheme(_.get(userData, 'fax', null));
      setAppTheme(_.get(userData, 'fax', null));
      if (userData.email && window.location.pathname === '/auth') {
        history.push('/SalesHome');
      }
    }
  }, [data]);

  useEffect(() => {
    if (dbList.data) {
      subscriptionDB(_.get(dbList.data, 'getSubscriptionDB', []));
    }
  }, [dbList]);

  useEffect(() => {
    if (currentDB.data) {
      addCurrentDB(_.get(currentDB.data, 'getCurrentDB.databaseName', []));
    }
  }, [currentDB]);

  useEffect(() => {
    if (location.pathname === '/dropboxAuth') {
      const params: URLSearchParams = new URLSearchParams(location.search);
      const code: string | null = params.get('code');
      setShowDropboxLogin({ show: false, message: '' });
      if (code) {
        const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
        const headers = {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          typeauthorization: localStorage.getItem('authType')
        };
        api
          .getRestFileStorageDropboxAuth({
            code: code,
            headers
          })
          .then((res) => {
            const successAlert = {
              show: true,
              alertText: res.message,
              variant: 'success'
            };
            setAlertBarOptions(successAlert);
            setTimeout(() => {
              setAlertBarOptions({
                ...successAlert,
                show: false
              });
            }, 3500);
            writeStorage('dropboxAuth', true);
          })
          .catch((err) => {
            setAlertBarOptions({
              show: true,
              alertText: err.message,
              variant: 'danger'
            });
            setCatchedError(err);
          });
      }
    }
    if (location.pathname === '/SalesHome' || location.pathname === '/') {
      navbarType.type !== 'condensed' && setNavBar('condensed');
    }
  }, [location]);

  const setNavBar = (type: string) => {
    const collapsedNavStyle = 'sidebar-enable';
    const bodyElement = document.getElementsByTagName('body')[0];
    setNavbarType({ type, isMobile });
    if (type === 'condensed') {
      bodyElement.className += collapsedNavStyle;
    } else {
      bodyElement.className = bodyElement.className.replaceAll(
        collapsedNavStyle,
        ''
      );
    }
  };
  const toggleMenuCallback = () => {
    if (navbarType.type === 'default') {
      setNavBar('condensed');
    } else if (navbarType.type === 'condensed') {
      setNavBar('default');
    }
  };

  useEffect(() => {
    if (screenWidth < 768) addScreenSizeAction('XS');
    if (screenWidth >= 768 && screenWidth < 1024) addScreenSizeAction('SM');
    if (screenWidth >= 1024 && screenWidth < 1280) addScreenSizeAction('MD');
    if (screenWidth >= 1280) addScreenSizeAction('LG');
  }, [addScreenSizeAction, screenWidth]);

  const closeHandler = () => {
    setCatchedError('');
  };

  return (
    <div className='App'>
      <Head isAuth={isAuth} />
      {alertBarOptions.show && (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      )}
      {browserList && (
        <BrowserList
          list={browserList}
          setList={setBrowserList}
          formType={'Hotkeys'}
          onClickAction={() => {}}
          header={''}
        />
      )}
      {Error && (
        <Alert type='error' message={Error} closeHandler={closeHandler} />
      )}
      {showDropboxLogin && <DropboxModal />}
      <div className='body'>
        {isAuth ? (
          <>
            <Header
              className='unscrollable'
              toggleMenuCallback={toggleMenuCallback}
              isMobile={isMobile}
            />
            {navbarType.type !== 'condensed' ? (
              <SimpleBar className='simpleBar' style={{ maxHeight: '100%' }}>
                <Navigation />
              </SimpleBar>
            ) : (
              <Navigation />
            )}
            <div className={'wrapper'}>
              <CapabilityWrapper />
              <GenericRouter menuAttributes={menuAttributes} />
            </div>
          </>
        ) : (
          <div className={'wrapper'}>
            <Authorization />
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  Error: getCatchedError(state),
  isAuth: state.authorization.isAuth,
  theme: state.utils.theme,
  browserList: state.utils.browserList,
  showDropboxLogin: getShowDropboxLogin(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addScreenSizeAction,
      setCatchedError,
      authorizationType,
      loadUser,
      setTheme,
      subscriptionDB,
      addCurrentDB,
      setBrowserList,
      setShowDropboxLogin
    },
    dispatch
  );
};
export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(App);
