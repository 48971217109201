import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { TFieldsValues } from '../types';
import { genericQuery, genericMutation } from '../GraphQL';

import {
  getLocalStorageItem,
  getRenderedMeta,
  showSuccessMessage
} from '../utils';

import {
  updateCapabilityState,
  callMethodFromBackend
} from '../components/GenericToolbar/handlers';

interface ISTDCampaign2Company {
  apolloClient: any;
  history: RouteComponentProps['history'];
  setAlertBarOptions: (alertBarOptions: TFieldsValues) => void;
  selectedItems: TFieldsValues[];
  setSelectionModal: (alertBarOptions: TFieldsValues) => void;
  setDialogWindowProps: (dialogProps: TFieldsValues) => void;
  refetch: Function;
}

export class STDCampaign2Company {
  private selectedItems;
  private apolloClient;
  private history;
  private setAlertBarOptions;
  private setSelectionModal;
  private setDialogWindowProps;
  private refetch;

  constructor(classProps: ISTDCampaign2Company) {
    this.apolloClient = classProps.apolloClient;
    this.history = classProps.history;
    this.setAlertBarOptions = classProps.setAlertBarOptions;
    this.selectedItems = classProps.selectedItems;
    this.setSelectionModal = classProps.setSelectionModal;
    this.setDialogWindowProps = classProps.setDialogWindowProps;
    this.refetch = classProps.refetch;
  }

  public capabilityMarkDone = async () => {
    const meta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `lst.spplCampaignOutcomes.Standard.json`
    );
    const { fields } = meta;
    const { id } = this.selectedItems[0];
    const query = genericQuery(
      'spplCampaignOutcomes',
      fields.map((field: { attribute: string }) => field.attribute),
      'orderBy:code'
    );
    const { data } = await this.apolloClient.query({ query });
    this.setSelectionModal({
      show: true,
      options: data?.spplCampaignOutcomes || [],
      fields: fields,
      onConfirmHandler: ({ option }: { option: TFieldsValues }) => {
        const mutation = genericMutation(
          'cdmca2C',
          'update',
          `id:"${id}",done:true, primaryOutcome:"${option.id}"`
        );
        this.apolloClient.mutate({ mutation }).then(async (res: any) => {
          showSuccessMessage(this.setAlertBarOptions);
          setTimeout(() => {
            this.history.push('/ParticipantsOverview', { showListModal: true });
          }, 500);
        });
      }
    });
  };

  public capabilityMarkUnDone = async () => {
    const { id } = this.selectedItems[0];
    const dialogMeta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `Dlg.ConfirmRemoveDone.json`
    );
    this.setDialogWindowProps &&
      this.setDialogWindowProps({
        show: true,
        meta: dialogMeta.data?.form,
        handlers: {
          confirm: () => {
            const mutation = genericMutation(
              'cdmca2C',
              'update',
              `id:"${id}",done:false`
            );
            this.apolloClient.mutate({ mutation }).then((res: any) => {
              showSuccessMessage(this.setAlertBarOptions);
            });
          },
          cancel: () =>
            this.setDialogWindowProps({ show: false, meta: {}, handlers: {} })
        }
      });
  };

  public capabilityCreateActivities = async () => {
    const selectedIds = this.selectedItems.map(
      (item: TFieldsValues) => `"${item.id}"`
    );
    const query = genericQuery(
      'cdmca2Cs',
      ['primComp'],
      `idIn:[${selectedIds}]`
    );
    const { data } = await this.apolloClient.query({ query });
    if (data.cdmca2Cs) {
      const ids = data.cdmca2Cs.map(
        (item: { primComp: string }) => item.primComp
      );
      await updateCapabilityState({
        step: 0,
        method: 'CDMModel.capabilityCreateActivities',
        args: {},
        accessTokenRequired: true,
        ids
      });
      await callMethodFromBackend(this.setAlertBarOptions);
    }
  };

  private onMoveToCampaign = async (id: string) => {
    this.selectedItems.map((item: TFieldsValues) => {
      const mutation = genericMutation(
        'cdmca2C',
        'update',
        `id:"${item.id}", primCamp:"${id}"`
      );
      this.apolloClient.mutate({ mutation }).then((res: any) => {
        this.refetch && this.refetch();
        showSuccessMessage(this.setAlertBarOptions);
      });
    });
  };

  private selectCampaignFromList = async (onConfirmHandler: Function) => {
    const meta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `Frm.CampaignOverview.json`
    );
    const { fields, dataSource } = meta.data.form.mainGroup[0].fullGrid;
    const query = genericQuery(dataSource, fields, 'doneEq:false');
    const { data } = await this.apolloClient.query({ query });
    this.setSelectionModal({
      show: true,
      options: data[dataSource] || [],
      fields: fields,
      onConfirmHandler
    });
  };

  public capabilityMoveToCampaign = async () => {
    const onConfirmHandler = ({ option }: { option: TFieldsValues }) => {
      this.onMoveToCampaign(option.id);
    };
    this.selectCampaignFromList(onConfirmHandler);
  };

  public copyToOtherCampaign = () => {
    const onConfirmHandler = ({ option }: { option: TFieldsValues }) => {
      console.log(option);
    };
    this.selectCampaignFromList(onConfirmHandler);
  };

  public capabilityOpenUserCalendar = async () => {
    const { id } = this.selectedItems[0];
    //Replace crU with primUser
    const responsibleUserQuery = genericQuery(
      'cdmca2C',
      ['primUser:crU'],
      `id:"${id}"`
    );
    const responsibleUser = await this.apolloClient.query({
      query: responsibleUserQuery
    });
    const { cdmca2C } = responsibleUser.data;
    const usersMeta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `lst.cdmusers.Standard.json`
    );
    const { fields } = usersMeta;
    const query = genericQuery(
      'cdmusers',
      fields.map((field: { attribute: string }) => field.attribute),
      'orderBy:uName'
    );
    const usersList = await this.apolloClient.query({ query });
    const { cdmusers } = usersList.data;
    let data = cdmusers;
    const defaultUser = data.find(
      (user: TFieldsValues) => user.id === cdmca2C.primUser
    );
    data = data.filter((user: TFieldsValues) => user.id !== cdmca2C.primUser);
    data.unshift(defaultUser);
    this.setSelectionModal({
      show: true,
      options: data || [],
      fields: fields,
      onConfirmHandler: async ({ option }: { option: TFieldsValues }) => {
        this.history.push({
          pathname: '/AppointmentCalendarUsers',
          state: { ...option }
        });
      }
    });
  };

  public capabilityMakeReCall = () => {
    console.log('capabilityMakeReCall');
  };

  public capabilityMailingAssign = async () => {
    const meta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `lst.tszMailings.Simple.json`
    );
    const { fields } = meta;
    const query = genericQuery(
      'tszMailings',
      fields.map((field: { attribute: string }) => field.attribute),
      'orderByDesc:dato, orderBy:head'
    );
    const { data } = await this.apolloClient.query({ query });
    const { id } = this.selectedItems[0];
    this.setSelectionModal({
      show: true,
      options: data.tszMailings || [],
      fields: fields,
      onConfirmHandler: async ({ option }: { option: TFieldsValues }) => {
        console.log(option);
      }
    });
  };

  public capabilityCreateForecast = async () => {
    console.log('capabilityCreateForecast');
  };
}
