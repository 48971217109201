import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MenuDropdown from '../MenuDropdown';
import { Breadcrumb } from '..';
import logoImg from '../../assets/photos/logoLight.png';
import logoImgSm from '../../assets/photos/logoLight_sm.png';
import logoImgDark from '../../assets/photos/logoDark.png';
import logoImgDarkSm from '../../assets/photos/logoDark_sm.png';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';

// Import other Dropdown

const TopBar = (props: any) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [metadatasList]: any = useLocalStorage('metadatasList');

  const [menuMetadata, setMenuMetadata] = useState(
    _.find(metadatasList, ['fileName', `Mnu.main.json`])
  );

  useEffect(() => {
    if (metadatasList) {
      if (_.find(metadatasList, ['fileName', `Mnu.main.json`])) {
        setMenuMetadata(_.find(metadatasList, ['fileName', `Mnu.main.json`]));
      }
    }
  }, [metadatasList]);

  const toolbarItems = menuMetadata?.data?.menu?.toolbar || {};

  const theme = props.theme;
  /**
   * Toggle Search
   */
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  /**
   * Toggle sidebar
   */
  const toggleMenu = () => {
    props.toggleMenuCallback();
  };

  /**
   * Toggle full screen
   */

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ // @ts-ignore
      !document.mozFullScreenElement && // @ts-ignore
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen(); // @ts-ignore
      } else if (document.documentElement.mozRequestFullScreen) {
        // @ts-ignore
        document.documentElement.mozRequestFullScreen(); // @ts-ignore
      } else if (document.documentElement.webkitRequestFullscreen) {
        // @ts-ignore
        document.documentElement.webkitRequestFullscreen(
          // @ts-ignore
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      // @ts-ignore
      if (document.cancelFullScreen) {
        // @ts-ignore
        document.cancelFullScreen(); // @ts-ignore
      } else if (document.mozCancelFullScreen) {
        // @ts-ignore
        document.mozCancelFullScreen(); // @ts-ignore
      } else if (document.webkitCancelFullScreen) {
        // @ts-ignore
        document.webkitCancelFullScreen(); // @ts-ignore
      }
    }
  };

  useEffect(() => {}, [theme]);
  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header' id='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box'>
              {theme === 'light' ? (
                <Link to='/SalesHome' className='logo'>
                  <span className='logo-md'>
                    <img src={logoImgDark} alt='' height='50' />
                  </span>
                  <span className='logo-sm'>
                    <img src={logoImgDarkSm} alt='' height='25' />
                  </span>
                </Link>
              ) : (
                <Link to='/SalesHome' className='logo'>
                  <span className='logo-md'>
                    <img src={logoImg} alt='' height='50' />
                  </span>
                  <span className='logo-sm'>
                    <img src={logoImgSm} alt='' height='25' />
                  </span>
                </Link>
              )}
            </div>
            <button
              type='button'
              onClick={toggleMenu}
              className='btn btn-sm px-3 font-size-24 header-item waves-effect'
              id='vertical-menu-btn'
            >
              <i className='mdi mdi-menu'></i>
            </button>
            <Breadcrumb />
          </div>

          <div className='d-flex align-items-center'>
            {toolbarItems?.entries?.map((item: any, idx: number) => (
              <MenuDropdown key={idx} item={item} />
            ))}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  theme: state.utils.theme
});

export default connect(mapStateToProps)(withRouter(TopBar));
