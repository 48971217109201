import { Configuration, LogLevel } from '@azure/msal-browser';
import {  writeStorage } from '@rehooks/local-storage';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MS_APPLICATION_ID ?? '',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: process.env.REACT_APP_REDIRECT_URI_MICROSOFT,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    iframeHashTimeout: 10000,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info: 
            if(message.includes("loginFailure")) {
              writeStorage('isLoginInProgress', false);
            }
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openId']
};

export const loginWithMailRequest = {
  scopes: ['User.Read', 'email', 'Mail.Read', 'Mail.Send', 'Mail.ReadWrite']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me'
};
