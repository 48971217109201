import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Col, Row, Button } from 'react-bootstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useApolloClient } from 'react-apollo';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';

import { genericQuery } from '../../GraphQL';
import { convertSearchOptionToText } from '../../utils';

const SearchWindow = ({
  showModal,
  setShowModal,
  searchFields,
  filterExpression,
  setFilterExpression
}) => {
  const [metadatasList] = useLocalStorage('metadatasList');

  const [fieldsLookups, setFieldsLookups] = useState({});
  const [filters, setFilters] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [datasetValues, setDatasetValues] = useState({});
  const [currentExpression, setCurrentExpression] = useState(filterExpression);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (metadatasList && metadatasList.length) {
      if (_.find(metadatasList, ['fileName', 'Frm.Lookups.json'])) {
        setFieldsLookups(
          _.find(metadatasList, ['fileName', 'Frm.Lookups.json'])
        );
      }
    }
  }, [metadatasList]);

  const getOptions = (attribute) => {
    const queryField = searchFields.find(
      (field) => field.attribute === attribute
    );
    const { relationDataSource } = queryField;
    const { fields } = fieldsLookups.lookups.find(
      (lookup) => lookup.name === `${relationDataSource}Lookup`
    );
    const query = genericQuery(relationDataSource, [fields], '', '', 0);
    apolloClient.query({ query }).then((res) => {
      const options = res.data[relationDataSource].map((option) => {
        return convertSearchOptionToText(option);
      });
      setDropdownOptions({
        ...dropdownOptions,
        [relationDataSource]: options
      });
    });
  };

  const filterRelatedField = (e, attribute) => {
    const { value } = e.target;
    if (value.length === 0) {
      const clearedValues = selectedValues;
      delete clearedValues[attribute];
      const clearedFilters = filters;
      delete clearedFilters[attribute.replace('Relation', '')];
      setSelectedValues(clearedValues);
      delete datasetValues[attribute];
      setDatasetValues(datasetValues);
      setFilters(clearedFilters);
      const expression = currentExpression
        .split(',')
        .map((filter) => {
          if (filter.includes('prim')) {
            return filters[filter.replace(' ', '').split(':')[0]] ? '' : filter;
          }
          return filter;
        })
        .join(',');

      setCurrentExpression(expression);
      return;
    }
    setDatasetValues({ ...datasetValues, [attribute]: value });
  };

  const applyFilters = () => {
    const filtersArray = currentExpression.split(',');
    Object.entries(filters).map(([key, value]) => {
      const filterValue = ` ${key}:${
        key.includes('Date') || key.includes('Like') || key.includes('prim')
          ? `"${value}"`
          : value
      } `;
      if (value !== undefined && value !== '') {
        if (!currentExpression.includes(key)) {
          filtersArray.push(filterValue);
        } else {
          const index = filtersArray.findIndex((element) =>
            element.includes(key)
          );
          filtersArray.splice(index, 1, filterValue);
        }
      }
    });

    const updatedFilter = filtersArray
      .map((filter) => {
        return filter.includes(':unset')
          ? ''
          : filters[filter.replace(' ', '').split(':')[0]]
          ? filter
          : '';
      })
      .join(',');
    setShowModal(false);
    setCurrentExpression(updatedFilter);
    setFilterExpression(updatedFilter);
  };

  const formBody = (field, group = false) => {
    const {
      caption,
      attribute,
      relationDataSource,
      inputType,
      defCol,
      label,
      value
    } = field;

    return (
      <Col sm={defCol ? defCol : 4}>
        {!attribute.toLowerCase().includes('relation') ? (
          <AvField
            key={`input-form-${caption}`}
            name={`${attribute}-${caption}`}
            label={label && `${label}`}
            id={`${attribute}-${caption}${label && `-${label}`}`}
            defaultChecked={
              inputType === 'radio' && `${value}` === selectedValues[attribute]
            }
            defaultValue={selectedValues[attribute]}
            data-optionvalue={field.value !== undefined ? field.value : ''}
            type={inputType ? inputType : 'text'}
            onChange={(e) => {
              if (field.value !== undefined) {
                let val = e.target.dataset.optionvalue;
                setSelectedValues({
                  ...selectedValues,
                  [attribute]: val
                });
                setFilters({
                  ...filters,
                  [attribute]: attribute.toLowerCase().includes('like')
                    ? `${val}%`
                    : val
                });
              } else {
                setSelectedValues({
                  ...selectedValues,
                  [attribute]: e.target.value
                });
                setFilters({
                  ...filters,
                  [attribute]: attribute.toLowerCase().includes('like')
                    ? `${e.target.value}%`
                    : e.target.value
                });
              }
            }}
          />
        ) : (
          <AsyncTypeahead
            isLoading={false}
            id={`select-${attribute}`}
            className='form-group'
            labelKey={'optionText'}
            minLength={0}
            useCache={true}
            onSearch={() => getOptions(attribute)}
            onFocus={() => getOptions(attribute)}
            options={dropdownOptions[relationDataSource]}
            defaultInputValue={
              selectedValues[attribute] || datasetValues[attribute]
            }
            onChange={(selected) => {
              if (selected[0]) {
                setSelectedValues({
                  ...selectedValues,
                  [attribute]: selected[0][attribute]
                });
                setFilters({
                  ...filters,
                  [attribute.replace('Relation', '')]: selected[0].id
                });
              } else {
                const unsetFilters = filters;
                delete unsetFilters[attribute.replace('Relation', '')];
                setFilters(unsetFilters);
              }
            }}
            onBlur={(e) => filterRelatedField(e, attribute)}
            renderMenuItemChildren={(option, props) => (
              <Fragment>
                <span>{option.optionText}</span>
              </Fragment>
            )}
          />
        )}
      </Col>
    );
  };
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row className='modal-header-row'>
          <Button onClick={applyFilters}>
            <i className={`icon-search btn-icon`}></i>
            Confirm
          </Button>
        </Row>
        {searchFields &&
          searchFields.map((field) => {
            const { caption, group } = field;

            return (
              <AvForm>
                <Row>
                  <Col sm={4}>{caption}</Col>
                  {group
                    ? group.map((groupElement) => formBody(groupElement, true))
                    : formBody(field)}
                </Row>
              </AvForm>
            );
          })}
      </Modal.Body>
    </Modal>
  );
};

export default SearchWindow;
