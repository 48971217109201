import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
//@ts-ignore
import { AvForm } from 'availity-reactstrap-validation';
import _ from 'lodash';
import { InputRender, ListModalWrapper } from '.';
import { TFieldsValues, EventType } from '../../../types';
import { RadiosComponent } from '../../../pages/DetailsPage/components';
import {
  decimalToColor,
  colorToDecimal,
  deformatValue,
  divideNestedAttribute,
  textGenerator
} from '../../../utils';
import { getDefaultValue } from '../functions';
import { IconWrapper } from '../../../components';

const RegularView = ({
  layoutMeta,
  fieldsValues,
  renderedData,
  setRenderedData,
  fieldClickHandler,
  renderAlertBar,
  editedFields,
  setEditedFields
}: any) => {
  const { fields } = layoutMeta.dataEntry;
  const [listModal, showListModal] = useState({
    show: false,
    field: null,
    defaultSearch: ''
  });
  const history = useHistory();
  const itemId = history.location.pathname.replace('-', ' ').split(' ')[1];
  const selectItem = (item: TFieldsValues) => {
    const { attribute } = listModal.field || { attribute: '' };
    const nestedAttr = divideNestedAttribute(attribute);
    const mainAttribute = nestedAttr[0].replace('Relation', '');
    const data = renderedData;
    if (!data[nestedAttr[0]] || data[nestedAttr[0]] === null) {
      data[nestedAttr[0]] = {};
    }
    if (data[nestedAttr[0]]) {
      nestedAttr[1].split(' ').map((attribute: string) => {
        data[nestedAttr[0]][attribute] = item[attribute];
      });
    } else {
      data[nestedAttr[0]] = item;
    }
    data[nestedAttr[0]].id = item.id;
    const defaultData = fieldsValues[nestedAttr[0]] || { id: '' };
    setRenderedData({
      ...renderedData,
      ...data,
      [mainAttribute]: item.id,
      [`${mainAttribute}Relation`]: item
    });
    setEditedFields({ ...editedFields, [mainAttribute]: item.id });
    window.onbeforeunload = () => 'Are you sure you want to leave?';
    showListModal({ show: false, field: null, defaultSearch: '' });
  };

  const cacheEditedFields = (field: any, value: string | number | boolean) => {
    let editedValue = value;
    const { attribute, inputType } = field;
    if (value !== fieldsValues[attribute]) {
      editedValue = deformatValue(inputType, value);
      setEditedFields({ ...editedFields, [attribute]: editedValue });
    } else {
      const cachedEdits = editedFields;
      delete cachedEdits[attribute];
      setEditedFields(cachedEdits);
    }
  };

  const handleChange = (
    event: any,
    field: { attribute: string; inputType: string },
    valueIsString = true
  ) => {
    const { attribute, inputType } = field;
    let type = '';
    let checked = false;
    let inputValue: number | string | boolean = '';
    if (event) {
      type = event.target.type;
      checked = event.target.checked;
      inputValue = valueIsString
        ? event.target.value
        : parseInt(event.target.value);
    }
    if (inputType === 'color') {
      inputValue = colorToDecimal(inputValue);
    }
    if (inputType === 'radio') {
      if (inputValue === 'true' || inputValue === 'false') {
        inputValue = inputValue === 'true';
      }
    }
    if (inputType === 'checkbox') {
      inputValue = checked;
    }
    renderedData[attribute] = inputValue;
    cacheEditedFields(field, inputValue);
    setRenderedData({ ...renderedData, [attribute]: inputValue });
  };
  const onRelationChange = (e: EventType, attribute: string) => {
    const [mainAttribute] = divideNestedAttribute(attribute);
    const editedRelation = renderedData[mainAttribute] || {};
    editedRelation.relationSearchValue = e.target.value;
    setRenderedData({ ...renderedData, [mainAttribute]: editedRelation });
  };
  const printHandlerButtons = (field: any) => {
    const printButton = (handler: any) => (
      <Button
        className={'handler-button'}
        style={{ fontSize: field.attribute ? '18px' : '25px' }}
        onClick={() => fieldClickHandler(handler, field)}
      >
        <IconWrapper iconName={handler.icon} />
      </Button>
    );
    return (
      field.handlers &&
      field.handlers.map((handler: any) => {
        if (handler.tooltip) {
          return (
            <OverlayTrigger
              overlay={<Tooltip id='tooltip'>{handler.tooltip}</Tooltip>}
            >
              {printButton(handler)}
            </OverlayTrigger>
          );
        } else {
          return <>{printButton(handler)}</>;
        }
      })
    );
  };
  const renderCheckBoxes = (field: any, isGrouped = false) => {
    return (
      <Col className={isGrouped ? 'rendered-field-group' : 'details-fields'}>
        <label>
          <input
            id={`${field.inputType}-${field.attribute}`}
            type={field.inputType}
            defaultChecked={renderedData[field.attribute]}
            disabled={renderedData.delD || field.disabled}
            onClick={(e) => handleChange(e, field, false)}
          />
          {field.caption}
        </label>
      </Col>
    );
  };

  useEffect(() => {
    if (fieldsValues) {
      if (Object.keys(fieldsValues).length > 0 && !renderedData) {
        setRenderedData(fieldsValues);
        const attributes = {};
        fields.map((field: any) => {
          if (field.attribute) {
            // @ts-ignore
            attributes[field.attribute] = fieldsValues[field.attribute]
              ? fieldsValues[field.attribute]
              : '';
          }
        });
      }
    }
  }, [fieldsValues, renderedData]);

  const renderInput = (field: any, i: number) => {
    const {
      dataSource,
      caption,
      inputType,
      attribute,
      group,
      validation,
      required,
      value
    } = field;
    if (dataSource) {
      let defaultInput = '';
      return (
        <Row className='editData-row'>
          <Col sm={4}>
            {validation || required ? (
              <>
                {caption}
                <span className='edit-input_important'>*</span>
              </>
            ) : (
              caption
            )}
          </Col>
          <Col
            sm={field.handlers ? 7 - field.handlers.length : 6}
            style={{
              width:
                window.innerWidth < 768
                  ? field?.handlers?.length > 1
                    ? '75%'
                    : '85%'
                  : '100%'
            }}
          >
            <Form.Group>
              <Row>
                <Col sm={12}>
                  {field.attribute && field.attribute.includes('Relation') ? (
                    <>
                      <Form.Control
                        className='rendered-field'
                        value={getDefaultValue(
                          renderedData,
                          attribute,
                          inputType
                        )}
                        onFocus={(e: EventType) => {
                          defaultInput = e.target.value;
                          e.target.select();
                        }}
                        style={{
                          textDecoration: renderedData[
                            divideNestedAttribute(field.attribute)[0]
                          ]?.delD
                            ? 'line-through'
                            : field.form
                            ? 'underline'
                            : 'none'
                        }}
                        onChange={(e: EventType) => {
                          if (renderedData.delD || field.disabled) {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                          onRelationChange(e, attribute);
                        }}
                        onDoubleClick={() =>
                          field.form &&
                          history.push({
                            pathname: `${field.form}-${
                              renderedData[divideNestedAttribute(attribute)[0]]
                                ?.id
                            }`,
                            state: { dataSource }
                          })
                        }
                        onBlur={(e: EventType) => {
                          const { value } = e.target;
                          value !== ''
                            ? value !== defaultInput &&
                              showListModal({
                                show: true,
                                field,
                                defaultSearch: value
                              })
                            : (e.target.value = defaultInput);
                        }}
                      />
                    </>
                  ) : (
                    <>{getDefaultValue(renderedData, attribute)}</>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
          {field.format && (
            <Button
              className={'handler-button'}
              disabled={renderedData.delD || !field.format}
              onClick={() =>
                field.format &&
                showListModal({
                  show: true,
                  field,
                  defaultSearch: ''
                })
              }
            >
              <i className='icon-buttonDropDownSelect'></i>
            </Button>
          )}
          {printHandlerButtons(field)}
        </Row>
      );
    }
    if (inputType === 'checkbox') {
      return renderCheckBoxes(field);
    }
    if (
      inputType === 'color' &&
      (renderedData[field.attribute] || itemId === 'create')
    ) {
      return (
        <Row>
          <Col sm={4}>{caption}</Col>
          <Col sm={6}>
            <input
              id={`${field.inputType}-${field.attribute}`}
              className={`rendered-field_color`}
              type={field.inputType}
              onChange={(e) => handleChange(e, field)}
              style={{ width: '100%' }}
              disabled={renderedData.delD || field.disabled}
              value={decimalToColor(renderedData[field.attribute])}
            />
          </Col>
        </Row>
      );
    }
    if (inputType === 'radio') {
      return (
        <RadiosComponent
          field={field}
          renderedData={renderedData}
          handleChange={handleChange}
          editing={true}
        />
      );
    }
    if (
      field.enum &&
      (renderedData[attribute] !== undefined ||
        renderedData[attribute] !== null ||
        window.location.pathname.includes('-create'))
    ) {
      return (
        <Row>
          <Col sm={4}>{caption}</Col>
          <Col sm={6}>
            <select
              id={`${caption}-select`}
              className='form-control rendered-field rendered-field_enum'
              value={
                (renderedData[attribute] !== undefined &&
                  renderedData[attribute] !== null &&
                  field.enum.find(
                    (option: { name: string }) =>
                      option.name === renderedData[attribute]?.toString()
                  )?.caption) ||
                ''
              }
              disabled={renderedData.delD || field.disabled}
              onChange={(e: any) => {
                const { value } = e.target;
                const selectedEnum = field.enum.find(
                  (option: { name: string; caption: string }) =>
                    option.caption === value
                );
                handleChange({ target: { value: selectedEnum.name } }, field);
              }}
            >
              {!renderedData[attribute] && (
                <option style={{ display: 'none' }} value={''}></option>
              )}
              {field.enum.map((option: { name: string; caption: string }) => (
                <option value={option.caption}>{option.caption}</option>
              ))}
            </select>
          </Col>
        </Row>
      );
    }
    if (!group) {
      return (
        <InputRender
          caption={
            validation || required ? (
              <>
                {caption}
                <span className='edit-input_important'>*</span>
              </>
            ) : (
              caption
            )
          }
          field={field}
          renderedData={renderedData}
          setRenderedData={setRenderedData}
          editField={(value: any) =>
            setEditedFields({ ...editedFields, ...value })
          }
          className='rendered-field'
          key={`inputRender-${caption}-${i}`}
          keyProp={`${caption}-${i}`}
          defCol={6}
          itemId={itemId}
          disabled={renderedData.delD || field.disabled}
          defaultValue={
            value
              ? value
              : typeof renderedData[attribute] !== 'object'
              ? renderedData[attribute]
              : textGenerator(fieldsValues, {
                  attribute,
                  type: field.inputType
                })
          }
          onChange={(e: EventType) => handleChange(e, field, field)}
          fieldClickHandler={fieldClickHandler}
          renderAlertBar={renderAlertBar}
          printHandlerButtons={printHandlerButtons}
        />
      );
    } else {
      return (
        <Row style={{ marginLeft: '-12px', marginBottom: '10px' }}>
          {caption && <Col sm={4}>{caption}</Col>}
          <Col>
            <Row className='details-fields '>
              {group.map((groupElement: any, i: number) => {
                const { inputType, defCol, attribute, disabled } = groupElement;
                if (inputType === 'checkbox') {
                  return renderCheckBoxes(groupElement, true);
                }
                if (inputType === 'button') {
                  return (
                    <div className={'render-field_button'}>
                      {printHandlerButtons({ handlers: [groupElement] })}
                    </div>
                  );
                }
                if (!groupElement.fieldText) {
                  return (
                    <>
                      {groupElement.caption && (
                        <Col sm={2}>{groupElement.caption}</Col>
                      )}
                      <Col sm={defCol || 3}>
                        <Form.Control
                          id={`group-${attribute}`}
                          type={inputType ? inputType : 'text'}
                          onChange={(e: EventType) =>
                            handleChange(e, field, false)
                          }
                          disabled={renderedData.delD || disabled}
                          defaultValue={renderedData[attribute] || ''}
                        />
                      </Col>
                      {printHandlerButtons(groupElement)}
                    </>
                  );
                }
              })}
            </Row>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div>
      {listModal.show && (
        <ListModalWrapper
          listModal={listModal}
          setListModal={showListModal}
          selectItem={selectItem}
          defaultData={renderedData}
          setRenderedData={setRenderedData}
        />
      )}
      <h3 className={'d-flex'}>
        {layoutMeta.caption}
        <span
          style={{ paddingLeft: '2rem', display: 'flex', alignItems: 'center' }}
        >
          <IconWrapper iconName={layoutMeta?.icon} iconSize={24} />
        </span>
      </h3>
      {layoutMeta.fieldText && (
        <Col sm={12} className='rendered-field-text'>
          {layoutMeta.fieldText}
        </Col>
      )}
      <>
        <Col
          sm={12}
          style={{
            paddingLeft: layoutMeta.dataEntry.fields.find(
              (field: TFieldsValues) => field.type === 'textAreaPlain'
            )
              ? '0px'
              : ''
          }}
        >
          <div className='edit-input-fields'>
            <AvForm>
              {fields.map((field: any, i: number) => (
                <>{renderInput(field, i)}</>
              ))}
            </AvForm>
          </div>
        </Col>
      </>
    </div>
  );
};

export default RegularView;
