import { TFieldsValues } from '../../types';
import { divideNestedAttribute, applyFormatType } from '../../utils';

export const getDefaultValue = (
  data: TFieldsValues,
  nestedAttr: string,
  inputType?: string
) => {
  const [mainAttribute, subAttributes] = divideNestedAttribute(nestedAttr);
  const valuesObject = data[mainAttribute];
  if (valuesObject) {
    if (valuesObject.relationSearchValue) {
      return valuesObject.relationSearchValue;
    }
    const defaultValue = subAttributes.split(' ').map((field: string) => {
      if (field === 'id') {
        return '';
      }
      return data[mainAttribute] ? data[mainAttribute][field] : '';
    });
    if (inputType) {
      return applyFormatType(defaultValue[0], inputType, subAttributes[0]);
    }
    if (defaultValue.slice(1).every((val: string) => val === '')) {
      return defaultValue.join('');
    }
    return defaultValue.join(' ');
  }
};
