import React, { useState } from 'react';

const FileImport = ({ uploadHandler }) => {
  const [fileName, setFileName] = useState();
  const onUpload = (e) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    }
    uploadHandler(e);
  };
  return (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text' id='inputGroupFileAddon01'>
          Upload
        </span>
      </div>
      <div className='custom-file'>
        <input
          type='file'
          className='custom-file-input'
          id='inputGroupFile01'
          aria-describedby='inputGroupFileAddon01'
          accept='.xlsx'
          ref={React.createRef()}
          onDrop={(e) => onUpload(e)}
          onChange={(e) => onUpload(e)}
        />
        <label className='custom-file-label' htmlFor='inputGroupFile01'>
          {fileName ? fileName : 'Drop a file here or click to browse'}
        </label>
      </div>
    </div>
  );
};

export default FileImport;
