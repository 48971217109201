import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { setBrowserList } from '../../redux';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import { getRenderedMeta, updateFormatListForNavigation } from '../../utils';
import { HotKey } from '../../types';

const HotkeyInfo = ({
  setBrowserList
}: {
  browserList: any;
  setBrowserList: any;
}) => {
  const [metadatasList] = useLocalStorage('metadatasList');

  const [contentMeta, setContentMeta] = useState({});
  const [caption, setCaption] = useState('');
  const [hotkeys, setHotkeys] = useState<{ [key: string]: HotKey }>({});

  useEffect(() => {
    if (metadatasList && metadatasList.length) {
      if (_.find(metadatasList, ['fileName', 'Mnu.main.json'])) {
        // @ts-ignore
        setContentMeta(_.find(metadatasList, ['fileName', 'Mnu.main.json']));
      }
    }
  }, [metadatasList]);

  useEffect(() => {
    setCaption(_.get(contentMeta, 'shortcuts', {}).caption || '');
    setHotkeys(_.get(contentMeta, 'shortcuts', {}).hotkeys || {});
  }, [contentMeta]);

  const history = useHistory();

  const handleHotkeys = async (hotkey: HotKey) => {
    const { pathname, state, listBrowser, forms } = hotkey;

    if (listBrowser) {
      setBrowserList(listBrowser);
    } else {
      if (pathname) {
        await getRenderedMeta(
          metadatasList,
          `Frm.${pathname.substring(1)}.json`
        );
        await updateFormatListForNavigation(
          pathname.substring(1),
          [],
          () => {}
        );
        forms &&
          (await Promise.all(
            forms.map(async (form: string) => {
              await getRenderedMeta(metadatasList, `Frm.${form}.json`);
            })
          ));
        history.push({ pathname, state });
      }
    }
  };
  return (
    <Col sm={7} className='tab-component'>
      <Row className={`edit-wrapper`}>
        <Col>
          <Row className='details-caption'>
            <h3>{caption}</h3>
          </Row>
        </Col>
      </Row>
      <div className='edit-input-fields'>
        {Object.entries(hotkeys).map(([key, value]) => {
          return (
            <Row className='hotkey-info' onClick={() => handleHotkeys(value)}>
              <Col>{value.caption}</Col>
              <Col>
                {navigator.userAgent.toLowerCase().includes('mac')
                  ? value.mac
                  : value.win}
              </Col>
            </Row>
          );
        })}
      </div>
    </Col>
  );
};

const mapStateToProps = (state: any) => ({
  browserList: state.utils.browserList
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setBrowserList
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HotkeyInfo);
