import {
  ADD_AUTH_TYPE,
  ADD_USER,
  ADD_SUBSCRIPTIONS_DB,
  ADD_CURRENT_DB,
  SET_SHOW_DROPBOX_LOGIN,
  IS_LOGIN_REQUIRED,
  IS_LOGIN_REQUEST_FOR_MAIL
} from '../types';

export const authorizationType = (payload) => {
  return {
    type: ADD_AUTH_TYPE,
    payload,
  };
};
export const loadUser = (payload) => {
  return {
    type: ADD_USER,
    payload,
  };
};

export const subscriptionDB = (payload) => {
  return {
    type: ADD_SUBSCRIPTIONS_DB,
    payload,
  };
};

export const addCurrentDB = (payload) => {
  return {
    type: ADD_CURRENT_DB,
    payload,
  };
};

export const setShowDropboxLogin = (payload) => {
  return {
    type: SET_SHOW_DROPBOX_LOGIN,
    payload,
  };
};

export const setIsLoginRequired = (payload) => {
  return {
    type: IS_LOGIN_REQUIRED,
    payload,
  };
};

export const setIsLoginRequestForMail = (payload) => {
  return {
    type: IS_LOGIN_REQUEST_FOR_MAIL,
    payload,
  };
};



