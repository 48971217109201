import { ADD_CALENDAR_EVENTS } from '../types';

const initialState = {
  calendarEvents: [],
};

const addCalendarEvents = (state, payload) => ({
  ...state,
  calendarEvents: payload,
});

const handlers = {
  [ADD_CALENDAR_EVENTS]: addCalendarEvents,
};

export default (state = initialState, action) => {
  const handler = handlers[action.type];
  const newState = handler ? handler(state, action.payload) : state;
  return newState;
};
