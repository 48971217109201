import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';

const Input = ({
  icon,
  placeholder,
  type,
  className,
  onChange,
  defaultValue,
}) => {
  return (
    <AvForm className={`${className} custom-input`}>
      {icon}
      <AvField
        name={className}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={defaultValue}
      />
    </AvForm>
  );
};

export default Input;
