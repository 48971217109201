import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TabContent, Row, Collapse, Card, CardBody } from 'reactstrap';
import { useApolloClient } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { useLocalStorage } from '@rehooks/local-storage';
import { components, TabsBar } from './components';
import { AlertBar, GenericToolbar, DocumentPreview } from '../../components';
import { getScreenSize, setCatchedError } from '../../redux';
import { updateMetadataList, setRecentViewList } from '../../utils';
import { TFieldsValues } from '../../types';
import { dispatcher, getDetailName } from '../../helpers';
import { editMutation, updateDescription } from '../../GraphQL';
import { createDefaultFields, getItemId } from './functions';
import { downloadFileHandler } from '../FileManagerAzure/components/handlers';

const DetailsPage = ({
  location,
  screenSize,
  history,
  setCatchedError
}: any) => {
  const { defaultState, origin }: any = history.location.state || {
    defaultState: {}
  };
  const [metadatasList] = useLocalStorage('metadatasList');
  const [menuMetadata] = useState<any>(
    _.find(metadatasList, ['fileName', `Mnu.main.json`])
  );
  const [editedFields, setEditedFields] = useState<TFieldsValues>({});
  const [fieldsValues, setFieldsValues] = useState<TFieldsValues>({});
  const [alertBarOptions, setAlertBarOptions] = useState({
    show: false,
    alertText: '',
    variant: ''
  });
  const [showPreview, setShowPreview] = useState({
    url: '',
    head: '',
    show: false,
    docExt: '',
    blob: new Blob()
  });

  const apolloClient = useApolloClient();
  const pageData = window.location.pathname
    .replaceAll('/', '')
    .replace('-', ' ')
    .split(' ');
  const isSystemSettings = pageData[0] === 'SystemSettings';
  const [itemId] = useState(getItemId());

  const getMetadataFile = async (page: any) => {
    //@ts-ignore
    let detailsMeta = metadatasList.find(
      (meta: { fileName: string }) => meta.fileName === `Frm.${page}.json`
    );
    if (!detailsMeta) {
      try {
        const updatedList = await updateMetadataList(
          `Frm.${page}.json`,
          metadatasList
        );
        detailsMeta = updatedList?.find(
          (meta: { fileName: string }) => meta.fileName === `Frm.${page}.json`
        );
      } catch (err: any) {
        setCatchedError(err.message);
      }
    }
    return detailsMeta;
  };
  const [meta, setMeta] = useState<any>({ data: { form: { tab: [] } } });
  const { hash } = window.location;
  const tabHashId: any =
    hash !== ''
      ? hash.includes('?')
        ? hash.substring(1, hash.indexOf('?'))
        : hash.substring(1)
      : '0';
  const getTabsSorted = (tabs: any) => {
    if (!tabs) {
      return [];
    }
    return isSystemSettings
      ? _.orderBy(tabs, [(tab) => tab.caption.toLowerCase()], ['asc'])
      : tabs;
  };
  const getFieldsArray = (mainGroup: any) => {
    return mainGroup.group
      .map((componentData: any) => {
        if (componentData.group) {
          const groupedFields = componentData.group
            .map((groupComponent: any) => {
              if (groupComponent.component === 'DetailsComponent') {
                return [...groupComponent.dataEntry.fields];
              }
              return [];
            })
            .flat();
          return [...groupedFields];
        }
        if (componentData.component === 'DetailsComponent') {
          return [...componentData.dataEntry.fields];
        }
        return [];
      })
      .flat();
  };
  const getTabFields = (tabs: any) => {
    const mainGroup = tabs[customActiveTab]?.mainGroup
      ? tabs[customActiveTab].mainGroup[0]
      : tabs[customActiveTab];
    return mainGroup ? getFieldsArray(mainGroup) : [];
  };
  const [defaultTabsSet, setDefaultTabsSet] = useState(
    getTabsSorted(meta.data.form.tab)
  );
  const [tabs, setTabs] = useState(defaultTabsSet);
  const [customActiveTab, setCustomActiveTab] = useState(
    tabs[tabHashId] ? tabHashId : '0'
  );
  const [showAccordion, setAccordion] = useState<any>({});

  const [tabFields, setTabFields] = useState(getTabFields(tabs));

  const renderAlertBar = (
    show: boolean,
    alertText: string,
    variant: string
  ) => {
    setAlertBarOptions({
      show,
      alertText,
      variant
    });
    setTimeout(() => {
      setAlertBarOptions({ show: false, alertText: '', variant: '' });
    }, 4000);
  };
  const saveChanges = (id: string) => {
    if (!tabs[customActiveTab]) {
      return new Promise(function (resolve, reject) {
        reject('Layout info doesn`t exist');
      });
    }
    const mainGroup = tabs[customActiveTab].mainGroup
      ? tabs[customActiveTab].mainGroup[0]
      : tabs[customActiveTab];
    const dataSource =
      mainGroup.dataSource || mainGroup.group[0].dataEntry.dataSource;
    const mutationFields =
      itemId === 'create'
        ? createDefaultFields(pageData[0], editedFields)
        : editedFields;
    const updateMutation = editMutation(dataSource, mutationFields, id, {
      dataEntry: { fields: tabFields }
    });
    return apolloClient
      .mutate({ mutation: updateMutation })
      .then((res: any) => {
        dispatcher.dispatch('fieldsUpdated', false);
        dispatcher.dispatch('navigate', true);
        renderAlertBar(true, 'Saved successfully ', 'success');
        if (editedFields.hasOwnProperty('primaryCdmcommRelation')) {
          const cdmcommAction =
            fieldsValues.primaryCdmcommRelation &&
            itemId &&
            fieldsValues.primaryCdmcommRelation.length !==
              editedFields.primaryCdmcommRelation &&
            fieldsValues.primaryCdmcommRelation.length > 0
              ? 'update'
              : 'create';
          let data = editedFields;
          data.primaryCdmcommRelation = editedFields.primaryCdmcommRelation;
          data.primaryCdmcommRelation.map((note: { id: string }) => {
            if (cdmcommAction === 'create') {
              note.id =
                res.data[
                  `${
                    !itemId || itemId === 'create' ? 'create' : 'update'
                  }${dataSource}`
                ].id;
            }
            if (note.id) {
              const descriptionMutation = updateDescription(
                note,
                cdmcommAction
              );
              apolloClient
                .mutate({ mutation: descriptionMutation })
                .catch((err) => {
                  renderAlertBar(true, err.message, 'danger');
                });
            }
          });
        }
        if (history.location.pathname.includes('-create')) {
          const { id } = res.data[`create${dataSource}`];
          const { state } = history.location;
          history.push({
            pathname: `${history.location.pathname.split('-')[0]}-${id}`,
            state: { dataSource: `${dataSource}s` }
          });
        }
      });
  };

  useEffect(() => {
    if (!_.isEmpty(fieldsValues)) {
      const caption = getDetailName(fieldsValues);
      setRecentViewList(caption);
    }
  }, [pageData[1], fieldsValues]);

  useEffect(() => {
    (async () => {
      if (!_.find(metadatasList, ['fileName', `Dlg.SysLeaveConfirm.json`])) {
        await updateMetadataList('Dlg.SysLeaveConfirm.json', metadatasList);
      }
      if (tabs) {
        setCustomActiveTab(tabHashId);
      }
      const meta = await getMetadataFile(
        location.state && location.state.fileName
          ? location.state.fileName
          : pageData[0]
      );
      setMeta(meta);
      const defaultTabsSet = getTabsSorted(meta.data.form.tab);
      setDefaultTabsSet(defaultTabsSet);
      setTabs(defaultTabsSet);
      setTabFields(getTabFields(defaultTabsSet));
    })();
    return () => {
      setEditedFields({});
      setFieldsValues({});
      setDefaultTabsSet([]);
      setTabs([]);
      setMeta({ data: { form: { tab: [] } } });
    };
  }, [pageData[0], pageData[1]]);
  useEffect(() => {
    history.location.state &&
      setEditedFields({
        ...editedFields,
        ...defaultState
      });
  }, [defaultState]);
  useEffect(() => {
    if (defaultTabsSet && Object.keys(showAccordion).length === 0) {
      const accordionTabs = {};
      defaultTabsSet.map((tab: any, i: number) => {
        //@ts-ignore
        accordionTabs[tab.caption] = i !== 0 ? false : true;
      });
      setAccordion(accordionTabs);
    }
  }, []);
  useEffect(() => {
    if (location.search.includes('?doc-')) {
      const fileName = location.search.replace('?doc-', '');
      const fileNameArr = fileName.split('.');
      const head = fileNameArr.slice(0, -1).join('.');
      const docExt = fileNameArr.slice(-1)[0];
      downloadFileHandler(fileName)
        .then((blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          setShowPreview({
            show: true,
            head,
            docExt,
            blob,
            url
          });
        })
        .catch((err) => {
          setCatchedError(err.message);
        });
    } else {
      setShowPreview({
        url: '',
        head: '',
        show: false,
        docExt: '',
        blob: new Blob()
      });
    }
  }, [location.search]);
  useEffect(() => {
    if (tabs.length && tabs[tabHashId]) {
      setCustomActiveTab(tabHashId);
    }
    document.getElementById('editPage-container')?.focus();
  }, [tabs, window.location.hash]);

  try {
    let currentPageName = pageData[0];
    currentPageName = `edit${currentPageName
      .charAt(0)
      .toUpperCase()}${currentPageName.slice(1)}`.replace('edit', '');

    const TabComponentContent = (tab: any) => {
      if (!tab.group) {
        return null;
      }
      const componentsTab = tab.group.map((element: any, index: number) => {
        //@ts-ignore
        const CustomComponent = components[element.component];
        return (
          <>
            {element.group ? (
              <div
                className={`${
                  element.arrange === 'horizontal' && element.defCol
                    ? 'row'
                    : `col-sm-${element.defCol || 6}`
                } tab-component`}
              >
                {element.group.map((groupedComponent: any) => {
                  const CustomComponent = //@ts-ignore
                    components[groupedComponent.component];
                  return (
                    <CustomComponent
                      itemId={itemId}
                      fields={
                        groupedComponent.dataEntry
                          ? groupedComponent.dataEntry.fields
                          : []
                      }
                      layoutMeta={{
                        ...groupedComponent,
                        tabFields: tab.tabFields,
                        defCol: groupedComponent.defCol || 12
                      }}
                      saveTabChanges={saveChanges}
                      editedFields={editedFields}
                      setEditedFields={setEditedFields}
                      setFieldsValues={setFieldsValues}
                      setAlertBarOptions={setAlertBarOptions}
                      renderAlertBar={renderAlertBar}
                      parentDataSource={_.get(
                        meta,
                        'data.form.dataSource',
                        null
                      )}
                    />
                  );
                })}
              </div>
            ) : element.component ? (
              <CustomComponent
                itemId={itemId}
                fields={element.dataEntry ? element.dataEntry.fields : []}
                layoutMeta={{
                  ...element,
                  icon: tabHashId === '0' && index === 0 ? tabs[0].icon : '',
                  tabFields: tab.tabFields
                }}
                saveTabChanges={saveChanges}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
                setFieldsValues={setFieldsValues}
                setAlertBarOptions={setAlertBarOptions}
                renderAlertBar={renderAlertBar}
                parentDataSource={_.get(meta, 'data.form.dataSource', null)}
              />
            ) : null}
          </>
        );
      });
      return componentsTab;
    };
    return (
      <div className='editPage-container pt-2' id='editPage-container'>
        <GenericToolbar
          dataSource={`${
            tabs[0] &&
            (tabs[0].mainGroup
              ? tabs[0].mainGroup[0].dataSource
              : tabs[0].group[0].dataSource)
          }s`}
          toolbar={meta.data.form?.toolbar?.entries || []}
          miniGridData={
            history.location.state ? history.location.state.data : []
          }
          pageOrigin={origin}
        />
        {alertBarOptions.show && (
          <AlertBar
            showAlertBar={alertBarOptions.show}
            hideAlertBar={() =>
              setAlertBarOptions({
                ...alertBarOptions,
                show: !alertBarOptions.show
              })
            }
            alertText={alertBarOptions.alertText}
            color={alertBarOptions.variant}
          />
        )}
        <div
          className={isSystemSettings ? 'row' : 'col'}
          style={{ alignItems: 'start' }}
        >
          <TabsBar
            tabs={tabs}
            customActiveTab={customActiveTab}
            setCustomActiveTab={setCustomActiveTab}
            pageName={pageData[0]}
            setTabs={setTabs}
            getTabsSorted={getTabsSorted}
            defaultTabsSet={defaultTabsSet}
          />

          {screenSize !== 'XS' ? (
            <TabContent
              className={`col-sm-${isSystemSettings ? 9 : 12}`}
              activeTab={customActiveTab}
            >
              {showPreview.show ? (
                <div className={'tabContent_doc-preview'}>
                  <DocumentPreview
                    preview={showPreview}
                    onHide={() =>
                      setShowPreview({
                        url: '',
                        head: '',
                        show: false,
                        docExt: '',
                        blob: new Blob()
                      })
                    }
                  />
                </div>
              ) : (
                <>
                  {getTabsSorted(meta.data.form.tab).map(
                    (tab: any, index: number) => {
                      if (index === parseInt(customActiveTab)) {
                        const mainGroup = tab.mainGroup
                          ? tab.mainGroup[0]
                          : tab;
                        const tabFields = getFieldsArray(mainGroup);
                        return (
                          <div key={`tabpane-${tab.caption}-${index}`}>
                            <Row sm='12' className='tabpane-content'>
                              {TabComponentContent({ ...mainGroup, tabFields })}
                            </Row>
                          </div>
                        );
                      }
                    }
                  )}
                </>
              )}
            </TabContent>
          ) : (
            <div id='accordion' className='accordion'>
              {getTabsSorted(meta.data.form.tab).map(
                (tab: any, index: number) => {
                  const mainGroup = tab.mainGroup ? tab.mainGroup[0] : tab;
                  const tabFields = getFieldsArray(mainGroup);
                  return (
                    <div className='card mb-1'>
                      <div
                        className='card-header p-3'
                        id={`headingOne-${index}`}
                        onClick={() => {
                          setCustomActiveTab(index);
                          history.push({
                            pathname: history.location.pathname.split('#')[0],
                            hash: `${index}`,
                            state: history.location.state
                          });
                          document
                            ?.getElementById(`headingOne-${index}`)
                            ?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'nearest'
                            });
                          setAccordion({
                            [tab.caption]: !showAccordion[tab.caption]
                          });
                        }}
                      >
                        <h5 className='m-0'>
                          <span>
                            {tab.caption}
                            {
                              //@ts-ignore
                              showAccordion[tab.caption] ? (
                                <i className='mdi mdi-chevron-up'></i>
                              ) : (
                                <i className='mdi mdi-chevron-down'></i>
                              )
                            }
                          </span>
                        </h5>
                      </div>
                      {index === parseInt(customActiveTab) && (
                        //@ts-ignore
                        <Collapse isOpen={showAccordion[tab.caption]}>
                          <Card>
                            {showAccordion[tab.caption] && (
                              <CardBody>
                                {tab.mainGroup &&
                                  TabComponentContent({
                                    ...mainGroup,
                                    tabFields
                                  })}
                              </CardBody>
                            )}
                          </Card>
                        </Collapse>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
    //@ts-ignore
    const errorText = err.message.slice(0, _.indexOf('at'));
    return (
      <div className='editPage-container'>
        {/* @ts-ignore */}
        <AlertBar alertText={errorText} color={'danger'} showAlertBar={true} />
      </div>
    );
  }
};

const mapStateToProps = (state: any) => ({
  screenSize: getScreenSize(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setCatchedError
    },
    dispatch
  );
};

export default withRouter<any & RouteComponentProps<{}>, any>(
  connect<any, any>(mapStateToProps, mapDispatchToProps)(DetailsPage) as any
);
