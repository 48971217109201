import { IToolbarItem } from '../../types';

export const toggleButtonStatus = (
  selection: string,
  selectionDataLength: number
) => {
  let disabled = false;
  if (selection) {
    if (selection === 'single') {
      disabled = selectionDataLength !== 1;
    }
    if (selectionDataLength > 1) {
      disabled = true;
    }
  }
  if (selection === 'multi') {
    disabled = selectionDataLength < 1;
  }
  return disabled;
};

export const prepareDeletion = (
  selectedForDeletionLength: number,
  setConfirmationWindow: (show: boolean) => void
) => {
  if (selectedForDeletionLength > 0) {
    setConfirmationWindow(true);
  }
};

export const getPagePath = () => {
  return window.location.pathname
    .replaceAll('/', '')
    .replace('-', ' ')
    .split('#')[0]
    .split(' ');
};

export const resizeToolbar = (toolbar: IToolbarItem[], screenSize: string) => {
  const dropdownItems: IToolbarItem[] = [];
  const toolbarItems: IToolbarItem[] = [];
  const dropdownWidth = document.getElementById('dropdown')?.offsetWidth || 0;
  const availableWidth = window.innerWidth - dropdownWidth;
  const maxItemWidth = 160;
  let usedWidth = 0;
  toolbar.map((item: IToolbarItem) => {
    if (
      usedWidth > availableWidth ||
      usedWidth + maxItemWidth > availableWidth
    ) {
      dropdownItems.push(item);
    } else {
      toolbarItems.push(item);
      usedWidth += maxItemWidth;
    }
  });
  return { dropdownItems, toolbarItems };
};

export const formatDataForPrinting = (
  data: Array<string[]>,
  header: string[]
) => {
  const dataForPDF = data.map((dataRow) => {
    const dataEntry: any = {};
    dataRow.map((field: string, i: number) => {
      dataEntry[header[i]] = field ? field.toString() : '';
    });
    return dataEntry;
  });
  return dataForPDF;
};

export const pdfStyle = (length: number) => {
  return `
 .pdf-header{ 
   font-family:sans-serif; 
   font-weight:500; 
   font-size:32px; 
 } 
 #pdf-table thead tr th {
   font-weight: 600;
   text-align: left;
   border-bottom: 2px solid #000;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
 }
 #pdf-table  tbody tr td{
   padding:0;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   border-right:3px solid #fff;
 }
 #pdf-table .pdf-table-numbercell{
   text-align:right;
 }
 #pdf-table {
   font-size: ${length < 8 ? '4px' : '8px'};
   font-family: sans-serif;
 }
`;
};
