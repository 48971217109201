import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';
import _ from 'lodash';

const ValueSelect = ({ value, type, onChange, enumList, isDisabled }) => {
    const [options, setOptions] = useState([]);
    const [activeOption, setActiveOption] = useState({});

    useEffect(() => {
        if (value !== '') {
            const data = [
                {
                    value: value,
                    label: value,
                },
            ];
            if (data.length) {
                setActiveOption(data);
            }
        }
    }, [value]);

    useEffect(() => {
        if (enumList.length) {
            const data = enumList.map((item) => {
                return { value: item, label: item };
            });
            setOptions(data);

            if (value === '' || _.isEmpty(value)) {
                setActiveOption(data[0]);
                onChange(data[0].value);
            }
        }
    }, [enumList]);

    const handleChange = (newValue, actionMeta) => {
        if (newValue) {
            setActiveOption(newValue);
            onChange(newValue.value);
        } else {
            setActiveOption('');
            onChange('');
        }
    };

    return (
        <Select
            isSearchable
            placeholder={'Select value'}
            onChange={handleChange}
            options={options}
            value={activeOption}
            isDisabled={isDisabled}
        />
    );
};

export default ValueSelect;
