import {
  SCROLL,
  ADD_SCREENSIZE,
  SET_CATCHED_ERROR,
  SET_CURRENT_PAGE_TAG,
  SET_THEME,
  SET_BROWSER_LIST,
} from '../types';

export const scrollAction = (formatName) => {
  return {
    type: SCROLL,
    formatName,
  };
};

export const addScreenSizeAction = (payload) => ({
  type: ADD_SCREENSIZE,
  payload,
});

export const setCurrentPageTag = (payload) => ({
  type: SET_CURRENT_PAGE_TAG,
  payload,
});

export const setCatchedError = (payload) => ({
  type: SET_CATCHED_ERROR,
  payload,
});

export const setTheme = (payload) => ({
  type: SET_THEME,
  payload,
});

export const setBrowserList = (payload) => ({
  type: SET_BROWSER_LIST,
  payload,
});
