import moment from 'moment';
import _ from 'lodash';
import { createCompanyDoc, genericMutation, genericQuery } from '../../GraphQL';

import {
  setAppTheme as setTheme,
  updateMetadataList,
  startsWithIgnoreCase,
  getLocalStorageItem,
  textGenerator,
  divideNestedAttribute
} from '../../utils';
import {
  getMeetingAddressShort,
  getMeetingAddress,
  findCompaniesHandler,
  getAddress,
  detailButtonOpenLinkedInSearch
} from './functions';
import { TFieldsValues } from '../../types';

export const capabilityMatchFromCVR = ({
  data,
  apolloClient
}: {
  data: { pNumber: number; cvrNumber: number; enhedsNummerP: number };
  apolloClient: any;
}) => {
  const { pNumber, cvrNumber, enhedsNummerP } = data;
};

export const capabilityFindCompaniesNearby = async (handlerProps: {
  setDialogWindowProps: Function;
  data: TFieldsValues;
  setCatchedError: Function;
  apolloClient: any;
}) => {
  const { setDialogWindowProps, data, setCatchedError } = handlerProps;
  const { geolocationLat, geolocationLon } = data;
  const metaFileName = 'Dlg.STDCompany.FindCompaniesNearbyLatLon.json';
  const metadatasList = getLocalStorageItem('metadatasList');
  const updatedMeta = await updateMetadataList(metaFileName, metadatasList);
  const dialogMeta = updatedMeta?.find(
    ({ fileName }) => fileName === metaFileName
  );
  if (geolocationLat && geolocationLon) {
    setDialogWindowProps({
      show: true,
      meta: dialogMeta.data.form,
      onConfirm: (inputData: string) => {
        //@ts-ignore
        findCompaniesHandler(inputData, handlerProps);
        setDialogWindowProps();
      }
    });
  } else {
    setCatchedError(
      `#SorryGeoCoding % 'Længde- og breddegrad er ikke angivet og vi kunne ikke automatisk udlede denne fra adressen'`
    );
    return;
  }
};

export const detailButtonOpenLinkedIn = ({ data }: { data: TFieldsValues }) => {
  const { linkedInID } = data;
  if (linkedInID === null) {
    detailButtonOpenLinkedInSearch(data);
    return;
  }
  let link = '';
  const id = linkedInID.replaceAll(' ', '');
  if (startsWithIgnoreCase(id, 'http') || startsWithIgnoreCase(id, 'www')) {
    link = `${!id.match(/^http?:\/\//i) ? `https://${id}` : id}`;
  } else if (parseInt(id[0])) {
    link = `https://www.linkedin.com/profile/view?id=${id}`;
  } else {
    link = `https://www.linkedin.com/in/${id}`;
  }
  window.open(link, '_blank')?.focus();
};

export const openHomePage = ({
  field,
  data
}: {
  field: { [x: string]: any; attribute: 'string'; type: 'string' };
  data: TFieldsValues;
}) => {
  let link = textGenerator(data, field);
  if (!link) {
    if (data.alias) {
      link = `https://www.google.dk/search?q=${data.alias}`;
      window.open(link, '_blank');
    }
    return;
  } else {
    if (
      startsWithIgnoreCase(link, 'http') ||
      startsWithIgnoreCase(link, 'www')
    ) {
      link = `${!link.match(/^http?:\/\//i) ? `https://${link}` : link}`;
    } else {
      link = `https://www.${link}`;
    }
  }
  window.open(link, '_blank');
};

export const copyFromMeetingAddress = async ({
  field,
  data,
  apolloClient,
  setRenderedData
}: {
  field: { attribute: string };
  data: TFieldsValues;
  apolloClient: any;
  setRenderedData: Function;
}) => {
  const query = genericQuery(
    'cdmcomp',
    [
      'name name2 addr1 addr2 primaryPostCodeRelation{postCode town} country:primCounRelation{head}'
    ],
    `id:"${data.id}"`
  );
  const companyData = await apolloClient.query({ query });
  let address = await getMeetingAddress(companyData.data.cdmcomp, apolloClient);
  setRenderedData({ ...data, [field.attribute]: address });
  address = address.replaceAll('\r', '\\r').replaceAll('\n', '\\n');
  const mutation = genericMutation(
    'cdmcomp',
    'update',
    `id:"${data.id}" ${field.attribute}:"${address}"`
  );
  await apolloClient.mutate({ mutation });
};

export const capabilityOpenCVRdk = ({
  data,
  setCatchedError,
  field
}: {
  data: TFieldsValues;
  setCatchedError: Function;
  field: { [x: string]: any; attribute: 'string'; type: 'string' };
}) => {
  const value = textGenerator(data, field);
  const { name, cvrNumber, primCounRelation } = data;
  if (primCounRelation && primCounRelation?.code !== 'DK') {
    setCatchedError(
      `#FunktionOnlyOnDanishCompanies % 'Denne funktion kan kun bruges på danske firmaer' `
    );
    return;
  }
  let link;
  if (parseInt(value) > 0) {
    window.open(
      `https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrNumber}`
    );
    return;
  }
  if (!name) {
    setCatchedError(`(#ValidateErrorXXXMissing #'Juridisk navn' `);
    return;
  } else {
    window.open(
      `https://datacvr.virk.dk/data/visninger?type=virksomhed&soeg=${name}`
    );
  }
};

export const capabilityOpenMap = async ({
  data,
  apolloClient
}: {
  data: TFieldsValues;
  apolloClient: any;
}) => {
  const navigateToAddress = getAddress(data);
  const navigateFrom = await getMeetingAddressShort(apolloClient);
  window.open(
    `https://maps.google.dk/maps?hl=da&saddr=${navigateFrom}&daddr=${navigateToAddress}`
  );
};

export const capabilityOpenMapGeo = async ({
  data,
  setCatchedError
}: {
  data: TFieldsValues;
  setCatchedError: Function;
}) => {
  const { geolocationLat, geolocationLon } = data;
  if (geolocationLat && geolocationLon) {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${geolocationLat},${geolocationLon}`
    );
  } else {
    setCatchedError(
      `#SorryGeoCoding % 'Længde- og breddegrad er ikke angivet og vi kunne ikke automatisk udlede denne fra adressen'`
    );
    return;
  }
};

export const countDuration = ({
  data
}: {
  data: { startTime: string; endTime: string; duration?: moment.Duration }[];
}) => {
  return data.map(
    (entry: {
      startTime: string;
      endTime: string;
      duration?: moment.Duration;
    }) => {
      const start = moment(entry.startTime, 'hh:mm:ss.ms'); //todays date
      const end = moment(entry.endTime, 'hh:mm:ss.ms'); // another date
      const duration = moment.duration(start.diff(end));
      entry.duration = duration;
      return entry;
    }
  );
};

export const createCompanyDocs = (passedParams: {
  userId: string;
  head: string;
  itemId: string;
  filterExpression: string;
}) => {
  const creationDate = moment().format('YYYY-MM-DD');
  const creationTime = moment().format('hh:mm:ss.m');
  const type = 'DOC';
  const inputValues = {
    primType: '"28184-----MASTERSYS"',
    primUser: `"${passedParams.userId}"`,
    head: `"${type}:${passedParams.head}"`,
    taskStatus: 2,
    freeBusyStatus: 2,
    startDate: `"${creationDate}"`,
    endDate: `"${creationDate}"`,
    startTime: `"${creationTime}"`,
    endTime: `"${creationTime}"`,
    activityClass: `"Task"`,
    [passedParams.filterExpression]: `"${passedParams.itemId}"`
  };
  return createCompanyDoc(inputValues);
};

export const setAppTheme = (attribute: any) => {
  const { fax } = attribute?.data || { fax: '' };
  if (fax === 'Light' || fax === 'Dark') {
    setTheme(fax);
  }
};

export const calculateDates = (
  renderedData: TFieldsValues,
  attributes: string[]
) => {
  const startDate = renderedData[attributes[0]];
  const endDate = renderedData[attributes[1]];
  const duration = moment.duration(moment(endDate).diff(moment(startDate)));
  const months = duration.months();
  const days = duration.days();
  //@ts-ignore
  return !isNaN(duration)
    ? months > 0
      ? `${months} Month${months > 1 ? 's' : ''}`
      : `${days} Day${days > 1 ? 's' : ''}`
    : '';
};

export const getFullString = ({
  data,
  field,
  setRenderedData
}: {
  data: TFieldsValues;
  field: TFieldsValues;
  setRenderedData: Function;
}) => {
  if (data) {
    const { attribute, fields } = field;
    const fullStringData = fields.map((field: string) => {
      const [mainAttribute, subAttributes] = divideNestedAttribute(field);
      const relation = data[mainAttribute.replaceAll(' ', '')];

      return relation
        ? subAttributes
            .split(',')
            .map((attribute: string) =>
              textGenerator(relation, { attribute, type: '' })
            )
        : '';
    });
    const stringValue = fullStringData
      .map((stringData: string[]) =>
        stringData ? stringData.join('<br/>') : ''
      )
      .join('<br/>');
    const updateData = data;
    updateData[attribute] = `${stringValue}`;
    setRenderedData(updateData);
  }
};

export const openLatestFinancialStatementPDF = ({
  selectedData,
  filterExpression,
  apolloClient
}: any) => {
  if (selectedData.length > 0) {
    const item = selectedData[0];
    const query = genericQuery(
      'cdmkobopenaccounts',
      ['urlPDF'],
      filterExpression
    );
    // apolloClient.query({ query }).then(({ data }: any) => {
    //   const { urlPDF } = data.cdmkobopenaccounts;
    //   window.open(urlPDF, '_blank');
    // });
  }
};
