import _ from 'lodash';
import { API } from '../../API';
import store, { addCapabilityParams, unselectAll } from '../../redux';
import { MsAuthModule, TokenInfo } from '../../helpers/AuthLogic/msAuth';
import {
  getRenderedMeta,
  getLocalStorageItem,
  redirectToDetail,
  showSuccessMessage
} from '../../utils';
import { viewMapTemplate } from '../../pages/DetailsPage/functions';
import { genericQuery, genericMutation } from '../../GraphQL';
import { TFieldsValues } from '../../types';

interface infologItem {
  result: string | null;
  text: string | null;
}

export const callMethodFromBackend = async (setAlertBarOptions: Function) => {
  const {
    step,
    method,
    dataSource,
    accessTokenRequired,
    args,
    ids,
    accessToken,
    action,
    cvrRegisterInfo
  } = store.getState().dataManagement.capabilityParams;
  if (method && ids) {
    const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
    let headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      typeauthorization: localStorage.getItem('authType')
    };
    if (accessTokenRequired) {
      if (!accessToken) {
        const isMail =
          method === 'STDSaleForecast.capabilitySendManualMail' ? true : false;
        const authModule: MsAuthModule = new MsAuthModule();
        const msTokensInfo: TokenInfo | null = await authModule.getIdToken(
          isMail
        );
        headers = {
          ...headers,
          ...{ accessToken: _.get(msTokensInfo, 'accessToken', null) }
        };
      } else {
        headers = {
          ...headers,
          ...{ accessToken: accessToken }
        };
      }
    }

    const dataObject: any = {
      run: {
        method: method,
        step: step
      },
      args: args,
      ids: ids,
      action: action,
      cvrRegisterInfo: cvrRegisterInfo
    };
    if (dataSource && dataSource !== '') {
      dataObject.datasource = dataSource;
    }
    return api
      .postRestCapability({
        headers,
        capabilityInfo: dataObject
      })
      .then((res) => {
        if (res.code === 200) {
          const infologArr = res.data.infolog.map(
            (item: infologItem) => item.text
          );
          const errorInfologArr = res.data.infolog.filter(
            (item: infologItem) => item.result === '1' || item.result === '2'
          );
          let isError = errorInfologArr.length ? true : false;

          if (_.get(res, 'data.form', null)) {
            updateCapabilityState({
              ...(res.data.form !== undefined && { form: res.data.form }),
              ...(res.data.step !== undefined && { step: res.data.step }),
              ...(res.data.method !== undefined && { method: res.data.method }),
              ...(res.data.ids !== undefined && { ids: res.data.ids }),
              ...(res.data.args !== undefined && { args: res.data.args }),
              ...(res.data.cvrRegisterInfo !== undefined && {
                cvrRegisterInfo: res.data.cvrRegisterInfo
              }),
              ...(res.data.accessToken !== undefined && {
                accessToken: res.data.accessToken
              })
            });
            if (isError) {
              setAlertBarOptions({
                show: true,
                alertText: infologArr.join('.'),
                variant: 'danger'
              });
              return 'Error';
            }
          } else if (_.get(res, 'data.defaultValues', null)) {
            return { defaultValues: _.get(res, 'data.defaultValues', {}) };
          } else if (_.get(res, 'data.id', null)) {
            store.dispatch(unselectAll());
            return {
              id: _.get(res, 'data.id', ''),
              selectForm: _.get(res, 'data.selectForm', '')
            };
          } else {
            store.dispatch(unselectAll());
            const successAlert = {
              show: true,
              alertText: infologArr.join('.'),
              variant: isError ? 'warning' : 'success'
            };
            setAlertBarOptions(successAlert);
            setTimeout(() => {
              setAlertBarOptions({
                ...successAlert,
                show: false
              });
            }, 5000);
          }
        } else {
          setAlertBarOptions({
            show: true,
            alertText: res.message,
            variant: 'danger'
          });
          return res.message;
        }
        return res;
      })
      .catch((err) => {
        setAlertBarOptions({
          show: true,
          alertText: err.message || err,
          variant: 'danger'
        });
      });
  }
};

export const createCalendarActivity = async ({
  history,
  dataSource,
  defaultValues
}: {
  history: any;
  dataSource: string;
  defaultValues?: { [key: string]: string };
}) => {
  updateCapabilityState({
    step: 0,
    method: `${dataSource}.onNew`,
    args: {},
    ids: []
  });
  let capabilityDefaultValues = {};
  const resDefaultValues = await callMethodFromBackend(() => {});
  if (
    resDefaultValues &&
    resDefaultValues.defaultValues &&
    !_.isEmpty(resDefaultValues.defaultValues)
  ) {
    capabilityDefaultValues = resDefaultValues.defaultValues;
  }
  if (defaultValues) {
    capabilityDefaultValues = { ...capabilityDefaultValues, ...defaultValues };
  }
  history.push('/ActivityDetail-create', {
    defaultState: {
      activityClass: 'Calendar',
      ...capabilityDefaultValues
    },
    dataSource: `${dataSource}s`
  });
};

export const chooseDialUpPhoneNumber = ({
  option,
  telephonyInterfaceAPI,
  setAlertBarOptions,
  callingCode
}: {
  option: { number: string; attribute: string };
  telephonyInterfaceAPI: string;
  setAlertBarOptions: Function;
  callingCode: string;
}) => {
  const { number, attribute } = option || { number: '', attribute: '' };
  let callingNumber = number?.match(/\d/g)?.join('') || '';
  if (number[0] === '+') {
    callingNumber = `+${callingNumber}`;
  } else if (number.substring(0, 2) === '00') {
    callingNumber = callingNumber.replace('00', '+');
  } else {
    callingNumber = `+${callingCode}${callingNumber}`;
  }
  if (telephonyInterfaceAPI === 'Skype') {
    window.open(
      `skype:${
        attribute === 'skypeHandle' ? number : `${callingNumber || ''}`
      }?call`
    );
  } else {
    setAlertBarOptions({
      show: true,
      variant: 'danger',
      alertText: `${telephonyInterfaceAPI} is not supported yet.`
    });
    setTimeout(() => {
      setAlertBarOptions({ show: false, alertText: '' });
    }, 4000);
    return 'Error';
  }
};

export const updateCapabilityState = ({
  form,
  step,
  method,
  dataSource,
  accessTokenRequired,
  args,
  ids,
  isJoinArgs,
  accessToken,
  action,
  cvrRegisterInfo
}: any) => {
  const capabilityArgs = store.getState().dataManagement.capabilityParams.args;
  const procArgs = isJoinArgs ? [...capabilityArgs, ...[args]] : args;
  const capabilityObj = {
    ...(form !== undefined && { form }),
    ...(step !== undefined && { step }),
    ...(method !== undefined && { method }),
    ...(dataSource !== undefined && { dataSource }),
    ...(accessTokenRequired !== undefined && { accessTokenRequired }),
    ...(procArgs !== undefined && { args: procArgs }),
    ...(ids !== undefined && { ids }),
    ...(accessToken !== undefined && { accessToken }),
    ...(action !== undefined && { action }),
    ...(cvrRegisterInfo !== undefined && { cvrRegisterInfo })
  };

  store.dispatch(addCapabilityParams(capabilityObj));
};

export const createFromSelectionWindow = ({
  attribute,
  id,
  method,
  setAlertBarOptions,
  selectForm,
  history,
  option
}: {
  attribute: string;
  id: string;
  method: string;
  setAlertBarOptions: Function;
  dataSource: string;
  selectForm: string;
  history: any;
  option: TFieldsValues;
}) => {
  updateCapabilityState({
    step: 0,
    method,
    args: { [attribute]: option[attribute] },
    ids: [id]
  });
  callMethodFromBackend(setAlertBarOptions).then((res) => {
    if (res && res.id) {
      redirectToDetail(selectForm, { id: res.id }, history);
    }
  });
};

export const openModalHandler = async ({
  buttonItem,
  selectedData,
  setSelectionModal,
  setConfirmationWindow,
  setAlertBarOptions,
  apolloClient
}: {
  buttonItem: {
    method?: string;
    class?: string;
    accessTokenRequired?: boolean;
  };
  selectedData?: { id: string }[];
  setSelectionModal: Function;
  setConfirmationWindow?: Function;
  setAlertBarOptions: Function;
  apolloClient?: any;
}) => {
  const dialogMeta = await getRenderedMeta(
    getLocalStorageItem('metadatasList'),
    `Dlg.Confirm${buttonItem?.method}.json`
  );
  updateCapabilityState({
    step: 0,
    method: buttonItem?.method,
    accessTokenRequired: buttonItem?.accessTokenRequired,
    args: {},
    ids: selectedData?.length
      ? selectedData?.map((data: { id: string }) => data.id)
      : [
          window.location.pathname
            .replaceAll('/', '')
            .replace('-', ' ')
            .split(' ')[1]
        ] || []
  });
  if (dialogMeta) {
    const { form } = dialogMeta.data;
    let options = form.fields;
    if (!form.fields) {
      const queryParams = form.miniGrid.fields.map(
        (field: TFieldsValues) => field.attribute
      );
      const query = genericQuery(
        form.dataSource,
        queryParams,
        `orderBy:${queryParams[0]}`
      );
      const { data } = apolloClient && (await apolloClient.query({ query }));
      options = data[form.dataSource];
    }
    if (form.miniGrid) {
      setSelectionModal({
        show: true,
        options,
        fields: form.miniGrid.fields,
        onSelectProperties: {
          selectForm: form.selectForm,
          id:
            window.location.pathname
              .replaceAll('/', '')
              .replace('-', ' ')
              .split(' ')[1] ||
            selectedData?.map((data: { id: string }) => data.id)[0] ||
            '',
          method: buttonItem?.method,
          setAlertBarOptions
        },
        onConfirmHandler: (props: any) => {
          createFromSelectionWindow({ ...props, attribute: form.attribute });
        }
      });
    } else {
      setConfirmationWindow &&
        setConfirmationWindow({
          show: true,
          meta: dialogMeta.data?.form,
          handlers: {
            confirm: () => callMethodFromBackend(setAlertBarOptions),
            cancel: () =>
              setConfirmationWindow({ show: false, meta: {}, handlers: {} })
          }
        });
    }
  } else {
    callMethodFromBackend(setAlertBarOptions);
  }
};

export const sendManualMail = (props: any) => {
  const {
    selectedItems,
    dataSource,
    apolloClient,
    buttonItem,
    setAlertBarOptions
  } = props;
  const idIn = selectedItems.map((item: { id: string }) => `"${item.id}"`);
  const query = genericQuery(dataSource, ['primCont'], `idIn:[${idIn}]`);
  apolloClient.query({ query }).then((res: any) => {
    const data = res.data[dataSource];
    const contact = data[0]?.primCont || data?.primCont || ''; // Only one contact for now
    updateCapabilityState({
      step: 0,
      method: buttonItem.method,
      args: {},
      accessTokenRequired: true,
      ids: [contact]
    });
    callMethodFromBackend(setAlertBarOptions);
  });
};

export const capabilityOpenMap = async ({
  selectedItems,
  dataSource,
  apolloClient,
  setAlertBarOptions
}: any) => {
  const idIn = selectedItems.map((item: { id: string }) => `"${item.id}"`);
  const query = genericQuery(
    dataSource,
    [
      'geolocationLat',
      'geolocationLon',
      'customer',
      'supplier',
      'alias',
      'name',
      'name2',
      'addr1',
      'addr2',
      'primaryPostCodeRelation{postCode town}',
      'country:primCounRelation{code head}'
    ],
    `idIn:[${idIn}]`
  );
  apolloClient.query({ query }).then((res: any) => {
    viewMapTemplate(res.data, {
      apolloClient,
      setCatchedError: (alertText: string) => {
        setAlertBarOptions({
          show: true,
          alertText,
          variant: 'warning'
        });
      }
    });
  });
};
