import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { TFieldsValues, IFormField } from '../types';
import { genericQuery } from '../GraphQL';
import { viewMapTemplate } from '../pages/DetailsPage/functions';

import {
  getLocalStorageItem,
  getRenderedMeta,
  updateFormatList
} from '../utils';

interface ISTDContactMail {
  apolloClient: any;
  history: RouteComponentProps['history'];
  setAlertBarOptions: (alertBarOptions: TFieldsValues) => void;
  selectedItems: TFieldsValues[];
  setSelectionModal: (alertBarOptions: TFieldsValues) => void;
}

export class STDContactMail {
  private selectedItems;
  private apolloClient;
  private history;
  private setAlertBarOptions;
  private setSelectionModal;

  constructor(classProps: ISTDContactMail) {
    this.apolloClient = classProps.apolloClient;
    this.history = classProps.history;
    this.setAlertBarOptions = classProps.setAlertBarOptions;
    this.selectedItems = classProps.selectedItems;
    this.setSelectionModal = classProps.setSelectionModal;
  }

  capabilityShowContacts = async () => {
    const { id } = this.selectedItems[0];
    await updateFormatList(
      'cdmconts',
      'cdmconts',
      getLocalStorageItem('tableFormatList')
    );
    await updateFormatList(
      'cdmconts',
      'cdmconts.Standard',
      getLocalStorageItem('tableFormatList')
    );
    await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      'Frm.ContactsOverview.json'
    );
    // Pass filterExpression cdmca2C primMail:`"${id}"`
    this.history.push('/ContactsOverview');
  };

  capabilityCreateMailing = async () => {
    const meta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `lst.tszMailings.Simple.json`
    );
    const { fields } = meta;
    const query = genericQuery(
      'tszMailings',
      fields.map((field: { attribute: string }) => field.attribute),
      'orderByDesc:dato, orderBy:head'
    );
    const { data } = await this.apolloClient.query({ query });
    const { id } = this.selectedItems[0];
    this.setSelectionModal({
      show: true,
      options: data.tszMailings || [],
      fields: fields,
      onConfirmHandler: async ({ option }: { option: TFieldsValues }) => {
        console.log(option);
      }
    });
  };
}
