import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import eachDeep from 'deepdash/eachDeep';
import { useLocalStorage } from '@rehooks/local-storage';

import { components } from './components';
import {
  getCriteriaList,
  updateCriteriaItem,
  updateEntityItem,
  getEntityList,
} from '../../../../redux';

const ValueEditor = ({
  criterias,
  entities,
  type,
  enumList,
  activeAttribute,
  updateCriteriaItem,
  updateEntityItem,
}) => {
  const [metadatasList] = useLocalStorage('metadatasList');

  const [element, setElement] = useState('');
  const [activeValue, setActiveValue] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [sqlOperators, setSqlOperators] = useState(
    _.find(metadatasList, ['fileName', 'ListSQLOperators.json']),
  );

  useEffect(() => {
    if (metadatasList && metadatasList.length) {
      if (_.find(metadatasList, ['fileName', 'ListSQLOperators.json'])) {
        setSqlOperators(
          _.find(metadatasList, ['fileName', 'ListSQLOperators.json']),
        );
      }
    }
  }, [metadatasList]);

  useEffect(() => {
    if (!_.isEmpty(activeAttribute)) {
      const activeEntityState = entities.filter((item) =>
        item.belongTo.includes(activeAttribute.id),
      );
      if (activeEntityState.length > 0) {
        setIsDisabled(!activeEntityState[0].canBeEdited);
      }
    }
  }, [activeAttribute, entities]);

  useEffect(() => {
    if (sqlOperators) {
      const operators = sqlOperators.data.form.mainGroup[0].operators.filter(
        (item) => item.type.includes(type),
      );

      let activeCriteria;

      eachDeep(
        _.cloneDeep(criterias),
        (child, i, parent, ctx) => {
          if (child.id === activeAttribute.id) {
            activeCriteria = _.cloneDeep(child);
          }
        },
        { childrenPath: 'children' },
      );

      const activeEntity = entities.filter((item) =>
        item.belongTo.includes(activeAttribute.id),
      );

      if (!_.isEmpty(activeCriteria)) {
        if (_.get(activeCriteria, 'isEntity', false)) {
          setActiveValue(_.get(activeEntity[0], 'value', []));
        } else {
          setActiveValue(_.get(activeCriteria, 'value', []));
        }

        if (operators.length && _.get(activeCriteria, 'operator', null)) {
          const component = operators[0].fields.filter(
            (item) => item.operator === _.get(activeCriteria, 'operator', null),
          );
          if (component.length) {
            if (_.isEmpty(_.get(activeCriteria, 'enumList', []))) {
              setElement(component[0].component);
            } else {
              setElement(component[0].componentEnum);
            }
          } else {
            setElement(null);
          }
        } else {
          setElement(null);
        }
      } else {
        setElement(null);
      }
    }
  }, [activeAttribute, criterias, sqlOperators]);

  const getInputType = () => {
    if (type === 'String') {
      return 'text';
    } else if (type === 'Int' || type === 'Float') {
      return 'number';
    } else if (type === 'Date') {
      return 'date';
    } else if (type === 'Time') {
      return 'time';
    } else return 'text';
  };
  const onChange = (value) => {
    let activeCriteria;

    eachDeep(
      _.cloneDeep(criterias),
      (child, i, parent, ctx) => {
        if (child.id === activeAttribute.id) {
          activeCriteria = _.cloneDeep(child);
        }
      },
      { childrenPath: 'children' },
    );
    if (!_.isEmpty(activeCriteria)) {
      if (_.get(activeCriteria, 'isEntity', false)) {
        const activeEntity = entities.filter((item) =>
          item.belongTo.includes(activeAttribute.id),
        );
        if (activeEntity.length) {
          updateEntityItem({
            ...activeEntity[0],
            value: value,
          });
        }
      } else {
        updateCriteriaItem({
          ...activeCriteria,
          value: value,
        });
      }
    }
  };

  const CustomComponent =
    element && components[element] ? components[element] : null;
  return (
    <>
      {CustomComponent ? (
        <CustomComponent
          value={activeValue}
          type={getInputType()}
          onChange={onChange}
          enumList={enumList}
          isDisabled={isDisabled}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  criterias: getCriteriaList(state),
  entities: getEntityList(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateCriteriaItem,
      updateEntityItem,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ValueEditor);
