import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import _ from 'lodash';
import eachDeep from 'deepdash/eachDeep';
import mapValuesDeep from 'deepdash/mapValuesDeep';
import filterDeep from 'deepdash/filterDeep';
import { useLocalStorage } from '@rehooks/local-storage';

import { Row, Button, Col, Card, Form } from 'react-bootstrap';
import { XSquareFill, Union, Intersect } from 'react-bootstrap-icons';
import { useApolloClient } from 'react-apollo';

import {
  getTableFieldList,
  getQueryList,
  getEntityList,
  getCriteriaList,
  addEntities,
  addEntityItem,
  updateEntityItem,
  addCriterias,
  addCriteriaItem,
  deleteCriteriaItem,
  getUser,
  getCurrentFormatList,
  getCurrentTable,
  setCatchedError,
  addTableCounter,
  getTableCounter,
} from '../../../redux';
import { QueryCriteriaOperators } from './';
import { ModalTitle, ModalTextArea } from './commonComponents';
import {
  ModalTable,
  ModalEntryInputForm,
} from '../../../components/QueryBuilderTable';
import { changeIdDeep } from '../../../helpers';
import {
  runSql_query,
  updateEvent,
  tableFieldList_query,
  getTextSql_query,
} from '../../../GraphQL';
import { generateQueryRules } from '../../../utils';

const QueryCriteriaWidget = withRouter(
  ({
    history,
    user,
    location,
    tableFieldList,
    queryList,
    entities,
    criteriaList,
    addEntities,
    addEntityItem,
    updateEntityItem,
    addCriterias,
    addCriteriaItem,
    deleteCriteriaItem,
    currentFormatList,
    currentTable,
    setCatchedError,
    addTableCounter,
    tableCounter,
  }) => {
    const apolloClient = useApolloClient();
    const [tableFormatList] = useLocalStorage('tableFormatList');
    const [metadatasList] = useLocalStorage('metadatasList');

    const params = new URLSearchParams(location.search);

    const [title, setTitle] = useState('');
    const [nodes, setNodes] = useState([]);
    const [criterias, setCriterias] = useState([]);
    const [entityCriterias, setEntityCriterias] = useState([]);
    const [activeAttribute, setActiveAttribute] = useState({});
    const [activeAttributeName, setActiveAttributeName] = useState('');
    const [lastAddPath, setLastAddPath] = useState('');
    const [limitValue, setLimitValue] = useState(40);
    const [offsetValue, setOffsetValue] = useState(0);
    const [queryResult, setQueryResult] = useState([]);
    const [listField, setListField] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalEntryInput, setShowModalEntryInput] = useState(false);
    const [titleModalVisible, setTitleModalVisible] = useState(false);
    const [editCurrent, setEditCurrent] = useState(false);
    const [currentTableCounter, setCurrentTableCounter] = useState(0);
    const [itemId, setItemId] = useState(null);
    const [externalNodeType, setExternalNodeType] = useState('yourNodeType');
    const [sqlOperators, setSqlOperators] = useState(
      _.find(metadatasList, ['fileName', 'ListSQLOperators.json']),
    );
    const [textSqlQuery, setTextSqlQuery] = useState('');
    const [showSqlQuery, setShowSqlQuery] = useState(false);

    useEffect(() => {
      addCriterias([]);
      addEntities([]);
      setItemId(params.get('itemId'));
    }, []);

    const createNestedRelationInfoObj = (parentRelation, currentRelation) => {
      let newObj = _.cloneDeep(parentRelation);
      getProp(newObj);

      function getProp(o) {
        if (_.get(o, 'relations', null)) {
          getProp(o.relations);
        } else {
          o.relations = currentRelation;
        }
      }
      return newObj;
    };

    const createRelationInfoObj = (
      relationArr,
      name,
      parentId,
      level,
      parentRelation,
      itemTableCounter,
    ) => {
      const createdRelations = relationArr.map((item) => {
        itemTableCounter = itemTableCounter + 1;
        let nestedRelationInfo;
        let currentRelationInfo = {
          relationName: item.relationName,
          tableName: item.tableName,
          joinType: name === 'Relations' ? 'leftOuterJoin' : 'innerJoin',
          alias: `t${itemTableCounter}`,
        };
        if (_.isEmpty(parentRelation)) {
          nestedRelationInfo = _.cloneDeep(currentRelationInfo);
        } else {
          nestedRelationInfo = createNestedRelationInfoObj(
            parentRelation,
            currentRelationInfo,
          );
        }
        const relInheritedAttr = item.attributesInfo.inheritedAttributes.map(
          (elem) => {
            return {
              id: `${parentId !== null ? parentId + '_' : ''}${
                item.relationName
              }_${elem.attributeName}`,
              title: elem.attributeTranslate
                ? elem.attributeTranslate
                : elem.attributeName,
              isAttribute: true,
              translation: elem.attributeTranslate,
              isEntity: true,
              type: elem.attributeType,
              enumList:
                elem.attributeType === 'Boolean'
                  ? ['Yes', 'No']
                  : elem.attributeEnum,
              tableName: item.tableName,
              alias: `t${itemTableCounter}`,
              relations: nestedRelationInfo,
              isNode: true,
            };
          },
        );
        const relMainAttr = item.attributesInfo.attributes.map((elem) => {
          return {
            id: `${parentId !== null ? parentId + '.' : ''}${
              item.relationName
            }.${elem.attributeName}`,
            title: elem.attributeTranslate
              ? elem.attributeTranslate
              : elem.attributeName,
            isAttribute: true,
            translation: elem.attributeTranslate,
            isEntity: true,
            type: elem.attributeType,
            enumList:
              elem.attributeType === 'Boolean'
                ? ['Yes', 'No']
                : elem.attributeEnum,
            tableName: item.tableName,
            alias: `t${itemTableCounter}`,
            relations: nestedRelationInfo,
            isNode: true,
          };
        });
        return {
          id: `${parentId !== null ? parentId + '.' : ''}${item.relationName}`,
          title: `${item.translatedTableName}-${item.relationName}`,
          tableName: item.tableName,
          isGroup: true,
          isAdditionalLoading: true,
          level: level,
          relations: nestedRelationInfo,
          alias: `t${itemTableCounter}`,
          children: [
            {
              id: `Attributes${name}_${
                parentId !== null ? parentId + '.' : ''
              }${item.relationName}`,
              title: 'Attributes',
              isGroup: true,

              children: [
                ...[
                  {
                    id: `InheritedAttributes.${
                      parentId !== null ? parentId + '.' : ''
                    }${item.relationName}`,
                    title: 'Inherited attributes',
                    isGroup: true,
                    children: relInheritedAttr,
                  },
                ],
                ...relMainAttr,
              ],
            },
          ],
        };
      });
      return createdRelations;
    };

    useEffect(() => {
      if (metadatasList && metadatasList.length) {
        if (_.find(metadatasList, ['fileName', 'ListSQLOperators.json'])) {
          setSqlOperators(
            _.find(metadatasList, ['fileName', 'ListSQLOperators.json']),
          );
        }
      }
    }, [metadatasList]);

    useEffect(() => {
      if (!_.isEmpty(tableFieldList)) {
        let itemTableCounter = tableCounter;
        const inheritedAttr =
          tableFieldList.attributesInfo.inheritedAttributes.map((item) => {
            return {
              id: `${tableFieldList.tableName}.${item.attributeName}`,
              title: item.attributeTranslate
                ? item.attributeTranslate
                : item.attributeName,
              isAttribute: true,
              translation: item.attributeTranslate,
              isEntity: true,
              type: item.attributeType,
              enumList:
                item.attributeType === 'Boolean'
                  ? ['Yes', 'No']
                  : item.attributeEnum,
              tableName: tableFieldList.tableName,
              alias: tableFieldList.tableName,
              isNode: true,
            };
          });
        const mainAttr = tableFieldList.attributesInfo.attributes.map(
          (item) => {
            return {
              id: `${tableFieldList.tableName}.${item.attributeName}`,
              title: item.attributeTranslate
                ? item.attributeTranslate
                : item.attributeName,
              isAttribute: true,
              translation: item.attributeTranslate,
              isEntity: true,
              type: item.attributeType,
              enumList:
                item.attributeType === 'Boolean'
                  ? ['Yes', 'No']
                  : item.attributeEnum,
              tableName: tableFieldList.tableName,
              alias: tableFieldList.tableName,
              isNode: true,
            };
          },
        );
        const relations = createRelationInfoObj(
          tableFieldList.relations,
          'Relations',
          null,
          1,
          {},
          itemTableCounter,
        );
        itemTableCounter = tableFieldList.relations.length;
        const innerRelations = createRelationInfoObj(
          tableFieldList.innerRelations,
          'InnerRelations',
          null,
          1,
          {},
          itemTableCounter,
        );
        itemTableCounter =
          itemTableCounter + tableFieldList.innerRelations.length;
        const notes = tableFieldList.notes.map((item) => {
          return {
            id: `${tableFieldList.tableName}.${item.noteType}.${item.noteKey}.1level`,
            title: `${item.noteTranslate}(${item.noteType})-#${item.noteKey}`,
            type: 'Note', //item.noteType,
            key: item.noteKey,
            isNote: true,
            isEntity: true,
            translation: item.noteTranslate,
            relations: {},
            isNode: true,
          };
        });

        const data = [
          {
            id: tableFieldList.tableName,
            title: `${tableFieldList.translatedTableName}-${tableFieldList.className}`,
            isGroup: true,
            children: [
              {
                id: 'Attributes',
                title: 'Attributes',
                isGroup: true,
                children: [
                  ...[
                    {
                      id: 'InheritedAttributes',
                      title: 'Inherited attributes',
                      isGroup: true,
                      children: inheritedAttr,
                    },
                  ],
                  ...mainAttr,
                ],
              },
              {
                id: 'Notes',
                title: 'Notes',
                isGroup: true,
                children: notes,
              },
              {
                id: 'Relations_1level',
                title: 'Look-up relations (Foreign Keys)',
                isGroup: true,
                children: relations,
              },
              {
                id: 'InnerRelations_1level',
                title: 'List relations (Sub Folders)',
                isGroup: true,
                children: innerRelations,
              },
            ],
          },
        ];
        const procData = changeIdDeep(data, false);
        setNodes(procData);
        addTableCounter(itemTableCounter);
      }
    }, [tableFieldList]);

    useEffect(() => {
      if (!editCurrent) {
        if (!itemId) {
          const objCriterias = [
            {
              id: `AndGroup-${Date.now()}`,
              title: 'AND Group',
              isCoreGroup: true,
              isGroup: true,
              groupType: 'AND',
            },
          ];
          setCriterias(objCriterias);
          addCriterias(objCriterias);
        } else {
          const queryInfo = queryList.filter((item) => itemId === item.id);
          if (queryInfo.length) {
            const rawData = JSON.parse(queryInfo[0].rawData);
            eachDeep(
              rawData.whereConditions,
              (child, i, parent, ctx) => {
                if (child.isEntity) {
                  addEntityItem({
                    id: child.id,
                    title: child.title,
                    translation: child.title,
                    entityType: 'Simple',
                    type: child.type,
                    canBeEdited: true,
                    required: false,
                    belongTo: [child.id],
                    value: child.value,
                  });
                }
              },
              { childrenPath: 'children' },
            );
            setTitle(_.get(queryInfo[0], 'sourceURL', ''));
            setCriterias(rawData.whereConditions);
            addCriterias(rawData.whereConditions);
          } else {
            setCriterias([
              {
                id: 'AndGroup',
                title: 'AND Group',
                isCoreGroup: true,
              },
            ]);
          }
        }
      }
    }, [itemId]);

    const sendQueryList = async (id) => {
      // const query = genericQuery(
      //   `spplFormPost`,
      //   ['ipAddress', 'rawData', 'sourceURL'],
      //   { id: id },
      // );
      // const spplFormPostResult = await apolloClient.query({
      //   query,
      //   variables: {
      //     offset: 0,
      //   },
      //   fetchPolicy: 'no-cache',
      // });
      // if (spplFormPostResult.data) {
      //   const rawData = JSON.parse(
      //     spplFormPostResult.data['spplFormPost'].rawData,
      //   );
      //   setTextSqlQuery(rawData.sql);
      // }
    };

    const updateEntityInCriterias = () => {
      if (!_.isEmpty(activeAttribute)) {
        const currentAttr = {
          ..._.cloneDeep(activeAttribute),
          isEntity: false,
        };
        const changeCriterias = mapValuesDeep(
          _.cloneDeep(criterias),
          (child) => {
            if (child.id === currentAttr.id) {
              return currentAttr;
            } else {
              return child;
            }
          },
          { childrenPath: 'children' },
        );
        setCriterias(changeCriterias);
      }
    };

    const handleAddGroupClick = (groupName) => {
      addNewGroup(groupName);
    };

    const handleChangeSettings = (e) => {
      if (e.target.name === 'limit') {
        setLimitValue(e.target.value);
      } else if (e.target.name === 'offset') {
        setOffsetValue(e.target.value);
      }
    };

    const handleShowSqlQuery = () => {
      apolloClient
        .query({
          query: getTextSql_query,
          variables: {
            id: itemId,
          },
        })
        .then((res) => {
          if (res.data) {
            setTextSqlQuery(res.data['getSqlQuery'].textSqlQuery);
            setShowSqlQuery(true);
          }
        })
        .catch((err) => {
          console.log('Error', err);
        });
    };

    const canDropAttribute = () => {
      return false;
    };

    const canDragAttribute = ({ node }) => {
      if (node.isGroup) {
        return false;
      }
      return true;
    };

    const canDragCriteria = ({ node }) => {
      return false;
    };

    const canDropCriteria = ({ node, nextParent, prevPath, nextPath }) => {
      if (node.children && node.children.length) {
        return false;
      }
      if (!nextParent) {
        return false;
      }
      return true;
    };

    const onChangeCriterias = (newCriteriaList) => {
      const arrCriteria = changeIdDeep(newCriteriaList, true);

      let currentCriteriaRedux = [];
      eachDeep(
        _.cloneDeep(criteriaList),
        (child) => {
          if (!_.get(child, 'isGroup', false)) {
            currentCriteriaRedux.push(child);
          }
        },
        { childrenPath: 'children' },
      );
      eachDeep(
        _.cloneDeep(arrCriteria),
        (child, i, parent, ctx) => {
          if (
            !_.find(entities, ['id', child.id]) &&
            !_.get(child, 'isGroup', false) &&
            _.get(child, 'isEntity', false)
          ) {
            setLastAddPath(child.id);
            addEntityItem({
              id: child.id,
              title: child.translation,
              translation: child.translation,
              entityType: 'Simple',
              type: child.type,
              canBeEdited: true,
              required: false,
              belongTo: [child.id],
            });
          }
        },
        { childrenPath: 'children' },
      );
      setCriterias(arrCriteria);
    };

    const handleClickNode = (node, path, treeIndex) => {
      setActiveAttribute({ ...node, path: path, treeIndex: treeIndex });
      const nameArr = _.get(node, 'id', '').split('-');
      setActiveAttributeName(nameArr.length ? nameArr[0] : '');
    };

    const handleRunQueryBefore = () => {
      if (!currentFormatList) {
        setCatchedError('There is no default format');
      } else {
        setTitleModalVisible(true);
      }
    };

    const handleRun = async () => {
      const entryArr = [];
      eachDeep(
        _.cloneDeep(criteriaList),
        (child) => {
          if (_.get(child, 'isEntity', false)) {
            entryArr.push(child);
          }
        },
        { childrenPath: 'children' },
      );
      if (entryArr.length > 0) {
        setShowModalEntryInput(true);
        setEntityCriterias(entryArr);
      } else {
        runQuery();
      }
    };

    const runQuery = async () => {
      const { rules, notes } = generateQueryRules(criteriaList, entities);
      const findFormats = _.find(tableFormatList, [
        'formatName',
        currentFormatList,
      ]);
      const tableFormatFields = _.get(findFormats, 'fields', [])
        .map((item) => {
          if (item.attribute.indexOf('(') === -1) {
            return {
              caption: item.caption,
              attribute: item.attribute,
              filters: _.get(item, 'filters', []),
            };
          }
        })
        .filter((elem) => {
          return elem.attribute && elem.attribute !== ' ';
        });

      const tableFields = {
        name: currentTable,
        fields: tableFormatFields,
      };

      const queryInfo = {
        queryName: title,
        tableFields: tableFields,
        whereConditions: rules,
        notes: notes,
      };

      const updateValues = {
        ipAddress: 'QUERY_COMMON',
        rawData: JSON.stringify(queryInfo).replace(/\"/g, '\\"'),
        sourceURL: title,
        userAgent: user.id,
      };
      if (itemId !== null) {
        updateValues.id = itemId;
      }

      const updateMutation = updateEvent(updateValues, 'spplFormPost');
      apolloClient
        .mutate({ mutation: updateMutation })
        .then(({ data }) => {
          const items = itemId
            ? data['updatespplFormPost']
            : data['createspplFormPost'];
          const infoQuery = {
            id: _.get(items, 'id', null),
            limit: limitValue,
            offset: offsetValue,
          };
          setEditCurrent(true);
          setItemId(infoQuery.id);
          const query = runSql_query(tableFields, infoQuery);
          apolloClient.query({ query }).then((res) => {
            setQueryResult(res.data['runSqlQuery']);
            setListField(tableFields.fields.map((item) => item.caption));
            setShowModal(true);
          });
        })
        .catch((err) => {
          console.log('Error', err);
        });
    };

    const handleEntryInputFormOK = () => {
      setShowModalEntryInput(false);
      runQuery();
    };

    const removeNode = (node, path, treeIndex) => {
      setCriterias(
        removeNodeAtPath({
          treeData: criterias,
          path: path,
          getNodeKey: ({ node: { id } }) => id,
        }),
      );
      deleteCriteriaItem(node);
    };

    const addNewGroup = (groupName) => {
      if (!_.isEmpty(activeAttribute)) {
        const currentTreeIndex = _.get(activeAttribute, 'treeIndex', null);
        const currentPath = _.get(activeAttribute, 'path', []);
        const newNodeId = `${groupName}Group-${Date.now()}`;
        const newNode = {
          id: newNodeId,
          title: `${groupName} Group`,
          isCoreGroup: false,
          isGroup: true,
          groupType: groupName,
        };

        const data = addNodeUnderParent({
          treeData: criterias,
          parentKey: currentTreeIndex,
          expandParent: true,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: newNode,
        });
        addCriteriaItem({
          data: newNode,
          path: [...currentPath, ...[newNodeId]],
        });
        setCriterias(_.get(data, 'treeData', []));
      }
    };

    const onMoveCriteria = (args) => {
      const currentPath = _.get(args, 'path', []);
      let nodeOperator;
      currentPath[currentPath.length - 1] = lastAddPath;
      let idValue = lastAddPath;
      const indId = idValue.indexOf('-');
      if (indId !== -1) {
        idValue = idValue.substring(0, indId);
      }
      const operators = sqlOperators.data.form.mainGroup[0].operators.filter(
        (item) => item.type.includes(_.get(args, 'node.type', '')),
      );
      if (operators.length) {
        nodeOperator = operators[0].fields[0].operator;
      }
      const newNode = {
        ..._.get(args, 'node', {}),
        operator: nodeOperator,
        id: lastAddPath,
      };
      addCriteriaItem({
        data: newNode,
        path: currentPath,
      });
    };

    const onVisibilityToggle = ({ treeData, node, expanded, path }) => {
      const nodeRelation = _.find(node.children, [
        'title',
        'Look-up relations (Foreign Keys)',
      ]);
      if (
        _.get(node, 'isAdditionalLoading', false) &&
        !nodeRelation &&
        expanded
      ) {
        let level = node.level + 1;
        if (level <= 3) {
          apolloClient
            .query({
              query: tableFieldList_query,
              variables: {
                tableName: _.get(node, 'tableName', null),
              },
            })
            .then((res) => {
              const parentId = node.id.split('-').length
                ? node.id.split('-')[0]
                : null;
              let itemTableCounter = tableCounter;

              const relations = createRelationInfoObj(
                res.data.getTableFieldsList.relations,
                'Relations',
                parentId,
                level,
                _.get(node, 'relations', {}),
                itemTableCounter,
              );
              itemTableCounter =
                itemTableCounter + res.data.getTableFieldsList.relations.length;
              const innerRelations = createRelationInfoObj(
                res.data.getTableFieldsList.innerRelations,
                'InnerRelations',
                parentId,
                level,
                _.get(node, 'relations', {}),
                itemTableCounter,
              );
              itemTableCounter =
                itemTableCounter +
                res.data.getTableFieldsList.innerRelations.length;
              const notes = res.data.getTableFieldsList.notes.map((item) => {
                return {
                  id: `${res.data.getTableFieldsList.tableName}.${item.noteType}.${item.noteKey}.${level}level`,
                  title: `${item.noteTranslate}(${item.noteType})-#${item.noteKey}`,
                  type: 'Note',
                  key: item.noteKey,
                  isNote: true,
                  isEntity: true,
                  translation: item.noteTranslate,
                  relations: _.get(node, 'relations', {}),
                };
              });

              const newRelationNode = {
                id: `Relations_${level}level`,
                title: 'Look-up relations (Foreign Keys)',
                isGroup: true,
                children: relations,
              };
              const newInnerRelationNode = {
                id: `InnerRelations_${level}level`,
                title: 'List relations (Sub Folders)',
                isGroup: true,
                children: innerRelations,
              };
              const newNotesNode = {
                id: `Notes_${level}level`,
                title: 'Notes',
                isGroup: true,
                children: notes,
              };
              const notesData = addNodeUnderParent({
                treeData: nodes,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: ({ node: { id } }) => id,
                newNode: newNotesNode,
              }).treeData;
              const relationData = addNodeUnderParent({
                treeData: notesData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: ({ node: { id } }) => id,
                newNode: newRelationNode,
              }).treeData;
              const data = addNodeUnderParent({
                treeData: relationData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: ({ node: { id } }) => id,
                newNode: newInnerRelationNode,
              }).treeData;

              const procData = changeIdDeep(data, false);
              setNodes(procData);
              addTableCounter(itemTableCounter);
            });
        }
      }
    };

    return (
      <Fragment>
        <ModalTextArea
          text={textSqlQuery}
          modalVisible={showSqlQuery}
          setModalVisible={setShowSqlQuery}
        />
        <ModalTitle
          title={title}
          setTitle={setTitle}
          isFormat={false}
          titleModalVisible={titleModalVisible}
          setTitleModalVisible={setTitleModalVisible}
          handleRun={handleRun}
        />
        <ModalTable
          listField={listField}
          queryResult={queryResult}
          showModal={showModal}
          setShowModal={setShowModal}
          setQueryResult={setQueryResult}
        />
        <ModalEntryInputForm
          showModal={showModalEntryInput}
          setShowModal={setShowModalEntryInput}
          entityCriterias={entityCriterias}
          handleOK={handleEntryInputFormOK}
        />
        <Card className='query-criteria-widget-card'>
          <Card.Header>
            <Row className='header-row'>
              <Button
                key={`btn-addORGroup`}
                onClick={() => handleAddGroupClick('OR')}
                disabled={!_.get(activeAttribute, 'isGroup', false)}
              >
                <div>
                  <Union />
                  <span className='primary-button-content'>Add OR Group</span>
                </div>
              </Button>
              <Button
                key={`btn-addANDGroup`}
                onClick={() => handleAddGroupClick('AND')}
                disabled={!_.get(activeAttribute, 'isGroup', false)}
              >
                <div>
                  <Intersect />
                  <span className='primary-button-content'>Add AND Group</span>
                </div>
              </Button>
              <Button key={`btn-runQuery`} onClick={handleRunQueryBefore}>
                <div>
                  <span>Run query</span>
                </div>
              </Button>
              <Button
                disabled={!itemId}
                key={`btn-showSql`}
                onClick={handleShowSqlQuery}
              >
                <div>
                  <span>Show Sql</span>
                </div>
              </Button>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Form.Group>
                <Row style={{ padding: '0 25px' }}>
                  <Form.Label column sm={3}>
                    Limit
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='number'
                      name='limit'
                      value={limitValue}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row style={{ padding: '0 25px' }}>
                  <Form.Label column sm={3}>
                    Offset
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='number'
                      name='offset'
                      value={offsetValue}
                      onChange={(e) => handleChangeSettings(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            <div className='query-criteria-widget-card-body'>
              <Col md={6} className='tab-component criteria-col'>
                <div className='criteria-sortable-tree-wrapper'>
                  <SortableTree
                    treeData={criterias}
                    onChange={(criterias) => onChangeCriterias(criterias)}
                    onMoveNode={onMoveCriteria}
                    shouldCopyOnOutsideDrop={true}
                    dndType={externalNodeType}
                    canDrop={canDropCriteria}
                    canDrag={canDragCriteria}
                    canNodeHaveChildren={(node) => !node.isAttribute}
                    getNodeKey={({ node }) => node.id}
                    generateNodeProps={({ node, path, treeIndex }) => ({
                      buttons: [
                        !node.isCoreGroup ? (
                          <button
                            className='sortable-tree-button'
                            onClick={(event) =>
                              removeNode(node, path, treeIndex)
                            }
                          >
                            <XSquareFill />
                          </button>
                        ) : null,
                      ],
                      title: (
                        <button
                          className={`button-sortable-tree  ${
                            node.id === _.get(activeAttribute, 'id', '')
                              ? 'active'
                              : null
                          }`}
                          onClick={() => handleClickNode(node, path, treeIndex)}
                        >
                          {node.title}
                        </button>
                      ),
                    })}
                  />
                </div>

                {!_.get(activeAttribute, 'isGroup', false) && (
                  <div style={{ marginLeft: '20px' }}>
                    {!_.isEmpty(activeAttributeName) && (
                      <span>
                        <h5>The field "{activeAttributeName}"</h5>
                      </span>
                    )}
                    {!_.isEmpty(activeAttribute) && (
                      <QueryCriteriaOperators
                        activeAttribute={activeAttribute}
                        updateEntityInCriterias={updateEntityInCriterias}
                      />
                    )}
                  </div>
                )}
              </Col>
              <Col md={7} className='tab-component criteria-col'>
                <div className='attribute-sortable-tree-wrapper'>
                  <SortableTree
                    treeData={nodes}
                    onChange={(nodes) => setNodes(nodes)}
                    shouldCopyOnOutsideDrop={true}
                    canDrop={canDropAttribute}
                    canDrag={canDragAttribute}
                    getNodeKey={({ node }) => node.id}
                    dndType={externalNodeType}
                    canNodeHaveChildren={(node) => !node.isAttribute}
                    onVisibilityToggle={(item) => onVisibilityToggle(item)}
                  />
                </div>
              </Col>
            </div>
          </Card.Body>
        </Card>
      </Fragment>
    );
  },
);

const mapStateToProps = (state) => ({
  user: getUser(state),
  tableFieldList: getTableFieldList(state),
  queryList: getQueryList(state),
  entities: getEntityList(state),
  criteriaList: getCriteriaList(state),
  currentFormatList: getCurrentFormatList(state),
  currentTable: getCurrentTable(state),
  tableCounter: getTableCounter(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addEntities,
      addEntityItem,
      updateEntityItem,
      addCriterias,
      addCriteriaItem,
      deleteCriteriaItem,
      setCatchedError,
      addTableCounter,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryCriteriaWidget);
