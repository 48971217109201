import React from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { writeStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import mapValuesDeep from 'deepdash/mapValuesDeep';
import filterDeep from 'deepdash/filterDeep';
import { googleSignOut, refreshExpiredToken, MsAuthModule } from '../helpers';
import colors from '../scss/colors.scss';
import { API } from '../API';

import { downloadFileHandler } from '../pages/FileManagerAzure/components/handlers';
import store from '../redux';
import {
  loadUser,
  authorizationType,
  subscriptionDB,
  setTheme
} from '../redux';
import { applyFormatType } from './formats';

export const flattenObject = (ob, prefix = false, result = null) => {
  result = result || {};
  if (typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
    result[prefix] = Array.isArray(ob) ? [] : {};
    return result;
  }
  prefix = prefix ? prefix + '.' : '';
  for (const i in ob) {
    if (!i.includes('__typename'))
      if (Object.prototype.hasOwnProperty.call(ob, i)) {
        if (typeof ob[i] === 'object' && ob[i] !== null) {
          flattenObject(ob[i], prefix + i, result);
        } else {
          result[prefix + i] = ob[i];
        }
      }
  }
  return result;
};

export const nestedAttributeTextGenerator = (dataValue, attribute) => {
  let dataField = divideNestedAttribute(attribute);
  let text = '';
  if (dataValue) {
    if (Array.isArray(dataValue)) {
      text = dataValue && dataValue[0] ? dataValue[0][dataField[1]] : '';
    } else if (typeof dataValue === 'object') {
      if (dataField[1].includes('{')) {
        const nestedAttribute = divideNestedAttribute(dataField[1]);
        text = nestedAttributeTextGenerator(
          dataValue[nestedAttribute[0]],
          dataField[1]
        );
      } else {
        if (dataField[1].split(' ').length > 1) {
          text = dataField[1]
            .split(' ')
            .map((field) => textGenerator(dataValue, { attribute: field }))
            .join(' ');
        } else {
          text = dataValue[dataField[1]];
        }
      }
    } else {
      text = dataValue;
    }
  }
  if (dataValue === 0) text = `${dataValue}`;
  return text;
};

export const textGenerator = (data, { attribute, type, ...field }) => {
  let dataSource;
  if (!attribute) {
    return '';
  }
  if (attribute.includes('Mth:')) {
    return data[attribute];
  }
  if (field.enum) {
    const selectedField = field.enum.find(
      (option) => option.name === data[attribute]
    );
    return selectedField?.caption || '';
  }
  if (attribute === 'head' && data.head && data.docExt) {
    return `${data.head}.${data.docExt}`;
  }
  if (attribute.indexOf('(') > 0) {
    dataSource = attribute.slice(0, attribute.indexOf('('));
    if (dataSource.indexOf('{')) {
      const subAttr = attribute.slice(
        attribute.indexOf(')') + 1,
        attribute.length
      );
      dataSource += subAttr;
      return textGenerator(data, { attribute: dataSource, type });
    }
  } else if (attribute.indexOf(':') > 0) {
    const attributeSubstr = attribute.slice(
      attribute.indexOf(':'),
      attribute.indexOf('{')
    );
    attribute = attribute.replace(attributeSubstr, '');
    return textGenerator(data, attribute);
  } else if (attribute.indexOf('{') > 0) {
    dataSource = attribute.slice(0, attribute.indexOf('{'));
  } else {
    dataSource = attribute;
  }
  let text = nestedAttributeTextGenerator(
    data[dataSource.replaceAll(' ', '')],
    attribute
  );
  text = text ? applyFormatType(text, type, attribute) : '';
  return text;
};

export const getSubString = (str) => str.substring(0, str.indexOf('{'));

export const mergeNestedAttributes = (nestedAttributes) => {
  let nested = '';
  const composedAttributes = nestedAttributes.map((nestedAttribute) => {
    const dividedAttribute = divideNestedAttribute(nestedAttribute);
    let filteredByAttribute = nestedAttributes.map((attr) => {
      if (attr.includes(dividedAttribute[0])) {
        return divideNestedAttribute(attr)[1];
      }
    });
    filteredByAttribute = filteredByAttribute.filter(
      (attr) => attr !== undefined
    );
    let composedAttribute =
      ` ${dividedAttribute[0]}{${filteredByAttribute}} `.replaceAll(',', ' ');
    if (
      !nested.includes(dividedAttribute[0]) ||
      !nested.includes(`:${dividedAttribute[0]}`)
    ) {
      nested += ` ${composedAttribute} `;
    }
  });
  return nested;
};
export const divideNestedAttribute = (attribute) => {
  if (attribute && attribute.indexOf('{') > -1) {
    const parentAttribute = attribute.slice(0, attribute.indexOf('{'));
    const childAttribute = attribute.slice(
      attribute.indexOf('{') + 1,
      attribute.length - 1
    );
    return [parentAttribute, childAttribute];
  } else {
    return [attribute];
  }
};

export const checkValidation = (fields, data) => {
  let validationPassed = true;
  let validationError = null;
  if (!data) {
    return { validationPassed: false, validationError: 'No Values' };
  }
  fields.map((field) => {
    if (field.validation) {
      if (field.attribute.toLowerCase().includes('relation')) {
        const attribute = field.attribute.split('{')[0];
        if (!data[attribute]) {
          validationPassed = false;
          return;
        }
      } else {
        if (!data[field.attribute]) {
          validationPassed = false;
          validationError = field.validationError || 'Validation failed';
          return;
        }
        const stringToRegexp = new RegExp(field.validation);
        // const passed = stringToRegexp.test(`${data[field.attribute]}`); Uncomment when JSON validation field is fixed
        validationPassed =
          data[field.attribute] !== null || data[field.attribute] !== undefined; // Temporary
        // if (passed === false) {
        //   validationPassed = passed;
        //   return;
        // }
        return;
      }
    }
    return;
  });
  return { validationPassed, validationError };
};

export const clearCache = () => {
  window.localStorage.removeItem('authEmail');
  window.localStorage.removeItem('authType');
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('expDate');
  window.localStorage.removeItem('userName');
  window.localStorage.removeItem('permission');
  window.localStorage.removeItem('localUserData');
  window.localStorage.removeItem('id');
  localStorage.clear();
  sessionStorage.clear();
  store.dispatch(loadUser(null));
};

export const setAppTheme = (theme) => {
  const {
    bgLight,
    bgDark,
    textLight,
    textDark,
    secondaryDark,
    secondaryLight,
    tableSecondaryLight,
    tableSecondaryDark,
    inputLight,
    inputDark,
    borderDark,
    borderLight,
    calendarEventLight,
    additionalDark,
    iconDefault
  } = colors;
  if (theme) {
    theme = theme[0]?.toLocaleLowerCase() == 'd' ? 'dark' : 'light';
    const localUserData = getLocalStorageItem('localUserData');
    if (localUserData) {
      localUserData.fax.toLocaleLowerCase() !== theme.toLocaleLowerCase() &&
        setLocalStorageItem('localUserData', { ...localUserData, fax: theme });
    }
    store.dispatch(setTheme(theme));
    const body = document.body;
    body.dataset.topbar = theme;
    body.dataset.sidebar = theme;
    document.documentElement.style.setProperty(
      '--primaryBg',
      theme === 'light' ? bgLight : bgDark
    );
    document.documentElement.style.setProperty(
      '--primaryText',
      theme === 'light' ? textLight : textDark
    );
    document.documentElement.style.setProperty(
      '--secondaryBg',
      theme === 'light' ? secondaryLight : secondaryDark
    );
    document.documentElement.style.setProperty(
      '--tableSecondary',
      theme === 'light' ? tableSecondaryLight : tableSecondaryDark
    );
    document.documentElement.style.setProperty(
      '--inputPrimary',
      theme === 'light' ? inputLight : inputDark
    );
    document.documentElement.style.setProperty(
      '--borderPrimary',
      theme === 'light' ? borderLight : borderDark
    );
    document.documentElement.style.setProperty(
      '--calendarEvent',
      theme === 'light' ? calendarEventLight : additionalDark
    );
    document.documentElement.style.setProperty(
      '--iconDefault',
      theme === 'light' ? iconDefault : textDark
    );
  }
};

export const flattenDataSet = (data, fields) => {
  if (!fields) return data;
  const dataFields = fields.map((e) =>
    typeof e === 'string' ? e : e.attribute
  );
  data = formatDates(data);
  const flattenedDataSet = data.map((data, i) => {
    const dataSet = [];
    dataFields.map((field) => {
      if (field.indexOf('{') < 0) {
        const entry = data[field] ? data[field] : '';
        dataSet.push(entry);
      } else {
        const [parentField, childField] = divideNestedAttribute(field);
        if (parentField.includes('primaryCdmcommRelation')) {
          dataSet.push(data.primaryCdmcommRelation[0]?.txt || '');
        }
        if (data[parentField]) {
          if (data[parentField][childField]) {
            const entry = data[parentField][childField];
            dataSet.push(entry);
          } else {
            dataSet.push('');
          }
        } else {
          dataSet.push('');
        }
      }
    });
    return dataSet;
  });

  const dataHeadlines = fields.map((field) => field.caption);
  flattenedDataSet.unshift(dataHeadlines);
  return flattenedDataSet;
};

export const setDateToDefaultFormat = (value) => {
  try {
    return value
      ? new Intl.DateTimeFormat(window.navigator.languages[0]).format(
          moment(value)
        )
      : '';
  } catch {
    return '';
  }
};

export const formatDates = (data) => {
  if (data) {
    return data.map((entry) => {
      Object.entries(entry).map(([key, value]) => {
        if (value) {
          if (
            key.toLowerCase().includes('date') ||
            key.toLowerCase().includes('invoiceperiod') ||
            (key[key.length - 1] === 'D' && !key.includes('PWD'))
          ) {
            try {
              entry[key] = value.includes('-')
                ? new Intl.DateTimeFormat(window.navigator.languages[0]).format(
                    moment(value)
                  )
                : value;
            } catch {
              return value;
            }
          }
        }
      });
      return entry;
    });
  }
};
export const convertSearchOptionToText = (option, optionText = '') => {
  const divider = '  —  ';
  optionText = optionText.split(divider);
  Object.entries(option).map(([key, val]) => {
    if (key !== 'id' && key !== '__typename' && val !== null) {
      if (typeof val === 'object') {
        optionText = convertSearchOptionToText(
          val,
          optionText.join(divider)
        ).optionText;
      } else {
        !val.toString().match(/^ *$/) && optionText.push(val);
      }
    }
  });
  if (typeof optionText !== 'string') {
    optionText = optionText.filter(
      (option) => !option.toString().match(/^ *$/)
    );
    optionText = optionText.join(divider);
  }
  delete option.__typename;
  return { optionText, ...option };
};

export const remove = (arr, id) =>
  arr
    .filter((i) => i.id !== id)
    .map((o) => ({
      ...o,
      ...(o.children && { children: remove(o.children || [], id) })
    }));
export const setLocalStorageItem = (key, item) => {
  return key && item && localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItem = (key) => {
  if (localStorage.getItem(key)) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return localStorage.getItem(key);
    }
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getSelectedExcelData = (selected) => {
  const ws = XLSX.utils.aoa_to_sheet(selected, { cellStyles: true });
  const styledWs = { '!ref': ws['!ref'] };
  Object.entries(ws).map(([key, value]) => {
    if (key !== '!ref') {
      styledWs[key] = {
        v: ws[key].v,
        t: ws[key].t,
        s: { font: { bold: true } }
      };
    }
  });
  return styledWs;
};

export const getDefaultLocale = () => {
  const supportedLanguages = [
    { language: 'da', code: 45 },
    { language: 'sv', code: 46 },
    { language: 'de', code: 49 }
  ];
  const defaultLanguage = navigator.language.toLocaleLowerCase();
  let selectedLocaleFields;
  let languageSet = false;
  supportedLanguages.map((option) => {
    if (defaultLanguage.includes(option.language)) {
      selectedLocaleFields = require(`../Metadata/Locale.${option.code}.json`);
      languageSet = true;
    }
  });

  if (!languageSet) {
    selectedLocaleFields = require(`../Metadata/Locale.44.json`);
  }
  return selectedLocaleFields.fields;
};

export const handlePlaceholder = (
  attribute,
  primaryPlaceholder,
  secondaryPlaceholder = ''
) => {
  const locale = getDefaultLocale();
  const field = locale.fields.find((field) => field.attribute === attribute);
  return field.value
    .replaceAll('%1', primaryPlaceholder)
    .replaceAll('%2', secondaryPlaceholder);
};

export const redirectToDetail = async (item, el, history, state = {}) => {
  const metadatasList = getLocalStorageItem('metadatasList');
  const meta = _.find(metadatasList, ['fileName', `Frm.${item}.json`]);
  if (meta?.data) {
    const dataSource = `${meta.data.form.tab[0].mainGroup[0].dataSource}s`;
    history.push({
      pathname: `/${item}-${el.id}`,
      state: { id: el.id, ...state, dataSource: state.dataSource || dataSource }
    });
  } else {
    await updateMetadataList(`Frm.${item}.json`, metadatasList);
    await redirectToDetail(item, el, history, state);
    return;
  }
};
export const updateMetadataList = async (
  metadataName,
  metadatasList,
  token = null,
  authtype = null,
  checkToken = true
) => {
  let metadataResult;
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers = {
    authorization: token ? token : `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: authtype ? authtype : localStorage.getItem('authType')
  };
  try {
    metadataResult = await api.getRestMetadataStorageGetMetadata({
      headers,
      metadataNameList: metadataName,
      checkToken
    });
    if (metadataResult && metadataResult.code === 200) {
      const data = [...metadatasList, ...metadataResult.data];
      writeStorage('metadatasList', data);
      return data;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateFormatList = async (
  tableName,
  formatList,
  tableFormatList
) => {
  let tableFormatListResult;
  const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
  const headers = {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    typeauthorization: localStorage.getItem('authType')
  };
  try {
    tableFormatListResult =
      await api.getRestMetadataStorageGetTableFormatListByTableName({
        headers,
        tableName: tableName,
        formatList: formatList
      });
    if (tableFormatListResult.code === 200) {
      let data;
      if (tableFormatList && tableFormatList.length) {
        data = [
          ...tableFormatList,
          ..._.get(tableFormatListResult, 'data', [])
        ];
      } else {
        data = _.get(tableFormatListResult, 'data', []);
      }
      writeStorage('tableFormatList', _.uniqBy(data, 'formatName'));
      return data;
    } else {
      throw new Error(tableFormatListResult.message);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const generateQueryRules = (criteriaList, entities) => {
  let notes = [];
  const rules = mapValuesDeep(
    _.cloneDeep(criteriaList),
    (child, ind, parent) => {
      let isRelatedTable = false;
      let tableName;
      let fieldNameArr;
      let fieldName;
      let relationName = null;
      let value;
      if (
        _.get(child, 'isGroup', false) &&
        !_.isEmpty(_.get(child, 'children', []))
      ) {
        return {
          id: child.id,
          title: child.title,
          combinator: child.groupType ? child.groupType : child.combinator,
          parentCombinator: parent.groupType,
          isCoreGroup: _.get(child, 'isCoreGroup', false),
          isGroup: true,
          expanded: true
        };
      }
      if (_.get(child, 'operator', null) && !_.get(child, 'isNote', false)) {
        if (child.id.indexOf('Relation') !== -1) {
          isRelatedTable = true;
        }
        let attributeArr = child.id.split('.');
        if (attributeArr.length) {
          if (isRelatedTable) {
            const relationsNameArr = _.cloneDeep(attributeArr);
            const lastElemRelationsArr = relationsNameArr.pop();
            relationName = relationsNameArr.join('{');
            const bracketsCount = (relationName.match(/{/g) || []).length;
            relationName = relationName
              ? relationName + '}'.repeat(bracketsCount)
              : null;
            fieldNameArr = lastElemRelationsArr.split('-');
            fieldName = fieldNameArr[0];
          } else {
            tableName = attributeArr[0];
            fieldNameArr = attributeArr[1].split('-');
            fieldName = fieldNameArr[0];
          }
        }
        if (isRelatedTable) {
          tableName = child.tableName;
        }
        if (child.isEntity) {
          const activeEntity = entities.filter((item) =>
            item.belongTo.includes(child.id)
          );
          if (activeEntity.length > 0) {
            value = _.get(activeEntity[0], 'value', '');
          }
        } else {
          value = _.get(child, 'value', '');
        }
        return {
          id: child.id,
          title: child.title,
          tableName: tableName,
          relationName: relationName,
          type: child.type,
          field: fieldName,
          value: value,
          operator: _.get(child, 'operator', ''),
          not: child.notValue,
          alias: child.alias,
          relationInfo: _.get(child, 'relations', {}),
          isEntity: child.isEntity
        };
      }
      if (_.get(child, 'isNote', false)) {
        notes.push({
          id: child.id,
          title: child.title,
          tableName: 'cdmcomm',
          field: 'line',
          operator: _.get(child, 'operator', ''),
          not: child.notValue,
          key: child.key,
          relationInfo: _.get(child, 'relations', {})
        });
      }
    },
    { childrenPath: 'children' }
  );

  let procRules = filterDeep(
    rules,
    (child, ind, parent) => {
      if (child) return true;
    },
    { childrenPath: 'children' }
  );
  return { rules: procRules, notes: notes };
};

export const startsWithIgnoreCase = (str, startsWith) => {
  return str.replaceAll(' ', '').toLowerCase().startsWith(startsWith);
};

const forceLogout = () => {
  const authType = localStorage.getItem('authType');
  if (authType === 'google') {
    googleSignOut();
  }
  clearCache();
  store.dispatch(loadUser(null));
  store.dispatch(subscriptionDB([]));
  store.dispatch(authorizationType(null));
};

export const tokenPromise = (requestType, checkToken) => {
  return new Promise((resolve, reject) => {
    let token = '';
    let curTime = new Date();
    curTime = curTime.setMinutes(curTime.getMinutes() + 5);
    //login request
    if (requestType === 'gql' || !checkToken) {
      if (
        localStorage.getItem('token') === null &&
        localStorage.getItem('expDate') === null
      ) {
        resolve({
          token: `Bearer ${localStorage.getItem('token')}`,
          authType: localStorage.getItem('authType')
        });
        return;
      }
    }

    if (new Date(curTime) < new Date(localStorage.getItem('expDate'))) {
      resolve({
        token: `Bearer ${localStorage.getItem('token')}`,
        authType: localStorage.getItem('authType')
      });
    } else {
      if (localStorage.getItem('authType') === 'google') {
        refreshExpiredToken()
          .then((data) => {
            if (_.get(data, 'access_token', null) === null) {
              throw new Error("access token doesn't exist");
            }
            localStorage.setItem('token', _.get(data, 'access_token', ''));
            localStorage.setItem(
              'expDate',
              new Date(_.get(data, 'expires_at', ''))
            );
            token = _.get(data, 'access_token', null);
            resolve({
              token: `Bearer ${token}`,
              authType: localStorage.getItem('authType')
            });
            //return data;
          })
          .catch((error) => {
            forceLogout();
            reject(error);
          });
      } else if (localStorage.getItem('authType') === 'ms') {
        console.log('Check token');
        const authModule = new MsAuthModule();
        authModule
          .getIdToken()
          .then((data) => {
            if (_.get(data, 'idToken', null) === null) {
              throw new Error("token doesn't exist");
            }
            if (_.get(data, 'redirect', null) === null) {
              localStorage.setItem('token', _.get(data, 'idToken', ''));
              localStorage.setItem(
                'expDate',
                new Date(_.get(data, 'expiresOn', ''))
              );
              token = _.get(data, 'idToken', null);
              resolve({
                token: `Bearer ${token}`,
                authType: localStorage.getItem('authType')
              });
            }
          })
          .catch((error) => {
            forceLogout();
            reject(error);
          });
      }
    }
  });
};

export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    navigator.platform.includes('Mac')
  );
};

export const decimalToColor = (decimal) =>
  decimal
    ? `#${decimal
        .toString(16)
        .padStart(6, '0')
        .match(/.{1,2}/g)
        .reverse()
        .join('')}`
    : '#000';

export const colorToDecimal = (color) =>
  parseInt(
    `0x${color
      .toString()
      .replace('#', '')
      .match(/.{1,2}/g)
      .reverse()
      .join('')}`,
    16
  );

export const getRenderedMeta = async (metadatasList, fileName) => {
  let meta = _.find(metadatasList, ['fileName', fileName]);
  if (!meta) {
    try {
      const updatedMeta = await updateMetadataList(fileName, metadatasList);
      meta = _.find(updatedMeta, ['fileName', fileName]);
    } catch (err) {
      return null;
    }
  }
  return meta;
};

export const setRecentViewList = (caption) => {
  const recentViewsList = getLocalStorageItem('recentViewsList');
  const form = window.location.pathname.substring(1);
  if (!recentViewsList) {
    setLocalStorageItem('recentViewsList', [
      {
        caption,
        form
      }
    ]);
  } else {
    const viewsArray = recentViewsList;
    if (
      form !== 'AppointmentCalendarUsers' &&
      _.find(viewsArray, ['form', window.location.pathname.substring(1)])
    ) {
      _.remove(viewsArray, {
        form
      });
    }
    if (viewsArray.length === 15) {
      viewsArray.pop();
    }
    viewsArray.unshift({
      caption,
      form
    });
    setLocalStorageItem('recentViewsList', viewsArray);
  }
};

export const getCellStyles = async (data, dataSource, setCellStyle) => {
  if (dataSource === 'cdmdocuments') {
    const { id, docExt } = data;
    if (id && docExt) {
      const fileName = `${id.substring(0, 8)}.${docExt}`;
      try {
        await downloadFileHandler(fileName);
      } catch (err) {
        if (err) {
          setCellStyle({
            textDecoration: 'line-through'
          });
        }
      }
    }
  }
};

export const updateFormatListForNavigation = async (
  item,
  currentMetadata = [],
  setCatchedError
) => {
  const metadatasList = getLocalStorageItem('metadatasList');
  const tableFormatList = getLocalStorageItem('tableFormatList');
  let tableFormatListResult;
  let currentMetadataFile;
  if (currentMetadata.length) {
    currentMetadataFile = _.find(currentMetadata, [
      'fileName',
      `Frm.${item}.json`
    ]);
  } else {
    currentMetadataFile = _.find(metadatasList, [
      'fileName',
      `Frm.${item}.json`
    ]);
  }
  if (currentMetadataFile) {
    const fullGrid = _.get(
      currentMetadataFile,
      'data.form.mainGroup[0].fullGrid',
      null
    );
    if (fullGrid) {
      const { dataSource, listFormat } = fullGrid;
      if (
        !_.find(tableFormatList, ['formatName', `${dataSource}Formats`]) ||
        !_.find(tableFormatList, ['formatName', `${dataSource}.${listFormat}`])
      ) {
        if (listFormat) {
          try {
            tableFormatListResult = await updateFormatList(
              dataSource,
              `${dataSource}.${fullGrid.listFormat}`,
              tableFormatList
            );
            return true;
          } catch (err) {
            setCatchedError(`${err.message}`);
            return false;
          }
        }
      }
    }
    return true;
  }
};

export const showSuccessMessage = async (setAlertBarOptions) => {
  const successMessage = await getRenderedMeta(
    getLocalStorageItem('metadatasList'),
    `Dlg.Success.json`
  );
  setAlertBarOptions({
    show: true,
    alertText: `${successMessage.fields[0].caption}!`,
    variant: 'success'
  });
  setTimeout(() => {
    setAlertBarOptions({
      show: false,
      alertText: '',
      variant: 'success'
    });
  }, 3000);
};

export const printIcon = (iconName) => {
  let iconFontFamily = '';
  if (iconName.includes('mdi-')) {
    iconFontFamily = 'mdi';
  }
  if (iconName.includes('fa-')) {
    iconFontFamily = 'fab';
  }
  return <i className={`${iconFontFamily} ${iconName}`}></i>;
};

export const getDefaultDataSourceFromFormat = (item) => {
  const metadatasList = getLocalStorageItem('metadatasList');
  const tableFormatList = getLocalStorageItem('tableFormatList');
  const metadataFile = _.find(metadatasList, ['fileName', `Frm.${item}.json`]);
  const fullGrid = _.get(metadataFile, 'data.form.mainGroup[0].fullGrid', null);
  if (fullGrid) {
    const { dataSource, listFormat } = fullGrid;
    if (
      _.find(tableFormatList, ['formatName', `${dataSource}.${listFormat}`])
    ) {
      const defaultFormat = _.find(tableFormatList, [
        'formatName',
        `${dataSource}.${listFormat}`
      ]);
      return _.get(defaultFormat, 'dataSource', null);
    }
  }
  return null;
};
