import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SortableTree, { removeNodeAtPath } from 'react-sortable-tree';
import _ from 'lodash';
import { Card, Row, Form, Col, Button } from 'react-bootstrap';

import {
    getEntityList,
    getTranslationList,
    updateEntityItem,
} from '../../../../redux';
import { QueryTranslatedHeading } from '../commonComponents';
import { ValueEditor } from '../';

const QueryEntitiesWidget = ({ entities, updateEntityItem }) => {
    const [entitiesTree, setEntitiesTree] = useState([]);
    const [activeEntity, setActiveEntity] = useState({});
    const [canBeEdited, setCanBeEdited] = useState(false);
    const [required, setRequired] = useState(false);

    useEffect(() => {
        if (!_.isEmpty(entities)) {
            const entitiesArr = entities.map((item) => {
                return {
                    id: item.id,
                    title: item.title,
                    translation: item.translation,
                };
            });
            setEntitiesTree(entitiesArr);
            if (!_.isEmpty(activeEntity)) {
                const activeEntityState = entities.filter((item) =>
                    item.belongTo.includes(activeEntity.id)
                );
                if (activeEntityState.length > 0) {
                    setActiveEntity(activeEntityState[0]);
                }
            }
        } else {
            setEntitiesTree([]);
            setActiveEntity({});
        }
    }, [entities]);

    useEffect(() => {
        if (!_.isEmpty(activeEntity)) {
            setCanBeEdited(_.get(activeEntity, 'canBeEdited', false));
            setRequired(_.get(activeEntity, 'required', false));
        }
    }, [activeEntity]);

    const handleClickEntity = (node) => {
        const activeEntityState = entities.filter((item) =>
            item.belongTo.includes(node.id)
        );
        setActiveEntity(activeEntityState.length ? activeEntityState[0] : {});
    };

    const handleCanBeEditedChange = () => {
        let valRequired = required;
        setCanBeEdited(!canBeEdited);
        if (!canBeEdited === false) {
            valRequired = false;
            setRequired(false);
        }

        if (!_.isEmpty(activeEntity)) {
            updateEntityItem({
                ...activeEntity,
                canBeEdited: !canBeEdited,
                required: valRequired,
            });
        }
    };

    const handleRequiredChange = () => {
        setRequired(!required);
        if (!_.isEmpty(activeEntity)) {
            updateEntityItem({ ...activeEntity, required: !required });
        }
    };

    return (
        <Fragment>
            <Card className="query-criteria-widget-card">
                <Card.Body>
                    <div className="criteria-sortable-tree-wrapper">
                        <SortableTree
                            treeData={entitiesTree}
                            onChange={(entitiesTree) =>
                                setEntitiesTree(entitiesTree)
                            }
                            canNodeHaveChildren={() => false}
                            getNodeKey={({ node }) => node.id}
                            generateNodeProps={({ node, path, treeIndex }) => ({
                                title: (
                                    <button
                                        className={`button-sortable-tree  ${
                                            node.id ===
                                            _.get(activeEntity, 'id', '')
                                                ? 'active'
                                                : null
                                        }`}
                                        onClick={() => handleClickEntity(node)}
                                    >
                                        {node.title}
                                    </button>
                                ),
                            })}
                        />
                    </div>
                    {!_.isEmpty(activeEntity) && (
                        <Fragment>
                            <Row style={{ padding: '20px' }}>
                                {!_.isEmpty(activeEntity) && (
                                    <span>
                                        <h5>
                                            The field "
                                            {_.get(activeEntity, 'title', '')}"
                                        </h5>
                                    </span>
                                )}
                            </Row>
                            <QueryTranslatedHeading
                                activeEntity={activeEntity}
                                type={'entity'}
                                valueLabel={'Heading'}
                            />
                            <Row>
                                <Form.Group style={{ width: '100%' }}>
                                    <Row
                                        style={{
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                        }}
                                    >
                                        <Form.Label column sm={2}>
                                            Standard
                                        </Form.Label>
                                        <Col sm={6}>
                                            <ValueEditor
                                                activeAttribute={activeEntity}
                                                type={_.get(
                                                    activeEntity,
                                                    'type',
                                                    null
                                                )}
                                                enumList={_.get(
                                                    activeEntity,
                                                    'enumList',
                                                    null
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Row>
                            <Row style={{ paddingLeft: '24px' }}>
                                <Form.Check
                                    className="query-builder-checkbox"
                                    inline
                                    type="checkbox"
                                    label="Can be edited"
                                    value={canBeEdited}
                                    checked={canBeEdited}
                                    onChange={handleCanBeEditedChange}
                                />
                                <Form.Check
                                    className="query-builder-checkbox"
                                    inline
                                    type="checkbox"
                                    label="Required"
                                    value={required}
                                    checked={required}
                                    onChange={handleRequiredChange}
                                    disabled={!canBeEdited}
                                />
                            </Row>
                        </Fragment>
                    )}
                </Card.Body>
            </Card>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEntityItem,
        },
        dispatch
    );
};

const mapStateToProps = (state) => ({
    entities: getEntityList(state),
    translationList: getTranslationList(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryEntitiesWidget);
