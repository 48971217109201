import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { applyFormatType } from '../../../utils';

const dashboardHandlers: { [k: string]: Function } = require('../handlers');

const DashboardBox = ({ data, component, onClick }: any) => {
  const mainData = data[component.attribute];
  const getDefaultValue = ({
    attribute,
    handler,
    inputType
  }: {
    [k: string]: string;
  }) => {
    let value = '';
    if (handler) {
      value = dashboardHandlers[handler]({ data: mainData }) || 0;
    } else {
      value =
        component?.attribute && mainData ? `${mainData[attribute] || 0}` : '';
    }
    return `${applyFormatType(value, inputType, attribute)}`;
  };
  return (
    <Col>
      {data &&
        component.fields.map((field: any) => {
          const { caption, category, format, filterExpression, form } = field;
          return (
            <Col
              className='dashboard-box'
              style={{ textDecoration: form && format && 'underline' }}
              onClick={() =>
                form &&
                format &&
                onClick({
                  form,
                  format,
                  filterExpression,
                  dataSource: field.dataSource
                })
              }
            >
              <Row>
                {component.caption} {caption}
              </Row>
              <Row>{category}</Row>
              <Row className='dashboard-box_total'>
                {getDefaultValue(field)}
              </Row>
            </Col>
          );
        })}
    </Col>
  );
};

export default DashboardBox;
