import React, { useEffect, useState, FC } from 'react';
import { Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  withRouter,
  useHistory,
  useLocation,
  RouteComponentProps
} from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import moment from 'moment';
import _ from 'lodash';
import { IAppointmentCalendar, IFormField, TFieldsValues } from '../../types';
import { setFetchFunction, getCalendarEvents, getUser } from '../../redux';
import { GenericToolbar, DialogModal, ListModal } from '../../components';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
//@ts-ignore
import locales from '@fullcalendar/core/locales-all';
import { Calendar } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { genericQuery } from '../../GraphQL';
import {
  decimalToColor,
  formatDateInput,
  formatTimeInput,
  getLocalStorageItem,
  getRenderedMeta,
  setRecentViewList,
  updateFormatList
} from '../../utils';
import * as handlers from '../../components/GenericToolbar/handlers';

const AppointmentCalendar: FC<IAppointmentCalendar> = ({
  storedEvents,
  user,
  theme
}) => {
  const [metadatasList] = useLocalStorage('metadatasList');
  const [tableFormatList] = useLocalStorage('tableFormatList');
  const [defaultCalendarView, setDefaultCalendarView] = useLocalStorage(
    'defaultCalendarView'
  );
  const history: any = useHistory();
  const location: any = useLocation();
  const apolloClient = useApolloClient();
  const [activityMeta, setActivityMeta] = useState<any>(
    _.find(metadatasList, ['fileName', `Frm.ActivityDetail.json`])
  );
  const [calendarMeta, setCalendarMeta] = useState<any>(
    _.find(metadatasList, ['fileName', `Frm.AppointmentCalendar.json`])
  );
  const [defaultView] = useState(defaultCalendarView || 'timeGridWeek');
  const [userData, setUserData] = useState<any>(user);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [startDateGte, setStartDateGte] = useState('');
  const [filterId, setFilterId] = useState<any>();
  const [confirmationWindow, setConfirmation] = useState<any>({
    show: false,
    handlers: {},
    meta: {}
  });
  const [showListModal, setShowListModal] = useState({
    show: false,
    listMeta: {}
  });
  const dataSource = 'cdmacti';
  const detailsGroup = activityMeta?.data?.form?.tab[0].mainGroup[0].group;
  let queryFields =
    detailsGroup
      ?.map((entry: any) =>
        entry.dataEntry.fields.map((field: IFormField) => field.attribute)
      )
      .flat(1) || [];
  queryFields = queryFields.map((field: string) => {
    if (field && field.includes('primTypeRelation')) {
      return (field = field.replace(/.$/, ' calendarColor}'));
    }
    return field;
  });

  const filterByUser = (item: {
    id: string;
    firstN: string;
    lastN: string;
  }) => {
    const { id, firstN, lastN } = item;
    setFilterId(id);
    setShowListModal({ ...showListModal, show: false });
    const breadCrumbs = getLocalStorageItem('details');
    let recentViews = getLocalStorageItem('recentViewsList');
    const captionArr = breadCrumbs[breadCrumbs.length - 1].caption.split(' ');
    const view = {
      caption: `${firstN} ${lastN} ${captionArr[captionArr.length - 1]}`,
      state: item,
      form: window.location.pathname.substring(1)
    };
    if (_.find(recentViews, ['caption', view.caption])) {
      recentViews.splice(
        recentViews.findIndex(
          (recentView: { caption: string }) =>
            recentView.caption === view.caption
        ),
        1
      );
    }
    recentViews.unshift(view);
    breadCrumbs[breadCrumbs.length - 1].caption = view.caption;
    breadCrumbs[breadCrumbs.length - 1].state = view.state;
    writeStorage('details', breadCrumbs);
    writeStorage('recentViewsList', recentViews);
  };
  const handleEventInteraction = (event: any) => {
    const calendarEvent = calendarEvents.find(
      (calEvent: { id: string }) => calEvent.id === event.id
    );
    const { start, end } = event;
    const { id } = calendarEvent || {
      id: '',
      title: ''
    };
    const startISO = moment(start, 'hh:mm:ss').toISOString(true);
    const endISO = moment(end, 'hh:mm:ss').toISOString(true);
    const startTime = startISO
      ? startISO.slice(startISO.indexOf('T') + 1, startISO.indexOf('+'))
      : '';
    const endTime = endISO
      ? endISO.slice(endISO.indexOf('T') + 1, endISO.indexOf('+'))
      : '';
    const startDate = startISO.slice(0, startISO.indexOf('T'));
    const endDate = endISO?.slice(0, endISO.indexOf('T')) || startDate;
    const eventObject = {
      id,
      startDate,
      endDate,
      startTime,
      endTime
    };
    history.push(`/ActivityDetail-${id}`, {
      defaultState: eventObject,
      origin: '/AppointmentCalendar',
      dataSource: `${dataSource}s`
    });
  };

  const refetchEvents = (id = filterId, onDiscard = false) => {
    const query = genericQuery(
      `${dataSource}s`,
      queryFields,
      {
        startDateGte,
        endDateLte: (() =>
          moment(startDateGte).add(43, 'days').format('yyyy-MM-DD'))(),
        primUserEq: id,
        activityClass: 'Calendar'
      },
      'title:head start:startDate end:endDate'
    );

    return apolloClient.query({ query }).then((res) => {
      if (res.data) {
        const events = res.data[`${dataSource}s`];
        if (events) {
          events.map((event: any) => {
            if (event.startTime && event.endTime) {
              event.tooltipTitle = `${formatTimeInput(
                event.startTime
              )} - ${formatTimeInput(event.endTime)} ${event.title}`;
              event.start = `${event.start}T${event.startTime}`;
              event.end = `${event.end}T${event.endTime}`;
              event.color = event?.primTypeRelation?.calendarColor
                ? decimalToColor(event.primTypeRelation.calendarColor)
                : '#3788d8';
              event.eventDesctiption = `${
                event.location ? `${event.location}</br>` : ''
              }${event.primaryCdmcommRelation[0]?.txt || ''}`;
              delete event.startTime;
              delete event.endTime;
            }
          });
          if (onDiscard) setCalendarEvents([]);
          setCalendarEvents(events);
        }
      }
    });
  };
  useEffect(() => {
    const menuEntries: any = _.find(metadatasList, [
      'fileName',
      `Mnu.main.json`
    ]);
    const calendarSubMenu =
      menuEntries.data.menu.attributes[0].attributes[1].subMenu;
    const calendarMenuEntry = calendarSubMenu.find(
      (entry: { [k: string]: string }) =>
        entry.form === history.location.pathname.substring(1)
    );
    !calendarMenuEntry.modalList &&
      setRecentViewList(calendarMenuEntry.caption);
    if (!user.primLangRelation) {
      const query = genericQuery(
        'cdmuser',
        ['primLangRelation{isoCode}'],
        user ? `id:"${user.id}"` : ''
      );
      apolloClient.query({ query }).then((res) => {
        setUserData(res.data.cdmuser);
      });
    }
  }, []);

  useEffect(() => {
    const id =
      location.pathname === '/AppointmentCalendar'
        ? user && user.id
        : location?.state?.id;
    if (!filterId || (id && id !== filterId)) {
      setFilterId(id);
    }
    refetchEvents(id);
  }, [startDateGte, storedEvents, location.key]);

  useEffect(() => {
    (async () => {
      const defaultState: any = history?.location?.state;
      if (defaultState?.id) {
        filterByUser(defaultState);
      }
      if (defaultState?.showListModal) {
        let listMeta: any = _.find(tableFormatList, [
          'formatName',
          'cdmusers.Standard'
        ]);
        if (!tableFormatList || !listMeta) {
          const result = await updateFormatList(
            'cdmusers.Standard',
            'cdmusers.Standard',
            tableFormatList
          );
          listMeta = result[result.length - 1];
        }
        setShowListModal({
          show: true,
          listMeta: {
            ...listMeta,
            dataSource: 'cdmusers',
            format: 'Standard'
          }
        });
      }
      if (!activityMeta) {
        const meta = await getRenderedMeta(
          metadatasList,
          'Frm.ActivityDetail.json'
        );
        setActivityMeta(meta);
      }
      if (!calendarMeta) {
        const meta = await getRenderedMeta(
          metadatasList,
          'Frm.AppointmentCalendar.json'
        );
        setCalendarMeta(meta);
      }
      if (_.isEmpty(confirmationWindow.meta)) {
        const dialogMeta = await getRenderedMeta(
          metadatasList,
          'Dlg.ConfirmEditEvent.json'
        );
        setConfirmation({
          ...confirmationWindow,
          meta: dialogMeta.data.form
        });
      }
    })();
  }, [history.location.state]);

  return (
    <React.Fragment>
      <div className={`container-fluid calendar-theme calendar-theme_${theme}`}>
        <Row>
          {showListModal.show && (
            <ListModal
              form={'lst.cdmusers.Standard.json'}
              listMeta={showListModal.listMeta}
              filterExpression={''}
              setFilterExpression={() => {}}
              showModal={showListModal.show}
              setShowModal={setShowListModal}
              defaultHandler={(item: {
                id: string;
                firstN: string;
                lastN: string;
              }) => {
                filterByUser(item);
                refetchEvents(item.id);
              }}
            />
          )}
          <GenericToolbar
            dataSource={dataSource}
            toolbar={calendarMeta?.data.form?.toolbar?.entries || []}
            defaultHandlers={{
              createCalendarActivity: () =>
                handlers.createCalendarActivity({ history, dataSource }),
              showAnotherUsersCalendar: () =>
                history.push({
                  pathname: '/AppointmentCalendarUsers',
                  state: { showListModal: true, dataSource: `${dataSource}s` }
                })
            }}
          />
          {confirmationWindow.show && !_.isEmpty(confirmationWindow.meta) && (
            <DialogModal
              showModal={confirmationWindow.show}
              setShowModal={() =>
                setConfirmation({
                  show: false,
                  meta: {},
                  handlers: {}
                })
              }
              handlers={confirmationWindow.handlers}
              defaultOnDiscard={confirmationWindow.handlers?.discard}
              meta={confirmationWindow.meta}
            />
          )}
          <Col className='p-2'>
            {userData?.primLangRelation && (
              <FullCalendar
                plugins={[
                  BootstrapTheme,
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin
                ]}
                scrollTime={'08:00:00'}
                businessHours={{
                  daysOfWeek: [1, 2, 3, 4, 5],
                  startTime: '08:30',
                  endTime: '16:30'
                }}
                defaultView={defaultView}
                viewSkeletonRender={(e) => {
                  const { type } = e.view.viewSpec;
                  if (defaultCalendarView != type) {
                    setDefaultCalendarView(type);
                  }
                }}
                eventRender={(info) => {
                  if (!info.isMirror) {
                    const { eventDesctiption, location, tooltipTitle } =
                      info.event.extendedProps;
                    const { childNodes } = info.el;
                    if (defaultView !== 'dayGridMonth') {
                      const descriptionElement = document.createElement('div');
                      descriptionElement.innerHTML = `<span>${eventDesctiption}</span>`;
                      childNodes[0].appendChild(descriptionElement);
                    }
                    tippy(info.el, {
                      allowHTML: true,
                      moveTransition: '',
                      trigger: 'mouseenter focus',
                      content: `${tooltipTitle}</br>${eventDesctiption || ''}`
                    });
                  }
                }}
                themeSystem='bootstrap'
                slotDuration={'00:15:00'}
                minTime={'00:00:00'}
                maxTime={'24:00:00'}
                locale={locales.find(
                  (locale: TFieldsValues) =>
                    locale.code ===
                      userData?.primLangRelation.isoCode.toLowerCase() ||
                    locale.code === 'en-gb'
                )}
                handleWindowResize={true}
                header={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                editable={true}
                droppable={true}
                contentHeight={730}
                allDaySlot={false}
                eventLimit={5}
                selectable={true}
                events={calendarEvents}
                timeZone={'local'}
                datesRender={({ view, el }) => {
                  const start = view.activeStart.toISOString();
                  const startGte = start.slice(0, start.indexOf('T'));
                  setStartDateGte(startGte);
                }}
                dateClick={(e) => {
                  const { dateStr } = e;
                  const startDate = formatDateInput(dateStr);
                  const startTime = formatTimeInput(`${moment().hour()}:00`);
                  const endTime = `${moment().add(1, 'hour').format('HH')}:00`;
                  const defaultValues = {
                    startDate,
                    startTime,
                    endDate: startDate,
                    endTime
                  };
                  handlers.createCalendarActivity({
                    history,
                    dataSource,
                    defaultValues
                  });
                }}
                eventClick={({ event, el }) => handleEventInteraction(event)}
                eventDrop={({ event, el, revert }: any) => {
                  setConfirmation({
                    ...confirmationWindow,
                    show: true,
                    handlers: {
                      discard: () => {
                        setConfirmation({ ...confirmationWindow, show: false });
                        revert();
                      },
                      edit: () => handleEventInteraction(event)
                    }
                  });
                }}
                eventResize={({ event, el, revert }: any) =>
                  setConfirmation({
                    ...confirmationWindow,
                    show: true,
                    handlers: {
                      discard: () => {
                        setConfirmation({ ...confirmationWindow, show: false });
                        revert();
                      },
                      edit: () => handleEventInteraction(event)
                    }
                  })
                }
              />
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  storedEvents: getCalendarEvents(state),
  user: getUser(state),
  theme: state.utils.theme
});
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setFetchFunction
    },
    dispatch
  );
};

export default withRouter<IAppointmentCalendar & RouteComponentProps<{}>, any>(
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(AppointmentCalendar) as any
);
