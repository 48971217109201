import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { QueryTranslatedHeading } from '../../commonComponents';
import { components } from '../../ValueEditor/components';
import { getFormats, updateFormatItem } from '../../../../../redux';
import _ from 'lodash';

const FormatValueEditor = ({
    activeFormatField,
    formats,
    updateFormatItem,
}) => {
    const [defCol, setDefCol] = useState(4);
    const [deckCol, setDeckCol] = useState(1);
    const [sortable, setSortable] = useState('false');
    const [deckRow, setDeckRow] = useState('top');
    const [currentFormatField, setCurrentFormatField] = useState({});

    useEffect(() => {
        if (!_.isEmpty(activeFormatField)) {
            if (_.get(activeFormatField, 'defCol', null)) {
                setDefCol(activeFormatField.defCol);
            }
            if (_.get(activeFormatField, 'deckCol', null)) {
                setDeckCol(activeFormatField.deckCol);
            }
            if (_.get(activeFormatField, 'sortable', null) !== null) {
                setSortable(activeFormatField.sortable ? 'true' : ' false');
            }
            if (_.get(activeFormatField, 'deckRow', null)) {
                setDeckRow(activeFormatField.deckRow);
            }
        } else {
            setCurrentFormatField({});
        }
    }, [activeFormatField]);

    useEffect(() => {
        setCurrentFormatField(
            _.find(formats[0].children, ['id', activeFormatField.id])
        );
    }, [formats]);

    const onChange = (value, name) => {
        if (name === 'defCol') {
            setDefCol(parseInt(value));
            updateFormatItem({
                ...currentFormatField,
                defCol: parseInt(value),
            });
        } else if (name === 'deckCol') {
            setDeckCol(parseInt(value));
            updateFormatItem({
                ...currentFormatField,
                deckCol: parseInt(value),
            });
        }
    };
    const onChangeSortableSelect = (value) => {
        if (value === 'true') {
            setSortable(value);
            updateFormatItem({ ...currentFormatField, sortable: true });
        } else if (value === 'false') {
            setSortable(value);
            updateFormatItem({ ...currentFormatField, sortable: false });
        }
    };

    const onChangeDeckRow = (value) => {
        setDeckRow(value);
        updateFormatItem({ ...currentFormatField, deckRow: value });
    };

    const ValueInput = components.ValueInput;
    const ValueSelect = components.ValueSelect;

    return (
        <Fragment>
            <Row style={{ padding: '20px' }}>
                {!_.isEmpty(activeFormatField) && (
                    <span>
                        <h5>
                            The field "
                            {_.get(activeFormatField, 'attribute', '')}"
                        </h5>
                    </span>
                )}
            </Row>
            <QueryTranslatedHeading
                activeEntity={activeFormatField}
                type={'format'}
                valueLabel={'Caption'}
            />
            <Row style={{ alignItems: 'baseline', marginTop: '5px' }}>
                <Col>
                    <ValueInput
                        type={'number'}
                        value={defCol}
                        onChange={onChange}
                        isDisabled={false}
                        label={'defCol'}
                    />
                </Col>
                <Col>
                    <ValueInput
                        type={'number'}
                        value={deckCol}
                        onChange={onChange}
                        isDisabled={false}
                        label={'deckCol'}
                    />
                </Col>
            </Row>
            <Row style={{ alignItems: 'baseline', marginTop: '5px' }}>
                <Col>
                    <Form.Group>
                        <Row style={{ paddingLeft: '20px' }}>
                            <Form.Label
                                style={{ paddingRight: '0', paddingLeft: '0' }}
                                column
                                sm={4}
                            >
                                Sortable
                            </Form.Label>
                            <Col sm={8}>
                                <ValueSelect
                                    value={sortable}
                                    enumList={['true', 'false']}
                                    isDisabled={false}
                                    onChange={onChangeSortableSelect}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Row style={{ paddingLeft: '20px' }}>
                            <Form.Label
                                style={{ paddingRight: '0', paddingLeft: '0' }}
                                column
                                sm={4}
                            >
                                deckRow
                            </Form.Label>
                            <Col sm={8}>
                                <ValueSelect
                                    value={deckRow}
                                    enumList={[
                                        'top',
                                        'bottom',
                                        'center',
                                        'hide',
                                        'show',
                                        'left',
                                        'right',
                                    ]}
                                    isDisabled={false}
                                    onChange={onChangeDeckRow}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    formats: getFormats(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateFormatItem,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormatValueEditor);
