import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Button, Table } from 'react-bootstrap';
import { useApolloClient, useLazyQuery } from 'react-apollo';
import _ from 'lodash';
import eachDeep from 'deepdash/eachDeep';

import {
  tableList_query,
  tableFieldList_query,
  genericQuery,
  translationList_query,
  deleteMutation,
  runSql_query,
  updateEvent,
} from '../../GraphQL';
import {
  getTableList,
  addTableList,
  addTableFieldList,
  addQueryList,
  addTranslationList,
  getQueryList,
  addCurrentTable,
  addCurrentFormatList,
  setCatchedError,
  addCriterias,
  addEntityItem,
  getEntityList,
  getCriteriaList,
  getUser,
} from '../../redux';
import { SelectTable } from '../../components/QueryBuilderTable';
import {
  ModalTable,
  ModalEntryInputForm,
} from '../../components/QueryBuilderTable';
import { useLocalStorage } from '@rehooks/local-storage';
import {
  updateMetadataList,
  updateFormatList,
  generateQueryRules,
} from '../../utils';

const QueryDashboard = ({ history, ...props }) => {
  const apolloClient = useApolloClient();

  const columnTableName = ['Name', 'Class Name', 'Table Name'];

  const [tableFormatList] = useLocalStorage('tableFormatList');
  const [metadatasList] = useLocalStorage('metadatasList');

  const [getTableFieldList, { data }] = useLazyQuery(tableFieldList_query);
  const [getTableListQuery, tableList] = useLazyQuery(tableList_query);
  const [getTranslationListQuery, translationList] = useLazyQuery(
    translationList_query,
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [queryListForVision, setQueryListForVision] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState({});
  const [activeOperation, setActiveOperation] = useState('');
  const [toolbar, setToolbar] = useState(null);
  const [defaultFormats, setDefaultFormats] = useState([]);
  const [queryBuilderMeta, setQueryBuilderMeta] = useState(
    _.find(metadatasList, ['fileName', 'Frm.QueryDashboard.json']),
  );
  const [queryResult, setQueryResult] = useState([]);
  const [listField, setListField] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalEntryInput, setShowModalEntryInput] = useState(false);
  const [entityCriterias, setEntityCriterias] = useState([]);

  const sendQueryList = async () => {
    const query = genericQuery(
      `spplFormPosts`,
      ['ipAddress', 'rawData', 'sourceURL'],
      { ipAddress: 'QUERY_COMMON' },
    );

    const spplFormPostResult = await apolloClient.query({
      query,
      variables: {
        offset: 0,
      },
      fetchPolicy: 'no-cache',
    });
    props.addQueryList(spplFormPostResult.data['spplFormPosts']);
  };

  useEffect(() => {
    if (metadatasList && metadatasList.length) {
      if (_.find(metadatasList, ['fileName', 'Frm.QueryDashboard.json'])) {
        setQueryBuilderMeta(
          _.find(metadatasList, ['fileName', 'Frm.QueryDashboard.json']),
        );
      }
    }
  }, [metadatasList]);

  useEffect(() => {
    if (queryBuilderMeta) {
      setToolbar(queryBuilderMeta.data.form.mainGroup[0].toolbar);
      setDefaultFormats(queryBuilderMeta.data.form.formatSources);
    }
  }, [queryBuilderMeta]);

  useEffect(() => {
    getTableListQuery();
    getTranslationListQuery();
    (async () => {
      await sendQueryList();
      if (!_.find(metadatasList, ['fileName', 'ListSQLOperators.json'])) {
        try {
          const successMetadata = await updateMetadataList(
            'ListSQLOperators.json',
            metadatasList,
          );
        } catch (err) {
          setCatchedError(`${err.message}`);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (tableList.data) {
      const items = tableList.data['getTableList'];
      props.addTableList(items);
    }
  }, [tableList]);

  useEffect(() => {
    if (data) {
      const items = data['getTableFieldsList'];
      props.addTableFieldList(items);
      if (activeOperation === 'create') {
        history.push('/buildQuery');
      } else if (activeOperation === 'edit') {
        history.push({
          pathname: '/buildQuery',
          search: `?itemId=${_.get(selectedQuery, 'id', '')}`,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (translationList.data) {
      const items = translationList.data['getTranslationList'];
      props.addTranslationList(items);
    }
  }, [translationList]);

  useEffect(() => {
    const items = props.queryList.map((item) => {
      const rawData = JSON.parse(item.rawData);
      const tableName = rawData.tableFields.name;
      return {
        id: item.id,
        sourceURL: item.sourceURL,
        tableName: tableName,
        rawData: rawData,
      };
    });
    setQueryListForVision(items);
  }, [props.queryList]);

  const handleQueryBuilder = async (tableNameObj) => {
    let tableName = _.get(tableNameObj, 'tableName', '');
    if (tableName !== '') {
      props.addCurrentTable(tableName);
      getTableFieldList({ variables: { tableName: tableName } });
      const currentDefaultFormat = _.find(defaultFormats, [
        'dataSource',
        `${tableName}s`,
      ])
        ? _.find(defaultFormats, ['dataSource', `${tableName}s`]).listFormat
        : 'Standard';
      if (
        !_.find(tableFormatList, ['formatName', `${tableName}sFormats`]) ||
        !_.find(tableFormatList, [
          'formatName',
          `${tableName}s.${currentDefaultFormat}`,
        ])
      ) {
        try {
          let tableFormatListResult = await updateFormatList(
            `${tableName}s`,
            `${tableName}s.${currentDefaultFormat}`,
            tableFormatList,
          );
          props.addCurrentFormatList(
            tableFormatListResult
              ? `${tableName}s.${currentDefaultFormat}`
              : null,
          );
        } catch (err) {
          props.addCurrentFormatList(null);
        }
      } else {
        props.addCurrentFormatList(`${tableName}s.${currentDefaultFormat}`);
      }
    }
  };

  const handleClick = async (btnHandler) => {
    if (btnHandler === 'CreateCommonQuery') {
      setModalVisible(true);
      setActiveOperation('create');
    } else if (btnHandler === 'EditCommonQuery') {
      handleQueryBuilder(selectedQuery);
      setActiveOperation('edit');
    } else if (btnHandler === 'ExecuteCommonQuery') {
      handleRun();
    } else if (btnHandler === 'DeleteCommonQuery') {
      const deleteItemMutation = deleteMutation(
        _.get(selectedQuery, 'id', ''),
        'spplFormPost',
      );
      apolloClient
        .mutate({ mutation: deleteItemMutation })
        .then(async (res) => {
          await sendQueryList();
        })
        .catch((err) => {
          console.log(err.message);
        });

      setActiveOperation('delete');
    }
  };

  const handleClickTable = (item) => {
    setSelectedQuery(item);
  };

  const handleRun = async () => {
    let whereConditions = _.get(selectedQuery, 'rawData.whereConditions', []);
    props.addCriterias(whereConditions);

    const entryArr = [];
    eachDeep(
      whereConditions,
      (child) => {
        if (_.get(child, 'isEntity', false)) {
          entryArr.push(child);
          props.addEntityItem({
            id: child.id,
            title: child.title,
            translation: child.title,
            entityType: 'Simple',
            type: child.type,
            canBeEdited: true,
            required: false,
            belongTo: [child.id],
            value: child.value,
          });
        }
      },
      { childrenPath: 'children' },
    );
    if (entryArr.length > 0) {
      setShowModalEntryInput(true);
      setEntityCriterias(entryArr);
    } else {
      runQuery(
        _.get(selectedQuery, 'rawData.tableFields', []),
        _.get(selectedQuery, 'id', ''),
      );
    }
  };

  const handleEntryInputFormOK = () => {
    setShowModalEntryInput(false);
    const { rules, notes } = generateQueryRules(
      props.criteriaList,
      props.entities,
    );
    const queryInfo = {
      queryName: _.get(selectedQuery, 'sourceURL', ''),
      tableFields: _.get(selectedQuery, 'rawData.tableFields', {}),
      whereConditions: rules,
      notes: notes,
    };
    const updateValues = {
      id:  _.get(selectedQuery, 'id', ''),
      ipAddress: 'QUERY_COMMON',
      rawData: JSON.stringify(queryInfo).replace(/\"/g, '\\"'),
      userAgent: props.user.id,
    };
    const updateMutation = updateEvent(updateValues, 'spplFormPost');
    apolloClient
      .mutate({ mutation: updateMutation })
      .then(({ data }) => {
        runQuery(
          _.get(selectedQuery, 'rawData.tableFields', []),
          _.get(selectedQuery, 'id', ''),
        );
      })
      .catch((err) => {
        console.log('Error', err);
      });
  };

  const runQuery = (tableFields, id) => {
    const infoQuery = {
      id: id,
      limit: 40,
      offset: 0,
    };
    const query = runSql_query(tableFields, infoQuery);
    apolloClient.query({ query }).then((res) => {
      setQueryResult(res.data['runSqlQuery']);
      setListField(tableFields.fields.map((item) => item.caption));
      setShowModal(true);
    });
  };

  return (
    <Fragment>
      <SelectTable
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleQueryBuilder={handleQueryBuilder}
        dataList={props.tableList}
        columnName={columnTableName}
      />
      <ModalTable
        listField={listField}
        queryResult={queryResult}
        showModal={showModal}
        setShowModal={setShowModal}
        setQueryResult={setQueryResult}
      />
      <ModalEntryInputForm
        showModal={showModalEntryInput}
        setShowModal={setShowModalEntryInput}
        entityCriterias={entityCriterias}
        handleOK={handleEntryInputFormOK}
      />

      <div className='content-wrapper'>
        {toolbar ? (
          <Row className='header-row'>
            {toolbar.entries.map((btnField, i) => {
              return (
                <Button
                  key={`btn-${btnField.caption}-${i}`}
                  onClick={() => handleClick(btnField.handler)}
                >
                  <i className={`${btnField.icon} btn-icon`}></i>
                  {btnField.caption}
                </Button>
              );
            })}
          </Row>
        ) : null}
        <Table bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Table</th>
            </tr>
          </thead>
          <tbody>
            {queryListForVision.map((item, i) => {
              return (
                <tr
                  className={`option-row ${
                    selectedQuery.id === item.id ? 'row-selected' : ''
                  }`}
                  key={`option-item-${i}`}
                  onClick={() => handleClickTable(item)}
                  style={{ cursor: 'pointer' }}
                >
                  {Object.keys(item).map((oneKey, i) => {
                    if (oneKey !== 'id' && oneKey !== 'rawData') {
                      return (
                        <td key={`class-${item[oneKey]}-${i}`}>
                          {item[oneKey]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  tableList: getTableList(state),
  queryList: getQueryList(state),
  entities: getEntityList(state),
  criteriaList: getCriteriaList(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTableList,
      addTableFieldList,
      addQueryList,
      addTranslationList,
      addCurrentTable,
      addCurrentFormatList,
      setCatchedError,
      addCriterias,
      addEntityItem,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(QueryDashboard));
