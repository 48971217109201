import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

const RadiosComponent = ({ field, renderedData, editing, handleChange }) => {
  const { caption, group, attribute } = field;
  useEffect(() => {}, [renderedData]);
  const radiosStyle = {
    filter: `${editing ? '' : `grayscale(1)`}`,
    cursor: 'pointer'
  };
  return (
    <Row className='details-field'>
      {caption && <Col>{caption}</Col>}
      <Col className='rendered-field-group'>
        {group &&
          group.map((groupEl) => {
            const selectedVal = renderedData[attribute];
            const checked = selectedVal === groupEl.value;
            return (
              <Row className='ml-1'>
                <input
                  type='radio'
                  name={attribute}
                  id={groupEl.caption}
                  defaultValue={groupEl.value}
                  checked={checked && 'checked'}
                  style={radiosStyle}
                  onChange={
                    editing
                      ? (e) => handleChange(e, field)
                      : (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                  }
                />
                <label className='m-1' for={groupEl.caption}>
                  {groupEl.caption}
                </label>
              </Row>
            );
          })}
      </Col>
    </Row>
  );
};

export default RadiosComponent;
