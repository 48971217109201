import {
  ADD_DATA,
  SET_SORTINGDATA,
  CLEAR_DATA,
  ADD_FRM,
  SET_FETCH_FUNCTION,
  SET_NAVIGATION_VISIBILITY,
  SELECT_ITEM,
  SELECT_ALL,
  UNSELECT_ITEM,
  UNSELECT_ALL,
  SET_DETAILS,
  SET_DOC_PREVIEW,
  ADD_CAPABILITY_PARAMS
} from '../types';

export const addDataAction = (payload) => ({
  type: ADD_DATA,
  payload
});

export const setSortingDataAction = (payload) => ({
  type: SET_SORTINGDATA,
  payload
});

export const setDetails = (payload) => {
  return {
    type: SET_DETAILS,
    payload
  };
};

export const clearData = (payload) => ({
  type: CLEAR_DATA,
  payload
});

export const setNavigationVisibilityAciton = () => ({
  type: SET_NAVIGATION_VISIBILITY
});

export const addFRMAction = (payload) => ({
  type: ADD_FRM,
  payload
});

export const setFetchFunction = (payload) => {
  return {
    type: SET_FETCH_FUNCTION,
    payload
  };
};

export const selectItem = (payload) => ({
  type: SELECT_ITEM,
  payload
});
export const unselectItem = (payload) => ({
  type: UNSELECT_ITEM,
  payload
});

export const unselectAll = (payload) => ({
  type: UNSELECT_ALL,
  payload
});

export const selectAll = (payload) => ({
  type: SELECT_ALL,
  payload
});

export const setDocPreview = (payload) => ({
  type: SET_DOC_PREVIEW,
  payload
});

export const addCapabilityParams = (payload) => ({
  type: ADD_CAPABILITY_PARAMS,
  payload
});
