import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({
  innerText,
  onConfirm,
  showModal,
  onDiscard,
  setShowModal,
}: any) => {
  const confirmAction = () => {
    onConfirm();
    setShowModal(false);
  };
  const cancelAction = () => {
    onDiscard(true);
    setShowModal(false);
  };
  return (
    <Modal
      className='confirmation-modal-content'
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body className='confirmation-modal'>
        <p>{innerText}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={cancelAction}>
          Cancel
        </Button>
        <Button variant='primary' onClick={confirmAction}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
