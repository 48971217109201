import {
  IFormField,
  IFormFullGrid,
  IUser,
  ISelectedFullGridFormat
} from '../types';
import { flattenDataSet } from '../utils';
import { genericQuery } from '../GraphQL';

import { GridClass } from './GridClass';

export class FullGridClass extends GridClass {
  fields: string[];
  apolloClient: any;
  selectAll: Function;
  selectedFullGridFormat: ISelectedFullGridFormat;

  constructor(
    fullGrid: IFormFullGrid,
    fields: string[],
    apolloClient: any,
    user: IUser,
    selectAll: Function,
    selectedFullGridFormat: ISelectedFullGridFormat
  ) {
    super(fullGrid, user);
    this.fields = fields;
    this.apolloClient = apolloClient;
    this.user = user;
    this.selectAll = selectAll;
    this.selectedFullGridFormat = selectedFullGridFormat;
  }

  getQueryFields(formatFields: any, additionalAttributes: Array<string>) {
    if (!formatFields || !formatFields.fields) {
      return [];
    }
    const queryFields = formatFields.fields.map(
      (field: IFormField) => field.attribute
    );
    if (additionalAttributes) {
      const fieldsToString = queryFields.join(' ');
      additionalAttributes.map((attr) => {
        if (!fieldsToString.includes(` ${attr}`)) queryFields.push(attr);
      });
    }
    return queryFields;
  }

  selectAllItems = async (filterExpression: string) => {
    const { dataSource } = this.grid;
    const query = genericQuery(
      dataSource,
      this.fields,
      filterExpression,
      '',
      0
    );
    const { data } = await this.apolloClient.query({
      query,
      variables: { offset: 0 }
    });
    this.selectAll(data[dataSource]);
    return flattenDataSet(data[dataSource], this.selectedFullGridFormat.fields);
  };
}
