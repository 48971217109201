import React, { Fragment, useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { ArrowLeftSquare, Save } from 'react-bootstrap-icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import Select from 'react-select';
import _ from 'lodash';

import { getMetadataForEdit, getActiveAzureContainer } from '../../redux';
import { API } from '../../API';
import AlertBar from '../../components/AlertBar';
import { FileNameModal } from './components';
import * as handlers from './components/handlers';
import { AlertBarOptions } from '../../types';

interface SelectInterface {
  label?: string;
  value?: string;
}

const FileEditor = ({
  history,
  location,
  metadataForEdit,
  activeAzureContainer
}: any) => {
  const params: URLSearchParams = new URLSearchParams(location.search);
  const itemId: string | null = params.get('itemId');

  const selectCustomStyles = {
    container: (provided: any) => ({
      ...provided,
      minWidth: '300px'
    })
  };

  const [editedFile, setEditedFile] = useState<string>('');
  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });
  const [langOptions, setLangOptions] = useState<any[]>([]);
  const [activeLangOptions, setActiveLangOptions] = useState<any>({
    label: 'json',
    value: 'json'
  });
  const [editorLanguage, setEditorLanguage] = useState<string | undefined>(
    'json'
  );
  const [fileName, setFileName] = useState<string>('');
  const [nameModalVisible, setNameModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const langs = [
      { label: 'json', value: 'json' },
      { label: 'js', value: 'javascript' }
    ];
    setLangOptions(langs);

    if (itemId && metadataForEdit !== '') {
      setEditedFile(metadataForEdit);
      const elemExt = itemId.split('.').pop();
      let fileExt: string | undefined;
      if (elemExt === 'js') {
        fileExt = 'javascript';
      } else {
        fileExt = elemExt;
      }
      setActiveLangOptions({ label: elemExt, value: fileExt });
      setEditorLanguage(fileExt);
    } else if (itemId && metadataForEdit === '') {
      history.push('/fileExplorer');
    } else {
      setEditedFile('');
      setActiveLangOptions(langs[0]);
      setEditorLanguage('json');
    }
  }, []);

  const handleEditorChange = (value: string | undefined) => {
    if(value) {
      setEditedFile(value);
    }   
  };

  const handleReturnToFileList = () => {
    history.push('/fileExplorer');
  };

  const handleSave = () => {
    if (itemId) {
      if (editedFile !== '') {
        let contentType = activeLangOptions.value;
        let filename = itemId;
        let blob = new Blob([editedFile], {
          type: contentType
        });
        const formData = new FormData();
        formData.append('file', blob, filename);
        const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
        const headers = {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          typeauthorization: localStorage.getItem('authType')
        };
        api
          .postRestMetadataStorageEditMetadata({ headers, file: formData })
          .then(async (res) => {
            if (res.code === 200) {
              const successAlert = {
                show: true,
                alertText: 'Success!',
                variant: 'success'
              };
              setAlertBarOptions(successAlert);
              setTimeout(() => {
                setAlertBarOptions({ ...successAlert, show: false });
                history.push('/fileExplorer');
              }, 3000);
            } else {
              setAlertBarOptions({
                show: true,
                alertText: res.message,
                variant: 'danger'
              });
            }
          })
          .catch((err) => {
            setAlertBarOptions({
              show: true,
              alertText: err.message,
              variant: 'danger'
            });
          });
      } else {
        setNameModalVisible(true);
      }
    }
  };

  const handleCreateNew = async () => {
    const successAlert = {
      show: true,
      alertText: 'Success!',
      variant: 'success'
    };
    let nameUploadFile;
    if (fileName.indexOf(`.${activeLangOptions.label}`) !== -1) {
      nameUploadFile = fileName;
    } else {
      nameUploadFile = `${fileName}.${activeLangOptions.label}`;
    }
    try {
      let contentType = activeLangOptions.value;
      let blob = new Blob([editedFile], {
        type: contentType
      });
      let resultUpload;
      if (activeAzureContainer === 'metadata-sys') {
        resultUpload = await handlers.uploadMetadataHandler(
          blob,
          nameUploadFile
        );
      } else {
        resultUpload = await handlers.uploadHandler(blob, nameUploadFile);
      }

      const { status, statusMessage } = resultUpload;

      if (status) {
        setAlertBarOptions(successAlert);
        setTimeout(() => {
          setAlertBarOptions({ ...successAlert, show: false });
          history.push('/fileExplorer');
        }, 3000);
      } else {
        setAlertBarOptions({
          show: true,
          alertText: statusMessage,
          variant: 'danger'
        });
      }
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleChangeSelectLanguage = (newValue: SelectInterface) => {
    if (!_.isEmpty(newValue)) {
      setActiveLangOptions(newValue);
      setEditorLanguage(newValue.value);
    }
  };

  return (
    <Fragment>
      <FileNameModal
        fileType={activeLangOptions.label}
        name={fileName}
        setName={setFileName}
        nameModalVisible={nameModalVisible}
        setNameModalVisible={setNameModalVisible}
        handleRun={handleCreateNew}
      />
      {alertBarOptions.show ? (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      ) : null}
      <div className='content-wrapper' tabIndex={0} style={{ outline: 'none' }}>
        <Row className='header-row' style={{ paddingTop: '10px' }}>
          <Button onClick={handleReturnToFileList}>
            <div>
              <ArrowLeftSquare />
              <span className='primary-button-content'>File list</span>
            </div>
          </Button>
          <Button onClick={handleSave}>
            <div>
              <Save />
              <span className='primary-button-content'>Save</span>
            </div>
          </Button>
          <Select
            isClearable
            placeholder={'Select language'}
            value={activeLangOptions}
            onChange={handleChangeSelectLanguage}
            options={langOptions}
            styles={selectCustomStyles}
            isDisabled={itemId ? true : false}
          />
        </Row>
        <Row>
          <Editor
            height='80vh'
            defaultLanguage={editorLanguage}
            language={editorLanguage}
            defaultValue={editedFile}
            onChange={handleEditorChange}
            value={editedFile}
          />
        </Row>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  metadataForEdit: getMetadataForEdit(state),
  activeAzureContainer: getActiveAzureContainer(state)
});

export default connect(mapStateToProps)(withRouter(FileEditor));
