import { takeEvery, put, select, call } from 'redux-saga/effects';
import { addDataAction, setCatchedError } from '../actions';
import { getFetchData, getSortedData, getCurrentPageTag } from '../selectors';
import { SCROLL } from '../types';
import { formatDates } from '../../utils';

function* update(scrollAction) {
  const oldData = yield select(getSortedData);
  const currentPageTag = yield select(getCurrentPageTag);
  const fetchMore = yield select(getFetchData);
  const { formatName } = scrollAction;
  if (oldData && currentPageTag && fetchMore) {
    try {
      const offset = oldData[formatName] ? oldData[formatName].data.length : 0;
      const { data } = yield call(fetchMore, offset);
      if (data && data[currentPageTag]) {
        yield put(
          addDataAction({
            [formatName]: {
              data: [
                ...oldData[formatName].data,
                ...formatDates(data[currentPageTag])
              ],
              hasMore: !(data[currentPageTag].length < 40)
            }
          })
        );
      }
    } catch (e) {
      console.log(e);
      yield put(setCatchedError(e.message));
    }
  }
}
export function* updateStoreOnScroll() {
  yield takeEvery(SCROLL, update);
}
