import gql from 'graphql-tag';
import { graphQLDateFormat } from '../utils';

export const genericMutation = (dataSource, action, inputValues = '') => {
  return gql` mutation{
    ${action}${dataSource}(input:{${inputValues}}){
      id
    }
  }`;
};

export const editMutation = (dataSource, data, itemId, layoutMeta) => {
  const fields = layoutMeta.dataEntry.fields;
  if (data) {
    delete data.__typename;
    let action = itemId ? 'update' : 'create' || data.id ? 'update' : 'create';

    if ((!itemId && !data.id) || itemId === 'create') {
      action = 'create';
    }
    let mutationParams =
      action === 'update' && !data.id ? ` id:"${itemId}" ` : ``;
    Object.entries(data).map(([key, value]) => {
      if (
        key &&
        key !== '' &&
        value !== undefined &&
        value !== null &&
        typeof value !== 'object'
      ) {
        const field = fields.find((field) => field.attribute === key);
        value = field?.inputType === 'date' ? graphQLDateFormat(value) : value;
        value =
          field?.inputType === 'int' ||
          field?.inputType === 'refnum' ||
          field?.inputType === 'amount' ||
          field?.inputType === 'radio' ||
          typeof value === 'boolean' ||
          field?.enum ||
          key.toLowerCase().includes('color') ||
          value === 'CALENDAR' ||
          typeof value === 'number'
            ? value
            : `"${value}"`;
        mutationParams += ` ${key}:${value}`;
      }
    });
    mutationParams = mutationParams
      .replaceAll('\r', '\\r')
      .replaceAll('\n', '\\n');
    return gql`
      mutation {
        ${action}${dataSource}(input: {${mutationParams}}) {
          id
        }
      }
    `;
  } else {
    return gql``;
  }
};

export const updateEvent = (fields, dataSource) => {
  delete fields.__typename;
  let mutationParams = '';
  let responseParams = 'id ';
  Object.entries(fields).map(([key, value]) => {
    if (key !== 'primCont' && !key.includes('primaryCdmcommRelation')) {
      mutationParams += ` ${key}:"${value}"`;
      responseParams += `${key} `;
    }
  });
  return gql`
      mutation {
        ${
          !fields.id ? 'create' : 'update'
        }${dataSource}(input: {${mutationParams}}) 
  {
          ${responseParams}
        }
      }
    `;
};

export const deleteEventMutation = (id) => {
  return gql`
    mutation {
      deleteItemcdmacti(input: { id: "${id}"}) {
        id
      }
    }
  `;
};

export const updateDescription = (fields, action) => {
  let mutationParams = '';
  let responseParams = '';
  Object.entries(fields).map(([key, value]) => {
    if (key === 'txt') {
      value = value.match(/(\r\n|\n|\r)/gm)
        ? value.replace(/(\r\n|\n|\r)/gm, `\\n`)
        : value;
    }
    if (key !== '__typename') {
      responseParams += `${key} `;
      mutationParams += ` ${key}:"${value}"`;
    }
  });
  return gql`
      mutation {
        ${action}${'cdmcomm'}(input: {${mutationParams}}) {
          ${responseParams}
        }
      }
    `;
};

export const createCompanyDoc = (inputValues) => {
  let mutationParams = '';
  Object.entries(inputValues).map(([key, value]) => {
    mutationParams += `${key}: ${value} `;
  });

  return gql`
    mutation{
      createcdmacti(input:{
        ${mutationParams}
      }){
        id
      }
    }
  `;
};

export const deleteMutation = (id, dataSource) => {
  return gql`
    mutation {
      deleteItem${dataSource}(input: { id: "${id}"}) {
        id
      }
    }
  `;
};

export const login = (code, authType) => {
  return gql`
  mutation  {
      login(input: { code: "${code}",authType: "${authType}" }) {
        title
        id
        email
        email360
        firstN
        lastN
        token
        expDate
        subscriptionDBs
        currentDB
        permission
        isDropboxAuth
        fileStorage
        dropboxMessage
      }
    }
  `;
};

export const logout = () => {
  return gql`
    mutation {
      logout {
        status
      }
    }
  `;
};

export const changeConnectionConfig = (databaseName) => {
  return gql`
  mutation  {
    changeConnectionConfig(input: { databaseName: "${databaseName}" }) {
      status
      }
    }
  `;
};
