import React, { useEffect, useState, FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { useQuery, useApolloClient } from 'react-apollo';
import { useLocalStorage } from '@rehooks/local-storage';
import { getRenderedMeta } from '../../utils';
import _ from 'lodash';

import {
  DataTable,
  SearchWindow,
  PrintPreview,
  GenericToolbar
} from '../../components';
import {
  addFRMAction,
  clearData,
  addDataAction,
  setFetchFunction,
  setCurrentPageTag,
  getScreenSize,
  getUser,
  getIfselectedExist,
  getSelectedDataFlat,
  selectAll,
  getSortedData,
  setCatchedError,
  getGridData,
  scrollAction
} from '../../redux';
import { genericQuery } from '../../GraphQL';
import { FullGridClass } from '../../classes';
import { IFullGrid } from '../../types';
import { getQueryFields, selectFormat } from './functions';
import { ListModal } from '../../components';

const FullGrid: FC<IFullGrid> = (props: IFullGrid) => {
  const {
    addFRMAction,
    screenSize,
    addDataAction,
    setFetchFunction,
    setCurrentPageTag,
    selectedData,
    selectAll,
    user,
    gridMeta,
    gridToolbar,
    gridFilterId,
    gridCaption,
    gridData,
    miniGridFields,
    setNotesValue,
    scrollAction
  } = props;
  const apolloClient = useApolloClient();
  const location = useLocation<any>();
  const [queryLimit] = useState(40);
  const [metadatasList] = useLocalStorage('metadatasList');
  const [DataTableFormatList] = useLocalStorage('tableFormatList');
  const [userId] = useLocalStorage('id');
  const {
    defaultFilterExpression,
    defaultFormat,
    defaultDataSource,
    showSearchModal
  }: any = location.state || {};
  const [showModal, setShowModal] = useState(showSearchModal || false);
  const [dataStorageId, setDataStorageId] = useState('');
  const currentPageName: string = location.pathname.replace('/', '');
  const [frm] = useState<any>(
    _.find(metadatasList, ['fileName', `Frm.${currentPageName}.json`])
  );
  const [grid] = useState(gridMeta || frm?.data?.form.mainGroup[0].fullGrid);
  const [filterId] = useState(gridFilterId || userId || user.id);
  const { selectFromList, dataSource } = grid;
  const [queryDataSource, setQueryDataSource] = useState(
    defaultDataSource || grid.dataSource
  );
  const [fullGridFormats] = useState<any>(
    _.find(DataTableFormatList, ['formatName', `${dataSource}Formats`])
  );
  const [formatName, setFormatName] = useState(
    defaultFormat || `${dataSource}.${grid.listFormat}`
  );
  const [selectedFullGridFormat, setSelectedFullGridFormat] = useState<any>(
    _.find(DataTableFormatList, ['formatName', formatName])
  );
  const [fields, setFields] = useState(
    getQueryFields(
      _.find(DataTableFormatList, ['formatName', formatName]),
      grid.additionalAttributes || []
    )
  );
  const [selectedAll, setSelectedAll] = useState(false);
  const [printData, setPrintData] = useState(selectedData);
  const [showListSelect, setShowListSelect] = useState(
    selectFromList ? true : false
  );
  const [listModalMeta, setListModalMeta] = useState<any>({});
  const fullGridClass = new FullGridClass(
    grid,
    fields,
    apolloClient,
    user,
    selectAll,
    selectedFullGridFormat
  );

  const [filterExpression, setFilterExpression] = useState(
    defaultFilterExpression || fullGridClass.getFilterExpression(filterId)
  );

  const searchQueries = frm?.data?.form.mainGroup
    ? frm.data.form.mainGroup[0].query
    : [];
  const query = genericQuery(
    queryDataSource,
    fields,
    filterExpression,
    '',
    queryLimit
  );
  const { data, refetch } = useQuery(query, {
    variables: {
      offset: 0,
      fetchPolicy: 'no-cache'
    }
  });
  const updateData = data ? data[queryDataSource] : [];

  const onSelectFormat = (caption: string) => {
    selectFormat(
      caption,
      fullGridFormats,
      DataTableFormatList,
      setFields,
      setSelectedFullGridFormat,
      setCatchedError,
      grid,
      setFormatName,
      setQueryDataSource
    );
  };
  const selectCallListItem = (item: any) => {
    setFilterExpression(fullGridClass.getFilterExpression(item.id));
    setShowListSelect(false);
    setListModalMeta({});
  };
  const loadData = async (storageId: string, isMiniGrid = false) => {
    addFRMAction({
      ...grid,
      fields
    });
    const result = await apolloClient.query({
      query,
      variables: { offset: 0 }
    });
    const data = result.data[dataSource] || [];
    const fetchMore = (offset: number) =>
      apolloClient.query({
        query,
        variables: { offset }
      });

    setFetchFunction(fetchMore);
    setDataStorageId(storageId);
    addDataAction({
      [storageId]: {
        data,
        hasMore: data && data.length === 40
      }
    });
    setCurrentPageTag(queryDataSource);
  };

  useEffect(() => {
    location?.state && setShowListSelect(location?.state.showListModal);
    (async () => {
      if (selectFromList) {
        const listMeta = await getRenderedMeta(
          metadatasList,
          `Frm.${selectFromList}.json`
        );
        setListModalMeta(listMeta.data.form.mainGroup[0].fullGrid);
      }
    })();
  }, [location.key]);
  useEffect(() => {
    const queryFields = getQueryFields(
      _.find(DataTableFormatList, [
        'formatName',
        selectedFullGridFormat ? selectedFullGridFormat.formatName : formatName
      ]),
      grid.additionalAttributes || []
    );
    if (queryFields) {
      setFields(queryFields);
    }
  }, [selectedFullGridFormat, formatName]);

  useEffect(() => {
    const newFormat: { fields: {}[]; formatName: string } | any = _.find(
      DataTableFormatList,
      ['formatName', formatName]
    );
    let storageId = '';
    if (newFormat) {
      if (gridCaption && selectedFullGridFormat !== newFormat) {
        setSelectedFullGridFormat(newFormat);
      }
      storageId = `${gridCaption ? gridCaption.replaceAll(' ', '') : ''}${
        newFormat.formatName
      }${location.pathname}`;
      loadData(storageId);
    }
    if (data && miniGridFields && miniGridFields.length) {
      storageId = `${gridCaption ? gridCaption.replaceAll(' ', '') : ''}`;
      loadData(storageId, true);
    }
    return () => {
      selectAll([]);
    };
  }, [data, selectedFullGridFormat]);

  useEffect(() => {
    if (miniGridFields && miniGridFields.length) {
      const miniGridQueryFields = getQueryFields(
        { fields: miniGridFields },
        grid.additionalAttributes || []
      );
      setFields(miniGridQueryFields);
    }
  }, [miniGridFields]);

  if (!screenSize) {
    return null;
  }
  const genericToolBarProps = {
    toolbar: gridToolbar
      ? gridToolbar.entries
      : currentPageName.includes('Overview')
      ? frm?.data?.form.toolbar?.entries
      : [],
    dataSource,
    fullGridFormats,
    selectAllItems: () => fullGridClass.selectAllItems(filterExpression),
    selectFormat: onSelectFormat,
    selectedFullGridFormat,
    setShowModal,
    grid,
    refetch,
    filterExpression,
    setPrintData,
    selectedAll
  };
  return (
    <div
      className='fullGrid-wrapper'
      style={{
        outline: 'none',
        overflow: 'hidden'
      }}
    >
      {showListSelect && listModalMeta.fields && (
        <ListModal
          form={selectFromList || ''}
          listMeta={listModalMeta}
          filterExpression={listModalMeta?.callListFilter || ''}
          setFilterExpression={setFilterExpression}
          showModal={showListSelect}
          setShowModal={setShowListSelect}
          defaultHandler={selectCallListItem}
        />
      )}
      <GenericToolbar {...genericToolBarProps} />
      <SearchWindow
        showModal={showModal}
        setShowModal={setShowModal}
        searchFields={searchQueries}
        filterExpression={filterExpression}
        setFilterExpression={setFilterExpression}
      />
      {updateData && (selectedFullGridFormat || miniGridFields?.length) && (
        <DataTable
          filterExpression={filterExpression}
          setFilterExpression={setFilterExpression}
          fullGridFormat={{ ...selectedFullGridFormat, dataStorageId }}
          selectAllItems={() => fullGridClass.selectAllItems(filterExpression)}
          heightStyles={{
            maxHeight: grid.maxHeight || '100%',
            minHeight: grid.minHeight || grid.maxHeight || '25vh'
          }}
          gridCaption={gridCaption}
          selectedAll={selectedAll}
          setSelectedAll={setSelectedAll}
          miniGridFields={miniGridFields}
          setNotesValue={setNotesValue}
        />
      )}
      <PrintPreview data={printData} pageHeader={''} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  screenSize: getScreenSize(state),
  hasSelected: getIfselectedExist(state),
  selectedData: getSelectedDataFlat(state),
  user: getUser(state),
  fullGridData: getSortedData,
  gridData: getGridData(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addFRMAction,
      clearData,
      setFetchFunction,
      addDataAction,
      setCurrentPageTag,
      selectAll,
      setCatchedError,
      scrollAction
    },
    dispatch
  );
};

export default connect<any, any, IFullGrid>(
  mapStateToProps,
  mapDispatchToProps
)(FullGrid);
