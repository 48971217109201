import { default as DetailsComponent } from './DetailsComponent';
import { default as DetailsMiniGrid } from './DetailsMiniGrid/DetailsMiniGrid.tsx';
import { default as NotesField } from './NotesField';
import { default as RadiosComponent } from './RadiosComponent';
import { default as DetailsCharts } from './DetailsCharts';
import { default as ImportData } from './ImportData';
export { default as TabsBar } from './TabsBar';

export const components = {
  DetailsMiniGrid,
  DetailsComponent,
  ImportData
};

export { NotesField, RadiosComponent, DetailsCharts };
