import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import _ from 'lodash';

import {
  getTranslationList,
  updateEntityItem,
  updateFormatItem,
} from '../../../../redux';
import { SelectTable } from '../../../../components/QueryBuilderTable';

const QueryTranslatedHeading = ({
  type,
  valueLabel,
  activeEntity,
  translationList,
  updateEntityItem,
  updateFormatItem,
}) => {
  const [valueHeading, setValueHeading] = useState('');
  const [isValueHeadingSet, setIsValueHeadingSet] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [listField, setListField] = useState(['Translation', 'Key']);

  useEffect(() => {
    if (!_.isEmpty(translationList)) {
      setQueryResult(translationList);
    }
  }, [translationList]);

  useEffect(() => {
    if (type === 'entity') {
      if (_.get(activeEntity, 'translation', '') !== '') {
        setValueHeading(_.get(activeEntity, 'translation', ''));
      }
    } else if (type === 'format') {
      if (_.get(activeEntity, 'caption', '') !== '') {
        setValueHeading(_.get(activeEntity, 'caption', ''));
      }
    }
  }, [activeEntity]);

  useEffect(() => {
    if (valueHeading !== '' && isValueHeadingSet) {
      if (type === 'entity') {
        updateEntityItem({
          ...activeEntity,
          translation: valueHeading,
        });
      } else if (type === 'format') {
        updateFormatItem({
          ...activeEntity,
          translation: valueHeading,
        });
      }
      setIsValueHeadingSet(false);
    }
  }, [valueHeading]);

  const handleChange = (e) => {
    setValueHeading(e.target.value);
    setIsValueHeadingSet(true);
  };

  const handleSearchTranslation = () => {
    setShowModal(true);
  };

  const handleNewTranslation = (item) => {
    setValueHeading(_.get(item, 'translation', ''));
  };

  return (
    <Fragment>
      <SelectTable
        modalVisible={showModal}
        setModalVisible={setShowModal}
        handleQueryBuilder={handleNewTranslation}
        dataList={queryResult}
        columnName={listField}
      />
      <Row>
        <Form.Group style={{ width: '100%' }}>
          <Row style={{ paddingLeft: '20px' }}>
            <Form.Label column sm={2}>
              {valueLabel}
            </Form.Label>
            <Col sm={6} style={{ position: 'relative' }}>
              <Form.Control
                type='text'
                name='from'
                value={valueHeading}
                onChange={(e) => handleChange(e)}
              />
              <Button
                className='query-entities-search-translation'
                onClick={handleSearchTranslation}
              >
                <Search />
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  translationList: getTranslationList(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateEntityItem,
      updateFormatItem,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryTranslatedHeading);
