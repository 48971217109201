'use strict';
let dispatcher = (function () {
  let events = new Map();
  let onceEvents = new Map();
  let isOnce = false;
  let checkValidate = function (checkTarget, target) {
    return Object.prototype.toString.call(checkTarget) === target;
  };
  let beforeSubscribe = function (processor, updater, isSubscribe) {
    if (isSubscribe) {
      if (!events.has(processor)) events.set(processor, []);
      if (!checkValidate(updater, '[object Function]')) return;
      return events.get(processor).push(updater);
    }
    if (!onceEvents.has(processor)) onceEvents.set(processor, []);
    if (!checkValidate(updater, '[object Function]')) return;
    onceEvents.get(processor).push(updater);
  };
  let dispatch = function (event, data) {
    if (isOnce && onceEvents.has(event)) {
      onceEvents.get(event).forEach(function (callback) {
        return callback(data);
      });
      onceEvents.delete(event);
    }
    if (!events.has(event)) return;
    events.get(event).forEach(function (callback) {
      return callback(data);
    });
  };
  let on = function (event, callback) {
    beforeSubscribe(event, callback, true);
  };
  let once = function (event, callback) {
    beforeSubscribe(event, callback, false);
    isOnce = true;
  };
  let off = function (event) {
    if (checkValidate(event, '[object Array]')) {
      return event.forEach(function (e) {
        if (events.has(e)) {
          events.delete(e);
        } else {
          onceEvents.delete(e);
        }
      });
    }
    return events.has(event) ? events.delete(event) : onceEvents.delete(event);
  };
  return {
    dispatch: dispatch,
    on: on,
    once: once,
    off: off
  };
})();
export { dispatcher };
