import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { TFieldsValues } from '../types';
import { genericQuery, genericMutation } from '../GraphQL';

import {
  getLocalStorageItem,
  getRenderedMeta,
  showSuccessMessage,
  updateFormatList
} from '../utils';

interface ISTDCampaign {
  apolloClient: any;
  history: RouteComponentProps['history'];
  setAlertBarOptions: (alertBarOptions: TFieldsValues) => void;
  selectedItems: TFieldsValues[];
  setSelectionModal: (alertBarOptions: TFieldsValues) => void;
}

export class STDCampaign {
  private selectedItems;
  private apolloClient;
  private history;
  private setAlertBarOptions;
  private setSelectionModal;

  constructor(classProps: ISTDCampaign) {
    this.apolloClient = classProps.apolloClient;
    this.history = classProps.history;
    this.setAlertBarOptions = classProps.setAlertBarOptions;
    this.selectedItems = classProps.selectedItems;
    this.setSelectionModal = classProps.setSelectionModal;
  }

  capabilityDone = () => {
    this.selectedItems.map((item: TFieldsValues) => {
      const mutation = genericMutation(
        'cdmcamp',
        'update',
        `id:"${item.id}",done:true`
      );
      this.apolloClient.mutate({ mutation }).then(async (res: any) => {
        showSuccessMessage(this.setAlertBarOptions);
      });
    });
  };

  capabilityRecalculate = async () => {
    await Promise.all(
      this.selectedItems.map(async (item: TFieldsValues) => {
        const { id } = item;
        const query = genericQuery(
          'cdmca2Cs',
          ['primCont', 'primCompRelation{delD}'],
          `primCamp:"${id}"`,
          '',
          0
        );
        const { data } = await this.apolloClient.query({ query });
        const { cdmca2Cs } = data;
        const numberOfCA2C = cdmca2Cs?.length || 0;
        const noCont =
          cdmca2Cs.filter((item: TFieldsValues) => item.primCont)?.length || 0;
        const noComp1 =
          cdmca2Cs.filter((item: TFieldsValues) => !item?.primCompRelation.delD)
            ?.length || 0;
        const mutation = genericMutation(
          'cdmcamp',
          'update',
          `id:"${id}" numberOfCA2C:${numberOfCA2C} noCont:${noCont} noComp1:${noComp1}`
        );
        await this.apolloClient.mutate({ mutation });
        showSuccessMessage(this.setAlertBarOptions);
      })
    );
  };

  capabilityOpenTMCallList = async () => {
    const { id } = this.selectedItems[0];
    await updateFormatList(
      'cdmca2Cs',
      'cdmca2Cs',
      getLocalStorageItem('tableFormatList')
    );
    await updateFormatList(
      'cdmca2Cs',
      'cdmca2Cs.CallList',
      getLocalStorageItem('tableFormatList')
    );
    await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      'Frm.ParticipantsOverview.json'
    );
    this.history.push('/ParticipantsOverview', {
      defaultFilterExpression: `primCamp:"${id}", done:false`
    });
  };

  capabilityShowCompanies = async () => {
    const { id } = this.selectedItems[0];

    await updateFormatList(
      'cdmcomps',
      'cdmcomps',
      getLocalStorageItem('tableFormatList')
    );
    await updateFormatList(
      'cdmcomps',
      'cdmcomps.Standard',
      getLocalStorageItem('tableFormatList')
    );
    await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      'Frm.CompanyOverview.json'
    );
    // Pass filterExpression primCamp:`"${id}"`
    this.history.push('/CompanyOverview');
  };

  capabilityShowContacts = async () => {
    const { id } = this.selectedItems[0];
    await updateFormatList(
      'cdmconts',
      'cdmconts',
      getLocalStorageItem('tableFormatList')
    );
    await updateFormatList(
      'cdmconts',
      'cdmconts.Standard',
      getLocalStorageItem('tableFormatList')
    );
    await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      'Frm.ContactsOverview.json'
    );
    // Pass filterExpression primCamp:`"${id}"`
    this.history.push('/ContactsOverview');
  };
}
