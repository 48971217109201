import { useEffect, useState } from 'react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export interface IBreadcrumb {
  caption: string;
  link?: string;
  active: boolean;
}

export const useBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const location = useLocation();

  const [metadatasList] = useLocalStorage('metadatasList');
  const [breadcrumbPath, setBreadcrumbPath] = useLocalStorage('breadcrumbPath');

  const [menuAttributes, setMenuAttributes] = useState<any>([]);

  useEffect(() => {
    if (metadatasList && metadatasList.length) {
      if (_.find(metadatasList, ['fileName', 'Mnu.main.json'])) {
        setMenuAttributes(
          // @ts-ignore
          _.find(metadatasList, ['fileName', 'Mnu.main.json']).data.menu
        );
      }
    }
  }, [metadatasList]);

  useEffect(() => {
    if (menuAttributes.attributes && menuAttributes.toolbar) {
      menuAttributes.toolbar.entries.map((btn: any) => {
        btn.entries &&
          btn.entries.map((entry: any) => {
            if (entry.form === location.pathname.substring(1)) {
              const breadcrumbs = [
                {
                  active: false,
                  link: '/',
                  caption: entry.caption
                }
              ];
              setBreadcrumbs(breadcrumbs);
              writeStorage('details', breadcrumbs);
            }
          });
      });
      menuAttributes?.attributes.map((attribute: any) => {
        if (attribute.attributes) {
          attribute.attributes.map((subAttribute: any) => {
            if (subAttribute.subMenu) {
              subAttribute.subMenu.map((subMenuEntry: any) => {
                if (location.pathname.includes(subMenuEntry.form)) {
                  const breadcrumbs = [
                    {
                      active: false,
                      link: '/',
                      caption: attribute.caption
                    },
                    {
                      active: false,
                      link: `/${subAttribute.form}`,
                      caption: subAttribute.caption
                    },
                    {
                      active: false,
                      link: `/${subMenuEntry.form}`,
                      caption: subMenuEntry.caption
                    }
                  ];
                  setBreadcrumbs(breadcrumbs);
                  writeStorage('details', breadcrumbs);
                }
              });
            }
            if (location.pathname.includes(subAttribute.form)) {
              const breadcrumbs = [
                {
                  active: false,
                  link: '/',
                  caption: attribute.caption
                },
                {
                  active: false,
                  link: `/${subAttribute.form}`,
                  caption: subAttribute.caption
                }
              ];
              setBreadcrumbs(breadcrumbs);
              writeStorage('details', breadcrumbs);
            }
            if (location.pathname === '/SalesHome') {
              writeStorage('details', []);
            }
          });
        }
      });
    }
  }, [location, menuAttributes]);
  useEffect(() => {
    if (location.pathname.includes('Detail') && breadcrumbs.length > 0) {
      const usersPath = breadcrumbs.map((step) => step.caption).join('/');
      setBreadcrumbPath(usersPath);
    }
  }, [location.pathname]);

  return {
    breadcrumbs
  };
};
