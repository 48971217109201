import {
  ADD_SCREENSIZE,
  SET_CATCHED_ERROR,
  SET_CURRENT_PAGE_TAG,
  SET_THEME,
  SET_BROWSER_LIST,
} from '../types';

import _ from 'lodash';

const initialState = {
  screenSize: '',
  catchedError: '',
  theme: 'light',
  browserList: false,
};

const setCahcedError = (state, payload) => ({
  ...state,
  catchedError: payload,
});

const setCurrentPageTag = (state, payload) => ({
  ...state,
  currentPageTag: payload,
});

const addScreenSize = (state, payload) => ({
  ...state,
  screenSize: payload,
});

const setTheme = (state, payload) => ({
  ...state,
  theme: payload && payload[0].toLowerCase() === 'd' ? 'dark' : 'light',
});

const setBrowserList = (state, payload) => ({
  ...state,
  browserList: payload,
});

const handlers = {
  [ADD_SCREENSIZE]: addScreenSize,
  [SET_CATCHED_ERROR]: setCahcedError,
  [SET_CURRENT_PAGE_TAG]: setCurrentPageTag,
  [SET_THEME]: setTheme,
  [SET_BROWSER_LIST]: setBrowserList,
};

export default (state = initialState, action) => {
  const handler = handlers[action.type];
  const newState = handler ? handler(state, action.payload) : state;
  return newState;
};
