import _ from 'lodash';
import print from 'print-js';
import { getPagePath, formatDataForPrinting, pdfStyle } from './functions';
import { deleteMutation, genericQuery } from '../../GraphQL';
import {
  updateMetadataList,
  flattenDataSet,
  getSelectedExcelData,
  divideNestedAttribute,
  getLocalStorageItem,
  getRenderedMeta
} from '../../utils';
import {
  IFormFullGrid,
  IUser,
  IFormField,
  ISelectedFullGridFormat
} from '../../types';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { dispatcher } from '../../helpers';
import { createCalendarActivity } from './handlers';

export class ToolbarClass {
  apolloClient: any;
  formType: string;
  defaultDataSet: any;
  selectAll: Function;
  setConfirmationWindow: Function;
  history: any;
  metadatasList: any;
  fullGrid: IFormFullGrid;
  setCatchedError: any;
  format: ISelectedFullGridFormat;

  constructor(
    apolloClient: any,
    formType: string,
    defaultDataSet: any,
    selectAll: Function,
    setConfirmationWindow: Function,
    history: any,
    metadatasList: any,
    fullGrid: IFormFullGrid,
    setCatchedError: () => void,
    format: ISelectedFullGridFormat
  ) {
    this.apolloClient = apolloClient;
    this.formType = formType;
    this.defaultDataSet = defaultDataSet;
    this.selectAll = selectAll;
    this.setConfirmationWindow = setConfirmationWindow;
    this.history = history;
    this.metadatasList = metadatasList;
    this.fullGrid = fullGrid;
    this.setCatchedError = setCatchedError;
    this.format = format;
  }

  delete = ({
    selectedItems,
    dataSource,
    refetch,
    addDataAction,
    miniGridData,
    pageOrigin
  }: {
    selectedItems: { id: string }[];
    dataSource: string;
    refetch: Function;
    addDataAction: Function;
    miniGridData: { id: string }[];
    pageOrigin: string | undefined;
  }) => {
    const pagePath = getPagePath();
    selectedItems.map((data: { id: string }) => {
      const mutation = deleteMutation(data.id, dataSource.slice(0, -1));
      this.apolloClient.mutate({ mutation }).then(() => {
        dispatcher.off('fieldsUpdated');
        dispatcher.off('navigate');
        dispatcher.off('saveData');
        window.onbeforeunload = null;
        if (this.formType.includes('overview')) {
          const defaultList =
            this.defaultDataSet[`${dataSource}.Standard`].data;
          const itemIndex = defaultList?.findIndex(
            (entry: { id: string }) => entry.id === data.id
          );
          defaultList.splice(itemIndex, 1);
          refetch().then((res: { data: any }) => {
            const data = res.data[dataSource];
            addDataAction({
              data,
              hasMore: data.length === 40
            });
            this.selectAll([]);
            this.setConfirmationWindow({ show: false });
          });
        } else {
          if (pageOrigin) {
            this.history.push(pageOrigin);
          } else if (miniGridData && miniGridData.length === 0) {
            this.history.push(`/${pagePath[0].replace('Detail', 'Overview')}`);
          } else {
            this.history.goBack();
          }
        }
      });
    });
  };

  create = async ({ defaultValues = {}, dataSource }: any) => {
    const openCreatePage = (form: string | undefined) => {
      const { state } = this.history?.location || {};
      form &&
        this.history.push(`/${form}-create`, {
          defaultState: defaultValues,
          dataSource: state?.dataSource,
          dataStorageId: state?.dataStorageId
        });
    };

    const pagePath = getPagePath();
    if (this.formType.includes('overview')) {
      if (
        _.find(this.metadatasList, [
          'fileName',
          `Frm.${this.fullGrid.selectForm}.json`
        ])
      ) {
        openCreatePage(this.fullGrid.selectForm);
      } else {
        try {
          const successMetadata = await updateMetadataList(
            `Frm.${this.fullGrid.selectForm}.json`,
            this.metadatasList
          );
          if (successMetadata) {
            openCreatePage(this.fullGrid.selectForm);
          }
        } catch (err) {
          this.setCatchedError(`${err.message}`);
        }
      }
    } else if (this.formType.includes('detail')) {
      if (_.find(this.metadatasList, ['fileName', `Frm.${pagePath[0]}.json`])) {
        const form = this.fullGrid?.selectForm || pagePath[0];
        openCreatePage(form);
      } else {
        try {
          const successMetadata = await updateMetadataList(
            `Frm.${pagePath[0]}.json`,
            this.metadatasList
          );
          if (successMetadata) {
            openCreatePage(pagePath[0]);
          }
        } catch (err) {
          this.setCatchedError(`${err.message}`);
        }
      }
    }
  };

  selectFullGridItem = ({
    itemIndex,
    setItemIndex,
    redirectToDetail,
    miniGridData
  }: {
    itemIndex: number;
    setItemIndex: Function;
    redirectToDetail: Function;
    miniGridData: { id: string }[];
  }) => {
    const pagePath = getPagePath();
    if (this.defaultDataSet[itemIndex]) {
      setItemIndex(itemIndex);
      redirectToDetail(
        pagePath[0],
        { id: this.defaultDataSet[itemIndex].id },
        this.history,
        // @ts-ignore
        { data: miniGridData }
      );
    }
  };

  onPrint({
    selectedData,
    filterExpression,
    selectedAll,
    setPrintData,
    user
  }: {
    selectedData: Array<string[]>;
    filterExpression: string;
    selectedAll: boolean;
    setPrintData: Function;
    user: IUser;
  }) {
    (async () => {
      const header = selectedData[0];
      const { caption, dataSource } = this.fullGrid;
      const query = genericQuery('cdmuser', ['uName'], `id:"${user.id}"`);
      const style = pdfStyle(header.length);
      const { data } = await this.apolloClient.query({ query });
      const { uName } = data.cdmuser;
      const printPDF = () => {
        print({
          printable: 'pdf-table',
          type: 'html',
          header: `<h3 class="pdf-header">${caption || ''}</h3>`,
          documentTitle:
            user &&
            `Printed ${uName ? `by ${uName}` : ''} at ${moment().format(
              'LT'
            )} `,
          style
        });
      };

      const dataForPDF = formatDataForPrinting(selectedData.slice(1), header);
      if (dataForPDF.length > 0 && !selectedAll) {
        printPDF();
      } else {
        const query = genericQuery(
          dataSource,
          this.format.fields.map((field) => field.attribute),
          filterExpression,
          '',
          0
        );
        this.apolloClient
          .query({ query, variables: { offset: 0 } })
          .then((res: any) => {
            let data = res.data[dataSource];
            data = flattenDataSet(data, this.format.fields);
            setPrintData(data);
            printPDF();
          });
      }
    })();
  }
  sendToExcel = async ({
    selectedItems,
    selectAllItems
  }: {
    selectedItems: Array<any>;
    selectAllItems: Function;
  }) => {
    let selectedData = flattenDataSet(selectedItems, this.format.fields);
    if (!selectedItems || selectedItems.length === 0) {
      selectedData = await selectAllItems();
    }
    const excelData = getSelectedExcelData(selectedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, excelData, 'SheetJS');
    XLSX.writeFile(wb, `${this.fullGrid.caption}-${this.format.name}.xlsx`);
    this.selectAll([]);
  };

  search = ({ setShowModal }: { setShowModal: (show: boolean) => void }) => {
    setShowModal(true);
  };

  capabilityDialUp = async ({
    dataSource,
    setSelectionModal,
    selectedItems,
    setAlertBarOptions
  }: any) => {
    const metadatasList = getLocalStorageItem('metadatasList');
    const metaFilename = `Dlg.${dataSource}.SelectPhonenumber.json`;
    let selectNumbersMeta = _.find(metadatasList, ['fileName', metaFilename]);
    if (!selectNumbersMeta) {
      const updatedMetaList = await updateMetadataList(
        metaFilename,
        metadatasList
      );
      selectNumbersMeta = _.find(updatedMetaList, ['fileName', metaFilename]);
    }
    const queryFields = await selectNumbersMeta.form.fields.map(
      (field: IFormField) => field.attribute
    );
    const query = genericQuery(
      selectNumbersMeta.form.dataSource.slice(0, -1),
      queryFields,
      `id:"${selectedItems[0].id}"`
    );
    const cdmsyst = genericQuery('cdmsyst', ['telephonyInterfaceAPI']);
    const cdmsystRes = await this.apolloClient.query({ query: cdmsyst });
    const { telephonyInterfaceAPI } = cdmsystRes.data.cdmsyst;
    const res = await this.apolloClient.query({ query });
    let data = res.data[dataSource.slice(0, -1)];
    Object.entries(data).map(([key, value]: any[]) => {
      if (value && typeof value === 'object') {
        data = { ...data, ...value };
      } else {
        data[key] = value;
      }
    });
    delete data.primContRelation;
    delete data.__typename;
    delete data.id;
    let options: any = [];
    let fields: IFormField[] = selectNumbersMeta.form.miniGrid.fields;
    selectNumbersMeta.form.fields.map(({ caption, attribute }: IFormField) => {
      if (caption) {
        const dataAttribute: string = attribute.includes('{')
          ? divideNestedAttribute(attribute)[1]
          : attribute;
        if (data[dataAttribute] && data[dataAttribute] !== '') {
          options.push({
            number: data[dataAttribute],
            description:
              dataAttribute === 'phone'
                ? caption
                : `${data?.firstN || ''} ${data?.lastN || ''} ${caption}`,
            attribute: dataAttribute
          });
        }
      }
    });

    const confirmationMeta = await getRenderedMeta(
      metadatasList,
      `Dlg.ConfirmCreateDialActi.json`
    );

    setSelectionModal({
      show: true,
      options,
      fields,
      requireConfirmationMeta: true,
      onSelectProperties: {
        telephonyInterfaceAPI,
        setAlertBarOptions,
        callingCode: data.primCounRelation.callingCode
      },
      confirmationMeta: confirmationMeta.data?.form,
      defaultHandler: 'chooseDialUpPhoneNumber',
      onConfirmHandler: () =>
        createCalendarActivity({ history: this.history, dataSource: 'cdmacti' })
    });
  };
}
