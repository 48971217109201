import { combineReducers } from 'redux';

import authorization from './authorization';
import calendar from './calendar';
import dataManagement from './dataManagement';
import utils from './utils';
import chartDashboard from './chartDashboard';
import fileManager from './fileManager';
import sqlQueryBuilder from './sqlQueryBuilder';
import translations from './translations';
import metadata from './metadata';

export default combineReducers({
  authorization,
  calendar,
  dataManagement,
  utils,
  chartDashboard,
  fileManager,
  sqlQueryBuilder,
  translations,
  metadata
});
