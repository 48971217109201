import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

import { Table } from '../../components';


const SelectionModalWithActions = ({ show, setModal, selectionMeta, handlers }: any) => {
  const { fields, options, group } = selectionMeta;
  const history = useHistory();

  const [selectItem, setSelectItem] = useState(null);

  const handleClick = (handler: string, action: any) => {
    if (handlers[handler]) {
        handlers[handler]?.(selectItem, action);
      }
  };

  return (
    <Modal
      show={show}
      size={'lg'}
      className='editing-modal'
      onHide={() => setModal(false)}
    >
      <Modal.Header closeButton onClick={() => setModal(false)}></Modal.Header>
      <Modal.Body style={{ overflow: 'hidden' }} className='editing-modal-body'>
        <div className={'listModal_table '}>
          <Table
            metadata={{}}
            scrollAction={() => {}}
            tableFormat={{
              fields
            }}
            itemClick={(option: any) => {
              setSelectItem(option);
            }}
            heightStyles={{
              maxHeight: '100%',
              minHeight: '10vh'
            }}
            data={options}
            hasMore={false}
            highlightOnClick={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {group
          ? group[0].entries.map(
              (button: {
                variant: string;
                handler: string;
                action: string;
                caption: string;
              }) => (
                <Button
                  variant={button.variant || 'secondary'}
                  onClick={() => handleClick(button.handler, button.action)}
                >
                  {button.caption}
                </Button>
              )
            )
          : null}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectionModalWithActions;
