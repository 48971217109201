import React from 'react';
import { connect } from 'react-redux';

const IconWrapper = ({ iconName, iconSize, theme }: any) => {
  const size = iconSize ? `${iconSize}px` : '16px';
  try {
    let pngIcon = require(`../../assets/icons/${iconName}.png`);
    if (pngIcon) {
      if (theme === 'dark') {
        try {
          pngIcon = require(`../../assets/icons/${iconName}_dark.png`);
        } catch {
          pngIcon = pngIcon;
        }
      }

      return (
        <>
          <img
            alt='icon'
            className='icon_png'
            src={pngIcon}
            height={size}
            width={size}
          />
        </>
      );
    }
    return <></>;
  } catch {
    return (
      <>
        <i className={`icon-${iconName}`} style={{ fontSize: size }}>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </i>
      </>
    );
  }
};

const mapStateToProps = (state: any) => ({
  theme: state.utils.theme
});

export default connect<any, any, any>(mapStateToProps)(IconWrapper);
