import React, { useState, useEffect, FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons';
import InfiniteScroll from 'react-infinite-scroller';
import TableRow from './TableRow';
import { IFormField, TFieldsValues } from '../../types';
import { alignTypedFields } from '../../utils';
import { getResponsiveCellWidth } from './functions';

const Table: FC<any> = (props: any) => {
  const [orderRule, setOrderRule] = useState<{ [k: string]: any }>({});

  const {
    metadata,
    scrollAction,
    unselectItem,
    selectItem,
    filterExpression,
    selectedAll,
    setFilterExpression,
    screenSize,
    heightStyles,
    data,
    selectItems,
    tableFormat,
    hasMore,
    itemClick,
    setNotesValue,
    highlightOnClick,
    fullGridFormat
  } = props;
  const { fields, formatName } = tableFormat;
  const { dataStorageId } = fullGridFormat || { dataStorageId: '' };
  const setSorting = (field: TFieldsValues) => {
    const { attribute } = field;
    const sortFilters = filterExpression.includes('orderBy')
      ? filterExpression
          .split(',')
          .map((key: string) => {
            const orderRule = key
              .slice(0, key.indexOf(':'))
              .replaceAll(' ', '');
            if (orderRule === 'orderBy') {
              key = `orderByDesc:${attribute}`;
              setOrderRule({ [attribute]: 'orderByDesc' });
            }
            if (orderRule === 'orderByDesc') {
              key = `orderBy:${attribute}`;
              setOrderRule({ [attribute]: 'orderBy' });
            }
            return key;
          })
          .join(',')
      : filterExpression.concat(`,orderBy:${attribute}`);
    setFilterExpression(sortFilters);
  };
  useEffect(() => {}, [data]);
  return (
    <React.Fragment>
      <table
        id='table-fullGrid'
        className='table fullGrid '
        style={heightStyles}
      >
        <thead className='fullGrid-thead'>
          <tr>
            {(metadata.listFormat || metadata.selection) && (
              <div className={'fullGrid_row-selector'}>
                <th>
                  <input
                    type='checkbox'
                    id='header-input'
                    className='check-box header-check-box fullGrid-checkbox'
                    onChange={selectItems}
                    defaultChecked={false}
                  />
                  <label htmlFor='header-input'></label>
                </th>
              </div>
            )}
            {fields &&
              fields.map((col: IFormField, index: number) => (
                <div
                  style={{
                    width: getResponsiveCellWidth(col?.width),
                    textAlign: col.type ? alignTypedFields(col.type) : 'left'
                  }}
                  className={`table-cell`}
                >
                  <th
                    style={{
                      width: getResponsiveCellWidth(col?.width),
                      cursor:
                        col.sortable !== false &&
                        !col.attribute.includes('Relation')
                          ? 'pointer'
                          : ''
                    }}
                    key={index}
                    onClick={
                      col.sortable !== false &&
                      !col.attribute.includes('Relation')
                        ? () => setSorting(col)
                        : () => null
                    }
                  >
                    {col.caption}
                    {col.sortable !== false &&
                      !col.attribute.includes('Relation') && (
                        <span>
                          {orderRule[col.attribute] &&
                            (orderRule[col.attribute] === 'orderBy' ? (
                              <ArrowDown className='sort-icons' />
                            ) : (
                              <ArrowUp className='sort-icons' />
                            ))}
                        </span>
                      )}
                  </th>
                </div>
              ))}
          </tr>
        </thead>
        <InfiniteScroll
          pageStart={0}
          useWindow={false}
          loadMore={() => {
            const gridFormat = `${formatName}${
              window.location.pathname.includes('Overview')
                ? window.location.pathname
                : ''
            }`;
            data.length > 0 && scrollAction && scrollAction(gridFormat);
          }}
          hasMore={hasMore === undefined ? false : hasMore}
        >
          <tbody className='fullGrid-tbody fullGrid-striped'>
            {data &&
              data.length > 0 &&
              data.map((el: { id: string }, i: number) => (
                <TableRow
                  el={el}
                  metadata={metadata}
                  fields={fields}
                  unselectItem={unselectItem}
                  selectItem={selectItem}
                  selectedAll={selectedAll}
                  key={`${el.id}-${i}`}
                  itemPosition={i}
                  dataStorageId={dataStorageId}
                  filterExpression={filterExpression}
                  screenSize={screenSize}
                  itemClick={itemClick}
                  setNotesValue={setNotesValue}
                  highlightOnClick={highlightOnClick}
                />
              ))}
          </tbody>
        </InfiniteScroll>
      </table>
    </React.Fragment>
  );
};

export default withRouter<any & RouteComponentProps<{}>, any>(Table as any);
