import { tokenPromise, clearCache } from '../utils';
import { CapabilityArgs, ResponseRest, RestHeaders } from '../types';
import _ from 'lodash';

type CallbackHandler = (err: any, res?: ResponseRest) => void;

/**
 * Describe API
 * @class API
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export default class API {
  private domain: string = '';

  constructor(domain?: string) {
    if (domain) {
      this.domain = domain;
    }
  }

  private serializeQueryParams(parameters: any) {
    let str = [];
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(
          encodeURIComponent(p) + '=' + encodeURIComponent(parameters[p])
        );
      }
    }
    return str.join('&');
  }

  private request(
    method: string,
    url: string,
    body: any,
    headers: any,
    queryParameters: any,
    form: any,
    reject: CallbackHandler,
    resolve: CallbackHandler,
    returnBlob: boolean = false,
    checkToken: boolean = true
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (body && !Object.keys(body).length) {
      body = undefined;
    }

    if (body) {
      body = JSON.stringify(body);
    }

    if (form instanceof FormData && method !== 'GET') {
      body = form;
    }

    tokenPromise('rest', checkToken)
      .then(() => {
        if (checkToken) {
          headers = {
            ...headers,
            ...{
              authorization: `Bearer ${localStorage.getItem('token')}`,
              typeauthorization: localStorage.getItem('authType')
            }
          };
        }
        fetch(urlWithParams, {
          method,
          headers,
          body: body
        })
          .then(async (response) => {
            let body;
            if (!response.ok) {
              body = await response.json();
              if (body.code === 'Unauthorized') {
                clearCache();
              }
              return reject(body?.message);
            }
            if (returnBlob) {
              const bodyBlob = await response.blob();
              resolve(bodyBlob);
            } else {
              body = await response.json();
              resolve(body);
            }
          })
      })
      .catch((error) => {
        reject(error);
      });
  }

  /**
   *
   * @method
   * @name API#postRestCapability
   * @param {} headers - an authorization headers
   * @param {} capabilityInfo - Information for transport endpoint.
   */
  postRestCapability(parameters: {
    headers: RestHeaders;
    capabilityInfo?: {
      run?: {
        method?: string;
        step?: number;
      };
      args?: CapabilityArgs;
      ids?: Array<string> | string;
    };
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/capability';
    let body: any;
    let queryParameters: any = {};
    let headers: any = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      headers = { ...headers, ...{ 'Content-Type': 'application/json' } };
      if (parameters['capabilityInfo'] !== undefined) {
        body = parameters['capabilityInfo'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      form = queryParameters;
      queryParameters = {};

      this.request(
        'POST',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Get metadata from Metadata storage
   * @method
   * @name API#getRestMetadataStorageGetMetadata
   * @param {} headers - an authorization headers
   * @param {} metadataNameList - Metadata file name list
   */
  getRestMetadataStorageGetMetadata(parameters: {
    headers: RestHeaders;
    metadataNameList?: string;
    $queryParameters?: any;
    $domain?: string;
    checkToken?: boolean;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/getMetadata';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    let checkToken: boolean | undefined = parameters['checkToken'];
    return new Promise((resolve, reject) => {
      if (parameters['metadataNameList'] !== undefined) {
        queryParameters['metadataNameList'] = parameters['metadataNameList'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve,
        false,
        checkToken
      );
    });
  }

  /**
   * Get format list for certain table
   * @method
   * @name API#getRestMetadataStorageGetTableFormatListByTableName
   * @param {} headers - an authorization headers
   * @param {} tableName - Table name
   * @param {} formatList - Format list, if not defined then everything for this table
   */
  getRestMetadataStorageGetTableFormatListByTableName(parameters: {
    headers: RestHeaders;
    tableName: string;
    formatList?: string;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/getTableFormatList/{tableName}';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      path = path.replace('{tableName}', `${parameters['tableName']}`);

      if (parameters['tableName'] === undefined) {
        reject(new Error('Missing required  parameter: tableName'));
        return;
      }

      if (parameters['formatList'] !== undefined) {
        queryParameters['formatList'] = parameters['formatList'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Update table format list in Metadata Storage
   * @method
   * @name API#postRestMetadataStorageUpdateTableFormatList
   * @param {file} tableFormatListFile - The file with format list to upload
   * @param {file} tableFormatFile - The file with format to upload
   * @param {} headers - an authorization headers
   */
  postRestMetadataStorageUpdateTableFormatList(parameters: {
    files: any;
    headers: RestHeaders;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/updateTableFormatList';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['files'] !== undefined) {
        form = parameters['files'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        'POST',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Get metadata file list
   * @method
   * @name API#getRestMetadataStorageGetFileList
   * @param {} headers - an authorization headers
   */
  getRestMetadataStorageGetFileList(parameters: {
    headers: RestHeaders;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/getFileList';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Get metadata file for editing
   * @method
   * @name API#getRestMetadataStorageGetMetadataForEditing
   * @param {} headers - an authorization headers
   * @param {} metadataNameList - Metadata file name list
   */
  getRestMetadataStorageGetMetadataForEditing(parameters: {
    headers: RestHeaders;
    metadataNameList?: string;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/getMetadataForEditing';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['metadataNameList'] !== undefined) {
        queryParameters['metadataNameList'] = parameters['metadataNameList'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Edit metadata file in Azure Blob Storage
   * @method
   * @name API#postRestMetadataStorageEditMetadata
   * @param {} headers - an authorization headers
   * @param {file} file - The file to upload
   */
  postRestMetadataStorageEditMetadata(parameters: {
    headers: RestHeaders;
    file?: {};
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/editMetadata';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['file'] !== undefined) {
        form = parameters['file'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        'POST',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Upload file from metadata storage
   * @method
   * @name API#postRestMatedataStorageUploadFile
   * @param {file} file - The file to upload
   * @param {} headers - an authorization headers
   */
  postRestMetadataStorageUploadFile(parameters: {
    file?: {};
    headers: RestHeaders;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/uploadFile';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['file'] !== undefined) {
        form = parameters['file'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        'POST',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Download file from Metadata Storage
   * @method
   * @name API#getRestMetadataStorageDownloadFile
   * @param {} headers - an authorization headers
   * @param {} fileNameList - file name list
   */
  getRestMetadataStorageDownloadFile(parameters: {
    headers: RestHeaders;
    fileNameList: string;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<Blob> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/metadataStorage/downloadFile';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['fileNameList'] !== undefined) {
        queryParameters['fileNameList'] = parameters['fileNameList'];
      }

      if (parameters['fileNameList'] === undefined) {
        reject(new Error('Missing required  parameter: fileNameList'));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve,
        true
      );
    });
  }

  /**
   * Dropbox authorization flow
   * @method
   * @name API#getRestFileStorageDropboxAuth
   * @param {} code - Dropbox auth code that we can trade for tokens
   * @param {} headers - an authorization headers
   */
  getRestFileStorageDropboxAuth(parameters: {
    code?: string;
    headers: RestHeaders;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/fileStorage/dropboxAuth';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['code'] !== undefined) {
        queryParameters['code'] = parameters['code'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Upload file from storage
   * @method
   * @name API#postRestFileStorageUploadFile
   * @param {file} file - The file to upload
   * @param {} headers - an authorization headers
   */
  postRestFileStorageUploadFile(parameters: {
    file?: {};
    headers: RestHeaders;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/fileStorage/uploadFile';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['file'] !== undefined) {
        form = parameters['file'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      queryParameters = {};

      this.request(
        'POST',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Download file from storage
   * @method
   * @name API#getRestFileStorageDownloadFile
   * @param {} headers - an authorization headers
   * @param {} fileNameList - file name list
   */
  getRestFileStorageDownloadFile(parameters: {
    headers: RestHeaders;
    fileNameList: string;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<Blob> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/fileStorage/downloadFile';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['fileNameList'] !== undefined) {
        queryParameters['fileNameList'] = parameters['fileNameList'];
      }

      if (parameters['fileNameList'] === undefined) {
        reject(new Error('Missing required  parameter: fileNameList'));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve,
        true
      );
    });
  }

  /**
   * Get user documents file list
   * @method
   * @name API#getRestFileStorageGetFileList
   * @param {} headers - an authorization headers
   */
  getRestFileStorageGetFileList(parameters: {
    headers: RestHeaders;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/fileStorage/getFileList';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Delete file from storage
   * @method
   * @name API#postRestFileStorageDeleteFile
   * @param {} headers - an authorization headers
   * @param {} fileNameList - file name list
   */
  postRestFileStorageDeleteFile(parameters: {
    headers: RestHeaders;
    fileNameList: string;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/fileStorage/deleteFile';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      if (parameters['fileNameList'] !== undefined) {
        queryParameters['fileNameList'] = parameters['fileNameList'];
      }

      if (parameters['fileNameList'] === undefined) {
        reject(new Error('Missing required  parameter: fileNameList'));
        return;
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'POST',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }

  /**
   * Return bank account detail
   * @method
   * @name API#getRestBankAccountBalanceDevelopmentChartDataById
   * @param {} headers - an authorization headers
   * @param {} id - Id
   * @param {} startDate - Start date, if not defined current date - 6 month
   * @param {} endDate - End date, if not defined current date
   */
  getRestBankAccountBalanceDevelopmentChartDataById(parameters: {
    headers: RestHeaders;
    id: string;
    startDate?: string;
    endDate?: string;
    $queryParameters?: any;
    $domain?: string;
  }): Promise<ResponseRest> {
    const domain = parameters.$domain ? parameters.$domain : this.domain;
    let path = '/rest/BankAccount.BalanceDevelopmentChartData/{id}';
    let body: any;
    let queryParameters: any = {};
    let headers: RestHeaders = parameters['headers'];
    let form: any = {};
    return new Promise((resolve, reject) => {
      path = path.replace('{id}', `${parameters['id']}`);

      if (parameters['id'] === undefined) {
        reject(new Error('Missing required  parameter: id'));
        return;
      }

      if (parameters['startDate'] !== undefined) {
        queryParameters['startDate'] = parameters['startDate'];
      }

      if (parameters['endDate'] !== undefined) {
        queryParameters['endDate'] = parameters['endDate'];
      }

      if (parameters.$queryParameters) {
        Object.keys(parameters.$queryParameters).forEach(function (
          parameterName
        ) {
          queryParameters[parameterName] =
            parameters.$queryParameters[parameterName];
        });
      }

      this.request(
        'GET',
        domain + path,
        body,
        headers,
        queryParameters,
        form,
        reject,
        resolve
      );
    });
  }
}
