import React, { FC, useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';
import { IListModalWrapper } from '../../../types';
import { divideNestedAttribute, updateMetadataList } from '../../../utils';
import { ListModal } from '../..';

const ListModalWrapper: FC<IListModalWrapper> = ({
  listModal,
  setListModal,
  selectItem,
  defaultData,
  setRenderedData
}) => {
  const [metadatasList] = useLocalStorage('metadatasList');
  const [listFields, setListFields] = useState();
  const { dataSource, format, attribute, filterExpression } =
    listModal.field || {};

  const getFormatMeta = async (dataSource: string, format: string) => {
    //@ts-ignore
    let formatMeta = metadatasList.find(
      (meta: { fileName: string }) =>
        meta.fileName === `lst.${dataSource}.${format}.json`
    );
    if (!formatMeta) {
      try {
        const updatedList = await updateMetadataList(
          `lst.${dataSource}.${format}.json`,
          metadatasList
        );
        formatMeta = updatedList?.find(
          (meta: { fileName: string }) =>
            meta.fileName === `lst.${dataSource}.${format}.json`
        );
      } catch (err: any) {
        console.log(`${err.message}`);
      }
    }
    return formatMeta;
  };
  const getFilterExpression = (): string => {
    let filter = '';
    if (filterExpression) {
      const [expression, attribute] = filterExpression.split(':');
      const filteredId =
        defaultData[attribute] ||
        defaultData[`${attribute}Relation`]?.id ||
        undefined;
      if (filteredId) {
        filter = `${expression}:"${filteredId}", `;
      }
    }
    const [mainAttribute, subAttributes] = divideNestedAttribute(attribute);
    filter += `orderBy:${subAttributes.split(' ')[0]}, `;
    return filter;
  };
  const onDiscard = () => {
    const [mainAttribute] = divideNestedAttribute(attribute);
    const relationData = defaultData[mainAttribute];
    relationData && delete relationData.relationSearchValue;
    setRenderedData({ ...defaultData, [mainAttribute]: relationData });
    setListModal({ show: false, field: null, defaultSearch: '' });
  };
  useEffect(() => {
    getFilterExpression();
    (async () => {
      const { fields } = await getFormatMeta(dataSource || '', format || '');
      setListFields(fields);
    })();
  }, []);
  return (
    <>
      {listModal.show && listFields && (
        <ListModal
          filterExpression={() => getFilterExpression()}
          form={''}
          listMeta={{
            ...listModal.field,
            fields: listFields,
            defaultData,
            defaultSearch: listModal.defaultSearch
          }}
          defaultHandler={selectItem}
          setFilterExpression={() => {}}
          showModal={listModal.show}
          setShowModal={setListModal}
          onDiscard={onDiscard}
        />
      )}
    </>
  );
};

export default ListModalWrapper;
