import React, { useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { useApolloClient } from 'react-apollo';
import classnames from 'classnames';
import { RegularView } from '.';
import { editQuery } from '../../../GraphQL';
import { components } from '../../../pages/DetailsPage/components';

const DetailedView = ({
  itemId,
  layoutMeta,
  fieldsValues,
  renderedData,
  setRenderedData,
  fieldClickHandler,
  setDefaultNotesValue
}: any) => {
  const [customActiveTab, setCustomActiveTab] = useState('0');
  const apolloClient = useApolloClient();
  const tabs = layoutMeta.tab;

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  useEffect(() => {
    if (tabs) {
      setCustomActiveTab('0');
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (fieldsValues.id) {
        let mainDataSource = '';
        let mainFields: any = [];
        let componentType = '';
        tabs[customActiveTab].mainGroup[0].group.map((component: any) => {
          componentType = component.component;
          if (component.component === 'DetailsComponent') {
            const { fields, dataSource } = component.dataEntry;
            mainDataSource = dataSource;
            mainFields.push(fields);
          }
        });
        if (componentType === 'DetailsComponent') {
          mainFields = mainFields.flat();
          const query = editQuery(mainDataSource, mainFields, itemId);
          const res = await apolloClient.query({
            query,
            variables: {
              offset: 0
            },
            fetchPolicy: 'no-cache'
          });
          await setRenderedData({
            ...renderedData,
            ...res.data[mainDataSource]
          });
        }
      }
    })();
  }, [customActiveTab]);

  return (
    <div className='editPage-container'>
      <Nav tabs className='nav-tabs-custom'>
        {tabs.map((tab: any, index: number) => {
          return (
            <NavItem key={`${tab.caption}-${index}`}>
              <NavLink
                className={classnames({
                  active: customActiveTab === `${index}`
                })}
                onClick={() => {
                  toggleCustom(`${index}`);
                }}
              >
                <span className='d-none d-sm-block'>{tab.caption}</span>
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={customActiveTab}>
        {tabs.map((tab: any, index: number) => {
          if (tab.mainGroup) {
            return (
              <TabPane
                key={`tabpane-${tab.caption}-${index}`}
                tabId={`${index}`}
                className='p-lg-3'
              >
                <Row sm='12' className='tabpane-content'>
                  {tab.mainGroup && index.toString() === customActiveTab ? (
                    <>
                      {(() => {
                        const componentsTab = tab.mainGroup[0].group.map(
                          (element: any, index: number) => {
                            return (
                              <Col
                                sm={
                                  element.component === 'DetailsMiniGrid'
                                    ? 12
                                    : element.defCol
                                    ? element.defCol
                                    : 6
                                }
                              >
                                <>
                                  {element.component !== 'DetailsMiniGrid' ? (
                                    <>
                                      <RegularView
                                        layoutMeta={layoutMeta}
                                        fieldsValues={fieldsValues}
                                        renderedData={renderedData}
                                        setRenderedData={setRenderedData}
                                        fieldClickHandler={fieldClickHandler}
                                        setDefaultNotesValue={
                                          setDefaultNotesValue
                                        }
                                      />
                                    </>
                                  ) : (
                                    <components.DetailsMiniGrid
                                      itemId={itemId}
                                      layoutMeta={element}
                                    />
                                  )}
                                </>
                              </Col>
                            );
                          }
                        );
                        return componentsTab;
                      })()}
                    </>
                  ) : null}
                </Row>
              </TabPane>
            );
          }
        })}
      </TabContent>
    </div>
  );
};

export default DetailedView;
