import React, { useState, useEffect } from 'react';
import MsgReader from '@kenjiuno/msgreader';
import { Row } from 'react-bootstrap';
import moment from 'moment';

const MailPreview = ({ blob }: { blob: Blob }) => {
  const [emailData, setEmailData] = useState<any>({
    heading: [],
    body: ''
  });
  useEffect(() => {
    blob.arrayBuffer().then((buffer) => {
      const testMsg = new MsgReader(buffer);
      const { headers, body, senderName, subject, recipients, senderEmail } =
        testMsg.getFileData();
      const headersArr: string[] = headers?.split('\r\n') || [''];
      const date = headersArr
        .map((row: string) => {
          let heading = row.substring(0, row.indexOf(':') + 1);
          return heading === 'Date:' ? row.replace(heading, '') : '';
        })
        .filter((row: string) => row !== '')
        .join('\r\n');
      const heading = [
        {
          caption: 'From',
          value: `${senderName} < ${senderEmail} >`
        },
        {
          caption: 'Date',
          value: moment(date).format('LLLL')
        },
        {
          caption: 'To',
          value: `${recipients?.map(({ recipType, smtpAddress, email, name }) =>
            recipType === 'to' ? smtpAddress || email || name : ''
          )}`
        },
        {
          caption: 'Subject',
          value: subject || ''
        }
      ];
      setEmailData({
        ...emailData,
        heading,
        body: body?.replaceAll('\r\n', '<br/>') || ''
      });
    });
  }, []);

  return (
    <div className='mail-preview_wrapper'>
      <div className='mail-heading'>
        {emailData?.heading.map(
          ({ caption, value }: { caption: string; value: string }) => {
            return (
              <Row>
                <div>
                  <strong>{caption}:</strong>
                </div>
                <div>{value}</div>
              </Row>
            );
          }
        )}
      </div>
      <div
        className='mail-body'
        dangerouslySetInnerHTML={{ __html: emailData.body }}
      ></div>
    </div>
  );
};

export default MailPreview;
