import { default as ValueInput } from './ValueInput';
import { default as ValueSelect } from './ValueSelect';
import { default as ValueBetween } from './ValueBetween';
import { default as ValueMultipleSelect } from './ValueMultipleSelect';
import { default as ValueCreatableSelect } from './ValueCreatableSelect';
import { default as DTValueCreatableSelect } from './DTValueCreatableSelect';

export const components = {
    ValueInput,
    ValueSelect,
    ValueBetween,
    ValueMultipleSelect,
    ValueCreatableSelect,
    DTValueCreatableSelect,
};
