import { createSelector } from 'reselect';
import { getSelected, getData, getMetaData } from './utils';
import { flattenDataSet } from '../../utils';

export const getSelectedData = (state) => state.dataManagement.selected;

export const getSelectedDataFlat = createSelector(
  getSelected,
  getData,
  getMetaData,
  (selected, data, metadata) => {
    return flattenDataSet(selected, metadata.fields);
  },
);
