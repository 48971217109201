import React from 'react';

export default function ContactAdministrator() {
  return (
    <div className='contactAdministrator__wrapper'>
      <span>
        Something went wrong. Please contact your{' '}
        <a target='_blank' href='https://www.360businesstool.com/'>
          administrator
        </a>
      </span>
    </div>
  );
}
