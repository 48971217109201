import React, { useEffect, useState, FC, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { useHistory } from 'react-router';

import {
  getCapabilityParams,
  addCapabilityParams,
  unselectAll
} from '../../redux';
import { ICapabilityWrapper, AlertBarOptions } from '../../types';
import {
  updateCapabilityState,
  callMethodFromBackend
} from '../GenericToolbar/handlers';
import {
  DialogModal,
  AlertBar,
  SelectionModal,
  SelectionModalWithActions
} from '../';
import useLocalStorage from '@rehooks/local-storage';
import {redirectToDetail} from '../../utils';

const CapabilityWrapper: FC<ICapabilityWrapper> = (props) => {
  const history = useHistory();

  const { form, step, method, args, ids } = props.capabilityParams;
  const [showModal, setShowModal] = useState(false);
  const [selectionModal, setSelectionModal] = useState<any>({
    show: false
  });
  const [showSelectionModalWithActions, setShowSelectionModalWithActions] =
    useState(false);
  const [dialogMeta, setDialogMeta] = useState();
  const [metadatasList] = useLocalStorage('metadatasList');
  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });

  useEffect(() => {
    (async () => {
      if (form.form && !_.isEmpty(form)) {
        setDialogMeta(form.form);
        if (form.form.name === 'Table') {
          setSelectionModal({
            show: true,
            options: _.get(form.form, 'data', []),
            fields: _.get(form.form, 'fields', []),
            requireConfirmationMeta: false,
            onConfirmHandler: selectDataHandler
          });
        } else if (form.form.name === 'TableWithActions') {
          setSelectionModal({
            options: _.get(form.form, 'data', []),
            fields: _.get(form.form, 'fields', []),
            group: _.get(form.form, 'group', [])
          });
          setShowSelectionModalWithActions(true);
        } else {
          setShowModal(true);
        }
      }
    })();
  }, [form]);

  const removeState = () => {
    props.addCapabilityParams({
      form: {},
      step: 0,
      method: '',
      dataSource: '',
      ids: [],
      args: {},
      accessToken: null,
      action: '',
      cvrRegisterInfo: []
    });
  };

  const cancelHandler = () => {
    setShowModal(false);
    setShowSelectionModalWithActions(false);
    removeState();
    props.unselectAll();
  };

  const saveDataHandler = (data: any, action: any) => {
    updateCapabilityState({ args: data });
    setShowModal(false);
    callMethodFromBackend(setAlertBarOptions).then((res) => {
      if (res !== 'Error' && !_.get(res, 'data.form', null)) {
        removeState();
      }
      if (res && res.id) {
        redirectToDetail(res.selectForm, { id: res.id }, history);
      }
    });
  };

  const selectDataHandler = (data: any, action: any) => {
    updateCapabilityState({ isJoinArgs: true, args: data, action: action });
    callMethodFromBackend(setAlertBarOptions).then((res) => {
      if (res !== 'Error' && !_.get(res, 'data.form', null)) {
        cancelHandler();
      } else {
        setShowModal(false);
      }
      if (res && res.id) {
        redirectToDetail(res.selectForm, { id: res.id }, history);
      }
    });
  };

  const confirmDialog = () => {
    callMethodFromBackend(setAlertBarOptions).then((res) => {
      setShowModal(false);
      if (res !== 'Error' && !_.get(res, 'data.form', null)) {
        removeState();
      }
      if (res && res.id) {
        redirectToDetail(res.selectForm, { id: res.id }, history);
      }
    });
  };

  return (
    <Fragment>
      {alertBarOptions?.show && (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              variant: '',
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      )}
      {showModal && dialogMeta ? (
        <DialogModal
          showModal={showModal}
          setShowModal={setShowModal}
          meta={dialogMeta}
          onConfirm={saveDataHandler}
          handlers={{ confirm: confirmDialog, cancel: cancelHandler }}
        />
      ) : null}
      {selectionModal.show && dialogMeta ? (
        <SelectionModal
          selectionMeta={selectionModal}
          setModal={setSelectionModal}
        />
      ) : null}
      {showSelectionModalWithActions && dialogMeta ? (
        <SelectionModalWithActions
          show={showSelectionModalWithActions}
          selectionMeta={selectionModal}
          setModal={cancelHandler}
          handlers={{
            confirm: selectDataHandler,
            cancel: cancelHandler
          }}
        />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  capabilityParams: getCapabilityParams(state)
});
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addCapabilityParams,
      unselectAll
    },
    dispatch
  );
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(CapabilityWrapper);
