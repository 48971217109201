import { createSelector } from 'reselect';
import { getMetaData, getData, getSortingData } from './utils';

export const getDataFields = createSelector(getMetaData, (metadata) => {
  const dataFields = metadata.fields.map((e) => {
    return e.attribute;
  });
  return dataFields;
});

export const getDetails = (state) => state.dataManagement.details;

export const getGridData = (state) => state.dataManagement.data;

export const getSortedData = createSelector(
  getData,
  getSortingData,
  (data, sortingData) => {
    if (sortingData) {
      if (!sortingData.prop) {
        return data;
      }
      if (sortingData.by === 'asc') {
        const getListOfProp = data.map((el) => el[sortingData.prop]).sort();
        const mgonisorteddata = getListOfProp.map((el) =>
          data.find((one) => one[sortingData.prop] === el),
        );
        return mgonisorteddata;
      } else {
        const getListOfProp = data
          .map((el) => el[sortingData.prop])
          .sort()
          .reverse();
        const mgonisorteddata = getListOfProp.map((el) =>
          data.find((one) => one[sortingData.prop] === el),
        );
        return mgonisorteddata;
      }
    }
  },
);

export const getSortedMobMetaData = createSelector(getMetaData, (metadata) => {
  let top = metadata.fields.filter((e) => e.deckRow === 'top');
  let center = metadata.fields.filter((e) => e.deckRow === 'center');
  let bottom = metadata.fields.filter((e) => e.deckRow === 'bottom');
  return { top, center, bottom, selectForm: metadata.selectForm };
});

export const getCapabilityParams = (state) => state.dataManagement.capabilityParams;

export const getShowDropboxLogin = (state) => state.authorization.showDropboxLogin;
export const getDropboxMessage = (state) => state.authorization.dropboxMessage;