import React, { useState, useEffect } from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
} from 'reactstrap';
import classnames from 'classnames';

import {
    QueryCriteriaWidget,
    QueryEntitiesWidget,
    QueryFormatList,
    QueryFormatWidget,
} from './components';
import _ from 'lodash';

const QueryBuilder = () => {
    const [customActiveTab, setCustomActiveTab] = useState('advanced');
    const [isUpdateFormat, setIsUpdateFormat] = useState(false);
    const [isFormatVisible, setIsFormatVisible] = useState(false);
    const [formatActiveOperation, setFormatActiveOperation] = useState(null);

    useEffect(() => {
        if (customActiveTab === 'format-list') {
            setIsUpdateFormat(true);
        }
    }, [customActiveTab]);

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    return (
        <div className="content-wrapper">
            <div className="editPage-container">
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: customActiveTab === 'advanced',
                            })}
                            onClick={() => {
                                toggleCustom('advanced');
                            }}
                        >
                            Advanced
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: customActiveTab === 'entries',
                            })}
                            onClick={() => {
                                toggleCustom('entries');
                            }}
                        >
                            Entries
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: customActiveTab === 'format-list',
                            })}
                            onClick={() => {
                                toggleCustom('format-list');
                            }}
                        >
                            Format list
                        </NavLink>
                    </NavItem>
                    <NavItem
                        className={`${
                            !isFormatVisible ? 'nav-item-hidden' : ''
                        }`}
                    >
                        <NavLink
                            className={classnames({
                                active: customActiveTab === 'format-create',
                            })}
                            onClick={() => {
                                toggleCustom('format-create');
                            }}
                        >
                            {formatActiveOperation === 'create'
                                ? 'Create *'
                                : 'Update *'}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent
                    className="query-builder-tab-content"
                    activeTab={customActiveTab}
                >
                    <TabPane
                        className="p-3 query-builder-tab-pane"
                        tabId="advanced"
                    >
                        <QueryCriteriaWidget />
                    </TabPane>
                    <TabPane className="query-builder-tab-pane" tabId="entries">
                        <QueryEntitiesWidget />
                    </TabPane>
                    <TabPane
                        className="query-builder-tab-pane"
                        tabId="format-list"
                    >
                        <QueryFormatList
                            setIsFormatVisible={setIsFormatVisible}
                            setCustomActiveTab={setCustomActiveTab}
                            isUpdateFormat={isUpdateFormat}
                            setIsUpdateFormat={setIsUpdateFormat}
                            setFormatActiveOperation={setFormatActiveOperation}
                        />
                    </TabPane>
                    <TabPane
                        className="query-builder-tab-pane"
                        tabId="format-create"
                    >
                        <QueryFormatWidget
                            formatActiveOperation={formatActiveOperation}
                            setCustomActiveTab={setCustomActiveTab}
                            setIsFormatVisible={setIsFormatVisible}
                            setFormatActiveOperation={setFormatActiveOperation}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
};

export default QueryBuilder;
