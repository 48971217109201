import {
  updateFormatList,
  getLocalStorageItem,
  getRenderedMeta
} from '../../utils';
import { genericQuery } from '../../GraphQL';

export const getRadius = (distance, data) => {
  const { geolocationLat, geolocationLon } = data;
  const degreesToRadians = (value) => value * (Math.PI / 180);
  const radiansToDegrees = (value) => value * (180 / Math.PI);
  const radDist = distance / 6371.01;
  const radLat = degreesToRadians(geolocationLat);
  const radLon = degreesToRadians(geolocationLon);
  let leftLat = radLat - radDist;
  let rightLat = radLat + radDist;
  const minLat = degreesToRadians(-90);
  const maxLat = degreesToRadians(90);
  const minLon = degreesToRadians(-180);
  const maxLon = degreesToRadians(180);
  let topLon, bottomLon;
  if (leftLat > minLat && rightLat < maxLat) {
    const deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
    topLon = radLon - deltaLon;
    if (topLon < minLon) topLon += 2 * Math.PI;
    bottomLon = radLon + deltaLon;
    if (bottomLon > maxLon) bottomLon -= 2 * Math.PI;
  } else {
    leftLat = Math.max(leftLat, minLat);
    rightLat = Math.max(rightLat, maxLat);
    topLon = minLon;
    bottomLon = maxLon;
  }
  leftLat = radiansToDegrees(leftLat);
  rightLat = radiansToDegrees(rightLat);
  topLon = radiansToDegrees(topLon);
  bottomLon = radiansToDegrees(bottomLon);
  return {
    leftTop: { lat: leftLat, lon: topLon },
    rightBottom: { lat: rightLat, lon: bottomLon }
  };
};

const defaultGoogleMapsAPIKey = async (apolloClient) => {
  // const query = genericQuery('cdmsyst', ['']);
  // const { data } = await apolloClient.query({ query });
  return 'AIzaSyDlYCT82gb_295zozy5i2XSkBXv7Jc4Nyo';
};
const spplColorPaletteV2 = (colorId) => {
  const colors = [
    '0080FF',
    'ecb57d',
    '7ded90',
    '16rF7A35C',
    '16r8085E9',
    '16rF15C80',
    '16rE4D354',
    '16r2B908F',
    '16rF45B5B',
    '16r91E8E1'
  ];
  return colors[colorId];
};
export const viewMapTemplate = async (
  companyData,
  { apolloClient, setCatchedError }
) => {
  let max = 50;
  const metadatasList = getLocalStorageItem('metadatasList');
  const errorsMeta = await getRenderedMeta(
    metadatasList,
    `Error.ShowOnMapError.json`
  );
  const { cdmcomps } = companyData;
  if (cdmcomps.length > max) {
    const errorMessage = errorsMeta.fields.find(
      (error) => error.name === 'MaxOnMap'
    );
    setCatchedError(errorMessage.caption);
  }
  const templateFile = require('../../assets/templates/mapWithDataPoints.htm');
  let dataPoints = '';
  let skipped = 0;
  await Promise.all(
    companyData.cdmcomps.map(async (companyData, idx) => {
      const { geolocationLat, geolocationLon, customer, supplier, alias } =
        companyData;
      if (geolocationLat && geolocationLon) {
        let color;
        if (customer) {
          color = `#${spplColorPaletteV2(2)}`;
        } else {
          if (supplier) {
            color = `#${spplColorPaletteV2(0)}`;
          } else {
            color = `#${spplColorPaletteV2(1)}`;
          }
        }
        let address = await getMeetingAddress(companyData, apolloClient);
        address = address.replaceAll('\r\n', '</br>');
        dataPoints += `{label:"${++idx}",title:"${alias}",lat:${geolocationLat},lon:${geolocationLon},body:"${address}",color:"${color}"},`;
      } else {
        skipped++;
      }
    })
  );
  setTimeout(async () => {
    dataPoints = dataPoints.slice(0, -1);
    if (skipped > 0) {
      const errorMessage = errorsMeta.fields.find(
        (error) => error.name === 'CannotMap'
      );
      setCatchedError(skipped + ' ' + errorMessage.caption);
    }
    if (dataPoints === '') {
      const errorMessage = errorsMeta.fields.find(
        (error) => error.name === 'NothingToMap'
      );
      setCatchedError(errorMessage.caption);
    } else {
      let templateHtml = await fetch(templateFile);
      templateHtml = await templateHtml.text();
      templateHtml = templateHtml.replace(`'###dataPoints###'`, dataPoints);
      templateHtml = templateHtml.replace(
        '###googleMapsAPIKey###',
        await defaultGoogleMapsAPIKey(apolloClient)
      );

      const mapWindow = window.open('', 'Google Maps - 360 Business Tool');
      mapWindow.document.open();
      mapWindow.document.write(templateHtml);
      mapWindow.document.close();
    }
  }, 500);
};
export const findCompaniesHandler = async (
  inputData,
  { data, apolloClient, history, setCatchedError }
) => {
  const { radius, customers, suppliers, others, showOnMap } = inputData;
  const { leftTop, rightBottom } = getRadius(radius, data);
  let defaultFilterExpression = `geolocationLatGte:"${leftTop.lat}" geolocationLatLte:"${rightBottom.lat}" geolocationLonGte:"${leftTop.lon}" geolocationLonLte:"${rightBottom.lon}"`;
  if (!customers) defaultFilterExpression += ` customer:false`;
  if (!suppliers) defaultFilterExpression += ` supplier:false`;
  if (!others) {
    defaultFilterExpression.replace('customer:false', '');
    defaultFilterExpression += ` customer:true`;
    defaultFilterExpression.replace('supplier:false', '');
    defaultFilterExpression += ` supplier:true`;
  }
  const formatList = getLocalStorageItem('tableFormatList');
  const tableFormat = await updateFormatList(
    'cdmcomps',
    'cdmcomps.Address',
    formatList
  );
  const addressFormat = tableFormat.find(
    ({ formatName }) => formatName === 'cdmcomps.Address'
  );
  const fields = addressFormat.fields.map((field) => field.attribute);
  const query = genericQuery('cdmcomps', fields, defaultFilterExpression);
  const result = await apolloClient.query({ query });
  if (!showOnMap) {
    history.push({
      pathname: '/CompanyOverview',
      state: { defaultFormat: 'cdmcomps.Address', defaultFilterExpression }
    });
  } else {
    await viewMapTemplate(result.data, { apolloClient, setCatchedError });
  }
};

export const detailButtonOpenLinkedInSearch = (data) => {
  const { firstN, lastN, primCompRelation } = data;
  let searchKeywords = '';
  if (firstN) searchKeywords += `${firstN} `;
  if (lastN) searchKeywords += `${lastN} `;
  if (primCompRelation && primCompRelation.alias)
    searchKeywords += `${primCompRelation.alias} `;
  const link = `https://www.linkedin.com/search/results/people/?keywords=${searchKeywords}`;
  window.open(link, '_blank').focus();
};

export const getAddress = (data) => {
  const { addr1, addr2, primaryPostCodeRelation } = data;
  let meetingAddress = '';
  if (addr1) meetingAddress += `${addr1}+`;
  if (addr2) meetingAddress += `${addr2}+`;
  if (primaryPostCodeRelation && primaryPostCodeRelation.postCode)
    meetingAddress += `${primaryPostCodeRelation.postCode}+`;
  if (primaryPostCodeRelation && primaryPostCodeRelation.town)
    meetingAddress += `${primaryPostCodeRelation.town}+`;
  meetingAddress = meetingAddress.slice(0, -1);
  return meetingAddress;
};
export const getCompanyUs = async (apolloClient) => {
  const query = genericQuery('cdmsyst', [
    'primaryCompanyUsRelation{addr1 addr2 primaryPostCodeRelation{postCode town} primCounRelation{head}}'
  ]);
  const { data } = await apolloClient.query({ query });
  return data.cdmsyst.primaryCompanyUsRelation;
};

export const getMeetingAddressShort = async (apolloClient) => {
  const companyUsData = await getCompanyUs(apolloClient);
  return getAddress(companyUsData);
};

export const getMeetingAddress = async (data, apolloClient) => {
  const { primCounRelation } = await getCompanyUs(apolloClient);
  const countryUs = primCounRelation.head;
  const { name, name2, addr1, addr2, primaryPostCodeRelation, country } = data;
  let address = '';
  if (name) address += `${name}\r\n`;
  if (name2) address += `${name2}\r\n`;
  if (addr1) address += `${addr1}\r\n`;
  if (addr2) address += `${addr2}\r\n`;
  if (primaryPostCodeRelation) {
    const { postCode, town } = primaryPostCodeRelation;
    if (postCode) address += `${postCode} `;
    if (town) address += `${town}`;
    address += '\r\n';
  }
  if (country && countryUs !== country.head) {
    address += `${country.head}\r\n`;
  }
  return address;
};

export const createDefaultFields = (currentPage, filledData) => {
  if (currentPage === 'CompanyDetail') {
    const defaultData = {
      ...filledData,
      carDistance: 0,
      carTime: 0,
      creditMax: 0,
      cyklus: 0,
      employed: 0,
      funk: 0,
      inProcess: 0,
      ownerCarTime: 0,
      lost: 0,
      seekString: filledData?.alias?.toUpperCase() || '',
      primaryInvoiceDeliveryMethod: '675382----MASTERSYS',
      name: filledData.alias
    };
    return defaultData;
  } else {
    return filledData;
  }
};

export const getItemId = () => {
  const pageData = window.location.pathname
    .replaceAll('/', '')
    .replace('-', ' ')
    .split(' ');

  return pageData[0] === 'SystemSettings' ? '1' : pageData[1];
};
