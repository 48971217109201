import _ from 'lodash';

export const getDetailName = (data) => {
  if (data) {
    return (
      data.alias ||
      (data.firstN && data.lastN && `${data.firstN} ${data.lastN}`) ||
      data.head ||
      data.heading ||
      'Details'
    );
  } else {
    return 'Details';
  }
};

export const changeIdDeep = (obj, changeId) => {
  let newObj = _.cloneDeep(obj);
  let ind = 0;
  getProp(newObj, ind);

  function getProp(o) {
    for (let prop in o) {
      if (typeof o[prop] === 'object') {
        getProp(o[prop]);
      } else {
        if (
          prop === 'id' &&
          typeof o[prop] === 'string' &&
          o[prop].indexOf('-') === -1
        ) {
          o[prop] = `${o[prop]}-${(
            Date.now() + Math.random().toString(36).substr(2, 5)
          ).toUpperCase()}`;
        }
        if (
          changeId &&
          prop === 'id' &&
          typeof o[prop] === 'string' &&
          o['isNode']
        ) {
          let idValue = o[prop];
          const indId = idValue.indexOf('-');
          if (indId !== -1) {
            idValue = idValue.substring(0, indId);
          }
          o[prop] = `${idValue}-${(
            Date.now() + Math.random().toString(36).substr(2, 5)
          ).toUpperCase()}`;
          o['isNode'] = false;
        }
      }
      ind = ind + 1;
    }
  }

  return newObj;
};

export const changeIdFormatDeep = (obj, changeTitle) => {
  let newObj = _.cloneDeep(obj);
  getProp(newObj);

  function getProp(o) {
    for (let prop in o) {
      if (typeof o[prop] === 'object') {
        getProp(o[prop]);
      } else {
        if (
          prop === 'id' &&
          typeof o[prop] === 'string' &&
          o[prop].indexOf('-') === -1
        ) {
          o[prop] = `${o[prop]}-${(
            Date.now() + Math.random().toString(36).substr(2, 5)
          ).toUpperCase()}`;
        } else if (
          prop === 'id' &&
          typeof o[prop] === 'string' &&
          o[prop].indexOf('-') !== -1
        ) {
          if (changeTitle) {
            if (o[prop].indexOf('Relation') !== -1) {
              let currentTitle = o['id'].replace('.', '{');
              const indId = currentTitle.indexOf('-');
              if (indId !== -1) {
                currentTitle = currentTitle.substring(0, indId);
              }
              const openBracketsMatchLngt = currentTitle.match(/\{/g)
                ? currentTitle.match(/\{/g).length
                : 0;
              const closeBracketsMatchLngt = currentTitle.match(/\}/g)
                ? currentTitle.match(/\}/g).length
                : 0;
              const lngtAddedBrackets =
                openBracketsMatchLngt - closeBracketsMatchLngt;
              if (lngtAddedBrackets > 0) {
                let i = 0;
                while (i < lngtAddedBrackets) {
                  currentTitle = `${currentTitle}}`;
                  i++;
                }
              }
              o['title'] = currentTitle;
            }
          }
        }
      }
    }
  }

  return newObj;
};

export const changeDeepObj = (array, attributes) => {
  let newArr = _.cloneDeep(array);
  const { id } = attributes;

  newArr.some(function f(a) {
    if (a.id === id) {
      a.attributes = attributes;
      const nameField = a.id.split('-');
      a.title = `${attributes.notValue ? 'NOT' : ''} ${nameField[0]} ${
        attributes.operator
      } ${
        Array.isArray(attributes.value)
          ? attributes.value.join()
          : attributes.value
      }`;
      return true;
    }
    if (Array.isArray(a.children)) {
      return a.children.some(f);
    }
  });
  return newArr;
};
