import gql from 'graphql-tag';
import { mergeNestedAttributes } from '../utils';
import { IFormField } from '../types';

export const editQuery = (
  dataSource: string,
  fields: IFormField[],
  itemId: string
) => {
  let queryBody = `id`;
  const nestedAttributes: string[] = [];
  fields.map((field) => {
    const { attribute, group } = field;
    if (attribute) {
      if (!attribute.includes('Mth:')) {
        if (attribute.indexOf('{') > 0 && attribute.indexOf('(') < 1) {
          nestedAttributes.push(attribute.replace(/.$/, ' id}'));
        } else {
          queryBody += ` ${attribute}`;
        }
      }
    } else {
      if (group) {
        group.map((groupElement) => {
          let attribute = groupElement.attribute;
          if (attribute) {
            if (attribute.indexOf('{') > 0) {
              nestedAttributes.push(attribute);
            } else {
              queryBody += ` ${attribute}`;
            }
          }
        });
      }
    }
  });
  const nested = mergeNestedAttributes(nestedAttributes);
  if (nested !== '') {
    queryBody += ` ${nested}`;
  }
  fields.map((field) => {
    if (field.fields) {
      queryBody += ` ${field.fields.join(' ')}`;
    }
  });
  return gql` query ($offset:[Int]){
     ${dataSource}(limit: 40, offset: $offset, showDeleted:true, ${
    itemId ? `id: "${itemId}"` : ''
  }){
       ${queryBody}
     }
  }`;
};

export const genericQuery = (
  dataSource: string,
  queryParams: string[],
  passedParams: string | object = '',
  overwrittenFields = '',
  limit = 40
) => {
  let params = '';
  if (typeof passedParams === 'object') {
    Object.entries(passedParams).map((param, i) => {
      const passedParam = Object.values(passedParams)[i];
      if (param[0] !== 'undefined') {
        params += ` ${Object.keys(passedParams)[i]}:${
          param[0] === 'limit' ||
          param[0] === 'offset' ||
          param[0] === 'orderBy'
            ? passedParam
            : `"${passedParam}"`
        }`;
      }
    });
  }
  let queryBody: string = `id `;
  const nestedParams: string[] = [];
  queryParams.map((param: string | { [k: string]: any }) => {
    if (param) {
      if (typeof param === 'string') {
        if (param && !overwrittenFields.includes(param)) {
          if (param.includes('{')) {
            nestedParams.push(param);
          } else {
            queryBody += ` ${param}`;
          }
        }
      } else {
        const { attribute, group } = param;
        if (attribute) {
          if (!attribute.includes('Mth:')) {
            if (attribute.indexOf('{') > 0 && attribute.indexOf('(') < 1) {
              nestedParams.push(attribute.replace(/.$/, ' id}'));
            } else {
              queryBody += ` ${attribute}`;
            }
          }
        } else {
          if (group) {
            group.map((groupElement: { attribute: string }) => {
              let attribute = groupElement.attribute;
              if (attribute) {
                if (attribute.indexOf('{') > 0) {
                  nestedParams.push(attribute);
                } else {
                  queryBody += ` ${attribute}`;
                }
              }
            });
          }
        }
      }
    }
  });
  const nested = mergeNestedAttributes(nestedParams);
  if (nested !== '') {
    queryBody += nested;
  }

  if (dataSource === 'cdmdocuments' && typeof passedParams === 'string') {
    let passedParamArr = passedParams.split(' ').filter((item) => item !== '');
    const orderByParam = passedParamArr.filter((item) =>
      item.includes('orderBy')
    );
    passedParamArr = passedParamArr.filter((item) => !item.includes('orderBy'));
    return documentsInfoTable(passedParamArr, orderByParam, queryBody);
  }
  return gql`
    query($offset:[Int]) {
      ${dataSource}${
    typeof passedParams === 'object'
      ? `(${params} offset:$offset)`
      : `(${passedParams} ${limit > 0 ? `limit:${limit}` : ''}, offset:$offset)`
  } {
        ${queryBody} ${overwrittenFields}
      }
    }
  `;
};
export const auth_query = gql`
  query Autentification($email: String) {
    cdmuser(email: $email) {
      title
      id
      email360
      lastN
      firstN
      email
      fax
      primLangRelation {
        isoCode
      }
    }
  }
`;

export const subscriptionsDB_query = gql`
  query {
    getSubscriptionDB {
      systemId
      databaseName
    }
  }
`;

export const currentDB_query = gql`
  query {
    getCurrentDB {
      databaseName
    }
  }
`;

export const documentsInfoTable = (
  passedParams: string[],
  orderByParam: string[],
  fields: IFormField[] | string
) => {
  let whereConditions = [];
  let orderBy = '';
  for (let param of passedParams) {
    let paramArr = param.split(':');
    if (paramArr.length) {
      whereConditions.push({
        field: `primaryActivityRelation.${paramArr[0]}`,
        action: '=',
        value: paramArr[1].replace(/[\[\]"]+/g, '')
      });
    }
  }
  if (orderByParam.length) {
    orderBy = orderByParam[0];
  }

  const jsonObj = {
    relatedTableJoin: 'primaryActivityRelation',
    joinOperator: 'leftOuterJoin',
    whereConditions: whereConditions
  };
  const resultJsonObj = JSON.stringify(JSON.stringify(jsonObj, null, '\r\t'));
  return gql`
    query($offset:[Int]) {
      cdmdocuments(
      relation: ${resultJsonObj},
      ${orderBy}
      offset: $offset
      ) {
        id
        ${fields}
      }
    }
  `;
};

export const tableList_query = gql`
  query {
    getTableList {
      className
      tableName
      translatedTableName
    }
  }
`;

export const tableFieldList_query = gql`
  query TableFieldList($tableName: String!) {
    getTableFieldsList(tableName: $tableName) {
      tableName
      className
      translatedTableName
      attributesInfo {
        attributes {
          attributeName
          attributeType
          attributeTranslate
          attributeEnum
        }
        inheritedAttributes {
          attributeName
          attributeType
          attributeTranslate
          attributeEnum
        }
      }
      relations {
        relationName
        tableName
        className
        translatedTableName
        attributesInfo {
          attributes {
            attributeName
            attributeType
            attributeTranslate
            attributeEnum
          }
          inheritedAttributes {
            attributeName
            attributeType
            attributeTranslate
            attributeEnum
          }
        }
      }
      innerRelations {
        relationName
        tableName
        className
        translatedTableName
        attributesInfo {
          attributes {
            attributeName
            attributeType
            attributeTranslate
            attributeEnum
          }
          inheritedAttributes {
            attributeName
            attributeType
            attributeTranslate
            attributeEnum
          }
        }
      }
      notes {
        noteType
        noteKey
        noteTranslate
      }
    }
  }
`;

export const runSql_query = (
  tableFields: { name: string; fields: IFormField[] },
  infoQuery: { id: string; limit: number; offset: number }
) => {
  const attrTableFields = tableFields.fields.map(
    (item: { attribute: string }) => item.attribute
  );
  let fieldsString = '';
  let conditionsString = '';
  const { id, limit, offset } = infoQuery;
  fieldsString = `${fieldsString} ... on ${tableFields.name}_Type {${attrTableFields}}`;
  conditionsString = `id: "${id}", limit: ${limit},offset: ${offset}`;
  return gql`
  query {
    runSqlQuery(${conditionsString}) {
        ${fieldsString}
      }
    }`;
};

export const translationList_query = gql`
  query {
    getTranslationList {
      translation
      key
    }
  }
`;

export const getTextSql_query = gql`
  query getSqlQuery($id: String!) {
    getSqlQuery(id: $id) {
      textSqlQuery
    }
  }
`;
