import {
    ADD_DASHBOARD_ITEMS,
    ADD_DASHBOARD_TYPE,
    ADD_DASHBOARD_USERS,
} from '../types';

const initialState = {
    dashboardItems: [],
    dashboardUsers: [],
    dashboardType: null,
};

const addDashboardItems = (state, payload) => ({
    ...state,
    dashboardItems: payload,
});

const addDashboardUsers = (state, payload) => ({
    ...state,
    dashboardUsers: payload,
});

const addDashboardType = (state, payload) => ({
    ...state,
    dashboardType: payload,
});

const handlers = {
    [ADD_DASHBOARD_ITEMS]: addDashboardItems,
    [ADD_DASHBOARD_USERS]: addDashboardUsers,
    [ADD_DASHBOARD_TYPE]: addDashboardType,
};

export default (state = initialState, action) => {
    const handler = handlers[action.type];
    const newState = handler ? handler(state, action.payload) : state;
    return newState;
};
