import React, { Fragment, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useApolloClient } from 'react-apollo';
import { editMutation, updateDescription } from '../../GraphQL';
import { AlertBar } from '../../components';
import { flattenObject, checkValidation } from '../../utils';
import { RegularView, GenericWindowToolbar } from './Components';
import DetailedView from './Components/DetailedView';
import { useHistory } from 'react-router';

const GenericWindow = ({
  showModal,
  itemId,
  layoutMeta,
  onHide,
  fieldsValues
}) => {
  const [renderedData, setRenderedData] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [defaultNoteValue, setDefaultNotesValue] = useState();
  const [mainId, setMainId] = useState(itemId);
  const history = useHistory();
  const [defaultModalValues, setDefaultModalValues] = useState({
    fields: {},
    relation: ''
  });
  const [alertBarOptions, setAlertBarOptions] = useState({
    show: false,
    alertText: '',
    variant: ''
  });
  const toolbar = layoutMeta.toolbar ? layoutMeta.toolbar.entries : null;
  const apolloClient = useApolloClient();

  const onModalClose = (option) => {
    if (option) {
      setDefaultModalValues({
        fields: {},
        relation: ''
      });

      const editedRenderedData = {};
      const updatedRenderedData = renderedData;
      Object.entries(updatedRenderedData).map(([key, value]) => {
        if (key.includes(option.relation)) {
          value = option.value;
        }
        editedRenderedData[key] = value;
      });
      editedRenderedData[option.relation] = option.value.id;
      setRenderedData({ ...renderedData, ...editedRenderedData });
    }
  };

  const saveChanges = () => {
    const dataSource = layoutMeta.dataSource || layoutMeta.dataEntry.dataSource;
    let metaFields = [];
    if (layoutMeta.tab) {
      layoutMeta.tab.map((tab) => {
        tab.mainGroup[0].group.map((component, i) => {
          if (component.component === 'DetailsComponent') {
            const { fields } = component.dataEntry;
            metaFields.push(...fields);
          }
        });
      });
    } else {
      metaFields = layoutMeta.dataEntry.fields;
    }
    const { validationPassed } = checkValidation(metaFields, renderedData);
    if (validationPassed) {
      const updateMutation = editMutation(dataSource, renderedData, mainId);
      apolloClient
        .mutate({ mutation: updateMutation })
        .then((res) => {
          if (mainId === 'create') {
            const { id } = res.data[`create${dataSource}`];
            history.push(`${history.location.pathname.split('-')[0]}-${id}`);
          }
          if (renderedData.primaryCdmcommRelation) {
            const cdmcommAction =
              defaultNoteValue && itemId ? 'update' : 'create';
            let data = renderedData;
            data.primaryCdmcommRelation = renderedData.primaryCdmcommRelation;
            if (cdmcommAction === 'create') {
              data.primaryCdmcommRelation.id =
                res.data[`${cdmcommAction}${dataSource}`].id;
            }
            const descriptionMutation = updateDescription(
              renderedData,
              cdmcommAction
            );
            apolloClient
              .mutate({ mutation: descriptionMutation })
              .then(() => {
                onHide(true);
              })
              .catch((err) => {
                setAlertBarOptions({
                  show: true,
                  alertText: err.message,
                  variant: 'danger'
                });
              });
          }
        })
        .finally(() => {
          if (validationPassed) onHide(true);
        })
        .catch((err) => {
          setAlertBarOptions({
            show: true,
            alertText: err.message,
            variant: 'danger'
          });
        });
    }
  };

  const buttonClickHandler = (element) => {
    const handler = element.handler;
    const metaLookup = element.metaLookup;
  };

  const onSaveData = (handler = 'saveData') => {
    let dataChanged = mainId === 'create' ? true : false;
    if (mainId !== 'create') {
      const originalData = flattenObject(fieldsValues);
      const updatedData = flattenObject(renderedData);
      if (handler === 'saveData') {
        Object.entries(originalData).map(([key, value]) => {
          if (updatedData[key] !== value) {
            dataChanged = true;
          }
        });
      }
    }
    if (dataChanged) {
      setShowConfirmationModal(true);
    } else {
      onHide();
    }
  };

  useEffect(() => {
    if (fieldsValues && renderedData && itemId !== 'create') {
      setRenderedData({ ...renderedData, ...fieldsValues });
    }
    setMainId(itemId);
    return () => {
      setRenderedData({});
    };
  }, [fieldsValues, itemId]);

  return (
    <Modal
      size={layoutMeta.tab ? 'lg' : 'md'}
      show={showModal}
      className='editing-modal'
      onHide={onHide}
    >
      {alertBarOptions.show ? (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      ) : null}
      <Fragment>
        <Modal.Header className='modal-header-text' closeButton>
          {fieldsValues.alias ? `${fieldsValues.alias} — Details` : ''}
        </Modal.Header>
        <Modal.Body>
          {toolbar && (
            <GenericWindowToolbar
              toolbar={toolbar}
              onSaveData={onSaveData}
              buttonClickHandler={buttonClickHandler}
            />
          )}
          {layoutMeta.tab ? (
            <DetailedView
              onSaveData={onSaveData}
              showModal={showModal}
              itemId={mainId}
              layoutMeta={layoutMeta}
              fieldsValues={fieldsValues}
              renderedData={renderedData}
              setRenderedData={setRenderedData}
              showConfirmationModal={showConfirmationModal}
              setShowConfirmationModal={setShowConfirmationModal}
              saveChanges={saveChanges}
              onHide={onHide}
              setDefaultModalValues={setDefaultModalValues}
              defaultModalValues={defaultModalValues}
              buttonClickHandler={buttonClickHandler}
              showNestedModal={showNestedModal}
              setShowNestedModal={setShowNestedModal}
              setDefaultNotesValue={setDefaultNotesValue}
              onModalClose={onModalClose}
            />
          ) : (
            <RegularView
              onSaveData={onSaveData}
              showModal={showModal}
              itemId={mainId}
              layoutMeta={layoutMeta}
              fieldsValues={fieldsValues}
              renderedData={renderedData}
              setRenderedData={setRenderedData}
              showConfirmationModal={showConfirmationModal}
              setShowConfirmationModal={setShowConfirmationModal}
              saveChanges={saveChanges}
              onHide={onHide}
              setDefaultModalValues={setDefaultModalValues}
              defaultModalValues={defaultModalValues}
              buttonClickHandler={buttonClickHandler}
              showNestedModal={showNestedModal}
              setShowNestedModal={setShowNestedModal}
              setDefaultNotesValue={setDefaultNotesValue}
              onModalClose={onModalClose}
            />
          )}
        </Modal.Body>
      </Fragment>
    </Modal>
  );
};

export default GenericWindow;
