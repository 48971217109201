import Draft from 'draft-js';
import Immutable from 'immutable';
import { CustomComponent } from './components';
const blockRenderMap = Immutable.Map({
  'new-block-type-name': {
    element: CustomComponent
  }
});

export const extendedBlockRenderMap =
  Draft.DefaultDraftBlockRenderMap.merge(blockRenderMap);
export const getBlockStyle = (block: any) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'new-block-type-name':
      return {
        component: CustomComponent,
        editable: false
      };
    default:
      return null;
  }
};

export const convertHtmlToRtf = (html: string) => {
  if (!(typeof html === 'string' && html)) {
    return null;
  }
  let tmpRichText, hasHyperlinks;
  let richText = html;

  // Singleton tags
  richText = richText.replace(
    /<(?:hr)(?:\s+[^>]*)?\s*[\/]?>/gi,
    '{\\pard\\brdrb\\brdrs\\brdrw10 \brsp20\\par}{\\pard\\par} '
  );
  richText = richText.replace(
    /<(?:br)(?:\s+[^>]*)?\s*[\/]?>/gi,
    '{\\pard\\par} '
  );

  // Empty tags
  richText = richText.replace(
    /<(?:p|div|section|article)(?:\s+[^>]*)?\s*[\/]>/gi,
    '{\\pard\\par} '
  );
  richText = richText.replace(/<(?:[^>]+)\/>/g, '');

  // Hyperlinks
  richText = richText.replace(
    /<a(?:\s+[^>]*)?(?:\s+href=(["'])(?:javascript:void\(0?\);?|#|return false;?|void\(0?\);?|)\1)(?:\s+[^>]*)?>/gi,
    '{{{ '
  );
  tmpRichText = richText;
  richText = richText.replace(
    /<a(?:\s+[^>]*)?(?:\s+href=(["'])(.+)\1)(?:\s+[^>]*)?>/gi,
    '{\\field{\\*\\fldinst{HYPERLINK "$2"}}{\\fldrslt{\\ul\\cf1 '
  );
  hasHyperlinks = richText !== tmpRichText;
  richText = richText.replace(/<a(?:\s+[^>]*)?>/gi, '{{{ ');
  richText = richText.replace(/<\/a(?:\s+[^>]*)?>/gi, ' }}}');

  // Start tags
  richText = richText.replace(/<(?:b|strong)(?:\s+[^>]*)?>/gi, '\\b ');
  richText = richText.replace(/<(?:i|em)(?:\s+[^>]*)?>/gi, '\\i ');
  richText = richText.replace(/<(?:u|ins)(?:\s+[^>]*)?>/gi, '\\ul ');
  richText = richText.replace(/<(?:strike|del)(?:\s+[^>]*)?>/gi, '\\strike ');
  richText = richText.replace(/<sup(?:\s+[^>]*)?>/gi, '\\super ');
  richText = richText.replace(/<sub(?:\s+[^>]*)?>/gi, '\\sub ');
  richText = richText.replace(
    /<(?:p|div|section|article)(?:\s+[^>]*)?>/gi,
    '\\pard '
  );
  richText = richText.replace(
    /<\/(?:p|div|section|article)(?:\s+[^>]*)?>/gi,
    '\\par '
  );

  // End tags

  richText = richText.replace(/<\/(?:b|strong)(?:\s+[^>]*)?>/gi, '\\b0 ');
  richText = richText.replace(/<\/(?:i|em)(?:\s+[^>]*)?>/gi, '\\i0 ');
  richText = richText.replace(/<\/(?:u|ins)(?:\s+[^>]*)?>/gi, '\\ul0 ');
  richText = richText.replace(
    /<\/(?:strike|del)(?:\s+[^>]*)?>/gi,
    '\\strike0 '
  );
  richText = richText.replace(/<\/sup(?:\s+[^>]*)?>/gi, '\\super0 ');
  richText = richText.replace(/<\/sub(?:\s+[^>]*)?>/gi, '\\sub0 ');
  richText = richText.replace(
    /<(?:p|div|section|article)(?:\s+[^>]*)?>/gi,
    '\\par '
  );

  // Strip any other remaining HTML tags [but leave their contents]
  richText = richText.replace(/<(?:[^>]+)>/g, '');

  // Prefix and suffix the rich text with the necessary syntax
  richText =
    '{\\rtf1\\ansi ' +
    (hasHyperlinks ? '{\\colortbl;\\red0\\green0\\blue255;}' : '') +
    richText +
    '}';
  return richText.replaceAll(' \n', '\n');
};

function outputTemplate(doc: any, defaults: any, content: any) {
  return `${content.replace(/\n/, '\n    ')}`;
}

export const rtfToHTML = (doc: any, options: any) => {
  const defaults = Object.assign(
    {
      font: doc.style.font || { name: 'Times', family: 'roman' },
      fontSize: doc.style.fontSize || 24,
      bold: false,
      italic: false,
      underline: false,
      strikethrough: false,
      foreground: { red: 0, blue: 0, green: 0 },
      background: { red: 255, blue: 255, green: 255 },
      firstLineIndent: doc.style.firstLineIndent || 0,
      indent: 0,
      align: 'left',
      valign: 'normal',

      paraBreaks: '\n\n',
      paraTag: 'p',
      template: outputTemplate
    },
    options || {}
  );
  const content = doc.content
    .map((para: any) => renderPara(para, defaults))
    .filter((html: string | null) => html != null)
    .join(defaults.paraBreaks);
  return defaults.template(doc, defaults, content);
};

function font(ft: { name: string; family: string }) {
  const name = ft.name.replace(/-\w+$/, '');
  const family = genericFontMap[ft.family];
  if (name === 'ZapfDingbatsITC') return '';
  return 'font-family: ' + name + (family ? `, ${family}` : '');
}

const genericFontMap: { [k: string]: string } = {
  roman: 'serif',
  swiss: 'sans-serif',
  script: 'cursive',
  decor: 'fantasy',
  modern: 'sans-serif',
  tech: 'monospace',
  bidi: 'serif'
};

function colorEq(aa: { [k: string]: string }, bb: { [k: string]: string }) {
  return aa.red === bb.red && aa.blue === bb.blue && aa.green === bb.green;
}

function CSS(chunk: any, defaults: any) {
  let css = '';
  if (
    chunk.style.foreground != null &&
    !colorEq(chunk.style.foreground, defaults.foreground)
  ) {
    css += `color: rgb(${chunk.style.foreground.red}, ${chunk.style.foreground.green}, ${chunk.style.foreground.blue});`;
  }
  if (
    chunk.style.background != null &&
    !colorEq(chunk.style.background, defaults.background)
  ) {
    css += `background-color: rgb(${chunk.style.background.red}, ${chunk.style.background.green}, ${chunk.style.background.blue});`;
  }
  if (
    chunk.style.firstLineIndent != null &&
    chunk.style.firstLineIndent > 0 &&
    chunk.style.firstLineIndent !== defaults.firstLineIndent
  ) {
    css += `text-indent: ${chunk.style.firstLineIndent / 20}pt;`;
  }
  if (chunk.style.indent != null && chunk.style.indent !== defaults.indent) {
    css += `padding-left: ${chunk.style.indent / 20}pt;`;
  }
  if (chunk.style.align != null && chunk.style.align !== defaults.align) {
    css += `text-align: ${chunk.style.align};`;
  }
  if (
    chunk.style.fontSize != null &&
    chunk.style.fontSize !== defaults.fontSize
  ) {
    css += `font-size: ${chunk.style.fontSize / 2}pt;`;
  }
  if (
    !defaults.disableFonts &&
    chunk.style.font != null &&
    chunk.style.font.name !== defaults.font.name
  ) {
    css += font(chunk.style.font);
  }
  return css;
}

function styleTags(chunk: any, defaults: any) {
  let open = '';
  let close = '';
  if (chunk.style.italic != null && chunk.style.italic !== defaults.italic) {
    open += '<em>';
    close = '</em>' + close;
  }
  if (chunk.style.bold != null && chunk.style.bold !== defaults.bold) {
    open += '<strong>';
    close = '</strong>' + close;
  }
  if (
    chunk.style.strikethrough != null &&
    chunk.style.strikethrough !== defaults.strikethrough
  ) {
    open += '<s>';
    close = '</s>' + close;
  }
  if (
    chunk.style.underline != null &&
    chunk.style.underline !== defaults.underline
  ) {
    open += '<span style="text-decoration:underline;><span data-text="true">';
    close = '</span></span>' + close;
  }
  if (chunk.style.valign != null && chunk.style.valign !== defaults.valign) {
    if (chunk.style.valign === 'super') {
      open += '<sup>';
      close = '</sup>' + close;
    } else if (chunk.style.valign === 'sub') {
      open += '<sup>';
      close = '</sup>' + close;
    }
  }
  return { open, close };
}

function renderPara(para: any, defaults: any) {
  if (!para.content || para.content.length === 0) return;
  const style = CSS(para, defaults);
  const tags = styleTags(para, defaults);
  const pdefaults = Object.assign({}, defaults);
  for (let item of Object.keys(para.style)) {
    if (para.style[item] != null) pdefaults[item] = para.style[item];
  }
  const paraTag = defaults.paraTag;
  return `<${paraTag}${style ? ' style="' + style + '"' : ''}>${
    tags.open
  }${para.content.map((span: any) => renderSpan(span, pdefaults)).join('')}${
    tags.close
  }</${paraTag}>`;
}

function renderSpan(span: any, defaults: any) {
  const style = CSS(span, defaults);
  const tags = styleTags(span, defaults);
  const value = `${tags.open}${span.value}${tags.close}`;
  if (style) {
    return `<span style="${style}">${value}</span>`;
  } else {
    return value;
  }
}
function htmlifyresult(opts: any, cb: any) {
  return (err: any, doc: any) => {
    if (err) return cb(err);
    try {
      return cb(null, rtfToHTML(doc, opts));
    } catch (ex) {
      return cb(ex);
    }
  };
}
export function rtfToHtmlFromString(string: string, opts?: any, cb?: any) {
  if (arguments.length === 2) {
    cb = opts;
    opts = null;
  }
  const parse = require('rtf-parser');
  return parse.string(
    string
      .replaceAll("{\\*\\pn\\pnlvlblt\\pnf1\\pnindent0{\\pntxtb\\'B7}}", '')
      .replaceAll(`{\\pntext\\f1\\'B7\\tab}`, ' • '),
    htmlifyresult(opts, cb)
  );
}
