export const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

export const BLOCK_TYPES = [
  { label: 'mdi mdi-format-header-1', style: 'header-one' },
  { label: 'mdi mdi-format-header-2', style: 'header-two' },
  { label: 'mdi mdi-format-quote-close', style: 'blockquote' },
  { label: 'icon-numberedlist', style: 'ordered-list-item' },
  { label: 'icon-bulletlist', style: 'unordered-list-item' }
];

export const INLINE_STYLES = [
  { label: 'icon-bold', style: 'BOLD' },
  { label: 'icon-italic', style: 'ITALIC' },
  { label: 'icon-underline', style: 'UNDERLINE' }
];
