import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Table } from 'react-bootstrap';
import _ from 'lodash';
import { PlusSquare, PencilSquare } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import {
  getCurrentTable,
  getCurrentFormatList,
  addCurrentFormatList,
} from '../../../redux';
import { bindActionCreators } from 'redux';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { updateFormatList } from '../../../utils';

const QueryFormatList = ({
  setCustomActiveTab,
  currentFormatList,
  currentTable,
  addCurrentFormatList,
  isUpdateFormat,
  setIsUpdateFormat,
  setIsFormatVisible,
  setFormatActiveOperation,
}) => {
  const [formatList, setFormatList] = useState([]);
  const [tableFormatList] = useLocalStorage('tableFormatList');

  useEffect(() => {
    if (isUpdateFormat) {
      if (tableFormatList) {
        const findFormats = _.find(tableFormatList, [
          'formatName',
          `${currentTable}sFormats`,
        ]);
        if (findFormats) {
          setFormatList(
            _.get(findFormats, 'formats', []).map((item) => ({
              caption: item.caption,
              formatName: item.selectFrom
                .replace(`lst.`, '')
                .replace('.json', ''),
            })),
          );
        }
      }
      setIsUpdateFormat(false);
    }
  }, [isUpdateFormat]);

  const handleCreateNewFormatList = () => {
    setFormatActiveOperation('create');
    setIsFormatVisible(true);
    setCustomActiveTab('format-create');
  };

  const handleEditFormatList = () => {
    setFormatActiveOperation('edit');
    setIsFormatVisible(true);
    setCustomActiveTab('format-create');
  };

  const handleClickTable = async (item) => {
    const newFormatName = item.formatName;
    if (!_.find(tableFormatList, ['formatName', newFormatName])) {
      try {
        let tableFormatListResult = await updateFormatList(
          `${currentTable}s`,
          newFormatName,
          tableFormatList,
        );
        addCurrentFormatList(tableFormatListResult ? newFormatName : null);
      } catch (err) {
        addCurrentFormatList(null);
      }
    } else {
      addCurrentFormatList(newFormatName);
    }
  };

  return (
    <Card className='query-criteria-widget-card'>
      <Card.Header>
        <Row className='header-row'>
          <Button
            key={`btn-createNewFormatList`}
            onClick={handleCreateNewFormatList}
          >
            <div>
              <PlusSquare />
              <span className='primary-button-content'> Create </span>
            </div>
          </Button>
          <Button key={`btn-editFormatList`} onClick={handleEditFormatList}>
            <div>
              <PencilSquare />
              <span className='primary-button-content'> Edit </span>
            </div>
          </Button>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table bordered>
          <thead>
            <tr>
              <th key={'format-name'}>Format name</th>
            </tr>
          </thead>
          <tbody>
            {formatList
              ? formatList.map((item, i) => {
                  return (
                    <tr
                      className={`option-row ${
                        currentFormatList === item.formatName
                          ? 'row-selected'
                          : ''
                      }`}
                      key={`tr-format-item-${i}`}
                      onClick={() => handleClickTable(item)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td key={`td-format-item-${i}`}>{item.caption}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  currentFormatList: getCurrentFormatList(state),
  currentTable: getCurrentTable(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCurrentFormatList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryFormatList);
