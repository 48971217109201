import React, { useState, useEffect, FC } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import {
  scrollAction,
  unselectItem,
  selectItem,
  getGridData,
  getIfselectedExist,
  getMetaData,
  getScreenSize,
  selectAll
} from '../../redux';
import { Table, DocumentPreview, DetailsForMobile } from '../../components';
import { IDataTable } from '../../types';

const DataTable: FC<IDataTable> = (props: IDataTable) => {
  const {
    gridData,
    fullGridFormat,
    selectAll,
    selectAllItems,
    selectedAll,
    setSelectedAll,
    gridCaption,
    miniGridFields,
    screenSize
  } = props;
  const [showPreview, setShowPreview] = useState({
    show: false,
    head: ' ',
    url: '',
    docExt: '',
    blob: new Blob()
  });
  const { fields, formatName } =
    miniGridFields && miniGridFields.length
      ? { fields: miniGridFields, formatName: '' }
      : fullGridFormat || { fields: [], formatName: '' };
  const { data, hasMore } =
    miniGridFields && miniGridFields.length
      ? gridData[`${gridCaption ? gridCaption.replaceAll(' ', '') : ''}`] || {}
      : gridData[
          `${gridCaption ? gridCaption.replaceAll(' ', '') : ''}${formatName}${
            window.location.pathname
          }`
        ] || {};

  const selectItems = () => {
    if (selectedAll && selectAll) {
      selectAll([]);
    } else {
      selectAllItems();
    }
    setSelectedAll(!selectedAll);
  };

  useEffect(() => {}, [data, fullGridFormat]);
  return (
    <React.Fragment>
      {screenSize === 'XS' && !miniGridFields ? (
        <DetailsForMobile
          {...props}
          metadata={props.metadata}
          data={data}
          tableFormat={{ fields, formatName }}
          hasMore={hasMore}
        />
      ) : (
        <div className='table-responsive'>
          {showPreview.show && (
            <DocumentPreview
              preview={showPreview}
              onHide={() =>
                setShowPreview({
                  url: '',
                  head: '',
                  show: false,
                  docExt: '',
                  blob: new Blob()
                })
              }
            />
          )}
          <Table
            {...props}
            data={data}
            selectItems={selectItems}
            tableFormat={{ fields, formatName }}
            hasMore={hasMore}
          />
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  gridData: getGridData(state),
  metadata: getMetaData(state),
  hasSelected: getIfselectedExist(state),
  screenSize: getScreenSize(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      scrollAction,
      unselectItem,
      selectItem,
      selectAll
    },
    dispatch
  );
};

export default withRouter<IDataTable & RouteComponentProps<{}>, any>(
  connect<any, any>(mapStateToProps, mapDispatchToProps)(DataTable) as any
);
