import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';

import { DropboxLogin } from './';
import { setShowDropboxLogin, getDropboxMessage } from '../../redux';

const DropboxModal = ({ setShowDropboxLogin, dropboxMessage }: any) => {
  const [metadatasList] = useLocalStorage('metadatasList');

  const dlg = _.find(metadatasList, ['fileName', 'Dlg.DropboxLogin.json']);

  const handleClick = (handler: string) => {
    if (handler === 'cancel') {
      setShowDropboxLogin(false);
    }
  };

  return (
    <div className={`alert-modal success`}>
      <Modal.Dialog>
        <Modal.Header>
          <h4>{_.get(dlg, 'form.caption', '')}</h4>
        </Modal.Header>
        <Modal.Body>
          {dropboxMessage.split('\\n').map((item: string, i: React.Key) => (
            <p style={{ lineHeight: 0.5 }} key={i}>
              {item}
            </p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <DropboxLogin />
          {_.get(dlg, 'form.group', null)
            ? _.get(dlg, 'form.group[0].entries', []).map(
                (button: {
                  variant: string;
                  handler: string;
                  caption: string;
                }) => (
                  <Button
                    variant={button.variant || 'secondary'}
                    onClick={() => handleClick(button.handler)}
                  >
                    {button.caption}
                  </Button>
                )
              )
            : null}
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  dropboxMessage: getDropboxMessage(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setShowDropboxLogin
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DropboxModal);
