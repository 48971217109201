import React from 'react';
import moment from 'moment';

export const dateFormat = 'DD-MM-YYYY';
const inputDateFormats = [
  dateFormat,
  'dd-MM-yyyy',
  'ddmmyy',
  'YYYY-MM-DD',
  'dd-MM-YY'
];
export const applyFormatType = (
  text: string | boolean | undefined | number,
  type: string,
  attribute: string
) => {
  if (!type) return text;
  if (!text) return '';
  if (typeof text !== 'undefined' && type === 'amount') {
    if (!text) return '0,00';
    const decimalValue = parseFloat(`${text}`)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      .toString();
    return decimalValue.replace(/.([^.]*)$/, ',' + '$1') || '0,00';
  }
  if (
    typeof text === 'string' &&
    (attribute.toLowerCase().includes('date') || type === 'date')
  ) {
    return moment(text).format(dateFormat);
  }
  if (typeof text === 'string' && type === 'time') {
    return text.substring(0, 5);
  }
  if (typeof text === 'string' && type === 'timestamp') {
    const [date, time] = text.split(' ');
    return `${moment(date).format(dateFormat)} ${time.substring(0, 5)}`;
  }
  if (type === 'refnum') {
    if (!text.toString().includes(',')) {
      const refnum = text.toString();
      let result: string | string[] = refnum.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      );
      return result;
    }
  }
  if (typeof text === 'boolean' || type === 'boolean') {
    if (text === true) {
      return <i className='icon-check'></i>;
    }
  }
  if (type === 'coordinate') {
    const [integer, decimal] = `${text}`.split('.');
    let decimalString: string = decimal.substring(0, 6);
    if (decimalString.length < 6) {
      for (let i = 0; i < 6 - decimalString.length; i++) {
        decimalString += '0';
      }
    }
    return `${integer}.${decimalString}`;
  }
  return text;
};

export const alignTypedFields = (fieldType: string) => {
  let textAlign: 'start' | 'end' = 'start';
  if (fieldType) {
    if (
      fieldType.includes('num') ||
      fieldType === 'int' ||
      fieldType === 'amount'
    ) {
      textAlign = 'end';
    }
  }
  return textAlign;
};

export const formatTimeInput = (value: string, renderAlertBar?: () => void) => {
  if (value === '') {
    return value;
  }
  const time = moment(value + ' ', [moment.ISO_8601, 'HH:mm']).format('HH:mm');
  if (time === 'Invalid date') {
    renderAlertBar && renderAlertBar();
    return '';
  }
  return time;
};
const getQuarterStart = (quarter: number = 0) =>
  moment().quarter(quarter).startOf('quarter').format(dateFormat);

const getQuarterEnd = (quarter: number = 0) =>
  moment().quarter(quarter).endOf('quarter').format(dateFormat);

const getHalfYearStart = (half: number) => {
  if (half === 1) return getQuarterStart(1);
  if (half === 2) return getQuarterStart(3);
};
const getHalfYearEnd = (half: number) => {
  if (half === 1) return getQuarterEnd(2);
  if (half === 2) return getQuarterEnd(4);
};

export const formatDateInput = (value: string, renderAlertBar?: () => void) => {
  if (!value) return null;
  const currentMonth = moment().month();
  const pqValues = ['pq', 'pq1', 'pq2', 'pq3', 'pq4', 'q1', 'q2', 'q3', 'q4'];
  const uqValues = ['uq', 'uq1', 'uq2', 'uq3', 'uq4'];
  const phValues = ['ph', 'ph1', 'ph2'];
  const uhValues = ['uh', 'uh1', 'uh2'];
  if (value === '') return null;
  if (value === '.') return moment().format(dateFormat);
  if (value && (value[0] === '-' || value[0] === '+'))
    return moment().add(parseInt(value), 'days').format(dateFormat);
  if (value === 'pw') return moment().isoWeekday(1).format(dateFormat);
  if (value === 'uw') return moment().isoWeekday(7).format(dateFormat);
  if (value === 'pm') return moment().startOf('month').format(dateFormat);
  if (value === 'um') return moment().endOf('month').format(dateFormat);
  if (value === 'pq') return getQuarterStart();
  if (value === 'uq') return getQuarterEnd();
  if (value === 'py') return moment().startOf('year').format(dateFormat);
  if (value === 'uy') return moment().endOf('year').format(dateFormat);

  if (pqValues.includes(value)) {
    const quarter = value.substr(-1);
    return getQuarterStart(parseInt(quarter));
  }

  if (uqValues.includes(value)) {
    const quarter = value.substr(-1);
    return getQuarterEnd(parseInt(quarter));
  }
  if (value === 'uh') {
    const halfYearEnd: any =
      currentMonth <= 5 ? getQuarterEnd(2) : getQuarterEnd(4);
    return halfYearEnd;
  }

  if (phValues.includes(value)) {
    const numberObj = value?.match(/\d+/);
    const halfYear = numberObj ? numberObj[0] : currentMonth <= 5 ? '1' : '2';
    return getHalfYearStart(parseInt(halfYear));
  }

  if (uhValues.includes(value)) {
    const numberObj = value?.match(/\d+/);
    const halfYear = numberObj ? numberObj[0] : currentMonth <= 5 ? '1' : '2';
    return getHalfYearEnd(parseInt(halfYear));
  }

  const date = moment(value, inputDateFormats).format(dateFormat);
  if (date === 'Invalid date') {
    renderAlertBar && renderAlertBar();
    return null;
  }
  return date;
};

export const graphQLDateFormat = (value: string) =>
  moment(value, inputDateFormats).format('YYYY-MM-DD');

export const deformatValue = (
  inputType: string | undefined,
  value: string | number | boolean
) => {
  if (!inputType || inputType === 'time' || inputType === 'date') {
    return value;
  }
  const valueStr = value.toString();
  if (inputType === 'refnum' || inputType === 'int') {
    const deformatedVal = valueStr.replaceAll(',', '.').replaceAll('.', '');
    return !isNaN(parseInt(deformatedVal)) ? parseInt(deformatedVal) : 0;
  }
  if (inputType === 'amount') {
    const deformatedVal = valueStr.replaceAll('.', '').replaceAll(',', '.');
    return !isNaN(parseFloat(deformatedVal)) ? parseFloat(deformatedVal) : 0;
  }
  return value;
};
