import {
  ADD_TABLE_LIST,
  ADD_TABLE_FIELD_LIST,
  ADD_QUERY_LIST,
  ADD_ENTITIES,
  ADD_ENTITY_ITEM,
  UPDATE_ENTITY_ITEM,
  DELETE_ENTITY_ITEM,
  ADD_CRITERIAS,
  ADD_CRITERIA_ITEM,
  UPDATE_CRITERIA_ITEM,
  DELETE_CRITERIA_ITEM,
  ADD_CURRENT_TABLE,
  ADD_CURRENT_FORMAT_LIST,
  ADD_FORMATS,
  ADD_FORMAT_ITEM,
  DELETE_FORMAT_ITEM,
  UPDATE_FORMAT_ITEM,
  ADD_TABLE_COUNTER
} from '../types';

export const addTableList = (payload) => ({
  type: ADD_TABLE_LIST,
  payload,
});

export const addTableFieldList = (payload) => ({
  type: ADD_TABLE_FIELD_LIST,
  payload,
});

export const addQueryList = (payload) => ({
  type: ADD_QUERY_LIST,
  payload,
});

export const addEntities = (payload) => {
  return {
    type: ADD_ENTITIES,
    payload,
  };
};

export const addEntityItem = (payload) => ({
  type: ADD_ENTITY_ITEM,
  payload,
});

export const updateEntityItem = (payload) => {
  return {
    type: UPDATE_ENTITY_ITEM,
    payload,
  };
};

export const deleteEntityItem = (payload) => {
  return {
    type: DELETE_ENTITY_ITEM,
    payload,
  };
};

export const addCriterias = (payload) => {
  return {
    type: ADD_CRITERIAS,
    payload,
  };
};

export const addCriteriaItem = (payload) => {
  return {
    type: ADD_CRITERIA_ITEM,
    payload,
  };
};

export const updateCriteriaItem = (payload) => {
  return {
    type: UPDATE_CRITERIA_ITEM,
    payload,
  };
};

export const deleteCriteriaItem = (payload) => {
  return {
    type: DELETE_CRITERIA_ITEM,
    payload,
  };
};

export const addCurrentTable = (payload) => {
  return {
    type: ADD_CURRENT_TABLE,
    payload,
  };
}

export const addCurrentFormatList = (payload) => {
  return {
    type: ADD_CURRENT_FORMAT_LIST,
    payload,
  };
};

export const addFormats = (payload) => {
  return {
    type: ADD_FORMATS,
    payload,
  };
};

export const addFormatItem = (payload) => {
  return {
    type: ADD_FORMAT_ITEM,
    payload,
  };
};

export const deleteFormatItem = (payload) => {
  return {
    type: DELETE_FORMAT_ITEM,
    payload,
  };
};

export const updateFormatItem = (payload) => {
  return {
    type: UPDATE_FORMAT_ITEM,
    payload,
  };
};

export const addTableCounter = (payload) => {
  return {
    type: ADD_TABLE_COUNTER,
    payload,
  };
};



