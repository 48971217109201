import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { TFieldsValues, IFormField } from '../types';
import { genericMutation, genericQuery } from '../GraphQL';

import {
  getLocalStorageItem,
  getSelectedExcelData,
  getRenderedMeta,
  showSuccessMessage
} from '../utils';

interface ITSZMailing {
  data: TFieldsValues;
  apolloClient: any;
  setDialogWindowProps: (dialogProps: TFieldsValues) => void;
  setCatchedError: (error: string) => void;
  setAlertBarOptions: (AlertBarOptions: TFieldsValues) => void;
  selectedItems: TFieldsValues[];
}

export class TSZMailing {
  private data;
  private selectedItems;
  private apolloClient;
  private setDialogWindowProps;
  private setCatchedError;
  private setAlertBarOptions;

  constructor(classProps: ITSZMailing) {
    this.data = classProps.data;
    this.apolloClient = classProps.apolloClient;
    this.setDialogWindowProps = classProps.setDialogWindowProps;
    this.setCatchedError = classProps.setCatchedError;
    this.setAlertBarOptions = classProps.setAlertBarOptions;
    this.selectedItems = classProps.selectedItems;
  }

  private updateStats = async (dataObject: TFieldsValues) => {
    const tszMailsData = await this.apolloClient.query({
      query: genericQuery(
        'tszMails',
        ['id'],
        `primaryMailing:"${dataObject.id}"`,
        '',
        0
      )
    });
    const tszOptOutsData = await this.apolloClient.query({
      query: genericQuery(
        'tszOptOuts',
        ['id'],
        `primaryMailing:"${dataObject.id}"`,
        '',
        0
      )
    });
    const { tszMails } = tszMailsData.data;
    const { tszOptOuts } = tszOptOutsData.data;
    const countAll = tszMails?.length || 0;
    const countUnsubscribes = tszOptOuts?.length || 0;
    let mutationString = `countAll:${countAll}, countUnsubscribes:${countUnsubscribes}`;
    if (dataObject.typeCode === 'mailchimp') {
      // mailChimpImportStats()
      const mailchimpLastSync = moment().format('YYYY-MM-DD HH:mm:ss.ms');
      mutationString += `, mailchimpLastSync:${mailchimpLastSync}`;
    } else {
      const tszMailsDataSent = await this.apolloClient.query({
        query: genericQuery(
          'tszMails',
          ['id'],
          `primaryMailing:"${dataObject.id}", sentTSIsNull:false`,
          '',
          0
        )
      });
      const { tszMails } = tszMailsDataSent.data;
      const countSent = tszMails?.length || 0;
      mutationString += `, countSent:${countSent}`;
    }
    const mutation = genericMutation(
      'tszMailing',
      'update',
      `id:"${dataObject.id}", ${mutationString}`
    );
    await this.apolloClient.mutate({ mutation });
    showSuccessMessage(this.setAlertBarOptions);
  };

  public detailsButtonExport = async () => {
    const format = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      'lst.tszMails.Excel.json'
    );
    const { fields } = format;
    const { head, id } = this.data;
    const query = genericQuery(
      'tszMails',
      [
        `primaryContactRelation {
      email
      title
      firstN
      lastN
      private
      mobile
      direct  
      primCompRelation {
        alias
        name
        name2
        addr1
        addr2
        primaryPostCodeRelation {
          postCode
          town
        }
        primCounRelation {
          head
          code
        }
        phone
        fax
        navisionDebitornr
        cvrNumber
        pNumber
        eanNumber
      }
    }`
      ],
      `primaryMailing:"${id}" ,sentTSIsNull: false`
    );
    this.apolloClient
      .query({ query })
      .then(({ data }: { data: { tszMails: TFieldsValues[] } }) => {
        const { tszMails } = data;
        if (!tszMails || tszMails.length === 0) {
          console.log('AlertBar @NoMailsTExport');
          return;
        }
        const row = tszMails.map((data) => {
          const rowSet: TFieldsValues[] = [];
          fields.map((field: TFieldsValues) => {
            let attributes = field.attribute.replaceAll('}', '').split('{');
            rowSet.push(_.get(data, attributes));
          });
          return rowSet;
        });
        row.unshift(fields.map((field: { caption: string }) => field.caption));
        const dataForExcel = getSelectedExcelData(row);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataForExcel, 'SheetJS');
        XLSX.writeFile(wb, `merge - ${head}.xlsx`);
      });
  };

  public capabilityMarkAllAsSent = async () => {
    const { id } = this.selectedItems[0];
    const dialogMeta = await getRenderedMeta(
      getLocalStorageItem('metadatasList'),
      `Dlg.MailingConfirmMarkAllSent.json`
    );
    const markAllAsSent = async () => {
      const query = genericQuery('tszMails', ['id'], `primaryMailing: "${id}"`);
      const { data } = await this.apolloClient.query({ query });
      const { tszMails } = data;
      if (tszMails && tszMails.length > 0) {
        const sentTs = moment().format('YYYY-MM-DD HH:mm:ss.ms');
        tszMails.map(({ id }: { id: string }) => {
          const mutation = genericMutation(
            'tszMail',
            'update',
            `id:"${id}" sentTS:"${sentTs}"`
          );
          this.apolloClient
            .mutate({ mutation })
            .then((res: any) => showSuccessMessage(this.setAlertBarOptions));
        });
      }
    };
    this.setDialogWindowProps({
      show: true,
      meta: dialogMeta.data.form,
      handlers: {
        confirm: () => {
          markAllAsSent();
          showSuccessMessage(this.setAlertBarOptions);
        }
      }
    });
  };

  public detailsButtonOpenMailChimp = async () => {
    const cdmsystQuery = genericQuery('cdmsyst', ['mailChimpServerURL']);
    const resCdmsyst = await this.apolloClient.query({ query: cdmsystQuery });
    const { mailChimpServerURL } = resCdmsyst.data.cdmsyst;
    if (mailChimpServerURL) {
      const query = genericQuery(
        'tszMailing',
        ['mailChimpCampaignId', 'mailChimpWebId'],
        `id:"${this.data.id}"`
      );
      const res = await this.apolloClient.query({ query });
      const { tszMailing } = res.data;
      if (tszMailing.mailChimpCustomerListId) {
        const link = `${
          mailChimpServerURL.split('.')[0]
        }.admin.mailchimp.com/campaigns/show?id=${
          tszMailing.mailChimpCampaignId
        }`;
        window.open(link, '_blank')?.focus();
      } else {
        const link = `${mailChimpServerURL.split('.')[0]}.admin.mailchimp.com`;
        window.open(link, '_blank')?.focus();
      }
    }
  };

  public detailsButtonFindMailChimpID = async () => {
    const cdmsystQuery = genericQuery('cdmsyst', [
      'mailChimpServerURL',
      'mailChimpAPIKey'
    ]);
    const { data } = await this.apolloClient.query({ query: cdmsystQuery });
    const { mailChimpServerURL, mailChimpAPIKey } = data.cdmsyst;
  };

  public capabilityUpdateFromMailChimp = async () => {
    console.log('capabilityUpdateFromMailChimp');
  };

  public capabilitySynchronizeMailChimp = async () => {
    console.log('capabilitySynchronizeMailChimp');
  };

  public detailsButtonOutlookCreateEditTemplate = async () => {
    console.log('detailsButtonOutlookCreateEditTemplate');
  };

  public detailsButtonOutlookSendEmails = async () => {
    console.log('detailsButtonOutlookCreateEditTemplate');
  };

  public detailsButtonUpdateStats = async () => {
    await this.updateStats(this.data);
  };

  public capabilityTest = () => {
    console.log('capabilityTest');
  };

  public capabilityUpdateStats = async () => {
    await Promise.all(
      this.selectedItems.map(async (item: TFieldsValues) => {
        const { typeCode } = item;
        if (typeCode === 'mailchimp') {
          await this.capabilityUpdateFromMailChimp();
        } else {
          await this.updateStats(item);
        }
      })
    );
  };

  public capabilityCopy = async () => {
    console.log('capabilityCopy');
  };

  public capabilityOutlookAttachTemplate = async () => {
    console.log('capabilityOutlookAttachTemplate');
  };
}
