import React, { useState, useEffect } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';

const SelectTable = (props) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [cachedData, setCachedData] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');

  const handleClose = () => {
    setSelectOptions([]);
    setCachedData([]);
    setSelectedTable('');
    props.setModalVisible(!props.modalVisible);
  };

  useEffect(() => {
    setCachedData(props.dataList);
    setSelectOptions(props.dataList);
  }, [props.dataList, cachedData]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const displayedData = selectOptions;
      let slicedData;
      let copyCashedData = cachedData.slice();
      if (copyCashedData.length > 40) {
        slicedData = copyCashedData.splice(0, 40);
      } else {
        slicedData = copyCashedData.splice(0, copyCashedData.length);
      }
      const loadMore = displayedData.concat(slicedData);
      setSelectOptions(loadMore);
    }
  };

  const handleClick = (item) => {
    setSelectedTable(item);
  };

  const handleOk = () => {
    props.handleQueryBuilder(props.dataList[selectedTable]);
    handleClose();
  };

  const renderTableItem = (obj) => {
    const values = [];
    getProp(obj);

    function getProp(o) {
      for (let key in o) {
        if (key !== '__typename') {
          if (o[key] !== null && typeof o[key] === 'object') {
            getProp(o[key]);
          } else {
            values.push({ key: key, value: o[key] });
          }
        }
      }
    }
    return values.map((item, i) => {
      return (
        <Col className='modal-select-option' key={`class-${item.value}-${i}`}>
          {item.value === null ? ' ' : item.value}
        </Col>
      );
    });
  };

  return (
    <Modal
      centered
      show={props.modalVisible}
      onHide={() => props.setModalVisible(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose table</Modal.Title>
      </Modal.Header>
      <Modal.Body className='editing-modal-body'>
        <Col className='modal-list-header modal-list'>
          <Row>
            {props.columnName.map((item, i) => {
              return (
                <Col key={`col-${i}`} style={{ textAlign: 'center' }}>
                  {item}
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col
          className='modal-horizontal-scroll modal-list'
          onScroll={(e) => handleScroll(e)}
        >
          {props.dataList.map((item, ind) => {
            return (
              <Row
                className={`option-row ${
                  selectedTable === ind ? 'row-selected' : ''
                }`}
                key={`option-${ind}`}
                onClick={() => handleClick(ind)}
              >
                {renderTableItem(item)}
              </Row>
            );
          })}
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleOk}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectTable;
