import React, { FC, useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useApolloClient } from 'react-apollo';
import { genericQuery } from '../../GraphQL';
import { Table } from '../../components';
import { IListModal, IFormField } from '../../types';
import { divideNestedAttribute, QUERY_LIMIT } from '../../utils';
import { getScreenSize } from '../../redux';

const ListModal: FC<IListModal> = ({
  showModal,
  setShowModal,
  listMeta,
  defaultHandler,
  screenSize,
  filterExpression,
  onDiscard
}) => {
  const apolloClient = useApolloClient();
  const [metadata, setMetadata] = useState(listMeta);
  const { dataSource, fields, attribute, defaultSearch, format, defaultData } =
    listMeta || {};
  const [mainAttribute, subAttributes] = divideNestedAttribute(attribute);
  const [defaultFilter, setFilterExpression] = useState(filterExpression);
  const [hasMore, setHasMore] = useState(false);
  const queryFields =
    fields && fields.map((field: { attribute: string }) => field.attribute);
  const [renderedData, setRenderedData] = useState<{ id: string }[]>([]);
  const formatName = `${dataSource}.${format}`;
  const selectItem = (item: { id: string }) => {
    if (defaultHandler) {
      defaultHandler(item);
    } else {
      const filter = defaultFilter
        .split(',')
        .map((filter) => {
          if (filter.includes('prim') && !filter.includes('[')) {
            filter = filter.slice(0, filter.indexOf(':'));
            return `${filter}:"${item.id}"`;
          }
          return filter;
        })
        .join(',');
      setShowModal(false);
      setFilterExpression(filter);
    }
  };
  const discardSelection = () =>
    onDiscard ? onDiscard() : setShowModal(false);

  const filterBySearch = (searchValue: string) => {
    setRenderedData([]);
    const filterBy =
      subAttributes !== undefined
        ? subAttributes.split(' ')[0]
        : queryFields[0] || null;
    let queryFilter = defaultFilter;
    if (searchValue && filterBy) {
      if (defaultFilter.indexOf(`${filterBy}LikeNoCase:`) > 0) {
        let filter = defaultFilter.substr(
          defaultFilter.indexOf(`${filterBy}LikeNoCase:`)
        );
        filter = filter.substr(0, filter.indexOf(','));
        const newFilter = defaultFilter.replace(
          filter,
          `${filterBy}LikeNoCase:"${searchValue}%"`
        );
        queryFilter = newFilter;
        setFilterExpression(newFilter);
      } else {
        queryFilter = `${defaultFilter} ${filterBy}LikeNoCase:"${searchValue}%", `;
        setFilterExpression(
          `${defaultFilter} ${filterBy}LikeNoCase:"${searchValue}%", `
        );
      }
    }
    loadMoreData('', queryFilter);
  };
  const loadMoreData = async (
    format: string,
    filter = defaultFilter,
    offset = 0
  ) => {
    const query = genericQuery(dataSource, queryFields, filter);
    const { data } = await apolloClient.query({
      query,
      variables: {
        offset,
        fetchPolicy: 'no-cache'
      }
    });
    if (data[dataSource]) {
      setHasMore(data[dataSource].length > QUERY_LIMIT - 1);
      offset > 0
        ? setRenderedData([...renderedData, ...data[dataSource]])
        : setRenderedData([...data[dataSource]]);
    }
  };
  useEffect(() => {
    (async () => {
      await filterBySearch(defaultSearch);
    })();
    if (showModal) {
      document.getElementById('modal-list')?.focus();
    }
    return () => {
      setMetadata({});
    };
  }, []);

  useEffect(() => {
    setRenderedData([]);
  }, [defaultFilter]);

  return (
    <Modal
      show={showModal}
      size={'lg'}
      className='editing-modal'
      id='modal-list'
      onHide={discardSelection}
      tabIndex={'0'}
      onKeyPress={(e: any) => {
        if (e.key === 'Enter' && renderedData.length === 1) {
          selectItem(renderedData[0]);
        }
      }}
    >
      <Modal.Header closeButton onClick={discardSelection}></Modal.Header>
      <Modal.Body style={{ overflow: 'hidden' }} className='editing-modal-body'>
        {attribute && (
          <div>
            <Form.Control
              defaultValue={defaultSearch}
              onChange={(e) => filterBySearch(e.target.value)}
            />
          </div>
        )}
        <div className={'listModal_table'}>
          <Table
            metadata={{}}
            scrollAction={() =>
              loadMoreData('', defaultFilter, renderedData.length)
            }
            tableFormat={{
              fields,
              formatName
            }}
            itemClick={selectItem}
            filterExpression={defaultFilter}
            setFilterExpression={setFilterExpression}
            screenSize={screenSize}
            heightStyles={{
              maxHeight: '100%',
              minHeight: '10vh'
            }}
            data={renderedData}
            hasMore={hasMore}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  screenSize: getScreenSize(state)
});

export default connect<any, any>(mapStateToProps)(ListModal);
