import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const FileNameModal = ({
  fileType,
  name,
  setName,
  nameModalVisible,
  setNameModalVisible,
  handleRun
}: any) => {
  const handleOkClick = () => {
    setNameModalVisible(false);
    handleRun();
  };

  const handleChange = (e: any) => {
    setName(`${e.target.value}`);
  };

  return (
    <Modal
      centered
      show={nameModalVisible}
      onHide={() => setNameModalVisible(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            type='text'
            placeholder='...'
            onChange={(e) => handleChange(e)}
            defaultValue={name}
            value={name}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={handleOkClick}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileNameModal;
