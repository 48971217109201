import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import * as XLSX from 'xlsx';
import isUtf8 from 'is-utf8';
import { useLocalStorage } from '@rehooks/local-storage';
import _ from 'lodash';

import { ExcelPreview, AlertBar } from '../../components';
import { FileImport } from './components';

const ExcelReader = ({ selectedMeta }) => {
  const [metadatasList] = useLocalStorage('metadatasList');

  const [fileIsInvalid, setFileIsInvalid] = useState(false);
  const [dataHeader, setDataHeader] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [dataCache, setDataCache] = useState();
  const [fieldsAttributes, setFieldsAttributes] = useState();
  const [amountFilter, setAmountFilter] = useState();
  const [maxFilter, setMaxFilter] = useState();
  const [actions, setActions] = useState({ create: true, update: false });
  const metaDataFields = selectedMeta
    ? selectedMeta
    : _.find(metadatasList, ['fileName', `Frm.FileImport.json`]).data.form.fields[0];
  const [selectedFormat, setFormat] = useState(metaDataFields.selectOptions[0]);

  const handleSelect = (selected) => {
    const option = metaDataFields.selectOptions.find(
      (option) => option.format === selected,
    );
    setFormat(option);
  };

  const filterData = (
    { amountFilter, maxFilter },
    data = dataCache,
    header = dataHeader,
  ) => {
    amountFilter = amountFilter || 0;
    maxFilter = maxFilter || 0;
    if (data && header) {
      const limit = maxFilter > 0 ? maxFilter : data.length;
      const filter = amountFilter > 0 ? amountFilter : 0;
      data = data.slice(filter, filter + limit);
      data = data.map((entry) => {
        const newEntry = Array.from(entry);
        const filledEntry = newEntry.map((el) => (el !== undefined ? el : ' '));
        return filledEntry;
      });
      setPreviewData(data);
      createRecord(data, header);
    }
  };

  const createRecord = (data, dataHeader) => {
    const { dataSource, fields, format } = selectedFormat;
    const attributes = fields.map((field) => field.attribute);
    data = data.map((entry) => {
      const dataValues = {};
      dataHeader.map((heading, index) => {
        const field = fields.find(
          (field) => field.caption.replaceAll(' ', '') === heading,
        );
        if (field) {
          const { attribute, type } = field;
          const value = entry[index];
          if (type === 'date') {
            dataValues[attribute] = moment(value).format('DD/MM/yyyy');
          } else {
            dataValues[attribute] = value;
          }
        } else {
          dataValues[heading.toLowerCase()] = '';
        }
      });

      return dataValues;
    });

    setPreviewData(data);
    setFieldsAttributes(fields);
  };

  const uploadHandler = (event) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
        cellDates: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      const header = data.shift();
      const notCorrupted = isUtf8(data[0][0]);
      if (notCorrupted) {
        setDataCache(data);
        setDataHeader(header);
        filterData({ maxFilter, amountFilter }, data, header);
      } else {
        setFileIsInvalid(true);
      }
    };

    if (event.target.files.length) {
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;
      if (rABS) reader.readAsBinaryString(fileObj);

      if (
        fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx' ||
        fileName.slice(fileName.lastIndexOf('.') + 1) === 'csv'
      ) {
        setFileIsInvalid(false);
      } else {
        setFileIsInvalid(true);
      }
    }
  };
  return (
    <div className='excel-import-page'>
      <Row className='import-settings'>
        <Col sm={3}>
          <Form.Label>Format</Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => handleSelect(e.target.value)}
          >
            {metaDataFields.selectOptions.map((option) => (
              <option>{option.format}</option>
            ))}
          </Form.Control>
        </Col>
        <Col sm={1}>
          <Form.Label>First</Form.Label>
          <Form.Control
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setAmountFilter(value);
              filterData({ amountFilter: value, maxFilter });
            }}
          />
        </Col>
        <Col sm={1}>
          <Form.Label>Max</Form.Label>
          <Form.Control
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setMaxFilter(value);
              filterData({ maxFilter: value, amountFilter });
            }}
          />
        </Col>
        <Col sm={2}>
          <Form.Check
            type='checkbox'
            label='Create new'
            defaultChecked={actions.create}
            onChange={(e) =>
              setActions({ ...actions, create: e.target.checked })
            }
          />
        </Col>
        <Col sm={2}>
          <Form.Check
            type='checkbox'
            label='Overwrite existing'
            defaultChecked={actions.update}
            onChange={(e) =>
              setActions({ ...actions, update: e.target.checked })
            }
          />
        </Col>
        <Col sm={2}>
          <Button type='submit'>Submit</Button>
        </Col>
      </Row>

      <FileImport uploadHandler={uploadHandler} />

      {fileIsInvalid && (
        <AlertBar
          showAlertBar={true}
          alertText={'File type is invalid or file is corrupted'}
          color='danger'
        />
      )}
      {fieldsAttributes ? (
        <ExcelPreview
          data={previewData}
          header={dataHeader}
          fieldsAttributes={fieldsAttributes}
        />
      ) : null}
    </div>
  );
};

export default ExcelReader;
