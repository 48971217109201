import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ModalTitle = ({
    title,
    setTitle,
    error,
    isFormat,
    checkAvailabilityTitle,
    titleModalVisible,
    setTitleModalVisible,
    handleRun,
}) => {
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        setIsInvalid(error && isFormat);
    }, [error, isFormat]);

    const handleOkClick = () => {
        if (isFormat) {
            if (checkAvailabilityTitle()) {
                setTitleModalVisible(false);
                handleRun();
            }
        } else {
            setTitleModalVisible(false);
            handleRun();
        }
    };

    return (
        <Modal
            centered
            show={titleModalVisible}
            onHide={() => setTitleModalVisible(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Enter Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        type="text"
                        placeholder="..."
                        onChange={(e) => setTitle(e.target.value)}
                        defaultValue={title}
                        isInvalid={isInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleOkClick}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalTitle;
