import React from 'react';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const ExcelPreview = ({ data, metadata, fieldsAttributes, header }) => {
  const fields = fieldsAttributes ? fieldsAttributes : metadata.fields;
  const columns = fields.map(({ attribute, caption }) => {
    return { name: attribute, title: caption };
  });

  const defaultColumnWidths = columns.map(({ name }) => {
    return { columnName: name, width: 150 };
  });
  return (
    <div className='card'>
      <Grid rows={data} columns={columns}>
        <VirtualTable />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow />
      </Grid>
    </div>
  );
};
export default ExcelPreview;
