import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Col, ListGroup } from 'react-bootstrap';
import classnames from 'classnames';
import { getScreenSize } from '../../../redux';
import { flatten } from 'flat';
import _ from 'lodash';
import { dispatcher } from '../../../helpers/dispatcher';
import { DialogModal, IconWrapper } from '../../../components';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';

const TabsBar = ({
  customActiveTab,
  tabs,
  setCustomActiveTab,
  screenSize,
  pageName,
  setTabs,
  defaultTabsSet,
  getTabsSorted
}: any) => {
  const history = useHistory();
  const [metadatasList] = useLocalStorage('metadatasList');
  const [leaveConfirmMeta, setLeaveConfirmMeta] = useState<any>(
    _.find(metadatasList, ['fileName', `Dlg.SysLeaveConfirm.json`])
  );
  const meta = leaveConfirmMeta?.data?.form;
  const [showAccordion, setAccordion] = useState(false);
  const [tabToGo, setTabToGo] = useState();
  const [locationToGo, setLocationToGo] = useState<any>();

  const [fieldsUpdated, setfieldsUpdated] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const viewTab = (tabIndex: number) => {
    toggleCustom(`${tabIndex}`);
    document
      .getElementsByClassName('editPage-container')[0]
      .scrollTo({ top: 0, behavior: 'smooth' });
  };
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      if (fieldsUpdated) {
        setTabToGo(tab);
        setShowConfirmationModal(true);
      } else {
        dispatcher.dispatch('fieldsUpdated', false);
        setTabToGo(undefined);
        const { pathname, state } = history.location;
        history.push({
          pathname: pathname.split('#')[0],
          hash: tab,
          state
        });
        setCustomActiveTab(tab);
      }
    }
  };

  const searchForField = (searchValue: any) => {
    let resultTabs: any[] = [];
    let tabsKeys: any = flatten(defaultTabsSet);
    tabsKeys = Object.entries(tabsKeys).filter(([key, value]) =>
      key.includes('caption')
    );
    let searchedTabs = tabsKeys
      .filter(([key, value]: any) =>
        String(value).toLowerCase().includes(searchValue)
      )
      .map(([key, value]: any) => key.split('.')[0]);
    searchedTabs = _.uniq(searchedTabs);
    searchedTabs.map((index: number) => resultTabs.push(defaultTabsSet[index]));
    resultTabs.length === 0 || searchValue === ''
      ? setTabs(defaultTabsSet)
      : setTabs(getTabsSorted(resultTabs));
  };
  const showAccordionMenu = () => {
    setAccordion(!showAccordion);
  };

  useEffect(() => {
    if (metadatasList) {
      if (_.find(metadatasList, ['fileName', `Dlg.SysLeaveConfirm.json`])) {
        setLeaveConfirmMeta(
          _.find(metadatasList, ['fileName', `Dlg.SysLeaveConfirm.json`])
        );
      }
    }
  }, [metadatasList]);

  useEffect(() => {
    dispatcher.on('fieldsUpdated', (updated: boolean) => {
      setfieldsUpdated(updated);
    });
    dispatcher.on('navigate', (navigate: boolean) => {
      if ((locationToGo || tabToGo) && navigate) {
        onDiscard();
      }
    });
    return () => {
      dispatcher.off('fieldsUpdated');
      dispatcher.off('navigate');
    };
  }, [locationToGo, tabToGo]);

  const onDiscard = () => {
    setfieldsUpdated(false);
    setCustomActiveTab(tabToGo);
    dispatcher.dispatch('fieldsUpdated', false);
    setTimeout(() => {
      if (tabToGo) {
        setCustomActiveTab(tabToGo);
      } else if (locationToGo?.pathname) {
        history.push(locationToGo?.pathname, history.location.state);
      }
    }, 20);
    setLocationToGo(undefined);
    setTabToGo(undefined);
  };

  const saveChanges = () => {
    dispatcher.dispatch('saveData', null);
  };
  const handleNavigationPrompt = (loaction: any) => {
    setLocationToGo(loaction);
    if (fieldsUpdated) {
      setShowConfirmationModal(true);
      return false;
    }
    return false;
  };
  const handlers = {
    saveData: () => saveChanges(),
    discardChange: () => onDiscard(),
    cancel: () => setShowConfirmationModal(false)
  };
  return (
    <>
      <Prompt when={fieldsUpdated} message={handleNavigationPrompt} />
      {leaveConfirmMeta && (
        <DialogModal
          meta={meta}
          handlers={handlers}
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
        />
      )}
      {screenSize !== 'XS' && (
        <>
          {pageName === 'SystemSettings' ? (
            <Col sm={3}>
              <form className='app-search d-none d-lg-block'>
                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Search...'
                    onChange={(e) =>
                      searchForField(e.target.value.toLocaleLowerCase())
                    }
                  />
                  <span className='icon-search'></span>
                </div>
              </form>
              <ListGroup>
                {tabs.map((tab: any, i: number) => {
                  return (
                    <ListGroup.Item
                      action
                      href={`#${i}`}
                      onClick={() => viewTab(i)}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <IconWrapper iconName={tab.icon} />
                        <span style={{ paddingLeft: '3px' }}>
                          {tab.caption}
                        </span>
                      </span>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Col>
          ) : (
            <Nav tabs className='nav-tabs-custom'>
              {tabs.map((tab: any, index: number) => {
                return (
                  <NavItem
                    key={`${tab.caption}-${index}`}
                    onClick={() => {
                      toggleCustom(`${index}`);
                    }}
                  >
                    <NavLink
                      href={`#${index}`}
                      disabled
                      className={classnames({
                        active: customActiveTab === `${index}`
                      })}
                    >
                      <span className='tab-heading d-sm-block'>
                        <IconWrapper iconName={tab.icon} />
                        {tab.caption}
                      </span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  screenSize: getScreenSize(state)
});

export default connect(mapStateToProps)(TabsBar);
