import React, { Fragment, useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import eachDeep from 'deepdash/eachDeep';
import { useLocalStorage } from '@rehooks/local-storage';

import { ValueEditor, QueryCriteriaType } from './';
import { getCriteriaList, updateCriteriaItem } from '../../../redux';

const selectCustomStyles = {
    menu: (provided, state) => ({
        ...provided,
        height: '160px',
        maxHeight: '160px',
        overflow: 'hidden',
    }),
    menuList: (provided, state) => ({
        ...provided,
        height: '160px',
        maxHeight: '160px',
    }),
};

const QueryCriteriaOperators = ({
    criterias,
    updateCriteriaItem,
    activeAttribute,
    updateEntityInCriterias,
}) => {
    const [metadatasList] = useLocalStorage('metadatasList');

    const [operatorsOptions, setOperatorsOptions] = useState([]);
    const [activeOperator, setActiveOperator] = useState({});
    const [activeNotValue, setActiveNotValue] = useState(false);
    const [isEntity, setIsEntity] = useState(null);
    const [type, setType] = useState(null);
    const [enumList, setEnumList] = useState([]);
    const [sqlOperators, setSqlOperators] = useState(_.find(metadatasList, ['fileName', 'ListSQLOperators.json']));

    useEffect(() => {
        if (metadatasList && metadatasList.length) {
          if (_.find(metadatasList, ['fileName', 'ListSQLOperators.json'])) {
            setSqlOperators(
              _.find(metadatasList, ['fileName', 'ListSQLOperators.json'])
            );
          }
        }
      }, [metadatasList]);

    useEffect(() => {
        let activeCriteria;
        let currentOperatorsOptions = [];

        eachDeep(
            _.cloneDeep(criterias),
            (child, i, parent, ctx) => {
                if (child.id === activeAttribute.id) {
                    activeCriteria = _.cloneDeep(child);
                }
            },
            { childrenPath: 'children' }
        );

        if (!_.isEmpty(activeCriteria) && sqlOperators) {
            //isEntity
            setIsEntity(_.get(activeCriteria, 'isEntity', null));
            //type
            setType(_.get(activeCriteria, 'type', null));
            //enum list
            setEnumList(_.get(activeCriteria, 'enumList', []));

            //operator list
            const operators =
                sqlOperators.data.form.mainGroup[0].operators.filter((item) =>
                    item.type.includes(_.get(activeCriteria, 'type', ''))
                );
            if (operators.length) {
                currentOperatorsOptions = operators[0].fields.map((elem) => ({
                    value: elem.operator,
                    label: elem.caption,
                }));
                setOperatorsOptions(currentOperatorsOptions);
            }
            //operator
            if (_.get(activeCriteria, 'operator', null)) {
                setActiveOperator(
                    currentOperatorsOptions.filter(
                        (item) =>
                            item.value ===
                            _.get(activeCriteria, 'operator', null)
                    )
                );
            } else {
                setActiveOperator(null);
            }
            //not Value
            if (_.get(activeCriteria, 'notValue', null)) {
                setActiveNotValue(
                    currentOperatorsOptions.filter(
                        (item) =>
                            item.value === _.get(activeCriteria, 'not', null)
                    )
                );
            } else {
                setActiveNotValue(false);
            }
        }
    }, [activeAttribute, criterias, sqlOperators]);

    useEffect(() => {
        const currentOperator = operatorsOptions.filter(
            (item) => item.value === activeOperator
        );

        if (currentOperator.length) {
            setActiveOperator(currentOperator[0]);
        } else if (!activeOperator && operatorsOptions.length) {
            let activeCriteria;
            eachDeep(
                _.cloneDeep(criterias),
                (child, i, parent, ctx) => {
                    if (child.id === activeAttribute.id) {
                        activeCriteria = _.cloneDeep(child);
                    }
                },
                { childrenPath: 'children' }
            );
            if (!_.isEmpty(activeCriteria)) {
                updateCriteriaItem({
                    ...activeCriteria,
                    operator: operatorsOptions[0].value,
                    value: '',
                    notValue: false,
                });
                setActiveOperator(operatorsOptions[0]);
            }
        }
    }, [operatorsOptions, activeOperator]);

    const handleChange = (newValue, actionMeta) => {
        if (newValue) {
            setActiveOperator(newValue);
            let activeCriteria;
            eachDeep(
                _.cloneDeep(criterias),
                (child, i, parent, ctx) => {
                    if (child.id === activeAttribute.id) {
                        activeCriteria = _.cloneDeep(child);
                    }
                },
                { childrenPath: 'children' }
            );
            if (!_.isEmpty(activeCriteria)) {
                updateCriteriaItem({
                    ...activeCriteria,
                    operator: newValue.value,
                    value: '',
                });
            }
        } else {
            setActiveOperator('');
        }
    };

    const handleChangeNotValue = () => {
        setActiveNotValue(!activeNotValue);
        let activeCriteria;
        eachDeep(
            _.cloneDeep(criterias),
            (child, i, parent, ctx) => {
                if (child.id === activeAttribute.id) {
                    activeCriteria = _.cloneDeep(child);
                }
            },
            { childrenPath: 'children' }
        );

        if (!_.isEmpty(activeCriteria)) {
            updateCriteriaItem({
                ...activeCriteria,
                notValue: !activeNotValue,
            });
        }
    };

    return (
        <Fragment>
            <Row style={{ paddingTop: '10px' }}>
                <Col md={2}>
                    <Form.Check
                        className="query-builder-checkbox"
                        inline
                        type="checkbox"
                        label="Not"
                        onChange={handleChangeNotValue}
                        value={activeNotValue}
                        checked={activeNotValue}
                    />
                </Col>
                <Col md={7}>
                    <QueryCriteriaType
                        activeAttribute={activeAttribute}
                        updateEntityInCriterias={updateEntityInCriterias}
                    />
                </Col>
            </Row>
            <Row style={{ alignItems: 'baseline', marginTop: '5px' }}>
                <Col>
                    <Select
                        isSearchable
                        placeholder={'Select operator'}
                        onChange={handleChange}
                        options={operatorsOptions}
                        value={activeOperator}
                        styles={selectCustomStyles}
                    />
                </Col>
                {!isEntity && (
                    <Col>
                        <ValueEditor
                            activeAttribute={activeAttribute}
                            operator={_.get(activeOperator, 'label', null)}
                            type={_.get(activeAttribute, 'type', null)}
                            enumList={enumList}
                        />
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    criterias: getCriteriaList(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateCriteriaItem,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryCriteriaOperators);
