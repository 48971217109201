import _ from 'lodash';
import { setLocalStorageItem } from '../../../utils';

const authenticate = async () => {
  const { gapi } = window;
  try {
    await gapi.auth2.init({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
    });
    console.log('authenticated');
    setLocalStorageItem('isLoginInProgress', false);
  } catch (error) {
    console.log(`Error authenticating gapi client: ${error}`);
  }
};

export const googleInit = async () => {
  const { gapi } = window;
  gapi &&
    (await new Promise((resolve, reject) => {
      gapi.load('client:auth2', async () => {
        try {
          await authenticate();
          resolve();
        } catch (error) {
          console.log(`Error initializing gapi client: ${error}`);
        }
      });
    }));
  return true;
};

export const googleSignIn = async () => {
  const { gapi } = window;
  if (!_.get(gapi, 'auth2', undefined)) {
    await googleInit();
  }
  const auth2 = gapi.auth2.getAuthInstance();
  const code = await auth2.grantOfflineAccess();
  return code;
};

export const googleSignOut = async () => {
  window.gapi.load('auth2', () => {
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      window.location.reload();
    });
  });
};

export const refreshExpiredToken = () => {
  return new Promise((resolve, reject) => {
    window.gapi.load('auth2', function () {
      const existingAuthInstance = window.gapi.auth2.getAuthInstance();

      let currentUserPromise;
      if (
        localStorage.getItem('token') === null ||
        localStorage.getItem('authEmail') === null
      ) {
        reject(new Error('current session not found'));
      }

      if (existingAuthInstance) {
        currentUserPromise = Promise.resolve(
          existingAuthInstance.currentUser.get()
        );
      } else {
        if (
          localStorage.getItem('token') !== null &&
          localStorage.getItem('authEmail') !== null
        ) {
          currentUserPromise = window.gapi.auth2
            .init({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
            })
            .then((res) => {
              return res.currentUser.get();
            })
            .catch((err) => {
              reject(new Error('no session bound'));
            });
        }
      }
      if (!currentUserPromise) reject(new Error('current session not found'));

      currentUserPromise
        .then((currentGoogleUser) => {
          return currentGoogleUser.reloadAuthResponse();
        })
        .then(function (newAuthResponse) {
          resolve(newAuthResponse);
        });
    });
  });
};
