export const COLORS_SERIES = [
  '#02a499',
  '#38a4f8',
  '#ec4561',
  '#3c4ccf',
  '#ffce56',
  '#ff9f40',
  '#73edff',
  '#28adff',
  '#a070a0',
  '#ffd4b3',
  '#0092fa',
  '#fdf56d',
  '#f8a193',
  '#6e2c35',
  '#cc92ba',
  '#4e4b5d',
  '#564a6f',
  '#425b5e',
  '#de6224',
  '#be3745',
  '#6cb542',
  '#45a9b0',
  '#a754a4'
];

export const QUERY_LIMIT = 40;
