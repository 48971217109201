import { createSelector } from "reselect";

export const getSelected = (state) => state.dataManagement.selected;
export const getSortingData = (state) => state.dataManagement.sortingData;

export const getHasMore = (state) => state.dataManagement.hasMore;
export const getData = (state) => state.dataManagement.data;

export const getTheme = (state) => state.utils.theme;
export const getScreenSize = (state) => state.utils.screenSize;
export const getNavigationVisibility = (state) =>
  state.dataManagement.isNavigationVisible;

export const getFetchData = (state) => state.dataManagement.fetchData;
export const getCurrentPageTag = (state) => state.utils.currentPageTag;
export const getCatchedError = (state) => state.utils.catchedError;
export const getCalendarEvents = (state) => {
  return state.calendar.calendarEvents;
};

export const getUser = (state) => state.authorization.user;

export const getMetaData = (state) => {
  return state.dataManagement.FRM;
};

export const getIfselectedExist = createSelector(
  getSelected,
  (selected) => !!selected.length
);

export const getDashboardItems = (state) => {
  return state.chartDashboard.dashboardItems;
};

export const getDashboardUsers = (state) => {
  return state.chartDashboard.dashboardUsers;
};

export const getDashboardType = (state) => {
  return state.chartDashboard.dashboardType;
};

export const getSubscriptionsDB = (state) => {
    return state.authorization.subscriptionsDB;
};

export const getCurrentDB = (state) => {
    return state.authorization.currentDB;
};

export const getAzureFileList = (state) => {
  return state.fileManager.azureFileList;
};

export const getActiveAzureContainer = (state) => {
  return state.fileManager.activeAzureContainer;
};

export const getSelectedAzureFiles = (state) => {
  return state.fileManager.selectedAzureFiles
};
export const getTableList = (state) => {
  return state.sqlQueryBuilder.tableList;
};

export const getTableFieldList = (state) => {
  return state.sqlQueryBuilder.tableFieldList;
};

export const getQueryList = (state) => {
  return state.sqlQueryBuilder.queryList;
};

export const getCriteriaList = (state) => {
  return state.sqlQueryBuilder.criterias;
};

export const getEntityList = (state) => {
  return state.sqlQueryBuilder.entities;
};

export const getTranslationList = (state) => {
  return state.translations.translationList;
};

export const getCurrentTable = (state) => {
  return state.sqlQueryBuilder.currentTable;
};

export const getCurrentFormatList = (state) => {
  return state.sqlQueryBuilder.currentFormatList;
};

export const getFormats = (state) => {
  return state.sqlQueryBuilder.formats;
}

export const getTableCounter = (state) => {
  return state.sqlQueryBuilder.tableCounter;
}

export const getMetadataForEdit = (state) => {
  return state.metadata.metadataForEdit;
};

export const getIsMetadataChange = (state) => {
  return state.metadata.isMetadataChange;
};
