import {
    ADD_AZURE_FILE_LIST,
    ADD_ACTIVE_AZURE_CONTAINER,
    SELECT_AZURE_FILE,
    UNSELECT_AZURE_FILE,
    DELETE_SELECTED_AZURE_FILES,
} from '../types';

const initialState = {
    azureFileList: [],
    activeAzureContainer: null,
    selectedAzureFiles: []
};

const addAzureFileList = (state, payload) => ({
    ...state,
    azureFileList: payload,
});

const addActiveAzureContainer = (state, payload) => ({
    ...state,
    activeAzureContainer: payload,
});

const selectAzureFile = (state, payload) => ({
    ...state,
    selectedAzureFiles: [...state.selectedAzureFiles, payload],
});

const unselectAzureFile = (state, payload) => ({
    ...state,
    selectedAzureFiles: state.selectedAzureFiles.filter(
        (item) => item !== payload,
    ),
});

const deleteSelectedAzureFiles = (state, payload) => ({
    ...state,
    selectedAzureFiles: [],
});

const handlers = {
    [ADD_AZURE_FILE_LIST]: addAzureFileList,
    [ADD_ACTIVE_AZURE_CONTAINER]: addActiveAzureContainer,
    [SELECT_AZURE_FILE]: selectAzureFile,
    [UNSELECT_AZURE_FILE]: unselectAzureFile,
    [DELETE_SELECTED_AZURE_FILES]: deleteSelectedAzureFiles,
};

export default (state = initialState, action) => {
    const handler = handlers[action.type];
    const newState = handler ? handler(state, action.payload) : state;
    return newState;
};
