import {
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
  AccountInfo,
  RedirectRequest,
  EndSessionRequest,
  EventType
} from '@azure/msal-browser';
import { writeStorage } from '@rehooks/local-storage';
import _ from 'lodash';

import { msalConfig, loginRequest, loginWithMailRequest } from './config';
import store, {
  setIsLoginRequired,
  setIsLoginRequestForMail
} from '../../../redux';

export interface TokenInfo {
  idToken?: string | null;
  accessToken?: string | null;
  expiresOn?: Date | null;
  redirect?: boolean;
}

/**
 * AuthModule for application - handles authentication in app.
 */
export class MsAuthModule {
  private myMSALObj: PublicClientApplication;
  private loginRedirectRequest: RedirectRequest;

  constructor() {
    this.myMSALObj = new PublicClientApplication(msalConfig);
    this.loginRedirectRequest = {
      ...loginRequest,
      prompt: 'select_account',
      redirectUri: process.env.REACT_APP_REDIRECT_URI_MICROSOFT
    };
  }

  private getAccount(): AccountInfo | null {
    const account = this.myMSALObj.getActiveAccount();
    return account;
  }

  loadAuthModule(): void {
    this.myMSALObj.addEventCallback((event) => {
      // set active account after redirect
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        _.get(event.payload, 'account')
      ) {
        const account = _.get(event.payload, 'account');
        this.myMSALObj.setActiveAccount(account);
      }
    });

    this.myMSALObj
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        if (!resp) {
          const accounts = this.myMSALObj.getAllAccounts();
          const isLoginRequired = store.getState().authorization.isLoginRequired;
          const isLoginStarted = localStorage.getItem('isLoginStarted');
          const isMail = store.getState().authorization.setIsLoginRequestForMail;
          if ((accounts.length === 0 || isLoginRequired) && isLoginStarted) {
            const account = accounts.length ? accounts[0] : null;
            const request = isMail
              ? {
                  ...loginWithMailRequest,
                  ...(account && { account: account }),
                  ...(!account && { prompt: 'select_account' })
                }
              : {
                  ...loginRequest,
                  ...(account && { account: account }),
                  ...(!account && { prompt: 'select_account' })
                };
            // No user signed in
            writeStorage('isLoginStarted', false);
            this.myMSALObj.loginRedirect(request);
          }
        } else {
          writeStorage('isLoginInProgress', true);
          this.handleResponse(resp);
        }
      })
      .catch(console.error);
  }

  handleResponse(response: AuthenticationResult | null) {
    if (response !== null) {
      let idToken: string | null = _.get(response, 'idToken', null);
      writeStorage('msToken', idToken);
    }
  }

  login(): void {
    this.myMSALObj
      .loginRedirect(this.loginRedirectRequest)
      .catch(console.error);
      writeStorage('isLoginInProgress', true);
  }

  logout(): void {
    const userEmail: string = window.localStorage.authEmail;
    let account: AccountInfo | null =
      this.myMSALObj.getAccountByUsername(userEmail);
    const logoutRequest: EndSessionRequest = {
      account
    };
    this.myMSALObj.logoutRedirect(logoutRequest);
  }

  getIdToken = async (isMail = false): Promise<TokenInfo | null> => {
    const account: AccountInfo | null = this.getAccount();
    try {
      if (!account) throw new Error('login_required');
      const mailScopes = ['email', 'Mail.Read', 'Mail.Send', 'Mail.ReadWrite'];

      const request: SilentRequest = {
        scopes: isMail ? [...['User.Read'], ...mailScopes] : ['User.Read'],
        account: account
      };

      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      let silentResult: AuthenticationResult =
        await this.myMSALObj.acquireTokenSilent(request);
      return {
        idToken: _.get(silentResult, 'idToken', null),
        accessToken: _.get(silentResult, 'accessToken', null),
        expiresOn: _.get(silentResult, 'expiresOn', null)
      };
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        const isLoginStarted = localStorage.getItem('isLoginStarted');
        if (!isLoginStarted) {
          writeStorage('isLoginStarted', true);
          store.dispatch(setIsLoginRequired(true));
          if(isMail) {
            store.dispatch(setIsLoginRequestForMail(true));
          }
        }
        return { redirect: true };
      } else {
        throw err;
      }
    }
  };

  isInteractionRequired(error: Error): Boolean {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1 ||
      error.message.indexOf('no_account_in_silent_request') > -1
    );
  }
}
