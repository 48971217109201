import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getUser } from '../../redux';
import { TabContent } from 'reactstrap';
import { ListModal } from '../../components';
import useLocalStorage from '@rehooks/local-storage';
import { getRenderedMeta, updateFormatList } from '../../utils';
import { TabsBar } from '../../pages/DetailsPage/components';
import { TotalsComponent } from './components';
import { IUser } from '../../types';

const SalesDashboard = ({ user, ...props }: { user: IUser }) => {
  const [metadataList] = useLocalStorage('metadatasList');
  const [tableFormatList] = useLocalStorage('tableFormatList');
  const location: any = useLocation();
  const [showListModal, setShowListModal] = useState({
    show: false,
    listMeta: {}
  });
  const pageData = window.location.pathname
    .replaceAll('/', '')
    .replace('-', ' ')
    .split(' ');

  const tabHashId: any =
    window.location.hash !== '' ? window.location.hash.substring(1) : '0';
  const [defaultTabsSet] = useState([]);
  const [tabs, setTabs] = useState(defaultTabsSet);
  const [customActiveTab, setCustomActiveTab] = useState(
    tabs[tabHashId] ? tabHashId : '0'
  );
  const [filterId, setFilterId] = useState('');
  const filterById = (user: { id: string }) => {
    setFilterId(user.id);
    setShowListModal({ ...showListModal, show: false });
  };

  useEffect(() => {
    (async () => {
      const layoutMeta = await getRenderedMeta(
        metadataList,
        `Frm.${pageData[0]}.json`
      );
      const meta = layoutMeta.data.form;
      if (meta.modalList && location?.state?.showListModal) {
        let listMeta: any = _.find(tableFormatList, [
          'formatName',
          'cdmusers.Standard'
        ]);
        if (!tableFormatList || !listMeta) {
          const result = await updateFormatList(
            'cdmusers.Standard',
            'cdmusers.Standard',
            tableFormatList
          );
          listMeta = result[0];
        }
        setShowListModal({
          show: true,
          listMeta: {
            ...listMeta,
            dataSource: 'cdmusers',
            format: 'Standard'
          }
        });
      }
      if (!meta.modalList && meta.filterExpression) {
        filterById(user);
      }
      setTabs(meta.tab);
    })();
  }, [location.state]);
  return (
    <>
      {showListModal.show && (
        <ListModal
          form={'lst.cdmusers.Standard.json'}
          listMeta={showListModal.listMeta}
          filterExpression={''}
          setFilterExpression={() => {}}
          showModal={showListModal.show}
          setShowModal={setShowListModal}
          defaultHandler={filterById}
        />
      )}
      <div className='editPage-container dashboard-tab pt-2'>
        <div className={'col'} style={{ alignItems: 'start' }}>
          <TabsBar
            tabs={tabs}
            customActiveTab={customActiveTab}
            setCustomActiveTab={setCustomActiveTab}
            pageName={pageData[0]}
            setTabs={setTabs}
            getTabsSorted={() => tabs}
            defaultTabsSet={tabs || []}
          />
          <TabContent className={`col-sm-12`} activeTab={customActiveTab}>
            {tabs.map((tab: any, index: number) => {
              if (index === parseInt(customActiveTab)) {
                const mainGroup = tab.mainGroup ? tab.mainGroup[0] : tab;
                return (
                  <TotalsComponent layoutMeta={mainGroup} filterId={filterId} />
                );
              }
            })}
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: getUser(state)
});

export default withRouter<any & RouteComponentProps<{}>, any>(
  connect<any, any>(mapStateToProps)(SalesDashboard) as any
);
