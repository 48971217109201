import React, { useEffect, useState, FC, Fragment } from 'react';
import {
  Row,
  Button,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import SelectionModal from './SelectionModal';
import { useLocalStorage } from '@rehooks/local-storage';
import { dispatcher } from '../../helpers/dispatcher';

import {
  getScreenSize,
  getSelectedData,
  getSortedData,
  getUser,
  addDataAction,
  selectAll,
  setCatchedError,
  getGridData
} from '../../redux';
import { DialogModal, AlertBar } from '..';
import { useApolloClient } from 'react-apollo';
import { getRenderedMeta, redirectToDetail } from '../../utils';
import {
  IGenericToolbar,
  IToolbarItem,
  ISelectedFullGridFormat,
  AlertBarOptions,
  TFieldsValues
} from '../../types';
import { IconWrapper } from '../../components';
import { toggleButtonStatus, resizeToolbar, getPagePath } from './functions';
import { ToolbarClass } from './ToolbarClass';
import { genericQuery } from '../../GraphQL';

const handlers: TFieldsValues = require('./handlers');
const classes: TFieldsValues = require('../../classes');

interface IGenericToolbarWithHistory extends IGenericToolbar {
  history: RouteComponentProps['history'] | any;
  location: RouteComponentProps['location'];
  match: RouteComponentProps['match'];
}

const GenericToolbar: FC<IGenericToolbarWithHistory> = (
  {
    toolbar,
    fullGridFormats,
    selectFormat,
    setShowModal,
    selectAllItems,
    selectedFullGridFormat,
    dataSource,
    screenSize,
    selectedData,
    grid,
    fullGridData,
    miniGridData,
    refetch,
    filterExpression,
    history,
    user,
    setPrintData,
    selectedAll,
    defaultHandlers,
    pageOrigin,
    gridData
  }: IGenericToolbarWithHistory,
  props
) => {
  const apolloClient = useApolloClient();
  const [metadatasList] = useLocalStorage('metadatasList');
  const [fieldsUpdated, setfieldsUpdated] = useState(false);
  const [detailScrollForward, setDetailScrollForward] = useState<boolean>(true);

  const formType = history.location.pathname.toLowerCase();
  const [pagePath, setPagePath] = useState(getPagePath());
  const defaultDataSet: any = (miniGridData && miniGridData.length > 0
    ? miniGridData
    : fullGridData) || {
    //@ts-ignore
    [selectedFullGridFormat.name]: []
  };
  const [itemIndex, setItemIndex] = useState(
    formType.includes('detail') && (history?.location?.state?.itemPosition || 0)
  );

  const [confirmationWindow, setConfirmationWindow] = useState({
    show: false,
    meta: {},
    handlers: {}
  });
  const selectionData = selectedData || [];
  const selectedItems =
    (formType.includes('overview')
      ? selectedData
      : [{ id: getPagePath()[1] }]) || [];

  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });
  const [selectionModal, setSelectionModal] = useState<any>({
    show: false
  });
  const toolbarClass: TFieldsValues = new ToolbarClass(
    apolloClient,
    formType,
    defaultDataSet,
    selectAll,
    setConfirmationWindow,
    history,
    metadatasList, //@ts-ignore
    grid,
    setCatchedError,
    selectedFullGridFormat
  );
  const handlerProps = {
    setShowModal,
    apolloClient,
    selectedItems,
    dataSource,
    selected: [{ id: pagePath[1] }],
    refetch,
    selectAllItems,
    addDataAction,
    miniGridData,
    itemIndex,
    setItemIndex,
    redirectToDetail,
    filterExpression,
    selectedData,
    user,
    selectedAll,
    setPrintData,
    defaultValues: {},
    pageOrigin,
    setSelectionModal,
    setAlertBarOptions,
    history,
    setConfirmationWindow
  };
  const callHandler = async (buttonItem: IToolbarItem) => {
    const { handler } = buttonItem;
    if (buttonItem.class && buttonItem.method && classes[buttonItem.class]) {
      const currentClass = new classes[buttonItem.class]({
        apolloClient,
        selectedItems,
        refetch,
        setSelectionModal,
        setAlertBarOptions,
        history,
        setDialogWindowProps: setConfirmationWindow,
        setCatchedError
      });
      currentClass[buttonItem.method]();
    } else if (handler) {
      if (handler === 'saveData') {
        dispatcher.dispatch('saveData', handler);
        return;
      }
      if (handler === 'delete') {
        const dialogName = buttonItem?.selectDialog || 'ConfirmDelete';
        let dialogMeta: any = await getRenderedMeta(
          metadatasList,
          `Dlg.${dialogName}.json`
        );
        dialogMeta &&
          setConfirmationWindow({
            show: true,
            meta: dialogMeta.data?.form,
            handlers: {
              calcel: () =>
                setConfirmationWindow({ show: false, meta: {}, handlers: {} }),
              [handler]: () => toolbarClass[handler](handlerProps)
            }
          });
        return;
      }
      if (toolbarClass[handler]) {
        if (handler === 'create') {
          const ids = handlerProps.selected[0].id || [];
          handlers.updateCapabilityState({
            step: 0,
            method: `${dataSource?.slice(0, -1)}.onNew`,
            args: {},
            ids
          });
          const resDefaultValues = await handlers.callMethodFromBackend(
            () => {}
          );
          if (
            resDefaultValues &&
            resDefaultValues.defaultValues &&
            !_.isEmpty(resDefaultValues.defaultValues)
          ) {
            handlerProps.defaultValues = resDefaultValues.defaultValues;
          }
        }
        toolbarClass[handler](handlerProps);
      } else if (defaultHandlers && defaultHandlers[handler]) {
        defaultHandlers[handler](handlerProps);
      } else if (handlers[handler]) {
        handlers[handler]({ ...handlerProps, buttonItem });
      } else {
        console.log(handler);
      }
    } else {
      handlers.openModalHandler({
        buttonItem,
        selectedData,
        setSelectionModal,
        setConfirmationWindow,
        setAlertBarOptions,
        apolloClient
      });
    }
  };

  const selectFullGridItem = (itemIndex: number, navDirection: string) => {
    const { state } = history?.location;
    let filter = state?.filterExpression;
    const { dataStorageId } = state;
    if (itemIndex < 0) {
      filter = !filter.includes('orderByDesc')
        ? filter.replace('orderBy', 'orderByDesc')
        : filter;
      setDetailScrollForward(false);
    }
    if (!detailScrollForward && itemIndex === 0) {
      filter = filter.includes('orderByDesc')
        ? filter.replace('orderByDesc', 'orderBy')
        : filter;
      setDetailScrollForward(true);
    }
    let pathname = window.location.pathname;
    let itemPosition = itemIndex;
    const currentId = pagePath[1].split('#')[0];
    if (gridData && gridData[dataStorageId]) {
      const { data } = gridData[dataStorageId];
      const direcitonValue =
        navDirection !== '' && navDirection === 'navigation-right' ? 1 : -1;
      itemPosition =
        data.findIndex((item: any) => item.id === currentId) + direcitonValue;
      const itemId =
        gridData[dataStorageId].data[itemPosition]?.id || currentId;
      pathname = `${pagePath[0]}-${itemId}`;
    }
    const query = genericQuery(dataSource || '', [''], filter, '', 1);
    apolloClient
      .query({ query, variables: { offset: itemPosition } })
      .then((res: any) => {
        if (dataSource) {
          const items = res.data[dataSource];
          items &&
            items[0] &&
            history.push({
              pathname: `${pagePath[0]}-${items[0].id}`,
              state: {
                ...state,
                id: items[0].id,
                filterExpression: filter,
                itemPosition,
                ignoreBreadCrumbs: true,
                dataSource
              }
            });
        }
      });
  };

  useEffect(() => {
    setPagePath(
      history.location.pathname.replaceAll('/', '').replace('-', ' ').split(' ')
    );
  }, [history.location.pathname]);

  useEffect(() => {
    dispatcher.on('fieldsUpdated', (updated: boolean) => {
      setfieldsUpdated(updated);
    });
  }, [history?.location?.state?.itemPosition]);

  const renderFormatSelector = (
    selectedFullGridFormat: ISelectedFullGridFormat,
    caption: string | undefined
  ) => {
    return (
      <Form.Group className='mr-3'>
        <label htmlFor={'fullGrid-format-select'}>{caption || ''}</label>
        <Form.Control
          as='select'
          id='fullGrid-format-select'
          onChange={(e) => {
            selectFormat && selectFormat(e.target.value);
          }}
        >
          {fullGridFormats?.formats.map(({ caption }: { caption: string }) => (
            <option selected={selectedFullGridFormat.name === caption}>
              {caption}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  };
  const renderDropdownItem = (item: IToolbarItem) => {
    const { type, caption, selection } = item;
    if (type === 'divider') {
      return <Dropdown.Divider />;
    }
    if (type === 'selectFormat') {
      return (
        <div style={{ padding: '0px 15px' }}>
          {
            //@ts-ignore
            renderFormatSelector(selectedFullGridFormat, caption)
          }
        </div>
      );
    }
    if (
      screenSize === 'XS' &&
      (selection === 'multi' || selection === 'single')
    ) {
      return;
    }
    if (!type || type === 'btn') {
      const disabled = selection
        ? toggleButtonStatus(selection, selectionData.length)
        : false;
      return (
        <Dropdown.Item
          style={{
            pointerEvents: disabled && 'none',
            color: disabled && 'grey'
          }}
          onClick={(e: any) => {
            if (disabled) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }
            callHandler(item);
          }}
        >
          {item.icon && <i className={`${item.icon} btn-icon`}></i>}
          {item.caption}
        </Dropdown.Item>
      );
    }
  };
  const renderToolbarButtons = () => {
    //@ts-ignore
    const { toolbarItems, dropdownItems } = resizeToolbar(toolbar, screenSize);
    return (
      <>
        {toolbarItems.map((toolbarItem: IToolbarItem) => {
          const { caption, type, icon, selection, handler, group } =
            toolbarItem;
          if (
            group &&
            type === 'navigation' &&
            history?.location?.state?.filterExpression &&
            !history.location.pathname.includes('-create')
          ) {
            return (
              <Row>
                {group.map((button: IToolbarItem, btnIndex: number) => (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      button.tooltip ? (
                        <Tooltip id='tooltip'>{button.tooltip}</Tooltip>
                      ) : (
                        <span></span>
                      )
                    }
                  >
                    <Button
                      className={'mr-2 toolbar-button'}
                      id={button.id}
                      onClick={() => {
                        const itemIndex =
                          history?.location?.state?.itemPosition || 0;
                        const newIndex =
                          btnIndex === 0 ? itemIndex - 1 : itemIndex + 1;
                        selectFullGridItem &&
                          selectFullGridItem(newIndex, button.id || '');
                      }}
                    >
                      <IconWrapper iconName={button.icon} iconSize={24} />
                    </Button>
                  </OverlayTrigger>
                ))}
              </Row>
            );
          }
          if (type === 'btn') {
            return (
              <Button
                disabled={
                  handler === 'saveData'
                    ? !fieldsUpdated
                    : selection
                    ? toggleButtonStatus(selection, selectionData.length)
                    : false
                }
                className='mr-3 toolbar-button'
                onClick={() => callHandler(toolbarItem)}
              >
                {icon && <IconWrapper iconName={icon} iconSize={24} />}
                <span>{caption}</span>
              </Button>
            );
          }
          if (type === 'selectFormat') {
            //@ts-ignore
            return renderFormatSelector(selectedFullGridFormat, caption);
          }
          if (type === 'dropdown') {
            return (
              <Dropdown>
                <Dropdown.Toggle
                  id='dropdown-basic'
                  className={'toolbar-button'}
                >
                  {icon && <IconWrapper iconName={icon} iconSize={24} />}
                  <span>{caption}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-menu'>
                  {toolbarItem.entries &&
                    toolbarItem?.entries.map((item: IToolbarItem) =>
                      renderDropdownItem(item)
                    )}
                </Dropdown.Menu>
              </Dropdown>
            );
          }
        })}
        {dropdownItems.length > 0 && (
          <Dropdown id='dropdown'>
            <Dropdown.Toggle
              id='dropdown-basic'
              className={'toolbar-button'}
            ></Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
              {dropdownItems.map((item: IToolbarItem) =>
                renderDropdownItem(item)
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  };
  return (
    <Fragment>
      {alertBarOptions.show && (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      )}
      {selectionModal.show && (
        <SelectionModal
          selectionMeta={selectionModal}
          setModal={setSelectionModal}
          setDialogModal={setConfirmationWindow}
        />
      )}
      <Row className='fullGrid-topbar'>
        {confirmationWindow.show && (
          <DialogModal
            showModal={confirmationWindow.show}
            setShowModal={() =>
              setConfirmationWindow({
                show: false,
                meta: {},
                handlers: {}
              })
            }
            handlers={confirmationWindow.handlers}
            meta={confirmationWindow.meta}
          />
        )}
        {renderToolbarButtons()}
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  screenSize: getScreenSize(state),
  selectedData: getSelectedData(state),
  fullGridData: getSortedData(state),
  user: getUser(state),
  gridData: getGridData(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addDataAction,
      selectAll,
      setCatchedError
    },
    dispatch
  );
};

export default withRouter<
  IGenericToolbarWithHistory & RouteComponentProps<{}>,
  any
>(
  connect<any, any>(mapStateToProps, mapDispatchToProps)(GenericToolbar) as any
);
