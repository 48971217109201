import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { apolloLink } from './utils';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import App from './App.tsx';
import store from './redux';
import './index.css';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloLink,
  defaultOptions: defaultOptions,
});
ReactDOM.render(
  <BrowserRouter>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.unregister();
