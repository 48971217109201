import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Upload,
  Download,
  ArrowRepeat,
  ArrowRightShort,
  Plus
} from 'react-bootstrap-icons';

import { formatBytes } from '../../utils';
import { FileManagerTable } from './components';
import { API } from '../../API';
import AlertBar from '../../components/AlertBar';
import {
  addAzureFileList,
  getAzureFileList,
  addActiveAzureContainer,
  getActiveAzureContainer,
  deleteSelectedAzureFiles,
  getSelectedAzureFiles
} from '../../redux';
import * as handlers from './components/handlers';
import { AlertBarOptions } from '../../types';

const FileManagerAzure = ({ history, ...props }: any) => {
  const [selectedAll, selectAll] = useState<boolean>(false);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [permissionArr, setPermissionArr] = useState<string[]>([]);

  useEffect(() => {
    if (null !== localStorage.getItem('permission')) {
      setPermissionArr(localStorage.getItem('permission')!.split(','));
    }
    const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
    const headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      typeauthorization: localStorage.getItem('authType')
    };
    api
      .getRestMetadataStorageGetFileList({ headers })
      .then((resMetadataFiles) => {
        api.getRestFileStorageGetFileList({ headers }).then((resUserFiles) => {
          const azureList = [...resMetadataFiles.data, ...resUserFiles.data];
          props.addAzureFileList(azureList);
          if (azureList.length === 1) {
            props.addActiveAzureContainer(azureList[0].name);
          }
        }).catch((err) => {
          setAlertBarOptions({
            show: true,
            alertText: err,
            variant: 'danger'
          });
        });
      })
      .catch((err) => {
        setAlertBarOptions({
          show: true,
          alertText: err,
          variant: 'danger'
        });
      });
  }, []);

  useEffect(() => {
    if (
      props.activeAzureContainer === 'metadata-sys' &&
      !permissionArr.includes('system')
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [permissionArr, props.activeAzureContainer]);

  const handleClickUploadFile = () => {
    if (null !== hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChangeUploadFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    if (
      parseFloat(fileUploaded.size) >
      parseFloat(process.env.REACT_APP_MAX_FILE_SIZE ?? '')
    ) {
      setAlertBarOptions({
        show: true,
        alertText: `The file must not be bigger than ${formatBytes(
          process.env.REACT_APP_MAX_FILE_SIZE
        )}`,
        variant: 'danger'
      });
      return;
    }
    uploadFileAzureBlob(fileUploaded);
  };

  const uploadFileAzureBlob = async (file: File) => {
    try {
      const successAlert = {
        show: true,
        alertText: 'Success!',
        variant: 'success'
      };
      let resultUpload;
      if (props.activeAzureContainer === 'metadata-sys') {
        resultUpload = await handlers.uploadMetadataHandler(file);
      } else {
        resultUpload = await handlers.uploadHandler(file);
      }

      const { status, statusMessage } = resultUpload;

      if (status) {
        setAlertBarOptions(successAlert);
        handleRefresh();
        setTimeout(() => {
          setAlertBarOptions({ ...successAlert, show: false });
        }, 3500);
      } else {
        setAlertBarOptions({
          show: true,
          alertText: statusMessage,
          variant: 'danger'
        });
      }
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleRefresh = async () => {
    try {
      await handlers.refreshHandler();
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleDownloadFiles = async () => {
    let res;
    try {
      if (props.activeAzureContainer === 'metadata-sys') {
        res = await handlers.downloadMetadataFileHandler(
          props.selectedAzureFiles.join(',')
        );
      } else {
        res = await handlers.downloadFileHandler(
          props.selectedAzureFiles.join(',')
        );
      }
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;

      const fileName =
        props.selectedAzureFiles.length > 1
          ? `${props.activeAzureContainer}.zip`
          : `${props.selectedAzureFiles[0]}`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleShowFolderList = () => {
    props.addActiveAzureContainer(null);
    props.deleteSelectedAzureFiles();
    selectAll(false);
  };

  const handleCreateNewFile = () => {
    history.push('/fileEditor');
  };

  return (
    <Fragment>
      {alertBarOptions.show ? (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      ) : null}

      <div className='content-wrapper' tabIndex={0} style={{ outline: 'none' }}>
        <Row
          className='fullGrid-topbar border'
          style={{ paddingTop: '10px', padding: '10px 20px' }}
        >
          {props.selectedAzureFiles.length ? (
            <Fragment>
              <div>
                <Button
                  onClick={handleDownloadFiles}
                  style={{ marginRight: '15px' }}
                  disabled={disabledButton}
                >
                  <div>
                    <Download />
                    <span className='primary-button-content'>
                      Download files
                    </span>
                  </div>
                </Button>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Fragment>
                <Col>
                  <Button
                    onClick={handleClickUploadFile}
                    disabled={!props.activeAzureContainer || disabledButton}
                  >
                    <div>
                      <Upload />
                      <span className='primary-button-content'>
                        Upload files
                      </span>
                    </div>
                  </Button>
                  {props.activeAzureContainer === 'metadata-sys' && (
                    <Button
                      onClick={handleCreateNewFile}
                      style={{ marginLeft: '15px' }}
                      disabled={disabledButton}
                    >
                      <div>
                        <Plus />
                        <span className='primary-button-content'>
                          Create new
                        </span>
                      </div>
                    </Button>
                  )}
                  <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleChangeUploadFile}
                    style={{ display: 'none' }}
                  />
                </Col>
              </Fragment>
              <Button onClick={handleRefresh}>
                <div>
                  <ArrowRepeat />
                </div>
              </Button>
            </Fragment>
          )}
        </Row>
        <Row>
          <Col>
            <Row className='border' style={{ padding: '10px' }}>
              <Button onClick={handleShowFolderList} variant='light'>
                Files
              </Button>
              {props.activeAzureContainer && (
                <Fragment>
                  <ArrowRightShort />
                  <Button variant='light'>{props.activeAzureContainer}</Button>
                </Fragment>
              )}
            </Row>
            <Row>
              <FileManagerTable
                selectAll={selectAll}
                selectedAll={selectedAll}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  azureFileList: getAzureFileList(state),
  activeAzureContainer: getActiveAzureContainer(state),
  selectedAzureFiles: getSelectedAzureFiles(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addAzureFileList,
      addActiveAzureContainer,
      deleteSelectedAzureFiles
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileManagerAzure));
