import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { ExcelReader } from '../../../pages';
import { BrowserList } from '../../../components';

const ImportData = ({ layoutMeta }) => {
  const [importVisibility, setImportVisibility] = useState(false);
  const [importFormat, setImportFormat] = useState(null);
  const [showImportList, setShowImportList] = useState(null);
  const { caption, imports } = layoutMeta;
  const selectTable = (formats) => {
    setImportFormat(formats);
    setShowImportList(null);
    setImportVisibility(true);
  };

  return (
    <Col className='tab-component'>
      {showImportList && (
        <BrowserList
          list={showImportList}
          setList={setShowImportList}
          formType={'Formats'}
          onClickAction={selectTable}
          header={'Select table'}
        />
      )}
      <Row className={`edit-wrapper`}>
        <Col>
          <Row className='details-caption'>
            <h3>{caption}</h3>
          </Row>
        </Col>
      </Row>
      <div className='edit-input-fields'>
        <Row>
          {imports.map(({ caption, selectFormat }) => {
            return (
              <Col sm={4}>
                <Button
                  className={'m-2 w-100'}
                  onClick={() => {
                    setImportVisibility(false);
                    setImportFormat(selectFormat);
                    setShowImportList(
                      require(`../../../Metadata/Frm.${selectFormat}.json`).data
                        .form.fields
                    );
                  }}
                >
                  {caption}
                </Button>
              </Col>
            );
          })}
        </Row>
      </div>
      {importVisibility && <ExcelReader selectedMeta={importFormat} />}
    </Col>
  );
};

export default ImportData;
