import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { TFieldsValues } from '../../types';
import { Table } from '../../components';
const handlers: TFieldsValues = require('./handlers');

const SelectionModal = (props: any) => {
  const { selectionMeta, setModal, setDialogModal } = props;
  const {
    show,
    fields,
    options,
    onSelectProperties,
    confirmationMeta,
    onConfirmHandler,
    defaultHandler,
    requireConfirmationMeta
  }: any = selectionMeta;

  const history = useHistory();

  return (
    <Modal
      show={show}
      size={'lg'}
      className='editing-modal'
      onHide={() => setModal(false)}
    >
      <Modal.Header closeButton onClick={() => setModal(false)}></Modal.Header>
      <Modal.Body style={{ overflow: 'hidden' }} className='editing-modal-body'>
        <div className={'listModal_table '}>
          <Table
            metadata={{}}
            scrollAction={() => {}}
            tableFormat={{
              fields
            }}
            itemClick={(option: any) => {
              if (requireConfirmationMeta) {
                setDialogModal({
                  show: true,
                  meta: confirmationMeta,
                  handlers: {
                    confirm: () => {
                      const result = handlers[defaultHandler]({
                        ...onSelectProperties,
                        option
                      });
                      result !== 'Error' && onConfirmHandler();
                    },
                    cancel: () => {
                      handlers[defaultHandler]({
                        ...onSelectProperties,
                        option
                      });
                    }
                  }
                });
              } else {
                onConfirmHandler({
                  ...onSelectProperties,
                  option,
                  ...{ history }
                });
              }
              setModal(false);
            }}
            heightStyles={{
              maxHeight: '100%',
              minHeight: '10vh'
            }}
            data={options}
            hasMore={false}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectionModal;
