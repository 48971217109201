export const documentExtensionIcon = (docExtension: string) => {
  if (docExtension.includes('xls') || docExtension === 'csv') {
    return 'mdi-file-excel-outline';
  }
  if (docExtension === 'txt') {
    return 'mdi-file-document-outline';
  }

  if (docExtension.includes('doc')) {
    return 'mdi-file-word-outline';
  }
  if (docExtension === 'pdf') {
    return 'mdi-file-pdf-outline';
  }
  if (docExtension === 'msg') {
    return 'mdi-email-outline';
  }
  if (
    docExtension === 'png' ||
    docExtension === 'jpeg' ||
    docExtension === 'jpg'
  ) {
    return 'mdi-file-image-outline';
  }
  return 'mdi-file-outline';
};

export const getResponsiveCellWidth = (widthValue: number | undefined) =>
  widthValue
    ? `${(widthValue * window.innerWidth) / (window.innerWidth + widthValue)}px`
    : '6em';
