import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as pages from '../pages';
import * as components from '../components/';
import { flattenObject } from '../utils';
const GenericRouter = (props) => {
  const { menuAttributes } = props;
  const attributes = menuAttributes.map((obj) => obj.attributes).flat();
  const location = useLocation();
  try {
    const attributesFlatten = flattenObject(attributes);
    const forms = Object.entries(attributesFlatten).map(([key, value]) => {
      if (key.includes('.form')) {
        return value;
      }
    });
    const routes = forms.map((form, key) => {
      if (form) {
        let component = components.NotFound;
        if (components[form]) {
          component = components[form];
        } else if (pages[form]) {
          component = pages[form];
        } else {
          component = pages.FullGrid;
        }

        if (
          // Temporary solution
          form === 'SalesHome' ||
          form === 'SalesQuotationOverview'
        ) {
          component = components.NotFound;
        }
        if (form.includes('AppointmentCalendar')) {
          component = pages.AppointmentCalendar;
        }
        //dashboard Solution
        if (form.includes('SalesDashboard')) {
          component = pages.SalesDashboard;
        }
        if (form === 'FileImport') {
          component = pages.ExcelReader;
        }
        return <Route path={`/${form}`} component={component} key={key} />;
      } else {
        if (location.pathname.includes('Overview')) {
          return (
            <Route
              path={location.pathname}
              component={pages.FullGrid}
              key={`${location.pathname}-${location.key}`}
            />
          );
        }
      }
    });
    return (
      <>
        <Switch>
          <Route path='/auth' component={pages.Authorization} />
          <Route
            path='/contact-with-admin'
            component={pages.ContactAdministrator}
          />
          <Route path='/:category-:id' component={pages.DetailsPage} />
          <Route path='/SystemSettings' component={pages.DetailsPage} />
          <Route path='/Shortcuts' component={components.HotkeyInfo} />
          <Route path='/fileExplorer' component={pages.FileManagerAzure} />
          <Route path='/buildQuery/:itemId?' component={pages.QueryBuilder} />
          <Route path='/fileEditor/:itemId?' component={pages.FileEditor} />

          {routes}
        </Switch>
      </>
    );
  } catch (err) {
    return <>{components.NotFound}</>;
  }
};

export default GenericRouter;
