import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import { useBreadcrumbs } from '../../helpers/hooks/useBreadcrumbs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getScreenSize } from '../../redux';

const Breadcrumb = ({ screenSize }: { screenSize: string }) => {
  const { breadcrumbs } = useBreadcrumbs();
  const location = useLocation();
  const [details] = useLocalStorage<any>('details');
  useEffect(() => {}, [breadcrumbs, location.pathname]);
  return (
    <div className='page-title-box'>
      <ol className='breadcrumb mb-0'>
        {details &&
          screenSize !== 'XS' &&
          details.map((breadcrumb: any) => (
            <li
              key={breadcrumb.link}
              className={`breadcrumb-item ${
                !breadcrumb.active && breadcrumb.link ? 'active' : null
              }`}
            >
              {breadcrumb.active && breadcrumb.link ? (
                <Link to={breadcrumb.link}>{breadcrumb.caption}</Link>
              ) : (
                <>{breadcrumb.caption}</>
              )}
            </li>
          ))}
      </ol>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  screenSize: getScreenSize(state)
});

export default connect(mapStateToProps)(Breadcrumb);
