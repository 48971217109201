import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Form, Col } from 'react-bootstrap';
import _ from 'lodash';

const DTValueCreatableSelect = ({
    value,
    type,
    onChange,
    enumList,
    isDisabled,
}) => {
    const [options, setOptions] = useState([]);
    const [activeOptions, setActiveOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (Array.isArray(value)) {
            const data = value.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            });
            if (data.length) {
                setActiveOptions(data);
            }
        }
    }, []);

    useEffect(() => {
        const copyOptions = [...options];
        copyOptions.push({ value: inputValue, label: inputValue });
        setOptions(copyOptions);
    }, [inputValue]);

    const handleChangeInput = (e) => {
        setInputValue(e.target.value);
    };

    const handleChangeSelect = (newValue, actionMeta) => {
        if (newValue) {
            const data = newValue.map((item) => item.value);
            setActiveOptions(newValue);
            onChange(data);
        } else {
            setActiveOptions('');
            onChange([]);
        }
    };

    return (
        <Col>
            <Form.Group style={{ marginBottom: '15px' }}>
                <Form.Control
                    type={type}
                    value={inputValue}
                    onChange={(e) => handleChangeInput(e)}
                />
            </Form.Group>
            <Select
                isSearchable
                isMulti
                placeholder={'Select values'}
                onChange={handleChangeSelect}
                options={options}
                value={activeOptions}
                isDisabled={isDisabled}
            />
        </Col>
    );
};

export default DTValueCreatableSelect;
