import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import useLocalStorage from '@rehooks/local-storage';
import {
  updateCapabilityState,
  callMethodFromBackend
} from '../../../components/GenericToolbar/handlers';
import { updateFormatList, getRenderedMeta } from '../../../utils';
import DashboardBox from './DashboardBox';

interface ITotalsField {
  format: string;
  form: string;
  filterExpression: string;
  dataSource: string;
}
interface ITotalsComponent {
  dataSource: string;
}

const TotalsComponent = ({
  layoutMeta,
  filterId
}: {
  layoutMeta: any;
  filterId: string;
}) => {
  const history = useHistory();
  const [tableFormatList] = useLocalStorage('tableFormatList');
  const [metadataList] = useLocalStorage('metadatasList');
  const [dashboardData, setDashBoardData] = useState();

  const redirectToFullGrid = async (
    field: ITotalsField,
    component: ITotalsComponent
  ) => {
    const { format, form, dataSource } = field;
    const filterExpression = `ids:[${filterId ? `"${filterId}"` : ''}]`;
    const formatName = `${component.dataSource}.${format}`;
    if (!_.find(tableFormatList, ['formatName', formatName])) {
      await updateFormatList(component.dataSource, formatName, tableFormatList);
    }
    await getRenderedMeta(metadataList, `Frm.${form}.json`);
    history.push({
      pathname: `/${form}`,
      state: {
        defaultFilterExpression: filterExpression.replaceAll(
          'getCurrentDate',
          `"${moment(new Date()).format('yyyy-MM-DD')}"`
        ),
        defaultDataSource: dataSource,
        defaultFormat: formatName
      }
    });
  };

  useEffect(() => {
    (async () => {
      const ids = filterId ? [filterId] : [];
      updateCapabilityState({
        method: 'SPPLDashboard.capabilityGetSalesNumbers',
        ids
      });
      const { code, data } = await callMethodFromBackend(() => {});
      code === 200 && setDashBoardData(data.dashboardCounters);
    })();
  }, [filterId]);

  return (
    <>
      {layoutMeta &&
        dashboardData &&
        layoutMeta?.dataEntry.map((component: any) => {
          return (
            <Col className='dashboard-box_container' sm={3}>
              <DashboardBox
                data={dashboardData}
                component={component}
                onClick={async (field: ITotalsField) =>
                  await redirectToFullGrid(field, component)
                }
              />
            </Col>
          );
        })}
    </>
  );
};

export default TotalsComponent;
