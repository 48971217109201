import React, { Fragment, useState, useEffect } from 'react';
import { Col, Dropdown, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import {
  FolderFill,
  FileEarmarkFill,
  Download,
  Trash,
  ArrowRepeat
} from 'react-bootstrap-icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { formatBytes } from '../../../utils';
import { fileListFormat } from './fileListFormat';
import {
  addActiveAzureContainer,
  getActiveAzureContainer,
  selectAzureFile,
  unselectAzureFile,
  addMetadataForEdit
} from '../../../redux';
import { API } from '../../../API';
import * as handlers from './handlers';
import AlertBar from '../../../components/AlertBar';
import { AlertBarOptions, RefProps, RefType } from '../../../types';

const FileManagerTableRow = ({
  history,
  el,
  selectedAll,
  activeAzureContainer,
  addActiveAzureContainer,
  selectAzureFile,
  unselectAzureFile,
  addMetadataForEdit,
  onFileSelect
}: any) => {
  const [isSelected, setSelect] = useState<boolean>(false);
  const [alertBarOptions, setAlertBarOptions] = useState<AlertBarOptions>({
    show: false,
    alertText: '',
    variant: ''
  });
  const [permissionArr, setPermissionArr] = useState<string[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  useEffect(() => {
    if (null !== localStorage.getItem('permission')) {
      setPermissionArr(localStorage.getItem('permission')!.split(','));
    }
  }, []);

  useEffect(() => {
    if (
      activeAzureContainer === 'metadata-sys' &&
      !permissionArr.includes('system')
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [permissionArr, activeAzureContainer]);

  useEffect(() => {
    if (isSelected === selectedAll) {
      setSelect(!selectedAll);
      handleCheck();
    }
  }, [selectedAll]);
  const CustomToggle = React.forwardRef<RefType, RefProps>(
    ({ onClick }, ref: any) => (
      <Button
        className='dashboard-custom-toggle file-manager-custom-toogle'
        variant='outline-dark'
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <i className='icon-automation'></i>
      </Button>
    )
  );

  const rowContentGenerator = (element: any, attribute: any) => {
    let text = '';
    if (attribute === 'name') {
      text = _.get(element, 'name', '');
    } else if (attribute === 'modified') {
      if (_.get(element, 'modified', null)) {
        text = moment(_.get(element, 'modified', '')).format('MM-DD-YYYY');
      }
    } else if (attribute === 'size') {
      if (_.get(element, 'size', null)) {
        text = formatBytes(_.get(element, 'size', ''));
      }
    }
    if (_.get(element, 'isDirectory', false) && attribute === 'name') {
      return (
        <Fragment>
          <FolderFill />
          <span className='azure-file-manager-file-th-name-content'>
            {text.toString()}
          </span>
        </Fragment>
      );
    } else if (!_.get(element, 'isDirectory', false) && attribute === 'name') {
      return (
        <div className='azure-file-manager-file-th-content'>
          <div className='azure-file-manager-file-name'>
            <FileEarmarkFill />
            <span className='azure-file-manager-file-th-name-content'>
              {text.toString()}
            </span>
          </div>
          <Dropdown
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
              e.stopPropagation()
            }
            className='file-manager-custom-dropdown'
          >
            <Dropdown.Toggle
              as={CustomToggle}
              id='dropdown-custom-components'
            />
            <Dropdown.Menu className='file-manager-dropdown'>
              <Dropdown.Item
                onClick={handleDelete}
                className='file-manager-dropdown-item'
                as='button'
                disabled={activeAzureContainer === 'metadata-sys'}
              >
                <div className='file-manager-dropdown-item-content'>
                  <Trash style={{ backgroundColor: 'inherit' }} />
                  <span className='primary-button-content'>Delete</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleRefresh}
                className='file-manager-dropdown-item'
                as='button'
              >
                <div className='file-manager-dropdown-item-content'>
                  <ArrowRepeat style={{ backgroundColor: 'inherit' }} />
                  <span className='primary-button-content'>Refresh</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleDownloadFile}
                className='file-manager-dropdown-item'
                as='button'
                disabled={disabledButton}
              >
                <div className='file-manager-dropdown-item-content'>
                  <Download style={{ backgroundColor: 'inherit' }} />
                  <span className='primary-button-content'>Download</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return <Fragment>{text.toString()}</Fragment>;
    }
  };

  const handleClickFileItem = async (element: any) => {
    if (_.get(element, 'isDirectory', false)) {
      addActiveAzureContainer(element.name);
    } else {
      if (
        activeAzureContainer === 'metadata-sys' &&
        permissionArr.includes('system')
      ) {
        const elemExt = element.name.split('.').pop();
        if (elemExt === 'json' || elemExt === 'js') {
          const api = new API(`${process.env.REACT_APP_ENDPOINT_URL}`);
          const headers = {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            typeauthorization: localStorage.getItem('authType')
          };
          onFileSelect();
          try {
            let data = await api.getRestMetadataStorageGetMetadataForEditing({
              headers,
              metadataNameList: element.name
            });
            addMetadataForEdit(data.data);
            history.push({
              pathname: '/fileEditor',
              search: `?itemId=${_.get(element, 'name', '')}`
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          setAlertBarOptions({
            show: true,
            alertText: 'Editing files not supported',
            variant: 'danger'
          });
        }
      }
    }
  };

  const handleRefresh = async () => {
    try {
      await handlers.refreshHandler();
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleDelete = async () => {
    try {
      const successAlert = {
        show: true,
        alertText: 'Success!',
        variant: 'success'
      };

      const { status, statusMessage } = await handlers.deleteHandler(el.name);

      if (status) {
        setAlertBarOptions(successAlert);
        handleRefresh();
        setTimeout(() => {
          setAlertBarOptions({ ...successAlert, show: false });
        }, 3500);
      } else {
        setAlertBarOptions({
          show: true,
          alertText: statusMessage,
          variant: 'danger'
        });
      }
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleDownloadFile = async () => {
    try {
      let res;
      if (activeAzureContainer === 'metadata-sys') {
        res = await handlers.downloadMetadataFileHandler(el.name);
      } else {
        res = await handlers.downloadFileHandler(el.name);
      }
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', el.name);
      document.body.appendChild(link);
      link.click();
    } catch (err: any) {
      setAlertBarOptions({
        show: true,
        alertText: err.message,
        variant: 'danger'
      });
    }
  };

  const handleCheck = () => {
    if (isSelected) {
      selectAzureFile(el.name);
    } else {
      unselectAzureFile(el.name);
    }
    setSelect(!isSelected);
  };

  return (
    <Fragment>
      {alertBarOptions.show ? (
        <AlertBar
          showAlertBar={alertBarOptions.show}
          hideAlertBar={() =>
            setAlertBarOptions({
              ...alertBarOptions,
              show: !alertBarOptions.show
            })
          }
          alertText={alertBarOptions.alertText}
          color={alertBarOptions.variant}
        />
      ) : null}
      <tr>
        <Col className='fullGrid-checkbox'>
          <th>
            <input
              type='checkbox'
              id={el.id}
              className='check-box fullGrid-checkbox'
              disabled={_.get(el, 'isDirectory', false)}
              checked={!isSelected}
              onChange={handleCheck}
            />
            <label htmlFor={el.id}></label>
          </th>
        </Col>
        {fileListFormat.map((field, ind) => {
          return (
            <Col sm={field.defCol}>
              <th
                key={`fileListFormat-${ind}`}
                onClick={() => handleClickFileItem(el)}
              >
                {rowContentGenerator(el, field.attribute)}
              </th>
            </Col>
          );
        })}
      </tr>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  activeAzureContainer: getActiveAzureContainer(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addActiveAzureContainer,
      selectAzureFile,
      unselectAzureFile,
      addMetadataForEdit
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FileManagerTableRow));
