import React from 'react';
import { useHistory } from 'react-router';
import { DropdownItem } from 'reactstrap';
import { getLocalStorageItem } from '../../../utils';
import { IconWrapper } from '../../../components';
const SubMenuDropdown = ({ entry, toolbarAction, checkPermission }: any) => {
  const itemEntries = entry.entriesSource
    ? getLocalStorageItem(entry.entriesSource)
    : entry.entries;
  const history = useHistory();
  return (
    <div className='btn-group dropleft'>
      <a
        className='btn dropdown-toggle dropdown-item'
        data-toggle={itemEntries && 'dropdown'}
        aria-haspopup='true'
        aria-expanded='false'
      >
        <IconWrapper
          className={'align-middle mr-1'}
          iconName={entry.icon}
          iconSize={16}
        />
        {entry.caption}
      </a>
      {itemEntries && itemEntries.length > 0 && (
        <div className='dropdown-menu'>
          {itemEntries.map((item: any) => (
            <DropdownItem
              key={`${item.caption}-${item.icon}`}
              tag='a'
              href='#'
              disabled={
                item.check_permission
                  ? !checkPermission(item.permission_key)
                  : false
              }
              onClick={() => {
                history.push({
                  pathname: `/${item.form}`,
                  state: item.state,
                  key: item?.state?.id
                });
                return;
              }}
            >
              <IconWrapper
                className={'align-middle mr-1'}
                iconName={item.icon}
                iconSize={16}
              />
              {item.caption}
            </DropdownItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubMenuDropdown;
