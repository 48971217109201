import React from 'react';
import _ from 'lodash';

const PrintPreview = ({ data, pageHeader }) => {
  const header = data[0];
  return (
    <div className='pdf-wrapper'>
      <div id='pdf-table' className='table-responsive'>
        {pageHeader && <h3 id='pdf-header'>{pageHeader || ''}</h3>}
        <table>
          <thead>
            <tr className='pdf-table-header'>
              {header.map((heading) => (
                <th>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((dataRow) => (
              <tr>
                {header.map((heading, i) => (
                  <td
                    className={_.isNumber(dataRow[i]) && 'pdf-table-numbercell'}
                  >
                    {dataRow[i].toString().substr(0, 23)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrintPreview;
