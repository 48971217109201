import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useApolloClient } from 'react-apollo';
import {
  flattenObject,
  checkValidation,
  divideNestedAttribute
} from '../../utils';
import { RegularView } from './Components';
import DetailedView from './Components/DetailedView';
import { dispatcher } from '../../helpers';
import { TFieldsValues } from '../../types';
import './editableFields.scss';
import { genericQuery } from '../../GraphQL';

const EditableFields = (props: {
  itemId: string;
  layoutMeta: any;
  fieldsValues: TFieldsValues;
  fieldClickHandler: Function;
  fetchData: Function;
  defaultEdits: TFieldsValues;
  saveTabChanges: Function;
  editedFields: TFieldsValues;
  setEditedFields: Function;
  renderAlertBar: Function;
}) => {
  const {
    itemId,
    layoutMeta,
    fieldsValues,
    fieldClickHandler,
    fetchData,
    defaultEdits,
    saveTabChanges,
    editedFields,
    setEditedFields,
    renderAlertBar
  } = props;
  const apolloClient = useApolloClient();
  const [renderedData, setRenderedData] = useState<TFieldsValues>({});
  const [mainId, setMainId] = useState(itemId);
  const history = useHistory();

  const saveChanges = (id: string) => {
    let metaFields = [];
    if (layoutMeta.tab) {
      layoutMeta.tab.map((tab: any) => {
        tab.mainGroup[0].group.map((component: any, i: number) => {
          if (component.component === 'DetailsComponent') {
            const { fields } = component.dataEntry;
            metaFields.push(...fields);
          }
        });
      });
    } else {
      metaFields = layoutMeta.dataEntry.fields;
    }
    const { validationPassed, validationError } = checkValidation(
      metaFields,
      renderedData
    );
    if (validationPassed) {
      saveTabChanges(id)
        .then((res: any) => {
          fetchData(id);
          setEditedFields({});
        })
        .catch((err: any) => {
          console.log(err);
          renderAlertBar(true, err.message, 'danger');
        });
    } else {
      renderAlertBar(true, 'Validation error: ' + validationError, 'danger');
    }
  };

  const onSaveData = ({ handler = 'saveData', fieldsValues }: any) => {
    let dataChanged = mainId === 'create' ? true : false;
    if (mainId !== 'create') {
      const originalData = flattenObject(fieldsValues);
      const updatedData = flattenObject(editedFields) || {};
      if (handler === 'saveData') {
        Object.entries(updatedData).map(([key, value]) => {
          if (originalData && originalData[key] !== value) {
            dataChanged = true;
          }
        });
      }
    }
    if (dataChanged) {
      saveChanges(renderedData.id || mainId);
    }
  };
  useEffect(() => {
    (async () => {
      if (fieldsValues && renderedData) {
        if (!_.isEmpty(defaultEdits)) {
          for await (const field of _.get(layoutMeta, 'dataEntry.fields', [])) {
            if (field.dataSource && field.attribute.includes('Relation')) {
              const [mainAttribute, subAttributes] = divideNestedAttribute(
                field.attribute
              );
              if (
                defaultEdits[mainAttribute] &&
                Object.keys(defaultEdits[mainAttribute]).length === 1 &&
                defaultEdits[mainAttribute]['id']
              ) {
                const query = genericQuery(
                  field.dataSource.slice(0, -1),
                  subAttributes.split(' '),
                  `id:"${defaultEdits[mainAttribute]['id']}"`
                );
                const { data } = await apolloClient.query({ query });
                defaultEdits[mainAttribute] =
                  data[field.dataSource.slice(0, -1)];
              }
            }
          }
        }
        const data = { ...renderedData, ...fieldsValues, ...defaultEdits };
        if (itemId === 'deleted') {
          data.delD = true;
        }
        setRenderedData(data);
      }
      setMainId(itemId);
    })();
    return () => {
      setRenderedData({});
    };
  }, [itemId]);

  useEffect(() => {
    dispatcher.on('saveData', (handler: any) => {
      onSaveData({ fieldsValues, renderedData });
      console.log('Dispatch', history.location.state);
      // history.location.state = {};
    });
    return () => {
      dispatcher.off('saveData');
      dispatcher.off('delete');
      window.onbeforeunload = null;
    };
  }, [fieldsValues, renderedData]);

  useEffect(() => {
    const updated = !_.isEmpty(editedFields);
    dispatcher.dispatch('fieldsUpdated', updated);
    if (!_.isEmpty(editedFields)) {
      window.onbeforeunload = () => 'Are you sure you want to leave?';
    }
  }, [renderedData]);

  return (
    <div className='editable-fields'>
      {layoutMeta.tab ? (
        <DetailedView
          itemId={mainId}
          layoutMeta={layoutMeta}
          fieldsValues={fieldsValues}
          renderedData={renderedData}
          setRenderedData={setRenderedData}
          fieldClickHandler={fieldClickHandler}
        />
      ) : (
        <RegularView
          layoutMeta={layoutMeta}
          fieldsValues={fieldsValues}
          renderedData={renderedData}
          setRenderedData={setRenderedData}
          fieldClickHandler={fieldClickHandler}
          renderAlertBar={renderAlertBar}
          editedFields={editedFields}
          setEditedFields={setEditedFields}
        />
      )}
    </div>
  );
};

export default EditableFields;
