import _ from 'lodash';
import mapValuesDeep from 'deepdash/mapValuesDeep';
import {
  ADD_TABLE_LIST,
  ADD_TABLE_FIELD_LIST,
  ADD_QUERY_LIST,
  ADD_ENTITIES,
  ADD_ENTITY_ITEM,
  UPDATE_ENTITY_ITEM,
  DELETE_ENTITY_ITEM,
  ADD_CRITERIAS,
  ADD_CRITERIA_ITEM,
  UPDATE_CRITERIA_ITEM,
  DELETE_CRITERIA_ITEM,
  ADD_CURRENT_TABLE,
  ADD_CURRENT_FORMAT_LIST,
  ADD_FORMATS,
  ADD_FORMAT_ITEM,
  DELETE_FORMAT_ITEM,
  UPDATE_FORMAT_ITEM,
  ADD_TABLE_COUNTER
} from '../types';
import {remove} from '../../utils';

const initialState = {
  tableList: [],
  tableFieldList: [],
  queryList: [],
  entities: [],
  criterias: [],
  currentTable: null,
  currentFormatList: null,
  formats: [],
  tableCounter: 0
};

const addTableList = (state, payload) => ({
  ...state,
  tableList: payload,
});

const addTableFieldList = (state, payload) => ({
  ...state,
  tableFieldList: payload,
});

const addQueryList = (state, payload) => ({
  ...state,
  queryList: payload,
});

const addEntities = (state, payload) => ({
  ...state,
  entities: payload,
});

const addEntityItem = (state, payload) => ({
  ...state,
  entities: [...state.entities, payload],
});

const updateEntityItem = (state, payload) => {
  return {
    ...state,
    entities: state.entities.map((item) =>
      item.id === payload.id ? payload : item
    ),
  };
};

const deleteEntityItem = (state, payload) => ({
  ...state,
  entities: state.entities.filter((item) => item.id !== payload),
});

const addCriterias = (state, payload) => ({
  ...state,
  criterias: payload,
});

const addCriteriaItem = (state, payload) => {
  const {data, path} = payload;
  const lastPath = path[path.length - 1];
  const pathForAdded = path[path.length - 2];

  const addCriterias = mapValuesDeep(
    _.cloneDeep(state.criterias),
    (child) => {
      if (child.id === pathForAdded) {
        const children = _.get(child, 'children', []);
        return {...child, children: [...children, ...[data]]};
      } else {
        return child;
      }
    },
    {childrenPath: 'children'}
  );

  return {
    ...state,
    criterias: addCriterias,
  };
};

const updateCriteriaItem = (state, payload) => {
  const changeCriterias = mapValuesDeep(
    _.cloneDeep(state.criterias),
    (child) => {
      if (child.id === payload.id) {
        return payload;
      } else {
        return child;
      }
    },
    {childrenPath: 'children'}
  );
  return {
    ...state,
    criterias: changeCriterias,
  };
};

const deleteCriteriaItem = (state, payload) => {
  const deletedCriterias = remove(_.cloneDeep(state.criterias), payload.id);
  return {
    ...state,
    criterias: deletedCriterias,
  };
};

const addCurrentTable = (state, payload) => ({
  ...state,
  currentTable: payload,
});

const addCurrentFormatList = (state, payload) => ({
  ...state,
  currentFormatList: payload,
});

const addFormats = (state, payload) => ({
  ...state,
  formats: payload,
});

const addFormatItem = (state, payload) => {
  const child = state.formats[0];
  const children = _.get(child, 'children', []);
  const currentFormats = [{...child, children: [...children, ...[payload]]}];
  return {
    ...state,
    formats: currentFormats
  }
};

const updateFormatItem = (state, payload) => {
  const child = state.formats[0];
  const children = _.get(child, 'children', []).map(item =>  item.id === payload.id ? payload : item);
  const currentFormats = [{...child, children: children}];
  return {
    ...state,
    formats: currentFormats
  }
};

const deleteFormatItem = (state, payload) => {
  const child = state.formats[0];
  const children = _.get(child, 'children', []).filter(item => item.id !== payload.id);
  const currentFormats = [{...child, children: children}];
  return {
    ...state,
    formats: currentFormats
  }
}

const addTableCounter = (state, payload) => ({
  ...state,
  tableCounter: payload,
});

const handlers = {
  [ADD_TABLE_LIST]: addTableList,
  [ADD_TABLE_FIELD_LIST]: addTableFieldList,
  [ADD_QUERY_LIST]: addQueryList,
  [ADD_ENTITIES]: addEntities,
  [ADD_ENTITY_ITEM]: addEntityItem,
  [UPDATE_ENTITY_ITEM]: updateEntityItem,
  [DELETE_ENTITY_ITEM]: deleteEntityItem,
  [ADD_CRITERIAS]: addCriterias,
  [ADD_CRITERIA_ITEM]: addCriteriaItem,
  [UPDATE_CRITERIA_ITEM]: updateCriteriaItem,
  [DELETE_CRITERIA_ITEM]: deleteCriteriaItem,
  [ADD_CURRENT_TABLE]: addCurrentTable,
  [ADD_CURRENT_FORMAT_LIST]: addCurrentFormatList,
  [ADD_FORMATS]: addFormats,
  [ADD_FORMAT_ITEM]: addFormatItem,
  [UPDATE_FORMAT_ITEM]: updateFormatItem,
  [DELETE_FORMAT_ITEM]: deleteFormatItem,
  [ADD_TABLE_COUNTER]: addTableCounter
};

export default (state = initialState, action) => {
  const handler = handlers[action.type];
  const newState = handler ? handler(state, action.payload) : state;
  return newState;
};
