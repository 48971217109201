import React, { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {
  AvField,
  AvInput,
  AvGroup,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { TextEditor } from '../../../components';
import moment from 'moment';

const InputRender = ({
  caption,
  className,
  defCol,
  keyProp,
  defaultValue,
  onChange,
  field,
  renderedData,
  setRenderedData,
}) => {
  const attribute =
    field.attribute && field.attribute.includes('primaryCdmcommRelation')
      ? 'primaryCdmcommRelation'
      : field.attribute;
  const dateLimitation = new Date().setFullYear(new Date().getFullYear() - 10);
  const {
    inputType,
    type,
    captionSize,
    disabled,
    validationError,
    validation,
    selectOptions,
    measuredIn,
  } = field;
  useEffect(() => {}, [renderedData]);
  return (
    <Row key={keyProp} className='editData-row'>
      {caption ? <Col sm={captionSize - 2 || 3}>{caption}</Col> : null}
      <Col sm={caption ? defCol : 12}>
        {type === 'textAreaPlain' ? (
          <>
            {renderedData['primaryCdmcommRelation'] ? (
              <TextEditor
                editingBar={
                  renderedData['primaryCdmcommRelation'].com === '12'
                    ? true
                    : false
                }
                field={field}
                text={{
                  attribute,
                  value: renderedData['primaryCdmcommRelation'],
                }}
                renderedValues={renderedData}
                setRenderedValues={setRenderedData}
              />
            ) : (
              <Form.Control
                as='textarea'
                className={`form-control ${className}`}
                disabled={disabled}
                rows={4}
                style={{ maxHeight: '200px' }}
                value={defaultValue}
                onChange={onChange}
              />
            )}
          </>
        ) : caption ? (
          <AvGroup>
            <Row>
              <Col sm={measuredIn ? 10 : 12}>
                <AvInput
                  key={`input-form-${keyProp}-${caption}`}
                  name={
                    `${inputType}-${caption}` || `${inputType}-${attribute}`
                  }
                  type={inputType || 'text'}
                  errorMessage={validationError}
                  validate={{
                    required: {
                      value: validation ? true : false,
                    },
                  }}
                  disabled={disabled ? disabled : false}
                  className={`form-control ${className}`}
                  defaultValue={defaultValue}
                  onChange={onChange}
                  min={
                    inputType === 'date'
                      ? attribute === 'endDate'
                        ? renderedData.startDate
                        : dateLimitation
                      : null
                  }
                >
                  {selectOptions &&
                    selectOptions.map((selectOption) => (
                      <option>{selectOption}</option>
                    ))}
                </AvInput>
              </Col>
              {measuredIn && <Col>{measuredIn}</Col>}
            </Row>
            <AvFeedback>
              {inputType === 'date'
                ? `Date value must not be less than ${moment(
                    dateLimitation,
                  ).format('MMM DD YYYY')}`
                : 'This field is invalid'}
            </AvFeedback>
          </AvGroup>
        ) : null}
      </Col>
    </Row>
  );
};

export default InputRender;
