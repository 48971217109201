import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';

const AlertBar = ({ showAlertBar, hideAlertBar, alertText, color }) => {
  const [visible, setVisible] = useState(true);

  return (
    <>
      {visible ? (
        <Alert
          color={color}
          className='mb-0 alert-bar'
          style={{ zIndex: '10000' }}
          toggle={() => {
            if (hideAlertBar) {
              hideAlertBar();
            }
            setVisible(!visible);
          }}
        >
          <p
            dangerouslySetInnerHTML={{
              __html:
                alertText && alertText
                  ? alertText.replaceAll('\\n', '<br/>')
                  : ''
            }}
          ></p>
        </Alert>
      ) : null}
    </>
  );
};

export default AlertBar;
