import React, { useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
//@ts-ignore
import FileViewer from 'react-file-viewer';
import Editor from '@monaco-editor/react';
import { ErrorBoundary } from '../../components';
import MailPreview from './MailPreview';
//@ts-ignore
import canvasDatagrid from 'canvas-datagrid';
const DocumentPreview = ({
  preview,
  onHide
}: {
  preview: {
    show: boolean;
    head: string;
    docExt: string;
    blob: Blob;
  };
  onHide: Function;
}) => {
  const [txtValue, setTxtValue] = useState<string | null>();
  const [url] = useState(URL.createObjectURL(preview.blob));
  preview.head = preview.head.replaceAll('%20', ' ');
  const { head, docExt, blob } = preview;
  const history = useHistory();
  const onFileDownload = () => {
    if (docExt === 'csv') {
      fetch(url)
        .then((res) => res.text())
        .then((txt) => {
          downloadFile(
            'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + txt)
          );
        });
    } else {
      downloadFile(url);
    }
  };
  const downloadFile = (href = url) => {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${head}.${docExt}`);
    document.body.appendChild(link);
    link.click();
  };
  const renderPreview = (docExt: string) => {
    if (docExt) {
      if (docExt === 'txt') {
        fetch(url)
          .then((res) => res.text())
          .then((text) => setTxtValue(text))
          .catch((err) => console.log(err));
        if (txtValue) {
          return (
            <Editor
              height='20vh'
              defaultLanguage='txt'
              defaultValue={txtValue || ''}
              options={{ readOnly: true }}
            />
          );
        }
      }
      if (docExt === 'msg') {
        return <MailPreview blob={blob} />;
      }
      if (docExt === 'pdf') {
        const pdfBlob = new Blob([blob], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        return (
          <div style={{ height: '100%' }}>
            <ErrorBoundary>
              <FileViewer fileType={docExt} filePath={pdfUrl} />
            </ErrorBoundary>
          </div>
        );
      }
      if (docExt === 'xlsx' || docExt === 'xls') {
        blob.arrayBuffer().then((arrayBuffer) => {
          const buffer = arrayBuffer;
          const parentNode = document.getElementById('preview');
          if (parentNode?.innerHTML === '') {
            let grid = canvasDatagrid({
              parentNode
            });
            const wb = XLSX.read(buffer, {
              type: 'buffer'
            });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let data: any = XLSX.utils.sheet_to_json(ws, {
              header: 1
            });
            grid.style.height = '100%';
            grid.style.width = '100%';
            let L = 0;
            data.forEach(function (r: any) {
              if (L < r.length) L = r.length;
            });
            for (let i = data[0].length; i < L; ++i) {
              data[0][i] = '';
            }
            grid.data = data;
          }
        });
        return (
          <div style={{ height: '100%' }}>
            <div id='preview'></div>
          </div>
        );
      }
      try {
        return (
          <div style={{ height: '100%' }}>
            <ErrorBoundary>
              <FileViewer fileType={docExt} filePath={url} />
            </ErrorBoundary>
          </div>
        );
      } catch (error) {
        console.log(error);
        return <></>;
      }
    }
  };
  useEffect(() => {
    return () => {
      onHide();
      setTxtValue(null);
    };
  }, [history.location.search]);
  return (
    <div style={{ height: '50rem' }}>
      <div
        className={`${
          window.innerWidth < 768 ? 'col' : 'row justify-content-between'
        }`}
      >
        <h3>{`${head}.${docExt}`}</h3>
        <div className='d-flex'>
          <Button onClick={onFileDownload} style={{ marginBottom: '15px' }}>
            Download
          </Button>
        </div>
      </div>

      {renderPreview(docExt)}
    </div>
  );
};

export default DocumentPreview;
