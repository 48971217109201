import { IFormFullGrid, IUser } from '../types';
import moment, { Moment } from 'moment';

export class GridClass {
  public grid: IFormFullGrid;
  public user: IUser;

  constructor(grid: IFormFullGrid, user: IUser) {
    this.grid = grid;
    this.user = user;
  }

  protected filterByDate(attribute: string) {
    const param = attribute.split(':');
    const numberOfDays = param[1].split('-')[1];
    let filteredDate: Moment = moment(new Date(), 'DD-MM-yyyy');
    let date = '';
    if (param[1].includes('addDays')) {
      date = filteredDate.add(numberOfDays, 'days').format('DD-MM-yyyy');
    } else if (param[1].includes('subDays')) {
      date = filteredDate.subtract(numberOfDays, 'days').format('DD-MM-yyyy');
    }
    return ` ${param[0]}:"${date}" `;
  }

  public getFilterExpression(
    filteredId: string,
    defaultFilterExpression?: string
  ) {
    if (defaultFilterExpression) return defaultFilterExpression;
    const { filterExpression, orderBy } = this.grid;
    let filter = filterExpression
      ? filterExpression
          .split(',')
          .map((key: string) => {
            if ((key.includes('prim') || key === 'id') && !key.includes(':')) {
              return filteredId ? `${key}:"${filteredId}"` : '';
            } else if (key.includes('addDays') || key.includes('subDays')) {
              return this.filterByDate(key);
            } else {
              return key;
            }
          })
          .join(',')
      : '';
    if (orderBy) {
      filter += ` ,orderBy:${orderBy}, `;
    }
    return filter;
  }
}
