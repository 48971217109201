import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import MetisMenu from 'metismenujs';
import SimpleBar from 'simplebar-react';
import _ from 'lodash';
import { useLocalStorage } from '@rehooks/local-storage';
import {
  updateMetadataList,
  updateFormatListForNavigation,
  getDefaultDataSourceFromFormat
} from '../../utils';
import { IconWrapper } from '../../components';
import {
  getScreenSize,
  getNavigationVisibility,
  setCatchedError
} from '../../redux';

const Navigation = ({
  screenSize,
  isnavigationVisible,
  history,
  setCatchedError,
  ...props
}) => {
  const [metadatasList] = useLocalStorage('metadatasList');
  const [tableFormatList] = useLocalStorage('tableFormatList');

  const [menuAttributes, setMenuAttributes] = useState([]);
  const [isMenuAttributes, setIsMenuAttributes] = useState(false);

  useEffect(() => {
    if (isMenuAttributes) {
      initMenu();
      setIsMenuAttributes(false);
    }
  }, [isMenuAttributes]);

  useEffect(() => {
    if (metadatasList && metadatasList.length) {
      if (_.find(metadatasList, ['fileName', 'Mnu.main.json'])) {
        setMenuAttributes(
          _.find(metadatasList, ['fileName', 'Mnu.main.json']).data.menu
            .attributes
        );
      }
      setIsMenuAttributes(true);
    }
  }, [metadatasList]);

  const activateParentDropdown = (item) => {
    const parent = item.parentElement;

    if (parent) {
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');
      }
      return false;
    }
    return false;
  };

  const selectNavItem = async (item) => {
    if (item.form) {
      let itemValue;
      if (item.form.includes('AppointmentCalendar')) {
        itemValue = 'ActivityDetail';
      } else {
        itemValue = item.form;
      }

      if (_.find(metadatasList, ['fileName', `Frm.${itemValue}.json`])) {
        if (itemValue === 'ActivityDetail') {
          history.push({
            pathname: item.form,
            state: { showListModal: item.modalList }
          });
        } else {
          const success = await updateFormatListForNavigation(
            itemValue,
            [],
            setCatchedError
          );
          if (success) {
            const defaultDataSource = getDefaultDataSourceFromFormat(itemValue);
            if (
              item.modalList ||
              history.location.pathname !== `/${item.form}`
            ) {
              history.push({
                pathname: item.form,
                state: {
                  showListModal: item.modalList,
                  defaultDataSource: defaultDataSource
                }
              });
            }
          }
        }
      } else {
        try {
          const successMetadata = await updateMetadataList(
            `Frm.${itemValue}.json`,
            metadatasList
          );
          if (itemValue === 'ActivityDetail') {
            if (successMetadata) {
              history.push({
                pathname: item.form,
                state: {
                  showListModal: item.modalList
                }
              });
            }
          } else {
            const successFormat = await updateFormatListForNavigation(
              itemValue,
              successMetadata,
              setCatchedError
            );
            if (successMetadata && successFormat) {
              const defaultDataSource =
                getDefaultDataSourceFromFormat(itemValue);
              history.push({
                pathname: item.form,
                state: {
                  showListModal: item.modalList,
                  defaultDataSource: defaultDataSource
                }
              });
            }
          }
        } catch (err) {
          setCatchedError(`${err.message}`);
        }
      }
    }
    if (item.handleCreate) {
      if (
        _.find(metadatasList, ['fileName', `Frm.${item.handleCreate}.json`])
      ) {
        const success = await updateFormatListForNavigation(
          item.handleCreate,
          [],
          setCatchedError
        );
        if (success) {
          history.push({
            pathname: `${item.handleCreate}-create`
          });
        }
      } else {
        try {
          const successMetadata = await updateMetadataList(
            `Frm.${item.handleCreate}.json`,
            metadatasList
          );
          const successFormat = await updateFormatListForNavigation(
            item.handleCreate,
            successMetadata,
            setCatchedError
          );
          if (successMetadata && successFormat) {
            history.push({
              pathname: `${item.handleCreate}-create`
            });
          }
        } catch (err) {
          props.setCatchedError(`${err.message}`);
        }
      }
    }
    if (screenSize === 'XS' || screenSize === 'SM') {
      document.getElementById('vertical-menu-btn').click();
    }
  };

  const initMenu = () => {
    if (props.type !== 'condensed' || screenSize === 'XS') {
      new MetisMenu('#side-menu');

      let matchingMenuItem = null;
      let ul = document.getElementById('side-menu');
      let items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  };

  return (
    <div className='navigation'>
      <div className='vertical-menu'>
        <div data-simplebar className='h-100'>
          <div id='sidebar-menu'>
            <ul className='metismenu list-unstyled' id='side-menu'>
              {menuAttributes.map((attribute, index) => (
                <li key={`${attribute.caption}-${index}`}>
                  <a className='nav-main-section has-arrow waves-effect'>
                    <IconWrapper iconName={attribute.icon} />
                    <span>{attribute.caption}</span>
                  </a>
                  <ul className='sub-menu' aria-expanded='false'>
                    {attribute.attributes &&
                      attribute.attributes.map((attr, i) =>
                        attr.form ? (
                          <li
                            key={`${attr.form}-${index}-${i}`}
                            className={'nav-item-main'}
                          >
                            <Nav.Link
                              key={`${attr.caption}-${attr.form}-${index}-${i}`}
                              onClick={() => selectNavItem(attr)}
                            >
                              <div className='nav-icon-link'>
                                <IconWrapper
                                  iconName={attr.icon}
                                  iconSize={32}
                                />
                              </div>
                              {attr.caption}
                            </Nav.Link>
                          </li>
                        ) : (
                          <li className={'nav-item-main'}>
                            <Nav.Link to='/#' className='has-arrow'>
                              <div className='nav-icon-link'>
                                <IconWrapper
                                  iconName={attr.icon}
                                  iconSize={32}
                                />
                              </div>
                              <span className={'nav-item-main_heading'}>
                                {attr.caption}
                              </span>
                            </Nav.Link>
                            <ul className='sub-menu'>
                              {attr.subMenu.map((subMenuEntry, index) => (
                                <li key={`${subMenuEntry.form}-${index}-${i}`}>
                                  <Nav.Link
                                    key={`${subMenuEntry.caption}-${subMenuEntry.form}-${index}-${i}`}
                                    onClick={() => selectNavItem(subMenuEntry)}
                                  >
                                    <div className='nav-icon-subitem'>
                                      <IconWrapper
                                        iconName={subMenuEntry.icon}
                                      />
                                      {subMenuEntry.caption}
                                    </div>
                                  </Nav.Link>
                                </li>
                              ))}
                            </ul>
                          </li>
                        )
                      )}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  screenSize: getScreenSize(state),
  isnavigationVisible: getNavigationVisibility(state)
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCatchedError
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navigation));
