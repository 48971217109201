import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useApolloClient } from 'react-apollo';
import { genericQuery } from '../../GraphQL';
import { TFieldsValues, IEditableField } from '../../types';
import { divideNestedAttribute, graphQLDateFormat } from '../../utils';
import { RegularView } from '../EditableFields/Components';

const DialogModal = ({
  showModal,
  setShowModal,
  handlers,
  meta,
  onConfirm,
  defaultOnDiscard
}: any) => {
  const apolloClient = useApolloClient();

  const [inputValues, setInputValues] = useState<TFieldsValues>({});
  const [editedFields, setEditedFields] = useState(inputValues);
  const onHide = () => {
    defaultOnDiscard ? defaultOnDiscard() : setShowModal();
  };
  const handleClick = (handler: string) => {
    if (handlers && handlers[handler]) {
      handlers[handler]?.();
    }
    onHide();
  };
  const getDefaultInputValues = async () => {
    const defaultValues: TFieldsValues = {};
    await Promise.all(
      meta.fields.map(
        async (field: {
          attribute: string;
          value: string | number | boolean;
          dataSource: string | undefined;
        }) => {
          const { attribute, value, dataSource } = field;
          const [mainAttribute, subAttributes] =
            divideNestedAttribute(attribute);
          if (dataSource && value) {
            const query = genericQuery(
              dataSource.slice(0, -1),
              subAttributes.split(' '),
              value ? `id:"${value}"` : ''
            );
            const { data } = await apolloClient.query({ query });
            defaultValues[mainAttribute] = data[dataSource.slice(0, -1)];
            defaultValues[mainAttribute.replace('Relation', '')] =
              defaultValues[mainAttribute].id;
          } else {
            defaultValues[mainAttribute] = value;
          }
        }
      )
    );
    setInputValues(defaultValues);
  };
  const prepareData = (data: { [key: string]: any }) => {
    if (meta.fields) {
      const savedData: TFieldsValues = {};
      meta.fields.map(({ attribute, inputType }: IEditableField) => {
        if (attribute.includes('Relation')) {
          const [mainAttribute] = divideNestedAttribute(attribute);
          savedData[attribute] = inputValues[mainAttribute]?.id;
        } else if (inputType === 'date') {
          savedData[attribute] = graphQLDateFormat(inputValues[attribute]);
        } else {
          savedData[attribute] = inputValues[attribute];
        }
      });
      return savedData;
    }
    return data;
  };
  useEffect(() => {
    if (meta.fields) {
      (async () => {
        await getDefaultInputValues();
      })();
    }
  }, []);
  return (
    <Modal className={'editing-modal'} show={showModal} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>{meta?.caption || ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='tab-component'>
        {meta?.fields ? (
          <div className='edit-wrapper'>
            <RegularView
              fieldsValues={{}}
              renderedData={inputValues}
              setRenderedData={setInputValues}
              layoutMeta={{ caption: '', dataEntry: { ...meta } }}
              editedFields={editedFields}
              setEditedFields={setEditedFields}
            />
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html:
                meta && meta.fieldText
                  ? meta?.fieldText.replaceAll('\\n', '<br/>')
                  : ''
            }}
          ></div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {meta.group ? (
          meta.group[0].entries.map(
            (button: { variant: string; handler: string; caption: string }) => (
              <Button
                variant={button.variant || 'secondary'}
                onClick={() => handleClick(button.handler)}
              >
                {button.caption}
              </Button>
            )
          )
        ) : (
          <>
            <Button
              variant='primary'
              onClick={() => onConfirm(prepareData(inputValues))}
            >
              Ok
            </Button>
            <Button variant='secondary' onClick={() => setShowModal(false)}>
              Close
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DialogModal;
