import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';

import { getSubscriptionsDB, getCurrentDB } from '../../../redux';

const ChangeDatabase = ({
  handleChangeDatabase,
  titleModalVisible,
  setTitleModalVisible,
  subscriptionsDB,
  currentDB,
}: any) => {
  const [options, setOptions] = useState([]);
  const [defaultValue, setDefaultValue] = useState({
    value: null,
    label: null,
  });
  const [activeOption, setActiveOption] = useState({
    value: null,
    label: null,
  });

  useEffect(() => {
    const data = subscriptionsDB.map((item: any) => ({
      value: _.get(item, 'databaseName', ''),
      label: _.get(item, 'databaseName', ''),
    }));

    setOptions(data);
    setDefaultValue(_.find(data, ['value', currentDB]));
    setActiveOption(_.find(data, ['value', currentDB]));
  }, [subscriptionsDB, currentDB]);

  const handleChange = (newValue: any, actionMeta: any) => {
    if (!_.isEmpty(newValue)) {
      setActiveOption(newValue);
    }
  };

  return (
    <Modal
      centered
      show={titleModalVisible}
      onHide={() => setTitleModalVisible(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Database</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          isSearchable
          placeholder={'Select DB'}
          onChange={handleChange}
          options={options}
          defaultValue={defaultValue}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setTitleModalVisible(false)}>
          Close
        </Button>
        <Button
          variant='primary'
          onClick={() => handleChangeDatabase(activeOption.value)}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  subscriptionsDB: getSubscriptionsDB(state),
  currentDB: getCurrentDB(state),
});
const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDatabase);
