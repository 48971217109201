import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useApolloClient } from 'react-apollo';
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import { clearCache, updateMetadataList } from '../../utils';
import _ from 'lodash';
import { googleSignOut } from '../../helpers';
import { logout } from '../../GraphQL';
import { changeConnectionConfig } from '../../GraphQL';
import { SubMenuDropdown, ChangeDatabase } from './components';
import { IconWrapper } from '../../components';
import {
  setCatchedError,
  getCurrentDB,
  addCurrentDB,
  getSubscriptionsDB,
  getIsMetadataChange,
  addMetadataForEdit,
  getMetadataForEdit,
  addAzureFileList,
  loadUser,
  authorizationType,
  getUser,
  subscriptionDB
} from '../../redux';

const MenuDropdown = ({
  item,
  loadUser,
  authorizationType,
  subscriptionDB,
  user,
  authType,
  setCatchedError,
  currentDB,
  addCurrentDB,
  subscriptionsDB
}: any) => {
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(!menu);
  };

  const [metadatasList] = useLocalStorage('metadatasList');
  const { entries, icon } = item;
  const apolloClient = useApolloClient();
  const history = useHistory();
  const [titleModalVisible, setTitleModalVisible] = useState(false);
  const [isFileManagerDisabled, setIsFileManagerDisabled] = useState(false);

  const handleLogout = async () => {
    //temporary logout function
    const logoutMutation = await logout();
    await apolloClient
      .mutate({ mutation: logoutMutation })
      .then((res) => {
        console.log('Logout success');
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
    if (authType === 'google') {
      googleSignOut();
    }
    clearCache();
    loadUser(null);
    subscriptionDB([]);
    authorizationType(null);
    history.push('/auth');
  };

  const selectSettingItem = async (item: string) => {
    if (_.find(metadatasList, ['fileName', `Frm.${item}.json`])) {
      history.push(`/UserSettings-${localStorage.getItem('id') || user.id}`);
    } else {
      try {
        const successMetadata = await updateMetadataList(
          `Frm.${item}.json`,
          metadatasList
        );
        if (successMetadata) {
          history.push(
            `/UserSettings-${localStorage.getItem('id') || user.id}`
          );
        }
      } catch (err) {
        setCatchedError(`${err.message}`);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('permission')) {
      let permissionArr = localStorage.getItem('permission')?.split(',');
      if (
        !permissionArr?.includes('administrator') ||
        !permissionArr?.includes('system')
      ) {
        setIsFileManagerDisabled(true);
      }
    } else {
      setIsFileManagerDisabled(true);
    }
  }, []);

  const selectItem = async (item: string) => {
    if (_.find(metadatasList, ['fileName', `Frm.${item}.json`])) {
      history.push(`/${item}`);
    } else {
      try {
        const successMetadata = await updateMetadataList(
          `Frm.${item}.json`,
          metadatasList
        );
        if (successMetadata) {
          history.push(`/${item}`);
        }
      } catch (err) {
        setCatchedError(`${err.message}`);
      }
    }
  };

  const handleOpenChangeDatabase = () => {
    setTitleModalVisible(true);
  };

  const handleChangeDatabase = async (dbName: any) => {
    if (dbName !== currentDB) {
      addCurrentDB(dbName);
      setTitleModalVisible(false);
      const changeDBNameMutations = await changeConnectionConfig(dbName);
      apolloClient
        .mutate({ mutation: changeDBNameMutations })
        .then((res) => {
          history.push('/SalesHome');
        })
        .catch((err) => {
          setCatchedError(`${err.message}`);
        });
    }
  };

  const handleOpenFileManager = () => {
    history.push('/fileExplorer');
  };

  const checkPermission = (key: string): boolean => {
    switch (key) {
      case 'change_database':
        return !(subscriptionsDB.length <= 1);

      case 'filesystem':
        return !isFileManagerDisabled;

      default:
        return false;
    }
  };

  const toolbarAction = ({
    form,
    handler,
    link,
    state
  }: {
    form: string;
    handler: string;
    link: string;
    state: { [k: string]: any };
  }) => {
    if (form) {
      switch (form) {
        case 'UserSettings':
          selectSettingItem('UserSettings');
          break;
        case 'SystemSettings':
          selectItem('SystemSettings');
          break;
        case 'FileExplorer':
          if (!isFileManagerDisabled) handleOpenFileManager();
          break;
        default:
          history.push({ pathname: `/${form}`, state });
          break;
      }
    } else if (handler) {
      switch (handler) {
        case 'logout':
          handleLogout();
          break;
        case 'shortcuts':
          history.push('/Shortcuts');
          break;
        case 'ChangeDatabase':
          if (!(subscriptionsDB.length <= 1)) handleOpenChangeDatabase();
          break;
        default:
          break;
      }
    } else if (link) {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <React.Fragment>
      <ChangeDatabase
        handleChangeDatabase={handleChangeDatabase}
        titleModalVisible={titleModalVisible}
        setTitleModalVisible={setTitleModalVisible}
      />
      <Dropdown isOpen={menu} toggle={toggle} className='d-inline-block'>
        <DropdownToggle
          className='btn header-item waves-effect'
          id='page-header-dropdown'
          tag='button'
        >
          <div className='rounded-circle'>
            <IconWrapper iconName={icon} iconSize={24} />
          </div>
        </DropdownToggle>
        <DropdownMenu right>
          {entries?.map((entry: any, idx: number) => {
            if (entry.type === 'dropdown') {
              return (
                <SubMenuDropdown
                  key={`${entry.caption}-${entry.icon}`}
                  entry={entry}
                  toolbarAction={toolbarAction}
                  checkPermission={checkPermission}
                />
              );
            }
            return entry.type === 'profile' ? (
              <DropdownItem
                key={`${entry.caption}-${entry.icon}`}
                className={'header-menu'}
                tag='a'
                href='#'
              >
                <IconWrapper
                  className={'align-middle mr-1'}
                  iconName={icon}
                  iconSize={16}
                />

                {localStorage.getItem('userName') ||
                  `${_.get(user, 'firstN', '')} ${_.get(user, 'lastN', '')}`}
              </DropdownItem>
            ) : (
              <DropdownItem
                key={`${entry.caption}-${entry.icon}`}
                tag='a'
                href='#'
                disabled={
                  entry.check_permission
                    ? !checkPermission(entry.permission_key)
                    : false
                }
                onClick={() =>
                  toolbarAction({
                    form: entry.form,
                    handler: entry.handler,
                    link: entry.link,
                    state: entry.state
                  })
                }
              >
                <IconWrapper
                  className={'align-middle mr-1'}
                  iconName={entry.icon}
                  iconSize={16}
                />
                {entry.caption}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  user: getUser(state),
  authType: state.authorization.authType,
  currentDB: getCurrentDB(state),
  subscriptionsDB: getSubscriptionsDB(state),
  isMetadataChange: getIsMetadataChange(state),
  metadataForEdit: getMetadataForEdit(state)
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      setCatchedError,
      addCurrentDB,
      addMetadataForEdit,
      addAzureFileList,
      loadUser,
      authorizationType,
      subscriptionDB
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDropdown);
