import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import _ from 'lodash';

import { QueryTranslatedHeading } from './';

const ModalCreateEntry = ({ showModal, setShowModal }) => {
    const [activeType, setActiveType] = useState(null);
    const [entryType, setEntryType] = useState([
        { label: 'Interpreted (simple)', value: 'interpreted_Simple' },
        { label: 'Simple', value: 'Simple' },
    ]);

    const handleChangeEntryType = (newValue, actionMeta) => {
        if (newValue) {
            setActiveType(newValue);
        }
    };

    return (
        <Modal
            enforceFocus={false}
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    isSearchable
                    placeholder={'Select type'}
                    onChange={handleChangeEntryType}
                    options={entryType}
                    value={activeType}
                    // styles={selectCustomStyles}
                />
                <QueryTranslatedHeading
                    type={'entity'}
                    valueLabel={'Heading'}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setShowModal(false)}>
                    OK
                </Button>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCreateEntry;
