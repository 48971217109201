import React from 'react';

import { BLOCK_TYPES, INLINE_STYLES } from './constants';
export const CustomComponent = ({ children }: any) => {
  return <div>{children}</div>;
};

export const StyleButton = ({ style, onToggle, active, label }: any) => {
  const onMouseDown = (e: any) => {
    e.preventDefault();
    onToggle(style);
  };

  const className = !active
    ? 'RichEditor-styleButton'
    : 'RichEditor-styleButton RichEditor-activeButton';

  return (
    <i
      className={`material-icons ${className} ${label}`}
      onMouseDown={onMouseDown}
    ></i>
  );
};

export const BlockStyleControls = ({ editorState, onToggle }: any) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className='RichEditor-controls'>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export const InlineStyleControls = ({ editorState, onToggle }: any) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className='RichEditor-controls'>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
