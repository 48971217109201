import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, withRouter, RouteComponentProps } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'react-bootstrap';
import { IGridItem, IMobileGrid, TFieldsValues } from '../../types';
import { scrollAction, getMetaData } from '../../redux';
import Card from './Card';

const DetailsForMobile: FC<IMobileGrid> = (props: TFieldsValues) => {
  const history = useHistory();
  const { data, hasMore, metadata, tableFormat } = props;

  return (
    <div className='detailes-for-phone'>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          scrollAction();
        }}
        useWindow={false}
        hasMore={hasMore}
        loader={
          <div className='loader' key={0}>
            <Spinner animation='grow' variant='secondary' />
          </div>
        }
      >
        {data &&
          data.map((el: IGridItem, index: number) =>
            tableFormat.fields ? (
              <Card
                {...props}
                key={`data-card-${index}`}
                data={el}
                itemPosition={index}
                onClick={() => {
                  history.push({
                    pathname: `/${metadata.selectForm}-${el.id}`,
                    state: { id: el.id }
                  });
                }}
              />
            ) : (
              ''
            )
          )}
      </InfiniteScroll>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      scrollAction
    },
    dispatch
  );
};

const mapStateToProps = (state: any) => {
  return {
    metadata: getMetaData(state)
  };
};

export default withRouter<IMobileGrid & RouteComponentProps<{}>, any>(
  connect<any, any>(
    mapStateToProps,
    mapDispatchToProps
  )(DetailsForMobile) as any
);
