import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ModalTextArea = ({ text, modalVisible, setModalVisible }) => {
  return (
    <Modal centered show={modalVisible} onHide={() => setModalVisible(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Sql Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='controlTextarea1'>
            <Form.Control
              disabled={true}
              as='textarea'
              rows={3}
              defaultValue={text}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={() => setModalVisible(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTextArea;
